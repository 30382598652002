import * as Moment from 'moment-timezone';
import moment from 'moment-timezone';
import { IBookingDashboard } from '../api/dashboard.api';
import BookingStatusType from '../enums/BookingStatusType';

// fromTime and toTime should be in HH:mm format
export function generateTimes(fromTime: string, toTime: string): string[] {
  const from = moment(`2000/01/01 ${fromTime || '10:00'}`, 'YYYY/MM/DD HH:mm');
  const to = moment(`2000/01/01 ${toTime || '23:45'}`, 'YYYY/MM/DD HH:mm');

  if (from.isAfter(to)) {
    to.add(1, 'day'); // If toTime is midnight or after midnight
  }

  const temp = from.clone();
  const timesArr: string[] = [];
  while (temp.isSameOrBefore(to)) {
    timesArr.push(temp.format('HH:mm'));
    temp.add(15, 'minutes');
  }

  return timesArr;
}

type TimeRange = 'All' | 'Lunch' | 'Dinner';

export const getTimeRange = (timeRange: TimeRange, selectedDate: Moment.Moment) => {
  const dateFormat = 'YYYY-MM-DD';

  let startTime = new Date(`${selectedDate.format(dateFormat)}T08:00:00Z`);
  let endTime = new Date(`${selectedDate.clone().add(1, 'day').format(dateFormat)}T01:00:00Z`);
  if (timeRange === 'Lunch') {
    startTime = new Date(`${selectedDate.format(dateFormat)}T11:00:00Z`);
    endTime = new Date(`${selectedDate.format(dateFormat)}T16:59:59Z`);
  } else if (timeRange === 'Dinner') {
    startTime = new Date(`${selectedDate.format(dateFormat)}T17:00:00Z`);
    endTime = new Date(`${selectedDate.clone().add(1, 'day').format(dateFormat)}T02:00:00Z`);
  }

  return {
    startTime,
    endTime,
  };
};

export const filterBookingsByTimeRange = (
  timeRange: TimeRange,
  selectedDate: Moment.Moment,
  bookings: IBookingDashboard[],
) => {
  // Filter out unfinished bookings from web/app
  const filteredBookings = bookings.filter(
    (x) => x.statusId !== BookingStatusType.Waiting && !(x.statusId === 1 && x.sourceId > 1),
  );

  if (timeRange === 'All') {
    return filteredBookings;
  }

  const { startTime, endTime } = getTimeRange(timeRange, selectedDate);

  return filteredBookings.filter((x) => {
    // Reset seconds and milliseconds
    const s = new Date(`${x.start}Z`);
    s.setSeconds(0);
    s.setMilliseconds(0);
    const e = new Date(`${x.end}Z`);
    e.setSeconds(0);
    e.setMilliseconds(0);

    const bStart: any = s.getTime();
    const bEnd: any = e.getTime();

    return (
      (bStart >= startTime && bStart <= endTime && bEnd >= startTime && bEnd <= endTime) ||
      (bStart >= startTime && bStart <= endTime) ||
      (bEnd >= startTime && bEnd <= endTime) ||
      (bStart <= startTime && bEnd >= endTime)
    );
  });
};
