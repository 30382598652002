import styled from 'styled-components';
import theme from '../styles/theme';

const TabsStyle = styled.div`
  .container-fluid {
    padding: 8px;
  }
  ul.react-tabs__tab-list {
    display: flex;
    margin-bottom: 48px;
    padding: 0px;
    list-style-type: none;
    font-size: 16px;
  }

  li.react-tabs__tab {
    padding: 0px 8px 16px 8px;
    margin-right: 24px;
    color: ${theme.colors.dark2};
    font-weight: 500;

    &.react-tabs__tab--selected {
      color: ${theme.colors.brand1};
      border-bottom: 2px solid ${theme.colors.brand1};
    }

    &.react-tabs__tab--disabled {
      opacity: 0.3 !important;
      &:hover {
        cursor: not-allowed;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

export default TabsStyle;
