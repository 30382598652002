import React from 'react';
import { CountryCodeType } from '../api/translation.api';
import EnglishFlagIcon from './icons/EnglishFlagIcon';
import IcelandFlagIcon from './icons/IcelandFlagIcon';
import NorwayFlagIcon from './icons/NorwayFlagIcon';
import SpanishFlagIcon from './icons/SpanishFlagIcon';

const Flag = ({
  code,
  onClick,
  size = 20,
}: {
  code: CountryCodeType;
  onClick?: () => void;
  size?: number;
}) => {
  switch (code) {
    case 'en':
      return <EnglishFlagIcon size={size} onClick={onClick} />;
    case 'is':
      return <IcelandFlagIcon size={size} onClick={onClick} />;
    case 'es':
      return <SpanishFlagIcon size={size} onClick={onClick} />;
    case 'nb':
      return <NorwayFlagIcon size={size} onClick={onClick} />;
    default:
      return null;
  }
};

export default Flag;
