import React, { useContext } from 'react';
import { RestaurantTranslationType } from '../../../../api/translation.api';
import Input from '../../../../components/common/Input';
import Label from '../../../../components/common/Label';
import { RestaurantStateDispatchType, TranslationContext } from '../../Translator';
import FormSection from './FormSection';
import FormWrapper from './FormWrapper.styled';

const RestaurantForm = ({
  translationState: [restaurantState, setRestaurantState],
}: {
  translationState: RestaurantStateDispatchType;
}) => {
  const {
    currentLanguage: [language],
  } = useContext(TranslationContext);

  const updateField = <K extends keyof RestaurantTranslationType>(
    key: K,
    value: RestaurantTranslationType[K],
  ) => {
    const tempState = { ...restaurantState };
    tempState[language][key] = value;
    setRestaurantState(tempState);
  };

  return (
    <FormWrapper>
      <FormSection source={{ value: restaurantState.en.name }}>
        <Label>Name</Label>
        <Input
          id="restaurant-name"
          type="text"
          value={restaurantState[language].name}
          onChange={(value: string) => updateField('name', value)}
        />
      </FormSection>
      <FormSection source={{ value: restaurantState.en.about || '' }}>
        <Label>About</Label>
        <textarea
          value={restaurantState[language].about || ''}
          onChange={(event) => updateField('about', event.target.value)}
        />
      </FormSection>
      <FormSection source={{ value: restaurantState.en.info || '' }}>
        <Label>Info</Label>
        <textarea
          value={restaurantState[language].info || ''}
          onChange={(event) => updateField('info', event.target.value)}
        />
      </FormSection>
      <FormSection source={{ value: restaurantState.en.note || '' }}>
        <Label>Note</Label>
        <textarea
          value={restaurantState[language].note || ''}
          onChange={(event) => updateField('note', event.target.value)}
        />
      </FormSection>
      <FormSection source={{ value: restaurantState.en.onlineMaxGuestsText || '' }}>
        <Label>Group bookings text</Label>
        <Input
          id="group-bookings-text"
          type="text"
          value={restaurantState[language].onlineMaxGuestsText || ''}
          onChange={(value: string) => updateField('onlineMaxGuestsText', value)}
        />
      </FormSection>
      <FormSection source={{ value: restaurantState.en.policyText || '' }}>
        <Label>Reservation policy</Label>
        <textarea
          value={restaurantState[language].policyText || ''}
          onChange={(event) => updateField('policyText', event.target.value)}
        />
      </FormSection>
    </FormWrapper>
  );
};

export default RestaurantForm;
