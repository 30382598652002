import { AxiosResponse, CancelTokenSource } from 'axios';
import API from './api';

const api = new API();

export namespace SurveyNS {
  export type SurveyAnswerType = {
    id: number;
    bookingId: number;
    foodRating: number;
    serviceRating: number;
    atmosphereRating: number;
    averageRating: number;
    comment: string;
    created: string;
    bookingName: string;
    start: string;
    guests: number;
  };
}

export const fetchSurveyAnswers: (
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<SurveyNS.SurveyAnswerType[]>> = (cancelToken) => {
  return api._get('/survey', cancelToken);
};

export const fetchSurveyAnswer: (
  surveyAnswerId: number,
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<SurveyNS.SurveyAnswerType>> = (surveyAnswerId, cancelToken) => {
  return api._get(`/survey/${surveyAnswerId}`, cancelToken);
};
