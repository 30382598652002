import { AxiosResponse, CancelTokenSource } from 'axios';
import * as Moment from 'moment-timezone';
import API from './api';

type StatisticMetric = {
  metric: string;
  count: number;
};

export type BookingStatistics =
  | {
      statusCount: Array<{
        metric: string;
        count: number;
        statusId: number;
      }>;
      originCount: StatisticMetric[];
      guestsPerDay: StatisticMetric[];
      arrivalTimesBookings: StatisticMetric[];
      arrivalTimesGuests: StatisticMetric[];
      groupSize: StatisticMetric[];
      durations: StatisticMetric[];
      creationTime: StatisticMetric[];
      guestsTotal: number;
      bookingsTotal: number;
    }
  | boolean;

export type ReportRestaurantBookingsCount = {
  restaurantName: string;

  eventCount: number;
  portalCount: number;

  webCount: number;
  appCount: number;
  otherCount: number;
  agentCount: number;

  eventGuests: number;
  portalGuests: number;

  webGuests: number;
  appGuests: number;
  otherGuests: number;
  agentGuests: number;

  smsCount: number;
};

export type ReportRestaurantPayments = {
  restaurantId: number;
  restaurantName: string;
  totalAmount: number;
  paymentCount: number;
};

export type ReportRestaurantsSms = {
  restaurantName: string;
  count: number;
};

export type ActivityType = {
  bookingsCount: number;
  eventsCount: number;
  guestsCount: number;

  groupedBySource: Array<{
    sourceName: string;
    count: number;
  }>;
  groupedByRestaurant: Array<{
    restaurantName: string;
    count: number;
  }>;
  groupedByEvent: Array<{
    eventName: string;
    count: number;
  }>;
  groupedByEventsAndBookings: Array<{
    isEvent: boolean;
    count: number;
  }>;

  groupedBookingsByCreated: Array<{
    date: string;
    count: number;
  }>;
  groupedEventsByCreated: Array<{
    date: string;
    count: number;
  }>;
  groupedGuestsByCreated: Array<{
    date: string;
    count: number;
  }>;

  restaurantList: Array<{
    name: string;
    guestsCount: number;
    eventsCount: number;
    bookingsCount: number;
  }>;
};

export default class ReportAPI extends API {
  getRestaurantPayments(
    date: Moment.Moment,
    cancelToken: CancelTokenSource,
  ): Promise<
    AxiosResponse<{
      date: string;
      restaurants: ReportRestaurantPayments[];
    }>
  > {
    return this._get(`/report/payments/${date.format('YYYY-MM-DD')}`, cancelToken);
  }

  getRestaurantBookingsCount(
    date: Moment.Moment,
    cancelToken: CancelTokenSource,
  ): Promise<
    AxiosResponse<{
      date: string;
      restaurants: ReportRestaurantBookingsCount[];
    }>
  > {
    return this._get(`/report/bookingscount/${date.format('YYYY-MM-DD')}`, cancelToken);
  }

  getBookingStatistics(
    dateFrom: Moment.Moment,
    dateTo: Moment.Moment,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<BookingStatistics>> {
    return this._get(
      `/report/archive/bookingstatistics/${dateFrom.format('YYYY-MM-DD')}/${dateTo.format(
        'YYYY-MM-DD',
      )}`,
      cancelToken,
    );
  }

  getRestaurantSmsCount(
    date: Moment.Moment,
    cancelToken: CancelTokenSource,
  ): Promise<
    AxiosResponse<{
      date: string;
      restaurants: ReportRestaurantsSms[];
    }>
  > {
    return this._get(`/report/smscount/${date.format('YYYY-MM-DD')}`, cancelToken);
  }

  getActivity(
    dateFrom: string,
    dateTo: string,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<ActivityType>> {
    return this._get(`/report/activity/${dateFrom}/${dateTo}`, cancelToken);
  }
}
