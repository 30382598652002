import moment from 'moment-timezone';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { IBookingDashboard } from '../../api/dashboard.api';
import StatusColorBox from '../../components/StatusColorBox';
import theme from '../../styles/theme';

const BookingListItem = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr auto;

  position: relative;
  font-size: 12px;
  padding: 8px;
  border: 1px solid ${theme.colors.neutral2};
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-radius: 0px;

  ${(props: { hideBorderTop: boolean }) =>
    props.hideBorderTop &&
    css`
      border-top: 0px;
    `}

  &:hover {
    cursor: pointer;
  }

  > div {
    margin: auto;
    &:first-child {
      font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
      padding-right: 2px;

      &.lo-booking-hide-time {
        opacity: 0;
      }
    }
    &:nth-child(2) {
      padding-left: 4px;
      padding-right: 4px;
    }
    &:nth-child(3) {
      margin: 0px;
      padding-left: 4px;
      > div:last-child {
        padding-top: 4px;
        font-weight: 400;
      }
    }
    &:nth-child(4) {
      padding-left: 4px;
      i {
        font-size: 16px;
      }
    }
  }
  &:last-child {
    border-bottom: 1px solid ${theme.colors.neutral2};
  }
`;

type BknsType = {
  id: number;
  start: string;
  bookingName: string;
  guests: number;
  statusId: number;
  notes: string;
  tables: string;
  hideTime: boolean;
};

type Props = {
  bookings: IBookingDashboard[];
  onClick: (bookingId: number) => void;
};

const ListOverview: FunctionComponent<Props> = ({ bookings, onClick }) => {
  const [bkns, setBkns] = useState<BknsType[]>([]);

  useEffect(() => {
    const times: string[] = [];

    const temp: BknsType[] = bookings
      .sort(
        (a: IBookingDashboard, b: IBookingDashboard) =>
          new Date(a.start).getTime() - new Date(b.start).getTime(),
      )
      .map((x: IBookingDashboard) => {
        const start = moment(x.start).format('HH:mm');

        const b: BknsType = {
          id: x.id,
          start,
          bookingName: x.bookingName,
          guests: x.guests,
          statusId: x.statusId,
          notes: '',
          tables: '',
          hideTime: true,
        };

        if (!times.includes(start)) {
          b.hideTime = false;
          times.push(start);
        }

        // Guests notes
        if (x.bookingSpecialRequest) {
          b.notes += 'Guest notes: ';
          b.notes +=
            x.bookingSpecialRequest.length > 100
              ? `${x.bookingSpecialRequest.substr(0, 100)}...`
              : x.bookingSpecialRequest;
          b.notes += '. ';
        }

        // Internal notes
        if (x.bookingInternalNotes) {
          b.notes += 'Internal notes: ';
          b.notes +=
            x.bookingInternalNotes.length > 100
              ? `${x.bookingInternalNotes.substr(0, 100)}...`
              : x.bookingInternalNotes;
        }

        // Tables
        b.tables = x.tables && x.tables.length > 0 ? x.tables.map((t) => t.name).join(', ') : '';

        return b;
      });

    setBkns(temp);
  }, [bookings]);

  return (
    <div>
      {bkns.map((b: BknsType) => {
        return (
          <BookingListItem
            key={`bl-${b.id}`}
            onClick={() => onClick(b.id)}
            hideBorderTop={b.hideTime}
          >
            <div className={b.hideTime ? 'lo-booking-hide-time' : ''}>
              <b>{b.start}</b>
            </div>
            <div>
              <StatusColorBox statusId={b.statusId} />
            </div>
            <div>
              <div>
                {b.bookingName ? b.bookingName : '(no name)'}
                &nbsp;(
                {b.guests})
              </div>
              <div>{b.tables}</div>
            </div>
            {b.notes ? (
              <div className="info" data-tooltip={b.notes}>
                <i className="material-icons notes-indicator">info_outline</i>
              </div>
            ) : null}
          </BookingListItem>
        );
      })}
    </div>
  );
};

export default ListOverview;
