import React from 'react';
import styled from 'styled-components';
import Progress from './Progress';

const ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background-color: #fff;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  padding: 0px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    background-color: #f2f2f2;
  }

  > div.progress-and-arrow {
    display: flex;
    align-items: center;

    > i {
      color: #d9d9d9;
    }
  }
`;

const ListItem = ({
  title,
  progressRatio,
  onClick,
}: {
  title: string;
  progressRatio: number;
  onClick: () => void;
}) => (
  <ListItemWrapper onClick={onClick}>
    <div>{title}</div>
    <div className="progress-and-arrow">
      <Progress ratio={progressRatio} />
      <i className="material-icons">keyboard_arrow_right</i>
    </div>
  </ListItemWrapper>
);

export default ListItem;
