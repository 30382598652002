import { AxiosResponse, CancelTokenSource } from 'axios';
import * as Moment from 'moment-timezone';
import { MenuSelection } from '../components/ModalConductor/Booking';
import API from './api';

export type AvailabilityTableType = {
  id: number;
  isAvailable: boolean;
  isSuggested: boolean;
  minGuests: number;
  maxGuests: number;
  name: string;
  priority: number;
};

export interface IWaitingListBooking {
  id: number;
}

export default class BookingAPI extends API {
  getBookingById(bookingId: number) {
    return this._get(`/booking/${bookingId}`);
  }

  getBookingByIdV2(
    bookingId: number,
    cancelToken: CancelTokenSource,
  ): Promise<
    AxiosResponse<{
      booking: {
        id: number;
        start: string;
        end: string;
        duration: number;
        guests: number;
        bookingName: string;
        bookingEmail: string;
        bookingPhone: string;
        bookingSpecialRequest: string;
        bookingInternalNotes: string;
        contactName: string;
        contactPhone: string;
        bookedByName: string;
        status: number;
        statusName: string;
        sourceId: number;
        created: string;
        updated: string;
        expires: string | null;
        paymentLinkSentDate: string | null;
        eventId: number | null;
        eventName: string | null;
        menus: MenuSelection[];
        tables: Array<{
          id: number;
          floorPlanTableId: number;
          name: string;
        }>;
      };
    }>
  > {
    return this._get(`/booking/${bookingId}/v2`, cancelToken);
  }

  getAllBookings(cancelToken: CancelTokenSource) {
    return this._get('/booking', cancelToken);
  }

  updateBooking(bookingId: number, data: any) {
    return this._put(`/booking/${bookingId}`, data);
  }

  createQuickBooking(data: any) {
    return this._post('/booking/quickbook/v2', data);
  }

  updateBookingStatus(
    bookingId: number,
    statusId: number,
    cancelToken?: CancelTokenSource,
  ): Promise<
    AxiosResponse<{
      value: {
        id: number;
        statusId: number;
        bookingEnd: string;
      };
    }>
  > {
    return this._put(`/booking/updatestatus2/${bookingId}/${statusId}`, cancelToken);
  }

  makePayment(bookingId: number) {
    return this._get(`/booking/makepayment/${bookingId}`);
  }

  allTablesWithPreselected(
    start: Moment.Moment,
    duration: number,
    guests: number,
    cancelToken: CancelTokenSource,
  ) {
    return this._get(
      `/booking/alltableswithpreselected/${start.format('YYYY-MM-DDTHH:mm')}/${duration}/${guests}`,
      cancelToken,
    );
  }

  getBookingsForPrint(date: Moment.Moment) {
    return this._get(`/booking/print/${date.format('YYYY-MM-DD')}`);
  }

  getFloorPlanWithAvailabilityAndSuggestions(
    start: Moment.Moment,
    duration: number,
    guests: number,
    bookingIdToExclude: number | null,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<AvailabilityTableType[]>> {
    return this._get(
      `/booking/getfloorplanwithavailabilityandsuggestions/${start.format(
        'YYYY-MM-DDTHH:mm',
      )}/${duration}/${guests}/${bookingIdToExclude}`,
      cancelToken,
    );
  }

  getNoShowFee(
    start: Moment.Moment,
    guests: number,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<number | null>> {
    return this._get(
      `/booking/getnoshowfee/${start.format('YYYY-MM-DDTHH:mm')}/${guests}`,
      cancelToken,
    );
  }

  reSendConfirmationEmail(
    bookingId: number,
    email: string,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<boolean>> {
    return this._get(`/booking/resendconfirmationemail/${bookingId}/${email}`, cancelToken);
  }

  getUpcomingWaitingListBookings(
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<IWaitingListBooking[]>> {
    return this._get(`/booking/getupcomingwaitinglistbookings`, cancelToken);
  }
}
