import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import usePrevious from '../hooks/usePrevious';

const progressAnimation = (from: number, to: number) => keyframes`
  from {
    width: ${`${from}%`};
  }

  to {
    width: ${`${to}%`};
  }
`;

const ProgressBarWrapper = styled.div`
  height: 12px;
  width: 100%;
  border-radius: 12px;
  background-color: #ebebeb;

  > div.progress {
    background-color: ${({ progressColor }: { progressColor: string }) => progressColor};
    width: ${({ width }: { width: number }) => `${width}%`};
    height: 100%;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

    ${({ to }: { to: number }) =>
      to === 100 &&
      css`
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      `};

    will-change: width;
    animation: ${({ from, to }: { from: number; to: number }) => progressAnimation(from, to)} 500ms
      ease-out forwards;
  }
`;

const computeColor = (ratio: number) => {
  if (ratio < 0.2) {
    return '#ef5350';
  } else if (ratio >= 0.2 && ratio < 0.5) {
    return '#ffab66';
  } else if (ratio >= 0.5 && ratio < 1) {
    return '#f2c94c';
  } else if (ratio === 1) {
    return '#6fcf97';
  }

  return '#fff';
};

const ProgressBar = ({ ratio }: { ratio: number }) => {
  const prevRatio = usePrevious(ratio);

  if (ratio < 0 || ratio > 1) {
    return null;
  }

  return (
    <ProgressBarWrapper progressColor={computeColor(ratio)} from={prevRatio * 100} to={ratio * 100}>
      <div className="progress" />
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
