import Axios from 'axios';
import { inject } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { FloorPlanAPINS, getFloorPlans } from '../../api/floorplan.api';
import Button from '../../components/common/Button';
import RootStore from '../../stores/RootStore';
import { FlashMessageType } from '../../stores/UIStore';
import theme from '../../styles/theme';

const Style = styled.div`
  max-width: 512px;
`;

const ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background-color: #fff;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  padding: 0px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    background-color: #f2f2f2;
  }

  > i {
    color: #d9d9d9;
  }

  /* Name and description */
  > .fp-name-and-desc {
    width: 200px;
    overflow: hidden;

    > div {
      display: flex;
      height: 16px;
      align-items: center;

      &:last-child {
        /* Description */
        font-size: 12px;
        color: #999;
        font-weight: 300;
      }
    }
  }

  > .fp-numbers {
    font-size: 12px;
    font-weight: 300;
    color: #999;

    > div {
      display: flex;
      height: 16px;
      align-items: center;
    }
  }

  .fp-icon-alacarte {
    font-size: 14px;
    color: ${theme.colors.success};
  }
`;

interface IProps extends RouteComponentProps<any> {
  store: RootStore;
}

const FloorPlans: FC<IProps> = ({ history, store }) => {
  const [floorPlans, setFloorPlans] = useState<
    FloorPlanAPINS.IFloorPlanWithTableAndComboCount[] | null
  >(null);

  // Fetch floor plans
  useEffect(() => {
    const cancelToken = Axios.CancelToken.source();

    getFloorPlans(cancelToken)
      .then((res) => {
        setFloorPlans(res.data);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          store.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not fetch floor plans. Please try again or contact us.',
          });
        }
      });

    return () => cancelToken.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (floorPlans === null) {
    return <div>Loading...</div>;
  }

  return (
    <Style>
      <h1>Rooms</h1>
      <Button id="fp-create" onClick={() => history.push('/floorplan/create')} className="btn-cta">
        Create new
      </Button>
      <sub>Create or modify a room.</sub>

      {floorPlans.map((x) => (
        <ListItemWrapper onClick={() => history.push(`/floorplan/${x.id}`)} key={`fp-item-${x.id}`}>
          <div className="fp-name-and-desc">
            <div>{x.name.substr(0, 32)}</div>
            <div>{x.description.substr(0, 32)}</div>
          </div>

          <div className="fp-numbers">
            <div>Tables: {x.tablesCount}</div>
            <div>Combos: {x.combosCount}</div>
          </div>

          <div>
            {x.allowALaCarte && (
              <i className="fp-icon-alacarte material-icons" title="À la carte online allowed">
                restaurant
              </i>
            )}
          </div>

          <i className="material-icons">keyboard_arrow_right</i>
        </ListItemWrapper>
      ))}
    </Style>
  );
};

export default inject('store')(FloorPlans);
