import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { getOverallProgress, PageProgressType } from '../../api/translation.api';
import ListItem from './components/ListItem';

export const ListWrapper = styled.div`
  max-width: 560px;
`;

const PageSelector = ({ match, history }: RouteComponentProps) => {
  const [pageProgress, setPageProgress] = useState<PageProgressType | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const cancelToken = Axios.CancelToken.source();

    getOverallProgress(cancelToken)
      .then((res) => {
        setPageProgress(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });

    return () => cancelToken.cancel();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h1>Translate</h1>
      <sub>Select a page to translate</sub>

      {error && <div>Something went wrong</div>}

      {!loading && !error && pageProgress ? (
        <ListWrapper>
          <ListItem
            onClick={() => history.push(`${match.url}/restaurant`)}
            progressRatio={pageProgress.restaurant}
            title="Restaurant"
          />
          <ListItem
            onClick={() => history.push(`${match.url}/event`)}
            progressRatio={pageProgress.event}
            title="Events"
          />
          <ListItem
            onClick={() => history.push(`${match.url}/menu`)}
            progressRatio={pageProgress.menu}
            title="Menus"
          />
        </ListWrapper>
      ) : (
        <div>Loading..</div>
      )}
    </>
  );
};

export default PageSelector;
