enum BookingStatusType {
  Hold = 1,
  NewBooking = 2,
  Confirmed = 3,
  NotConfirmed = 4,
  PartiallySeated = 5,
  Seated = 6,
  PartiallyArrived = 7,
  Arrived = 8,
  NoShow = 9,
  Cancelled = 10,
  Completed = 11,
  Waiting = 12,
}

export default BookingStatusType;
