import React, { useContext } from 'react';
import ReactQuill from 'react-quill';
import { EventTranslationType } from '../../../../api/translation.api';
import Input from '../../../../components/common/Input';
import Label from '../../../../components/common/Label';
import { formats, modules } from '../../../../utils/react-quill-config';
import { EventStateDispatchType, TranslationContext } from '../../Translator';
import FormSection from './FormSection';
import FormWrapper from './FormWrapper.styled';

const EventForm = ({
  translationState: [eventState, setEventState],
}: {
  translationState: EventStateDispatchType;
}) => {
  const {
    currentLanguage: [language],
  } = useContext(TranslationContext);

  const updateField = <K extends keyof EventTranslationType>(
    key: K,
    value: EventTranslationType[K],
  ) => {
    const tempState = { ...eventState };
    tempState[language][key] = value;
    setEventState(tempState);
  };

  return (
    <FormWrapper>
      <FormSection source={{ value: eventState.en.name }}>
        <Label>Name</Label>
        <Input
          id="event-name"
          type="text"
          value={eventState[language].name}
          onChange={(value: string) => updateField('name', value)}
        />
      </FormSection>
      <FormSection source={{ value: eventState.en.shortDescription || '' }}>
        <Label>Short description (max 200 characters)</Label>
        <textarea
          value={eventState[language].shortDescription || ''}
          onChange={(event) =>
            updateField(
              'shortDescription',
              event.target.value.length > 200
                ? event.target.value.substr(0, 200)
                : event.target.value,
            )
          }
        />
      </FormSection>
      <FormSection source={{ value: eventState.en.description || '', isHtml: true }}>
        <Label>Long description</Label>
        <ReactQuill
          modules={modules}
          formats={formats}
          value={eventState[language].description || ''}
          theme="snow"
          onChange={(value, _, source) => source === 'user' && updateField('description', value)}
        />
      </FormSection>
    </FormWrapper>
  );
};

export default EventForm;
