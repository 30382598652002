import * as firebase from 'firebase/app';
import 'firebase/auth';

import { AxiosResponse, CancelTokenSource } from 'axios';
import API from './api';

export namespace UserAPINS {
  export enum UserAdminRole {
    Admin = 1, // Dineout super user
    Manager = 2, // Restaurant manager
    Staff = 3, // Restaurant staff
  }

  export interface IUserAdminModel {
    id: number;
    restaurantId: number | null;
    firebaseUId: string;
    email: string;
    name: string;
    role: UserAdminRole;
    isDeleted: boolean;
  }

  export interface IUserAdminModelWithPassword extends IUserAdminModel {
    password: string;
  }
}

export default class UserAPI extends API {
  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        console.log('Firebase signout successful');
      })
      .catch((error) => {
        // An error happened.
        console.log('Firebase signout FAILED', error);
      });
  }

  changeAdminRestaurant(
    newRestaurantId: number | null,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<boolean>> {
    return this._put(`/user/changeadminrestaurant/${newRestaurantId}`, cancelToken);
  }

  revokeRefreshToken(
    firebaseUId: string,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<null>> {
    return this._put(`/user/revokerefreshtoken/${firebaseUId}`, cancelToken);
  }
}
