import phoneUtil from 'google-libphonenumber';
import * as Moment from 'moment-timezone';
import BookingStatusType from '../enums/BookingStatusType';

type Item = {
  id: number;
  name: string;
};

export type StatusItem = {
  id: number;
  name: string;
  color: string;
  contrastColor: string;
};

type Card = {
  number: string | null;
  cvc: string | null;
  expiryMonth: string | null;
  expiryYear: string | null;
};

export function isEmailValid(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

export const isPhoneValid = (phone: string, region = '') => {
  try {
    const util = phoneUtil.PhoneNumberUtil.getInstance();
    const nr = util.parse(phone, region);
    return util.isValidNumber(nr);
  } catch {
    return false;
  }
};

export const isUrlValid = (url: string) => {
  try {
    const regex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    );
    return regex.test(url);
  } catch {
    return false;
  }
};

export const canPhoneNumberReceiveSMS = (phone: string, region = '') => {
  try {
    const util = phoneUtil.PhoneNumberUtil.getInstance();
    const nr = util.parse(phone, region);
    const type = util.getNumberType(nr);

    return (
      type === phoneUtil.PhoneNumberType.MOBILE ||
      type === phoneUtil.PhoneNumberType.FIXED_LINE_OR_MOBILE
    );
  } catch {
    return false;
  }
};

export const thousandSeperator = (x: number) => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join('.');
};

export const getAllRestaurantImageTypes = () => {
  return [
    { id: 1, name: 'Cover' },
    { id: 2, name: 'Logo' },
    { id: 3, name: 'Misc' },
    { id: 4, name: 'Thermal Printer Logo' },
  ];
};

export default class Utils {
  numberToWord(nbmr: number): string {
    switch (nbmr) {
      case 1:
        return 'One';
      case 2:
        return 'Two';
      case 3:
        return 'Three';
      case 4:
        return 'Four';
      case 5:
        return 'Five';
      case 6:
        return 'Six';
      case 7:
        return 'Seven';
      case 8:
        return 'Eight';
      case 9:
        return 'Nine';
      default:
        return nbmr.toString();
    }
  }

  getAllUserRoles(): Item[] {
    return [
      { id: 1, name: 'Admin' },
      { id: 2, name: 'Manager' },
      { id: 3, name: 'Staff' },
    ];
  }

  getRestaurantImageTypeNameById(id: number): string {
    const xx = getAllRestaurantImageTypes().find((x) => x.id === id);
    return xx ? xx.name : '';
  }

  roundUpQuarter(dateWithTime: Moment.Moment): Moment.Moment {
    if (dateWithTime.hour() === 23 && dateWithTime.minute() >= 45) {
      return dateWithTime.clone().set({
        hour: 23,
        minute: 45,
        second: 0,
      });
    }

    const minutes = Math.ceil(dateWithTime.minute() / 15) * 15;
    return dateWithTime.clone().set({
      minute: minutes,
      second: 0,
    });
  }

  createCreditCard(card: any): Card | null {
    if (!(card.number || card.cvc || card.expires)) {
      return null;
    }
    const newCard = {
      number: card.number.replace(/\s/g, ''),
      cvc: card.cvc,
      expiryMonth: card.expires.split(' / ').length === 2 ? card.expires.split(' / ')[0] : null,
      expiryYear: card.expires.split(' / ').length === 2 ? card.expires.split(' / ')[1] : null,
    };
    return newCard;
  }
}

export function getAllStatuses(): StatusItem[] {
  return [
    { id: 1, name: 'Hold', color: '#FFFD7A', contrastColor: '#1C1C1C' },
    { id: 2, name: 'New booking', color: '#BD6014', contrastColor: '#FFF' },
    { id: 3, name: 'Confirmed', color: '#9B51E0', contrastColor: '#FFF' },
    { id: 4, name: 'Not confirmed', color: '#CFA8DD', contrastColor: '#FFF' },
    { id: 5, name: 'Partially seated', color: '#6FCF97', contrastColor: '#FFF' },
    { id: 6, name: 'Seated', color: '#27AE60', contrastColor: '#FFF' },
    { id: 7, name: 'Partially arrived', color: '#56CCF2', contrastColor: '#FFF' },
    { id: 8, name: 'Arrived', color: '#2D9CDB', contrastColor: '#FFF' },
    { id: 9, name: 'No show', color: '#EB5757', contrastColor: '#FFF' },
    { id: 10, name: 'Cancelled', color: '#828282', contrastColor: '#FFF' },
    { id: 11, name: 'Completed', color: '#18191F', contrastColor: '#FFF' },
    { id: 12, name: 'Waiting', color: '#FBFFE6', contrastColor: '#1C1C1C' },
  ];
}

/**
 * To know which status buttons to show user depending on booking status.
 * @param statusId
 */
export function getStatusButtonsFromStatusId(statusId: number) {
  const status = statusId as BookingStatusType;

  switch (status) {
    // 1
    case BookingStatusType.Hold:
      return [BookingStatusType.NewBooking, BookingStatusType.Cancelled];
    // 2
    case BookingStatusType.NewBooking:
      return [
        BookingStatusType.Cancelled,
        BookingStatusType.Arrived,
        BookingStatusType.PartiallyArrived,
        BookingStatusType.Seated,
        BookingStatusType.PartiallySeated,
      ];
    // 3
    case BookingStatusType.Confirmed:
      return [BookingStatusType.Completed];
    // 4
    case BookingStatusType.NotConfirmed:
      return [BookingStatusType.Confirmed, BookingStatusType.Completed];
    // 5
    case BookingStatusType.PartiallySeated:
      return [BookingStatusType.Seated, BookingStatusType.Completed];
    // 6
    case BookingStatusType.Seated:
      return [BookingStatusType.Completed];
    // 7
    case BookingStatusType.PartiallyArrived:
      return [
        BookingStatusType.Arrived,
        BookingStatusType.Seated,
        BookingStatusType.PartiallySeated,
        BookingStatusType.Completed,
      ];
    // 8
    case BookingStatusType.Arrived:
      return [
        BookingStatusType.Seated,
        BookingStatusType.PartiallySeated,
        BookingStatusType.Completed,
      ];
    // 9
    case BookingStatusType.NoShow:
      return [];
    // 10
    case BookingStatusType.Cancelled:
      return [];
    // 11
    case BookingStatusType.Completed:
      return [];
    default:
      return [];
  }
}
