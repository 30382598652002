import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import React, { createContext, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  CountryCodeType,
  EventTranslationType,
  LanguageType,
  MenuTranslationType,
  RestaurantTranslationType,
} from '../../api/translation.api';
import { IRootStore } from '../../stores/RootStore';
import { FlashMessageType } from '../../stores/UIStore';
import { ratioOfExistingFields } from '../../utils/math.utils';
import EventTranslator from './components/translators/EventTranslator';
import MenuTranslator from './components/translators/MenuTranslator';
import RestaurantTranslator from './components/translators/RestaurantTranslator';

export type RestaurantStateType = Record<CountryCodeType, RestaurantTranslationType>;
export type EventStateType = Record<CountryCodeType, EventTranslationType>;
export type MenuStateType = Record<CountryCodeType, MenuTranslationType>;

export type TranslationStateType = RestaurantStateType | EventStateType | MenuStateType;
export type GetStringsFuncType<T extends TranslationStateType> = (
  data: T[CountryCodeType],
) => Omit<T[CountryCodeType], 'id' | 'eventId' | 'menuId'>;

export type RestaurantStateDispatchType = [
  RestaurantStateType,
  Dispatch<SetStateAction<RestaurantStateType>>,
];
export type EventStateDispatchType = [EventStateType, Dispatch<SetStateAction<EventStateType>>];
export type MenuStateDispatchType = [MenuStateType, Dispatch<SetStateAction<MenuStateType>>];

// TODO: Assign this type directly to onSave declaration so that the type doesn't need to be declared twice
export type SaveTranslationFunc = <T extends TranslationStateType>(
  translationState: T,
  getStrings: GetStringsFuncType<T>,
  saveToServer: (
    translations: Array<T[CountryCodeType] & { language: CountryCodeType }>,
    cancelToken: CancelTokenSource,
  ) => Promise<AxiosResponse<undefined>>,
) => Promise<AxiosResponse<undefined>>;

// TODO: Assign this type directly to computeLanguage declaration so that the type doesn't need to be declared twice
export type ComputeLanguageFunc = <T extends TranslationStateType>(
  translationState: T,
  getStrings: GetStringsFuncType<T>,
) => LanguageType[];

type TranslationContextType = {
  currentLanguage: [CountryCodeType, Dispatch<SetStateAction<CountryCodeType>>];
  saveBtnLoading: [boolean, Dispatch<SetStateAction<boolean>>];
};

export const TranslationContext = createContext<TranslationContextType>(
  {} as TranslationContextType,
);

const Translator = ({
  match,
  store,
}: RouteComponentProps<{ eventId?: string; menuId?: string }> & {
  store?: IRootStore;
}) => {
  const currentLanguage = useState<CountryCodeType>('en');
  const saveBtnLoading = useState(false);

  const [, setSaveBtnLoading] = saveBtnLoading;

  const type = match.params.eventId
    ? 'event'
    : match.params.menuId
    ? 'menu'
    : match.url.includes('restaurant')
    ? 'restaurant'
    : null;

  /*
   * Saves translations for Restaurant, Event or Menu.
   * Memoized via useCallback because it has a lot of logic that doesn't need to be re-created in every render
   */
  const onSave: SaveTranslationFunc = useCallback(
    <T extends TranslationStateType>(
      translationState: T,
      getStrings: GetStringsFuncType<T>,
      save: (
        translations: Array<T[CountryCodeType] & { language: CountryCodeType }>,
        cancelToken: CancelTokenSource,
      ) => Promise<AxiosResponse<undefined>>,
    ) => {
      return new Promise((resolve, reject) => {
        const cancelToken = axios.CancelToken.source();
        const translations: Array<T[CountryCodeType] & { language: CountryCodeType }> = [];

        setSaveBtnLoading(true);

        Object.keys(translationState).map((c) => {
          const code = c as CountryCodeType;

          if (!translationState[code]) {
            // Missing translations
            return;
          }

          const translationStrings = getStrings(translationState[code]);
          const hasTranslations = Object.keys(translationStrings).some(
            (field) =>
              !!translationStrings[
                field as keyof Omit<T[CountryCodeType], 'id' | 'eventId' | 'menuId'>
              ],
          );

          if (translationState[code]!.id < 0 && !hasTranslations) {
            // Not saving empty translations that haven't been saved before
            return;
          }

          translations.push({ ...translationState[code], language: code });
        });

        save(translations, cancelToken)
          .then((res) => {
            if (res.status === 200) {
              store!.UIStore.addFlashMessage({
                type: FlashMessageType.Success,
                text: 'Successfully saved translations',
              });
            } else if (res.status === 206) {
              store!.UIStore.addFlashMessage({
                type: FlashMessageType.Warning,
                text: 'Partially saved. Could not save all translations. Please try again.',
                timeout: 10000,
              });
            }

            setSaveBtnLoading(false);

            resolve(res);
          })
          .catch((e) => {
            if (!axios.isCancel(e)) {
              store!.UIStore.addFlashMessage({
                type: FlashMessageType.Error,
                text: 'Could not save translations',
              });
            }
            setSaveBtnLoading(false);

            reject(e);
          });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /*
   * Determines what languages should be translated and their translation ratio status
   * Memoized via useCallback because it has a lot of logic that doesn't need to be re-created in every render
   */
  const computeLanguages = useCallback(
    <T extends TranslationStateType>(
      translationState: T,
      getStrings: (
        data: T[CountryCodeType],
      ) => Omit<T[CountryCodeType], 'id' | 'eventId' | 'menuId'>,
    ): LanguageType[] => {
      const languages: LanguageType[] = [];
      if (translationState.en) {
        languages.push({
          countryCode: 'en',
          language: 'English',
          completedRatio: translationState.en
            ? ratioOfExistingFields(getStrings(translationState.en))
            : 0,
        });
      }

      if (translationState.is) {
        languages.push({
          countryCode: 'is',
          language: 'Icelandic',
          completedRatio: translationState.is
            ? ratioOfExistingFields(getStrings(translationState.is))
            : 0,
        });
      }

      if (translationState.es) {
        languages.push({
          countryCode: 'es',
          language: 'Spanish',
          completedRatio: translationState.es
            ? ratioOfExistingFields(getStrings(translationState.es))
            : 0,
        });
      }

      if (translationState.nb) {
        languages.push({
          countryCode: 'nb',
          language: 'Norwegian',
          completedRatio: translationState.nb
            ? ratioOfExistingFields(getStrings(translationState.nb))
            : 0,
        });
      }

      return languages;
    },
    [],
  );

  return (
    <TranslationContext.Provider value={{ currentLanguage, saveBtnLoading }}>
      {(() => {
        switch (type) {
          case 'restaurant':
            return <RestaurantTranslator onSave={onSave} computeLanguages={computeLanguages} />;
          case 'event':
            return <EventTranslator onSave={onSave} computeLanguages={computeLanguages} />;
          case 'menu':
            return <MenuTranslator onSave={onSave} computeLanguages={computeLanguages} />;
          default:
            return null;
        }
      })()}
    </TranslationContext.Provider>
  );
};

export default inject('store')(observer(Translator));
