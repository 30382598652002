import axios, { CancelTokenSource } from 'axios';
import moment from 'moment-timezone';
import * as Moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import ReactTable from 'react-table';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';
import ReportAPI, { ActivityType } from '../../api/report.api';
import Button from '../../components/common/Button';
import Datepicker from '../../components/common/Datepicker';
import ReactTableStyle from '../../components/ReactTable.styled';
import TabsStyle from '../../components/Tabs.styled';
import { getAllStatuses } from '../../utils';

const Style = styled.div`
  .statistics-header {
    position: sticky;
    top: 0px;
    margin: 0px -16px 48px -16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 9px 20px -12px #cacaca;
    padding: 8px 16px;
    border-radius: 3px;

    background-color: #fff;
    z-index: 99999;

    .statistics-guests-bookings-total {
      display: flex;
      font-size: 12px;

      > div {
        margin-right: 8px;
      }
    }

    .statistics-period {
      display: flex;
      align-items: center;
      font-size: 12px;

      .statistics-date-from,
      .statistics-date-to {
        width: 112px;
        margin: 0 8px;
      }
      #statistics-btn-update {
        width: 112px;
      }
    }
  }

  .chart-container {
    display: flex;
    width: 100%;
    height: 500px;
    margin-bottom: 32px;

    > div {
      flex-basis: 100%;

      &.fifty {
        flex-basis: 50%;
      }
    }
  }

  .col-center {
    text-align: center;
  }
`;

const TooltipStyle = styled.div`
  background-color: #fff;
  border: 1px solid #e8e8e8;
  padding: 8px;

  .tooltip-style-label {
    margin-bottom: 8px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
  }
`;

const columns = [
  {
    Header: 'Restaurant',
    headerClassName: 'col-restaurant',
    accessor: 'name',
    className: 'col-restaurant',
    filterable: true,
  },
  {
    Header: 'Guests',
    headerClassName: 'col-guests',
    accessor: 'guestsCount',
    className: 'col-center',
  },
  {
    Header: 'Bookings',
    headerClassName: 'col-bookings',
    accessor: 'bookingsCount',
    className: 'col-center',
  },
  {
    Header: 'Events',
    headerClassName: 'col-events',
    accessor: 'eventsCount',
    className: 'col-center',
  },
];

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#58508d',
  '#bc5090',
  '#003f5c',
  '#000000',
  '#00005f',
  '#000087',
  '#0000af',
  '#0000d7',
  '#0000ff',
  '#005f00',
  '#005f5f',
  '#005f87',
  '#005faf',
  '#005fd7',
  '#005fff',
  '#008700',
  '#00875f',
  '#008787',
  '#0087af',
  '#5f0000',
  '#5f005f',
  '#5f00d7',
  '#5f5f00',
  '	#5f5f5f',
  '#5f5f87',
  '#5f8700',
  '#5f87af',
  '#870000',
  '#8700af',
  '#875f87',
];

const RADIAN = Math.PI / 180;

const statusColors = getAllStatuses();

const renderCustomizedLabel: (obj: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  payload: any;
}) => React.ReactElement = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  let fill = '#FFF';
  if (payload.statusId) {
    fill = statusColors.find((sc) => sc.id === payload.statusId)!.contrastColor;
  }

  return (
    <text x={x} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const reportAPI: ReportAPI = new ReportAPI();

const Activity = () => {
  const [dateFrom, setDateFrom] = useState<Moment.Moment>(moment());
  const [dateTo, setDateTo] = useState<Moment.Moment>(moment());
  const [isLoading, setIsLoading] = useState(true);
  const [activity, setActivity] = useState<ActivityType | null>(null);

  const btnCancelToken = useRef<CancelTokenSource | null>(null);

  const fetchActivity = (
    from: Moment.Moment,
    to: Moment.Moment,
    cancelToken: CancelTokenSource,
  ) => {
    reportAPI
      .getActivity(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'), cancelToken)
      .then((res) => {
        res.data.groupedBookingsByCreated = res.data.groupedBookingsByCreated.sort(
          (a, b) =>
            moment(a.date, moment.defaultFormatUtc).unix() -
            moment(b.date, moment.defaultFormatUtc).unix(),
        );
        res.data.groupedEventsByCreated = res.data.groupedEventsByCreated.sort(
          (a, b) =>
            moment(a.date, moment.defaultFormatUtc).unix() -
            moment(b.date, moment.defaultFormatUtc).unix(),
        );
        res.data.groupedGuestsByCreated = res.data.groupedGuestsByCreated.sort(
          (a, b) =>
            moment(a.date, moment.defaultFormatUtc).unix() -
            moment(b.date, moment.defaultFormatUtc).unix(),
        );

        setActivity(res.data);
        setIsLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          alert('Error fetching activity');
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    fetchActivity(dateFrom, dateTo, cancelToken);

    return () => cancelToken.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!activity) {
    return <div>Loading...</div>;
  }

  return (
    <Style className="container">
      <h1>Activity</h1>
      <sub>Showing bookings created by date.</sub>

      <div className="statistics-header">
        <div className="statistics-guests-bookings-total">
          <div>
            <b>Guests:</b> {activity.guestsCount}
          </div>
          <div>
            <b>Bookings:</b> {activity.bookingsCount}
          </div>
          <div>
            <b>Events:</b> {activity.eventsCount}
          </div>
        </div>

        <div className="statistics-period">
          <b>Period:</b>
          <Datepicker
            value={dateFrom}
            onChange={(val) => {
              if (dateTo.isBefore(val)) {
                setDateTo(val);
              }
              setDateFrom(val);
            }}
            showIcon={false}
            className="statistics-date-from"
          />
          <span>-</span>
          <Datepicker
            value={dateTo}
            onChange={(val) => {
              if (dateFrom.isAfter(val)) {
                setDateFrom(val);
              }
              setDateTo(val);
            }}
            showIcon={false}
            className="statistics-date-to"
          />
          <Button
            id="statistics-btn-update"
            onClick={() => {
              setIsLoading(true);

              if (btnCancelToken.current) {
                btnCancelToken.current.cancel();
              }

              btnCancelToken.current = axios.CancelToken.source();
              fetchActivity(dateFrom, dateTo, btnCancelToken.current);
            }}
            isLoading={isLoading}
          >
            Update
          </Button>
        </div>
      </div>

      <TabsStyle>
        <Tabs>
          <TabList>
            <Tab>Charts</Tab>
            <Tab>List</Tab>
          </TabList>

          <TabPanel>
            <div className="chart-container">
              <div className="fifty">
                <h3>By event</h3>
                <ResponsiveContainer>
                  <PieChart>
                    <Tooltip
                      content={({ active, payload }: { active: boolean; payload: any }) => {
                        if (active) {
                          return (
                            <TooltipStyle>
                              <div className="tooltip-style-label">{payload[0].name}</div>
                              <div>
                                <b>Bookings:</b> {payload[0].value}
                              </div>
                            </TooltipStyle>
                          );
                        }

                        return null;
                      }}
                    />
                    <Pie
                      isAnimationActive={false}
                      data={activity.groupedByEvent.map((x) => ({
                        name: x.eventName,
                        value: x.count,
                      }))}
                      labelLine={false}
                      dataKey="value"
                      label={renderCustomizedLabel as any}
                    >
                      {activity.groupedByEvent.map((entry, index) => {
                        return (
                          <Cell
                            key={`cell-by-event-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        );
                      })}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>

              <div className="fifty">
                <h3>Bookings vs. events</h3>
                <ResponsiveContainer>
                  <PieChart>
                    <Legend wrapperStyle={{ fontSize: 12 }} verticalAlign="top" height={36} />
                    <Tooltip
                      content={({ active, payload }: { active: boolean; payload: any }) => {
                        if (active) {
                          return (
                            <TooltipStyle>
                              <div className="tooltip-style-label">{payload[0].name}</div>
                              <div>
                                <b>Bookings:</b> {payload[0].value}
                              </div>
                            </TooltipStyle>
                          );
                        }

                        return null;
                      }}
                    />
                    <Pie
                      isAnimationActive={false}
                      data={activity.groupedByEventsAndBookings.map((x) => ({
                        name: x.isEvent ? 'Events' : 'Bookings',
                        value: x.count,
                      }))}
                      labelLine={false}
                      dataKey="value"
                      label={renderCustomizedLabel as any}
                    >
                      {activity.groupedByEventsAndBookings.map((entry, index) => {
                        return (
                          <Cell
                            key={`cell-bookings-vs-events-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        );
                      })}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="chart-container">
              <div className="fifty">
                <h3>By restaurant</h3>
                <ResponsiveContainer>
                  <PieChart>
                    <Tooltip
                      content={({ active, payload }: { active: boolean; payload: any }) => {
                        if (active) {
                          return (
                            <TooltipStyle>
                              <div className="tooltip-style-label">{payload[0].name}</div>
                              <div>
                                <b>Bookings:</b> {payload[0].value}
                              </div>
                            </TooltipStyle>
                          );
                        }

                        return null;
                      }}
                    />
                    <Pie
                      isAnimationActive={false}
                      data={activity.groupedByRestaurant.map((x) => ({
                        name: x.restaurantName,
                        value: x.count,
                      }))}
                      labelLine={false}
                      dataKey="value"
                      label={renderCustomizedLabel as any}
                    >
                      {activity.groupedByRestaurant.map((entry, index) => {
                        return (
                          <Cell
                            key={`cell-restaurants-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        );
                      })}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>

              <div className="fifty">
                <h3>By source</h3>
                <ResponsiveContainer>
                  <PieChart>
                    <Legend wrapperStyle={{ fontSize: 12 }} verticalAlign="top" height={36} />
                    <Tooltip
                      content={({ active, payload }: { active: boolean; payload: any }) => {
                        if (active) {
                          return (
                            <TooltipStyle>
                              <div className="tooltip-style-label">{payload[0].name}</div>
                              <div>
                                <b>Bookings:</b> {payload[0].value}
                              </div>
                            </TooltipStyle>
                          );
                        }

                        return null;
                      }}
                    />
                    <Pie
                      isAnimationActive={false}
                      data={activity.groupedBySource.map((x) => ({
                        name: x.sourceName,
                        value: x.count,
                      }))}
                      labelLine={false}
                      dataKey="value"
                      label={renderCustomizedLabel as any}
                    >
                      {activity.groupedBySource.map((entry, index) => {
                        return (
                          <Cell key={`cell-source-${index}`} fill={COLORS[index % COLORS.length]} />
                        );
                      })}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="chart-container">
              <div>
                <h3>Bookings by created</h3>
                <ResponsiveContainer width="100%" height={400}>
                  <AreaChart
                    data={activity.groupedBookingsByCreated.map((x) => ({
                      name: moment(x.date, moment.defaultFormatUtc).format('DD MMM'),
                      uv: x.count,
                    }))}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip
                      content={({
                        active,
                        payload,
                      }: {
                        active: boolean;
                        payload: any;
                        label: string;
                      }) => {
                        if (active) {
                          return (
                            <TooltipStyle>
                              <div className="tooltip-style-label">{payload[0].payload.name}</div>
                              <div>
                                <b>Bookings:</b> {payload[0].payload.uv}
                              </div>
                            </TooltipStyle>
                          );
                        }

                        return null;
                      }}
                    />
                    <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="chart-container">
              <div>
                <h3>Events by created</h3>
                <ResponsiveContainer width="100%" height={400}>
                  <AreaChart
                    data={activity.groupedEventsByCreated.map((x) => ({
                      name: moment(x.date, moment.defaultFormatUtc).format('DD MMM'),
                      uv: x.count,
                    }))}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip
                      content={({
                        active,
                        payload,
                      }: {
                        active: boolean;
                        payload: any;
                        label: string;
                      }) => {
                        if (active) {
                          return (
                            <TooltipStyle>
                              <div className="tooltip-style-label">{payload[0].payload.name}</div>
                              <div>
                                <b>Bookings:</b> {payload[0].payload.uv}
                              </div>
                            </TooltipStyle>
                          );
                        }

                        return null;
                      }}
                    />
                    <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="chart-container">
              <div>
                <h3>Guests by created</h3>
                <ResponsiveContainer width="100%" height={400}>
                  <AreaChart
                    data={activity.groupedGuestsByCreated.map((x) => ({
                      name: moment(x.date, moment.defaultFormatUtc).format('DD MMM'),
                      uv: x.count,
                    }))}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip
                      content={({
                        active,
                        payload,
                      }: {
                        active: boolean;
                        payload: any;
                        label: string;
                      }) => {
                        if (active) {
                          return (
                            <TooltipStyle>
                              <div className="tooltip-style-label">{payload[0].payload.name}</div>
                              <div>
                                <b>Bookings:</b> {payload[0].payload.uv}
                              </div>
                            </TooltipStyle>
                          );
                        }

                        return null;
                      }}
                    />
                    <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </TabPanel>

          <TabPanel>
            <ReactTableStyle>
              <ReactTable
                data={activity.restaurantList}
                columns={columns}
                resizable={false}
                defaultFilterMethod={(filter: any, row: any) => {
                  const id = filter.pivotId || filter.id;
                  return row[id] !== undefined
                    ? String(row[id])
                        .toLowerCase()
                        .trim()
                        .replace(/\s/g, '')
                        .includes(filter.value.toLowerCase().trim().replace(/\s/g, ''))
                    : true;
                }}
              />
            </ReactTableStyle>
          </TabPanel>
        </Tabs>
      </TabsStyle>
    </Style>
  );
};

export default Activity;
