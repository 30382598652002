import React, { Component } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import Rule from './Rule';
import Rules from './Rules';

type IProps = RouteComponentProps<any>;

class RuleRoutes extends Component<IProps> {
  render() {
    return (
      <div className="container">
        <Switch>
          <Route path={`${this.props.match.url}/`} component={Rules} exact />
          <Route path={`${this.props.match.url}/create`} component={Rule} exact />
          <Route path={`${this.props.match.url}/edit/:ruleId`} component={Rule} exact />
        </Switch>
      </div>
    );
  }
}

export default RuleRoutes;
