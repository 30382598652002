import axios, { CancelTokenSource } from 'axios';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import styled from 'styled-components';

/* API's */
import BookingAPI from '../../api/booking.api';

import { inject, observer } from 'mobx-react';
import ReactTableStyle from '../../components/ReactTable.styled';
import { IRootStore } from '../../stores/RootStore';
import { FlashMessageType, ModalConductor } from '../../stores/UIStore';
import Utils, { getAllStatuses } from '../../utils';

const Style = styled.div`
  margin-bottom: 64px;

  .bookings-table {
    .col-id {
      flex: 32 0 auto !important;
      width: 32px !important;
    }
    .col-status {
      width: 56px !important;
      flex: 56 0 auto !important;
    }
    .col-arrival {
      width: 72px !important;
      flex: 72 0 auto !important;
    }
    .col-guests {
      flex: 24 0 auto !important;
      width: 24px !important;
      text-align: center;
    }
    .col-date {
      flex: 64 0 auto !important;
      width: 64px !important;
    }
    .col-phone {
      flex: 64 0 auto !important;
      width: 64px !important;
    }
  }
`;

interface IProps extends RouteComponentProps<any> {
  store?: IRootStore;
}

type State = {
  allBookings: any[];
};

@inject('store')
@observer
class Booking extends Component<IProps, State> {
  bookingAPI: BookingAPI;
  cancelToken: CancelTokenSource;
  utils: Utils;

  constructor(props: IProps) {
    super(props);

    this.state = {
      allBookings: [],
    };

    this.bookingAPI = new BookingAPI();
    this.utils = new Utils();
    this.cancelToken = axios.CancelToken.source();
  }

  componentDidMount() {
    this.getAllBookings();
  }

  componentWillUnmount() {
    this.cancelToken.cancel('getAllBookings cancelled in componentWillUnmount');
  }

  /**
   * Class methods
   */
  getAllBookings() {
    this.cancelToken = axios.CancelToken.source();

    this.bookingAPI
      .getAllBookings(this.cancelToken)
      .then((results) => {
        const data = results.data;

        this.setState({
          allBookings: data,
        });
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          this.props.store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Error fetching bookings',
            timeout: 10000,
          });
        }
      });
  }

  render() {
    const statusColors = getAllStatuses();

    const columns = [
      {
        Header: 'ID',
        headerClassName: 'col-id',
        accessor: 'id',
        className: 'col-id',
        filterable: true,
      },
      {
        Header: 'Status',
        headerClassName: 'col-status',
        accessor: 'statusName',
        className: 'col-status',
        filterable: true,
        Cell: (props: any) => {
          const status = statusColors.find(
            (x) => x.name.toLowerCase() === props.value.toLowerCase(),
          );

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  flexShrink: 0,
                  backgroundColor: status ? status.color : '#FFF',
                  height: '10px',
                  width: '10px',
                  marginRight: '4px',
                }}
              />
              {props.value}
            </div>
          );
        },
      },
      {
        Header: 'Arrival',
        headerClassName: 'col-arrival',
        accessor: 'start',
        className: 'col-arrival',
        Cell: (props: { value: moment.MomentInput }) =>
          moment(props.value).format('ddd, D MMM YYYY HH:mm'),
      },
      {
        Header: 'Guests',
        headerClassName: 'col-guests',
        accessor: 'guests',
        className: 'col-guests',
      },
      {
        Header: 'Customer',
        accessor: 'bookingName',
        filterable: true,
      },
      {
        Header: 'Phone',
        headerClassName: 'col-phone',
        accessor: 'bookingPhone',
        className: 'col-phone',
        filterable: true,
      },
      {
        Header: 'Created',
        headerClassName: 'col-date',
        accessor: 'created',
        className: 'col-date',
        Cell: (props: { value: moment.MomentInput }) =>
          moment(props.value).format('YYYY-MM-DD HH:mm'),
      },
      {
        Header: 'Updated',
        headerClassName: 'col-date',
        accessor: 'updated',
        className: 'col-date',
        Cell: (props: { value: moment.MomentInput }) =>
          moment(props.value).format('YYYY-MM-DD HH:mm'),
      },
    ];

    return (
      <Style>
        <h1>Bookings</h1>
        <sub>List of all your bookings</sub>

        <Grid fluid className="bookings-table">
          <Row>
            <Col xs={12}>
              <ReactTableStyle>
                <ReactTable
                  data={this.state.allBookings}
                  columns={columns}
                  resizable={false}
                  defaultFilterMethod={(filter: any, row: any) => {
                    const id = filter.pivotId || filter.id;
                    if (id === 'id') {
                      return row[id] !== undefined
                        ? String(row[id]).startsWith(filter.value)
                        : true;
                    }
                    return row[id] !== undefined
                      ? String(row[id])
                          .toLowerCase()
                          .trim()
                          .replace(/\s/g, '')
                          .includes(filter.value.toLowerCase().trim().replace(/\s/g, ''))
                      : true;
                  }}
                  getTdProps={(state: any, rowInfo: any) => ({
                    onClick: () => {
                      this.props.store!.UIStore.openModal({
                        type: ModalConductor.Type.Booking,
                        bookingId: rowInfo.original.id,
                        onSave: () => this.getAllBookings(),
                      });
                    },
                  })}
                />
              </ReactTableStyle>
            </Col>
          </Row>
        </Grid>
      </Style>
    );
  }
}

export default withRouter(Booking);
