import { AxiosResponse, CancelTokenSource } from 'axios';
import API from './api';

export type CustomerType = {
  id: number;
  restaurantId: number;
  phone: string;
  email: string | null;
  firstName: string;
  restaurantNotes: string | null;
  allowDineoutEmails: boolean;
  allowRestaurantEmails: boolean;
  created: string;
  updated: string;
};

export default class CustomerAPI extends API {
  getCustomers(cancelToken: CancelTokenSource): Promise<AxiosResponse<CustomerType[]>> {
    return this._get('/customer', cancelToken);
  }

  getCustomerById(id: number) {
    return this._get(`/customer/${id}`);
  }

  updateCustomer(id: number, data: any) {
    return this._put(`/customer/${id}`, data);
  }

  createCustomer(data: any) {
    return this._post('/customer', data);
  }

  getCustomerByPhone(phoneNumber: string) {
    const phoneEncoded = encodeURIComponent(phoneNumber);
    return this._get(`/customer/phone/${phoneEncoded}`);
  }
}
