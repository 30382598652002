import React, { Component } from 'react';
import TelInput from 'react-telephone-input/lib/withStyles';
import styled, { css } from 'styled-components';
import uuidv4 from 'uuid/v4';

import Flags from '../assets/flags.png';
import theme from '../styles/theme';

type SProps = { isDisabled: boolean; inputState: string; isRequired: boolean; hasLabel: boolean };

const InputStyle = styled.div`
  width: 100%;
  height: auto;

  label {
    font-size: 12px;
    color: #828282;

    .input-title {
      ${(props: SProps) =>
        props.isRequired &&
        css`
          &::after {
            content: ' *';
            display: inline;
            color: #ef5350;
          }
        `};
    }
  }

  .msg-error {
    min-height: 13px;
    margin-top: 8px;
    margin-bottom: 8px;
    color: ${theme.colors.danger};
    font-weight: 400;
  }

  .input-box {
    position: relative;
    display: flex;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    border: 1px solid;
    ${(props: SProps) =>
      props.hasLabel &&
      css`
        margin-top: 8px;
      `}

    input {
      width: 100%;
      height: 100%;
      padding-left: 8px;
      border: none;
      border-radius: 3px;
      font-size: 12px;

      ${(props: SProps) =>
        props.isDisabled &&
        css`
          &:hover {
            cursor: not-allowed;
          }
        `};

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }
      ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: ${theme.colors.neutral4};
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: ${theme.colors.neutral4};
        opacity: 1;
      }
      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: ${theme.colors.neutral4};
        opacity: 1;
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${theme.colors.neutral4};
      }
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${theme.colors.neutral4};
      }

      ::placeholder {
        /* Most modern browsers support this now. */
        color: ${theme.colors.neutral4};
      }
      &[type='time']::-webkit-clear-button {
        display: none;
      }
    }

    &::after {
      position: absolute;
      bottom: 50%;
      right: 8px;
      transform: translateY(50%);
      font-family: 'Material Icons';
      -webkit-font-feature-settings: 'liga';
      font-size: 18px;
      animation: fadeIn 0.333s ease-in;
      @keyframes fadeIn {
        from {
          bottom: 30%;
          opacity: 0;
        }
        to {
          bottom: 50%;
          opacity: 1;
        }
      }
    }

    ${(props: SProps) =>
      props.inputState === 'neutral' &&
      css`
        border-color: ${theme.colors.neutral4};
      `};

    ${(props: SProps) =>
      props.inputState === 'valid' &&
      css`
        border-color: ${theme.colors.success};
        &::after {
          content: 'check_circle';
          color: ${theme.colors.success};
        }
      `};

    ${(props: SProps) =>
      props.inputState === 'invalid' &&
      css`
        border-color: #ef5350;
        &::after {
          content: 'error';
          color: #ef5350;
        }
      `};
  }

  .react-tel-input {
    display: flex;
    flex-direction: row-reverse;
    height: 100% !important;
    width: 100% !important;

    input[type='text'],
    input[type='tel'] {
      height: 100% !important;
      border: 0px;
      box-shadow: none;
    }
    .selected-flag {
      height: 100% !important;
      z-index: 0;
    }
    .flag-dropdown {
      position: relative;
      border: 0px;
      background-color: ${theme.colors.neutral1} !important;
      &:focus {
        outline: rgb(94, 158, 215) auto 5px;
      }
    }
  }
`;

type InputState = 'valid' | 'invalid' | 'neutral';
type InputType = 'text' | 'email' | 'tel' | 'number' | 'time';

type Props = {
  title: string | null;
  value: string;
  type: InputType;
  onChange: (data: any) => void;
  isValid?: boolean;
  isRequired: boolean;
  isDisabled: boolean;
  errorMessage: string;
  placeholder: string;
  hideErrorMsg: boolean;
  id?: string;
};

type State = {
  hasEdited: boolean;
  hasBlurred: boolean;
  id: string;
};

class Input extends Component<Props, State> {
  static defaultProps = {
    type: 'text',
    value: '',
    isRequired: false,
    isDisabled: false,
    errorMessage: '',
    placeholder: '',
    hideErrorMsg: false,
    title: null,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      hasEdited: false,
      hasBlurred: false /* This means user has focused and then blurred */,
      id: props.id || uuidv4(),
    };
  }

  getInputState(isValid?: boolean): InputState {
    if (isValid === undefined) {
      return 'neutral';
    } else if (!isValid) {
      if (!this.state.hasBlurred) {
        return 'neutral'; // Don't show red until user leaves
      }
      return 'invalid';
    }
    return this.state.hasEdited ? 'valid' : 'neutral'; // Don't show green unless user has edited
  }

  render() {
    const { title, type, isRequired, isDisabled, errorMessage } = this.props;
    let { isValid, value, placeholder } = this.props;
    const htmlFor = `inp-${this.state.id}`;

    value = value || '';
    placeholder = placeholder || '';

    // Hardcoded validations below:
    if (isRequired || value) {
      if (type === 'email') {
        isValid = /\S+@\S+\.\S+/.test(value);
      } /* else if (type === 'tel') {
        isValid = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/.test(value.replace(/\D/g, ''));
      } */
    }

    return (
      <InputStyle
        inputState={this.getInputState(isValid)}
        isRequired={isRequired}
        hasLabel={!!this.props.title}
        isDisabled={isDisabled}
        tabIndex={-1}
      >
        <label htmlFor={htmlFor} tabIndex={-1}>
          <div className="input-title" tabIndex={-1}>
            {title}
          </div>
          <div className="input-box" tabIndex={-1}>
            {type === 'tel' ? (
              <TelInput
                title={title}
                placeholder={placeholder}
                defaultCountry="is"
                flagsImagePath={Flags}
                value={value}
                name={htmlFor}
                onChange={(val: any) => {
                  this.setState({ hasEdited: true });
                  this.props.onChange(val);
                }}
                onBlur={() => this.setState({ hasBlurred: true })}
                type="tel"
                disabled={isDisabled}
              />
            ) : (
              <input
                id={htmlFor}
                name={htmlFor}
                placeholder={placeholder || title || ''}
                value={value}
                type={type}
                tabIndex={0}
                onChange={(e) => {
                  this.setState({ hasEdited: true });
                  this.props.onChange(e.target.value);
                }}
                onBlur={() => this.setState({ hasBlurred: true })}
                disabled={isDisabled}
              />
            )}
          </div>
          {!this.props.hideErrorMsg ? (
            <div className="msg-error" tabIndex={-1}>
              {this.getInputState(isValid) === 'invalid' ? errorMessage : ''}
            </div>
          ) : null}
        </label>
      </InputStyle>
    );
  }
}

export default Input;
