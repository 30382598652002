import { AxiosResponse, CancelTokenSource } from 'axios';
import API from './api';

const api = new API();

export namespace CategoryAPINS {
  export interface IRestaurantCategoryWithLanguageModel {
    id: number;
    name: string;
    isDeleted: boolean;
  }
}

export const getAllCategories: (
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<CategoryAPINS.IRestaurantCategoryWithLanguageModel[]>> = (
  cancelToken,
) => {
  return api._get('/restaurantcategory', cancelToken);
};
