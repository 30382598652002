import React from 'react';

const IcelandFlagIcon = ({ size = 20, onClick }: { size?: number; onClick?: () => void }) => (
  <svg viewBox="0 0 512 512" width={size} height={size} onClick={onClick}>
    <circle cx={256} cy={256} r={256} fill="#f0f0f0" />
    <g fill="#0052b4">
      <path d="M8.819 322.783c14.812 54.959 47.457 102.593 91.355 136.322V322.783H8.819zM233.739 511.026A259.29 259.29 0 00256 512c118.279 0 217.805-80.221 247.181-189.217H233.739v188.243zM503.181 189.217C473.805 80.221 374.279 0 256 0c-7.502 0-14.923.342-22.261.974v188.243h269.442zM100.174 52.895c-43.898 33.73-76.543 81.363-91.355 136.322h91.355V52.895z" />
    </g>
    <path
      d="M509.833 222.609H200.348V6.085a254.258 254.258 0 00-66.783 25.042v191.481H2.167C.742 233.539 0 244.683 0 256s.742 22.461 2.167 33.391h131.398v191.481a254.258 254.258 0 0066.783 25.042V289.392h309.485C511.256 278.461 512 267.317 512 256s-.744-22.461-2.167-33.391z"
      fill="#d80027"
    />
  </svg>
);

export default IcelandFlagIcon;
