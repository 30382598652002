import styled, { css } from 'styled-components';
import theme from '../styles/theme';

type SProps = { isAvailable: boolean; isActive: boolean; isSuggested: boolean };

const TableButton = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 36px;
  padding: 8px;
  margin-right: 2px;
  margin-bottom: 2px;
  border-radius: 3px;
  font-size: 11px;
  background-color: ${theme.colors.neutral2};
  user-select: none;
  border: 0px;

  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;

  ${(props: SProps) =>
    !props.isAvailable &&
    css`
      background-color: #eb5757;
      color: #fff;
    `}

  ${(props: SProps) =>
    props.isActive &&
    css`
      background-color: ${theme.colors.success};
      font-weight: 400;
      transition: background-color 66ms ease-in;
      color: #fff;
    `}

  ${(props: SProps) =>
    props.isActive &&
    !props.isAvailable &&
    css`
      border: 3px solid #eb5757;
      border-style: dashed;
      padding: 5px;
      transition: all 66ms ease-in;
      color: #fff;
    `};

  ${(props: SProps) =>
    props.isSuggested &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 30%;
        display: block;
        width: 0px;
        height: 0px;
        opacity: 0;
        border-radius: 50%;
        background-color: ${theme.colors.success};
        animation: tableButtonDotIn 111ms ease-in forwards;

        @keyframes tableButtonDotIn {
          from {
            left: 30%;
            opacity: 0;
            width: 0px;
            height: 0px;
          }
          to {
            left: 50%;
            transform: translateX(-50%);
            opacity: 1;
            width: 6px;
            height: 6px;
          }
        }
      }
    `}

  &:hover {
    cursor: pointer;
  }
  &:focus {
    z-index: 1;
  }
`;

export default TableButton;
