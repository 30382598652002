import { AxiosResponse, CancelTokenSource } from 'axios';
import * as Moment from 'moment-timezone';
import BookingStatusType from '../enums/BookingStatusType';
import { PaymentType } from '../enums/Payment';
import API from './api';

export type DashboardMetaPaymentType = {
  amount: number;
  paymentDate: string | null;
  type: PaymentType;
  canBeCharged: boolean;
  isPaid: boolean;
};

export interface IBookingDashboard {
  bookingEmail: string;
  bookingName: string;
  bookingPhone: string;
  bookingSpecialRequest: string;
  bookingInternalNotes: string;
  contactName: string;
  contactPhone: string;
  end: string;
  guests: number;
  id: number;

  menuName: string | null;
  eventName: string | null;
  hasEvent: boolean | null;
  hasMenu: boolean | null;

  payments?: DashboardMetaPaymentType[];
  paymentLinkSentDate?: string;

  start: string;
  status: string;
  statusId: number;
  sourceId: number;
  tables: Array<{
    floorPlanTableId: number;
    name: string;
  }>;
}

export type DashboardData = {
  bookings: IBookingDashboard[];
  floorPlanTables: Array<{
    id: number;
    isBlurred: boolean;
    maxGuests: number;
    minGuests: number;
    name: string;
  }>;
};

export default class DashboardAPI extends API {
  getTimelineData(date: Moment.Moment): Promise<AxiosResponse<DashboardData>> {
    return this._get(`/dashboard/timeline3/${date.format('YYYY-MM-DD')}`);
  }

  getDashboardMeta(date: Moment.Moment): Promise<
    AxiosResponse<
      Array<{
        id: number;
        eventName: string | null;
        menuName: string | null;

        hasEvent: boolean;
        hasMenu: boolean;

        payments: DashboardMetaPaymentType[];
      }>
    >
  > {
    return this._get(`/dashboard/getdashboardmeta/${date.format('YYYY-MM-DD')}`);
  }

  getCharts(date: Moment.Moment) {
    return this._get(`/dashboard/charts/${date.format('YYYY-MM-DD')}`);
  }

  getStatistics(date: Moment.Moment) {
    return this._get(`/dashboard/statistics/${date.format('YYYY-MM-DD')}`);
  }

  getCalendarReservations(
    dateFrom: string,
    dateTo: string,
  ): Promise<
    AxiosResponse<
      Array<{
        id: number;
        guests: number;
        start: string;
        floorPlanName: string;
        statusId: BookingStatusType;
      }>
    >
  > {
    return this._get(`/dashboard/v2/calendar/${dateFrom}/${dateTo}`);
  }

  getReservationInfo(reservationId: number) {
    return this._get(`/dashboard/reservationinfo/${reservationId}`);
  }

  getRestaurantNote(date: Moment.Moment) {
    return this._get(`/dashboard/restaurantnote/${date.format('YYYY-MM-DD')}`);
  }

  updateBookingFromTimeline(bookingId: number, newStart: Date, newTableIds: number[]) {
    return this._put('/dashboard/updatebookingfromtimeline', {
      id: bookingId,
      newStart,
      newTableIds,
    });
  }

  hasOpeningHoursOverride(
    date: string,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<boolean>> {
    return this._get(`/dashboard/hasopeninghoursoverride/${date}`, cancelToken);
  }
}

const api = new API();

export const setWaitingListTimes = (
  dates: Moment.Moment[],
  cancelToken: CancelTokenSource,
): Promise<AxiosResponse<boolean>> => {
  return api._post('/waitinglisttime', dates, cancelToken);
};
