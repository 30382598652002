import styled, { css } from 'styled-components';

type SProps = { buttonWidth: number; compact?: boolean };

const GroupButtonsStyle = styled.div`
  display: flex;
  margin-bottom: 29px;
  align-items: center;
  border: 1px solid #ebebeb;
  border-radius: 3px;

  &,
  & * {
    outline: none;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(props: SProps) => `${props.buttonWidth}px` || 'auto'};
    height: 40px;
    flex-grow: 1;

    background-color: #fff;
    font-size: 12px;
    letter-spacing: 0.3px;
    transition: all 0.06s ease-in-out;

    &:nth-child(even) {
      border-left: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
    }

    &:first-child {
      border-right: none;
    }
    &:last-child {
      border-right: none;
    }

    &.active {
      background-color: #fafafa;
      font-weight: 500;
    }
    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      cursor: pointer;
    }

    ${(props: SProps) =>
      props.compact &&
      css`
        height: 32px !important;
        margin-top: 0px !important;
      `};
  }
`;

export default GroupButtonsStyle;
