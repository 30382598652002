import { AxiosResponse, CancelTokenSource } from 'axios';
import { PaymentType } from '../enums/Payment';
import API from './api';

export type MenuListItem = {
  id: number;
  name: string;
  isPublished: boolean;
  floorPlanName: string;
};

export interface IMenu {
  id: number;
  name: string;
  shortDescription: string;
  paymentType: PaymentType;
  paymentAmount: number | undefined;
  isPublished: boolean;
  minGuests: number;
  isExclusive: boolean;
  floorPlanId: number;
  onlineDurationMinutes: number | undefined;
}

export interface IMenuTime {
  id: number;
  menuId: number;
  time: string;
}

export interface IMenuWithTimes extends IMenu {
  times: IMenuTime[];
}

export default class MenuAPI extends API {
  getRestaurantMenus(
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<{ menus: MenuListItem[] }>> {
    return this._get('/menu', cancelToken);
  }

  getMenu(menuId: number, cancelToken: CancelTokenSource): Promise<AxiosResponse<IMenuWithTimes>> {
    return this._get(`/menu/${menuId}`, cancelToken);
  }

  createMenu(
    menu: IMenuWithTimes,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<{ success: boolean }>> {
    return this._post('/menu', menu, cancelToken);
  }

  editMenu(
    menu: IMenuWithTimes,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<{ success: boolean }>> {
    return this._put('/menu', menu, cancelToken);
  }

  deleteMenu(menuId: number): Promise<AxiosResponse<boolean>> {
    return this._delete(`/menu/${menuId}`);
  }
}
