import Axios, { CancelTokenSource } from 'axios';
import { inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styled from 'styled-components';
import {
  createFloorPlan,
  deleteFloorPlan,
  FloorPlanAPINS,
  getFloorPlan,
  updateFloorPlan,
} from '../../api/floorplan.api';
import Checkbox from '../../components/Checkbox';
import CheckboxNew from '../../components/CheckboxNew';
import Button from '../../components/common/Button';
import Input from '../../components/common/Input';
import Label from '../../components/common/Label';
import NumberStepper from '../../components/NumberStepper';
import SelectStyle from '../../components/Select.styled';
import TabsStyle from '../../components/Tabs.styled';
import { IRootStore } from '../../stores/RootStore';
import { FlashMessageType } from '../../stores/UIStore';
import { UserRole } from '../../stores/UserStore';
import theme from '../../styles/theme';

const Style = styled.div`
  padding-bottom: 64px;

  .space-below {
    margin-bottom: 8px;
  }
  .half-width {
    width: 50%;
  }

  i.delete {
    &:hover {
      cursor: pointer !important;
    }
  }

  .fp-create-item {
    width: 200px;
    margin-top: 32px;
  }

  h3 {
    :not(:first-child) {
      margin-top: 40px;
    }
  }

  .fp-table-headers {
    display: flex;
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 500;

    > div:nth-child(1) {
      margin-left: 24px;
      width: 188px;
    }
    > div:nth-child(2),
    > div:nth-child(3) {
      width: 120px;
    }
  }

  .fp-table-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2px;

    > *:not(:first-child) {
      margin-right: 8px;
    }
  }

  .sel-priority {
    width: 100px;
    margin-bottom: 0px;
  }

  /* Combinations */
  .fp-combo-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 8px;

    > * {
      margin-right: 8px;
    }

    .fp-combo-table-selector {
      width: 400px;
      .Select-control {
        height: auto !important;
        padding: 8px;
      }
    }
  }

  #fp-delete {
    background-color: ${theme.colors.danger};
    width: 140px;
  }

  div.label-wrapper {
    display: flex;
    align-items: center;

    > label {
      margin-right: 4px;
    }

    > i {
      color: ${theme.colors.warning};
      font-size: 20px;
    }
  }
`;

let crudCancelToken: CancelTokenSource | null = null;

const reorder = (list: FloorPlanAPINS.IFloorPlanTable[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result
    .map((x, i) => {
      x.order = i;
      return x;
    })
    .sort((a, b) => a.order - b.order);
};

// Filters out combos with deleted tables, so it can be deleted on save
const filterOrphanCombos = (
  combos: FloorPlanAPINS.IFloorPlanCombinationWithTables[],
  allTables: FloorPlanAPINS.IFloorPlanTable[],
) => {
  return combos.filter((combo) => {
    const tablesInCombo = allTables.filter((t) =>
      combo.tables.map((ct) => ct.floorPlanTableId).includes(t.id),
    );
    if (tablesInCombo.filter((x) => x.isDeleted).length > 0) {
      return false;
    }
    return true;
  });
};

let deleteFloorPlanCancelToken: CancelTokenSource | null = null;

const FloorPlan = ({
  match,
  store,
  history,
}: RouteComponentProps<{ id: string }> & { store: IRootStore }) => {
  const floorPlanId: number = match.params.id ? parseInt(match.params.id, 10) : -1;
  const [floorPlan, setFloorPlan] = useState<FloorPlanAPINS.IFloorPlan>({
    id: -1,
    restaurantId: -1,
    isDeleted: false,
    name: '',
    description: '',
    allowALaCarte: false,
    allowAutoCombos: false,
  });
  const [tables, setTables] = useState<FloorPlanAPINS.IFloorPlanTable[]>([
    {
      id: -1,
      floorPlanId: -1,
      name: 'New table',
      minGuests: 1,
      maxGuests: 2,
      priority: 1,
      isBlurred: false,
      isDeleted: false,
      order: 0,
    },
  ]);
  const [combinations, setCombinations] = useState<
    FloorPlanAPINS.IFloorPlanCombinationWithTables[]
  >([]);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(floorPlanId > 0 ? true : false);
  const [isDefaultFloorPlan, setIsDefaultFloorPlan] = useState(false);

  // Fetch floor plan
  useEffect(() => {
    const cancelToken = Axios.CancelToken.source();

    if (floorPlanId > 0) {
      getFloorPlan(floorPlanId, cancelToken)
        .then((res) => {
          setFloorPlan(res.data.floorPlan);

          // Initialize sort order so it begins at 0
          const tablesTemp = res.data.tables
            .sort((a, b) => a.order - b.order)
            .map((x, i) => {
              x.order = i;
              return x;
            });
          setTables(tablesTemp);

          setCombinations(
            filterOrphanCombos(res.data.combinations, tablesTemp).sort((a, b) => b.id - a.id),
          );
          setIsDefaultFloorPlan(res.data.isDefaultFloorPlan);

          setIsLoading(false);
        })
        .catch((e) => {
          if (!Axios.isCancel(e)) {
            store.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Could not fetch floor plans. Please try again or contact us.',
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Cleanup
  useEffect(() => {
    return () => {
      if (crudCancelToken) {
        crudCancelToken.cancel();
      }
      if (deleteFloorPlanCancelToken) {
        deleteFloorPlanCancelToken.cancel();
      }
    };
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const createOrUpdate = () => {
    if (floorPlanId === -1) {
      // CREATE
      setIsSaving(true);
      crudCancelToken = Axios.CancelToken.source();

      createFloorPlan(
        {
          floorPlan,
          tables,
        },
        crudCancelToken,
      )
        .then((res) => {
          setIsSaving(false);
          if (!res.data) {
            store.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Could not create floor plan.',
              timeout: 10000,
            });
          } else {
            store.UIStore.addFlashMessage({
              type: FlashMessageType.Success,
              text: 'Floor plan successfully created.',
            });

            history.replace('/floorplan');
          }
        })
        .catch((e) => {
          if (!Axios.isCancel(e)) {
            store.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Error creating floor plan.',
              timeout: 10000,
            });
          }
          setIsSaving(false);
        });
    } else {
      // UPDATE
      setIsSaving(true);
      crudCancelToken = Axios.CancelToken.source();

      updateFloorPlan(
        {
          floorPlan,
          combinations,
          tables,
        },
        crudCancelToken,
      )
        .then((res) => {
          setIsSaving(false);

          if (!res.data) {
            store.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Could not update floor plan. Please try again.',
              timeout: 10000,
            });
          } else {
            store.UIStore.addFlashMessage({
              type: FlashMessageType.Success,
              text: 'Floor plan successfully updated.',
            });

            history.push('/floorplan');
          }
        })
        .catch((e) => {
          if (!Axios.isCancel(e)) {
            store.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Error updating floor plan.',
              timeout: 10000,
            });
          }
          setIsSaving(false);
        });
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reordered = reorder(tables, result.source.index, result.destination.index);
    setTables(reordered);
  };

  const onTableChange = (
    field: 'name' | 'minGuests' | 'maxGuests' | 'isBlurred' | 'priority' | 'isDeleted',
    tableId: number,
    value: any,
  ) => {
    const table = tables.find((x) => x.id === tableId);

    if (!table) {
      return;
    }

    switch (field) {
      case 'name':
        table.name = value;
        break;
      case 'isBlurred':
        table.isBlurred = value;
        break;
      case 'minGuests': {
        table.minGuests = value;
        table.maxGuests = value > table.maxGuests ? value : table.maxGuests; // Prevent max going lower than min
        break;
      }
      case 'maxGuests': {
        table.maxGuests = value;
        table.minGuests = value < table.minGuests ? value : table.minGuests; // Prevent minGuests going lower than max
        break;
      }
      case 'priority': {
        table.priority = value;
        break;
      }
      case 'isDeleted': {
        if (confirm('Are you sure you want to delete this table?')) {
          table.isDeleted = true;
        }
      }
      default:
        break;
    }

    setTables([...tables.filter((x) => x.id !== tableId), table]);

    // Filter out combos which contain deleted or blurred tables
    if (field === 'isDeleted' || field === 'isBlurred') {
      setCombinations(filterOrphanCombos(combinations, tables));
    }
  };

  const onComboChange = (
    field: 'tableIds' | 'minGuests' | 'maxGuests' | 'priority',
    comboId: number,
    value: any,
  ) => {
    const combo = combinations.find((x) => x.id === comboId);

    if (!combo) {
      return;
    }

    switch (field) {
      case 'tableIds': {
        const tableIds: number[] = (value as string).split(',').map((x) => parseInt(x, 10));
        const newComboTables: FloorPlanAPINS.IFloorPlanCombinationTable[] = tableIds.map(
          (x, i) => ({
            id: -i,
            floorPlanTableId: x,
            floorPlanCombinationId: comboId,
          }),
        );

        combo.tables = newComboTables;
        break;
      }
      case 'minGuests': {
        combo.minGuests = value;
        combo.maxGuests = value > combo.maxGuests ? value : combo.maxGuests; // Prevent max going lower than min
        break;
      }
      case 'maxGuests': {
        combo.maxGuests = value;
        combo.minGuests = value < combo.minGuests ? value : combo.minGuests; // Prevent minGuests going lower than max
        break;
      }
      case 'priority':
        combo.priority = value;
        break;
      default:
        break;
    }

    setCombinations(
      [...combinations.filter((x) => x.id !== comboId), combo].sort((a, b) => b.id - a.id),
    );
  };

  const addCombo = () => {
    const sorted = combinations.sort((a, b) => b.id - a.id);
    let newId = -1;
    if (sorted.length > 0 && sorted[sorted.length - 1].id < 0) {
      newId = sorted[sorted.length - 1].id - 1;
    }

    const newCombo: FloorPlanAPINS.IFloorPlanCombinationWithTables = {
      id: newId,
      floorPlanId: floorPlan.id,
      priority: 1,
      minGuests: 1,
      maxGuests: 1,
      tables: [],
    };
    setCombinations([...combinations, newCombo]);
  };

  const addTable = () => {
    const sorted = tables.sort((a, b) => b.id - a.id);
    let newId = -1;
    if (sorted.length > 0 && sorted[sorted.length - 1].id < 0) {
      newId = sorted[sorted.length - 1].id - 1;
    }

    const sortedByOrder = tables.sort((a, b) => a.order - b.order);
    let newOrderId = 0;
    if (sortedByOrder.length > 0) {
      newOrderId = sortedByOrder[sortedByOrder.length - 1].order + 1;
    }

    const newTable: FloorPlanAPINS.IFloorPlanTable = {
      id: newId,
      floorPlanId: floorPlan.id,
      priority: 3,
      name: 'New table',
      minGuests: 1,
      maxGuests: 2,
      isBlurred: false,
      order: newOrderId,
      isDeleted: false,
    };
    setTables([...tables, newTable]);
  };

  const deleteFp = () => {
    if (confirm('Are you sure you want to delete this floor plan?')) {
      deleteFloorPlanCancelToken = Axios.CancelToken.source();

      deleteFloorPlan(floorPlan.id, deleteFloorPlanCancelToken)
        .then((res) => {
          if (res.data) {
            store.UIStore.addFlashMessage({
              type: FlashMessageType.Success,
              text: 'Floor plan successfully deleted.',
            });

            history.replace('/floorplan');
          } else {
            store.UIStore.addFlashMessage({
              type: FlashMessageType.Info,
              text: 'This floor plan cannot be deleted.',
              timeout: 10000,
            });
          }
        })
        .catch((e) => {
          if (!Axios.isCancel(e)) {
            store.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Error deleting floor plan.',
              timeout: 10000,
            });
          }
        });
    }
  };

  return (
    <Style>
      <h1>Room</h1>
      <Button
        id="fp-btn-save"
        disabled={!floorPlan.name || tables.filter((x) => !x.isDeleted).length === 0}
        isLoading={isSaving}
        className="btn-cta"
        onClick={() => createOrUpdate()}
      >
        Save
      </Button>
      <sub>Modify or delete room.</sub>

      <TabsStyle>
        <Tabs>
          <TabList>
            <Tab>Setup</Tab>
            <Tab disabled={floorPlan.id < 1}>Table Combinations</Tab>
            <Tab disabled={floorPlan.id < 1 || isDefaultFloorPlan}>Delete</Tab>
          </TabList>

          <TabPanel>
            <h3>Basic info</h3>
            <div className="space-below half-width">
              <Label>Name</Label>
              <Input
                id="fp-name"
                placeholder="Name"
                value={floorPlan.name}
                onChange={(v) => setFloorPlan({ ...floorPlan, name: v })}
                maxLength={100}
              />
            </div>

            <div className="space-below half-width">
              <Label>Description</Label>
              <Input
                id="fp-description"
                placeholder="Description"
                value={floorPlan.description}
                onChange={(v) => setFloorPlan({ ...floorPlan, description: v })}
                maxLength={100}
              />
            </div>

            {store?.UIStore.userStore.user?.role === UserRole.Admin && (
              <div className="space-below half-width">
                <div className="label-wrapper">
                  <Label>Allow auto combinations?</Label>
                  <i className="material-icons">warning</i>
                </div>
                <CheckboxNew
                  id="fp-allow-autocombos"
                  title="Allow any possible combination of tables and their summed guests."
                  checked={floorPlan.allowAutoCombos}
                  onChange={() =>
                    setFloorPlan({
                      ...floorPlan,
                      allowAutoCombos: !floorPlan.allowAutoCombos,
                    })
                  }
                />
              </div>
            )}

            <h3>Tables</h3>
            <div>
              <div className="fp-table-headers">
                <div>Name</div>
                <div>Min. guests</div>
                <div>Max. guests</div>
                <div>Priority</div>
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(providedOuter) => (
                    <>
                      <div ref={providedOuter.innerRef} {...providedOuter.droppableProps}>
                        {tables
                          .filter((x) => !x.isDeleted)
                          .sort((a, b) => a.order - b.order)
                          .map((x) => (
                            <Draggable
                              draggableId={x.id.toString()}
                              index={x.order}
                              key={`fp-table-${x.id}`}
                            >
                              {(providedInner) => (
                                <div
                                  className="fp-table-wrapper"
                                  ref={providedInner.innerRef}
                                  {...providedInner.draggableProps}
                                  {...providedInner.dragHandleProps}
                                >
                                  <i className="material-icons">drag_indicator</i>
                                  <Input
                                    id={`fp-t-name-${x.id}`}
                                    value={x.name}
                                    onChange={(v) => onTableChange('name', x.id, v)}
                                  />
                                  <NumberStepper
                                    value={x.minGuests}
                                    onChange={(v) => onTableChange('minGuests', x.id, v)}
                                  />
                                  <NumberStepper
                                    value={x.maxGuests}
                                    onChange={(v) => onTableChange('maxGuests', x.id, v)}
                                  />
                                  <SelectStyle className="sel-priority">
                                    <Select
                                      name="priority"
                                      onChange={(sel: any) =>
                                        onTableChange('priority', x.id, sel.value)
                                      }
                                      value={x.priority}
                                      clearable={false}
                                      options={[
                                        { value: 1, label: 'Lowest' },
                                        { value: 2, label: 'Low' },
                                        { value: 3, label: 'Medium' },
                                        { value: 4, label: 'High' },
                                        { value: 5, label: 'Highest' },
                                      ]}
                                    />
                                  </SelectStyle>
                                  <Checkbox
                                    title="Is blurred?"
                                    onChange={(checked) =>
                                      onTableChange('isBlurred', x.id, checked)
                                    }
                                    isChecked={x.isBlurred}
                                    name={`fp-t-isblrd-${x.id}`}
                                  />
                                  <i
                                    title="Delete table"
                                    className="delete material-icons"
                                    onClick={() => onTableChange('isDeleted', x.id, null)}
                                  >
                                    delete
                                  </i>
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                      {providedOuter.placeholder}
                    </>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            <Button id="fp-create-table" className="fp-create-item" onClick={() => addTable()}>
              Add table
            </Button>
          </TabPanel>

          <TabPanel>
            {combinations
              .sort((a, b) => b.id - a.id)
              .map((x) => (
                <div className="fp-combo-wrapper" key={`fp-combo-${x.id}`}>
                  <Select
                    className="fp-combo-table-selector"
                    simpleValue
                    multi
                    value={x.tables.map((t) => t.floorPlanTableId)}
                    onChange={(ids) => onComboChange('tableIds', x.id, ids)}
                    options={tables
                      .filter((t) => t.id > 0 && !t.isDeleted)
                      .map((t) => ({ label: t.name, value: t.id }))}
                    placeholder="Select tables"
                  />
                  <NumberStepper
                    value={x.minGuests}
                    onChange={(v) => onComboChange('minGuests', x.id, v)}
                  />
                  <NumberStepper
                    value={x.maxGuests}
                    onChange={(v) => onComboChange('maxGuests', x.id, v)}
                  />
                  <SelectStyle className="sel-priority">
                    <Select
                      name="priority"
                      onChange={(sel: any) => onComboChange('priority', x.id, sel.value)}
                      value={x.priority}
                      clearable={false}
                      options={[
                        { value: 1, label: 'Lowest' },
                        { value: 2, label: 'Low' },
                        { value: 3, label: 'Medium' },
                        { value: 4, label: 'High' },
                        { value: 5, label: 'Highest' },
                      ]}
                    />
                  </SelectStyle>
                  <i
                    title="Delete combination"
                    className="delete material-icons"
                    onClick={() =>
                      confirm('Are you sure you want to delete this combination?')
                        ? setCombinations(combinations.filter((c) => c.id !== x.id))
                        : {}
                    }
                  >
                    delete
                  </i>
                </div>
              ))}

            <Button id="fp-create-combo" className="fp-create-item" onClick={() => addCombo()}>
              Add combination
            </Button>
          </TabPanel>

          <TabPanel>
            <h3>Delete floor plan</h3>
            <Button id="fp-delete" onClick={() => deleteFp()}>
              Delete
            </Button>
          </TabPanel>
        </Tabs>
      </TabsStyle>
    </Style>
  );
};

export default inject('store')(FloorPlan);
