import Axios, { CancelTokenSource } from 'axios';
import { inject } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  CountryCodeType,
  getAllCategoryTranslations,
  saveCategoryTranslations,
  TranslationAPINS,
} from '../../api/translation.api';
import Button from '../../components/common/Button';
import RootStore from '../../stores/RootStore';
import { FlashMessageType } from '../../stores/UIStore';

const Style = styled.div`
  padding-bottom: 64px;

  .cat-trans-header {
    position: sticky;
    top: 0px;
  }

  table {
    width: 100%;
    margin-top: 64px;
  }
`;

let fetchCancelToken: CancelTokenSource | null = null;
let saveCancelToken: CancelTokenSource | null = null;

const Categories: FC<{ store: RootStore }> = ({ store }) => {
  const [allCategoryTranslations, setAllCategoryTranslations] = useState<
    Array<{
      restaurantCategoryId: number;
      translations: TranslationAPINS.IRestaurantCategoryLanguageModel[];
    }>
  >([]);
  const [isSaving, setIsSaving] = useState(false);

  // Cleanup
  useEffect(() => {
    return () => {
      if (saveCancelToken) {
        saveCancelToken.cancel();
      }
      if (fetchCancelToken) {
        fetchCancelToken.cancel();
      }
    };
  }, []);

  // Fetch all category translations
  useEffect(() => {
    fetchAllCategoryTranslations();

    return () => {
      if (fetchCancelToken) {
        fetchCancelToken.cancel();
      }
    };
  }, []);

  const fetchAllCategoryTranslations = () => {
    setAllCategoryTranslations([]);

    if (fetchCancelToken) {
      fetchCancelToken.cancel();
    }

    fetchCancelToken = Axios.CancelToken.source();

    getAllCategoryTranslations(fetchCancelToken)
      .then((res) => {
        const formatted = res.data.map((x) => {
          // Add objects for missing translations
          const is = x.translations.find((i) => i.language === 'is');
          if (!is) {
            x.translations.push({
              id: -1,
              language: 'is',
              name: '',
              restaurantCategoryId: x.restaurantCategoryId,
            });
          }

          const es = x.translations.find((i) => i.language === 'es');
          if (!es) {
            x.translations.push({
              id: -1,
              language: 'es',
              name: '',
              restaurantCategoryId: x.restaurantCategoryId,
            });
          }

          return x;
        });

        setAllCategoryTranslations(formatted);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          alert('Error fetching category translations.');
        }
      });
  };

  const updateTranslation = (
    restaurantCategoryId: number,
    language: CountryCodeType,
    value: string,
  ) => {
    const updated = allCategoryTranslations.map((x) => {
      if (x.restaurantCategoryId === restaurantCategoryId) {
        x.translations = x.translations.map((t) => {
          if (t.language === language) {
            t.name = value;
          }
          return t;
        });
      }

      return x;
    });

    setAllCategoryTranslations(updated);
  };

  const saveTranslations = () => {
    setIsSaving(true);

    if (saveCancelToken) {
      saveCancelToken.cancel();
    }

    saveCancelToken = Axios.CancelToken.source();

    const flattened = allCategoryTranslations
      .map((x) => x.translations)
      .reduce((accumulator, value) => accumulator.concat(value), []);

    saveCategoryTranslations(flattened, saveCancelToken)
      .then((res) => {
        if (res.status === 206) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Warning,
            text: 'Partially saved. Could not save all translations. Please try again.' + res.data,
            timeout: 10000,
          });
        } else {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Success,
            text: 'Translations successfully saved',
          });
        }

        if (!saveCancelToken) {
          saveCancelToken = Axios.CancelToken.source();
        }

        fetchAllCategoryTranslations();
        setIsSaving(false);
      })
      .catch((e) => {
        fetchAllCategoryTranslations();
        setIsSaving(false);

        if (!Axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Error saving translations.',
            timeout: 10000,
          });
        }
      });
  };

  if (allCategoryTranslations.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Style>
      <h1>Category translations</h1>
      <sub>Translate category names to different languages.</sub>

      <div className="cat-trans-header">
        <Button id="btn-save-categories" isLoading={isSaving} onClick={() => saveTranslations()}>
          Save
        </Button>
      </div>

      <table>
        <thead>
          <tr>
            <th>English</th>
            <th>Icelandic</th>
            <th>Spanish</th>
          </tr>
        </thead>
        <tbody>
          {allCategoryTranslations.map((x) => {
            const html = x.translations.map((t) => (
              <td key={`trans-${x.restaurantCategoryId}-${t.language}`}>
                <input
                  value={t.name}
                  onChange={(val) =>
                    updateTranslation(x.restaurantCategoryId, t.language, val.currentTarget.value)
                  }
                />
              </td>
            ));
            return <tr key={`cat-${x.restaurantCategoryId}`}>{html}</tr>;
          })}
        </tbody>
      </table>
    </Style>
  );
};

export default inject('store')(Categories);
