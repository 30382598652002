import { AxiosResponse, CancelTokenSource } from 'axios';
import API from './api';

const api = new API();

export namespace FloorPlanAPINS {
  export interface IFloorPlan {
    id: number;
    restaurantId: number;
    name: string;
    description: string;
    isDeleted: boolean;
    allowALaCarte: boolean;
    allowAutoCombos: boolean;
  }

  export interface IFloorPlanTable {
    id: number;
    floorPlanId: number;
    name: string;
    priority: number;
    minGuests: number;
    maxGuests: number;
    isBlurred: boolean;
    order: number;
    isDeleted: boolean;
  }

  export interface IFloorPlanCombination {
    id: number;
    floorPlanId: number;
    priority: number;
    minGuests: number;
    maxGuests: number;
  }

  export interface IFloorPlanCombinationTable {
    id: number;
    floorPlanCombinationId: number;
    floorPlanTableId: number;
  }

  export interface IFloorPlanCombinationWithTables extends IFloorPlanCombination {
    tables: IFloorPlanCombinationTable[];
  }

  export interface IFloorPlanWithTableAndComboCount extends IFloorPlan {
    tablesCount: number;
    combosCount: number;
  }
}

export const getFloorPlanTablesFlat: () => Promise<
  AxiosResponse<Array<FloorPlanAPINS.IFloorPlanTable & { floorPlanName: string }>>
> = () => {
  return api._get('/floorplan/tablesflat');
};

export const getFloorPlans: (
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<FloorPlanAPINS.IFloorPlanWithTableAndComboCount[]>> = (cancelToken) => {
  return api._get('/floorplan', cancelToken);
};

export const getFloorPlan: (
  floorPlanId: number,
  cancelToken: CancelTokenSource,
) => Promise<
  AxiosResponse<{
    floorPlan: FloorPlanAPINS.IFloorPlan;
    tables: FloorPlanAPINS.IFloorPlanTable[];
    combinations: FloorPlanAPINS.IFloorPlanCombinationWithTables[];
    isDefaultFloorPlan: boolean;
  }>
> = (floorPlanId, cancelToken) => {
  return api._get(`/floorplan/${floorPlanId}`, cancelToken);
};

export const updateFloorPlan: (
  data: {
    floorPlan: FloorPlanAPINS.IFloorPlan;
    tables: FloorPlanAPINS.IFloorPlanTable[];
    combinations: FloorPlanAPINS.IFloorPlanCombinationWithTables[];
  },
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<boolean>> = (data, cancelToken) => {
  return api._put('/floorplan', data, cancelToken);
};

export const createFloorPlan: (
  data: { floorPlan: FloorPlanAPINS.IFloorPlan; tables: FloorPlanAPINS.IFloorPlanTable[] },
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<boolean>> = (data, cancelToken) => {
  return api._post('/floorplan', data, cancelToken);
};

export const deleteFloorPlan: (
  floorPlanId: number,
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<boolean>> = (floorPlanId, cancelToken) => {
  return api._delete(`/floorplan/${floorPlanId}`, cancelToken);
};

export default class FloorPlanAPI extends API {
  updateFloorPlan(floorPlanId: number, data: any[]): Promise<any> {
    return this._put(`/floorplan/${floorPlanId}`, data);
  }

  getFloorPlanByRestaurantId(restaurantId: number): Promise<any> {
    return this._get(`/floorplan/restaurant/${restaurantId}`);
  }

  getFloorPlanForCombinationsByRestaurantId(restaurantId: number): Promise<any> {
    return this._get(`/floorplan/forcombinations/restaurant/${restaurantId}`);
  }

  createFloorPlanCombination(floorPlanId: number, data: any): Promise<any> {
    return this._post(`/floorplan/${floorPlanId}/combination`, data);
  }

  updateFloorPlanCombination(combinationId: number, data: any): Promise<any> {
    return this._put(`/floorplan/combination/${combinationId}`, data);
  }

  deleteFloorPlanCombination(combinationId: number): Promise<any> {
    return this._delete(`/floorplan/combination/${combinationId}`);
  }

  getFloorPlanTables() {
    return this._get('/floorplan/tables');
  }
}
