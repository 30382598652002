import React, { Component } from 'react';
import styled from 'styled-components';

import theme from '../../styles/theme';
import Message from './Message.styled';

const MessageStyle = styled(Message)`
  box-shadow: 0px 3px 4px rgba(159, 223, 113, 0.2);
  i {
    color: ${theme.colors.success};
  }
`;

type Props = {
  text: string;
  title?: string;
};

export default class SuccessMessage extends Component<Props> {
  render() {
    return (
      <MessageStyle>
        <div>
          <i className="material-icons">check_circle</i>
        </div>
        <div>
          <b>{this.props.title || 'Success!'}</b>
          <p>{this.props.text}</p>
        </div>
      </MessageStyle>
    );
  }
}
