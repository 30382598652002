import axios from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';
import styled from 'styled-components';
import { getAllRestaurants } from '../api/restaurant.api';
import UserAPI from '../api/user.api';
import SelectStyle from '../components/Select.styled';
import { IUser } from '../stores/UserStore';

const Style = styled.div`
  position: fixed;
  width: 400px;
  bottom: 0px;
  right: 0px;
  padding-bottom: 180px;
  background-color: #fff;
  box-shadow: 0 0 20px -10px black;
  border-radius: 3px;
  z-index: 9999999999;

  h3 {
    width: 100%;
    padding: 8px;
    margin: 0px;
    border-bottom: 1px solid #ebebeb;
  }

  .at-section {
    padding: 8px;
    select {
      width: 100%;
    }
  }
`;

const userApi = new UserAPI();

interface IProps extends RouteComponentProps<any> {
  user: IUser;
}

const AdminTools: FunctionComponent<IProps> = ({ user, history }) => {
  const [allRestaurants, setAllRestaurants] = useState<
    Array<{
      id: number;
      name: string;
      email: string;
    }>
  >([]);

  const [selectedRestaurantId, setSelectedRestaurantId] = useState<number | undefined>(
    user.restaurantId,
  );

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    getAllRestaurants(cancelToken)
      .then((res) => {
        setAllRestaurants(res.data);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          console.log('Error');
        }
      });

    return () => cancelToken.cancel();
  }, []);

  useEffect(() => {
    if (user.restaurantId === selectedRestaurantId) {
      return;
    }

    const changeAdminRestCancelToken = axios.CancelToken.source();
    const revokeRefreshTokenCancelToken = axios.CancelToken.source();

    // First we revoke user's refresh token
    userApi
      .revokeRefreshToken(user.firebaseUId, revokeRefreshTokenCancelToken)
      .then((res) => {
        if (res.status === 200) {
          // Then we change claims in token
          userApi
            .changeAdminRestaurant(
              selectedRestaurantId ? selectedRestaurantId : null,
              changeAdminRestCancelToken,
            )
            .then((resTwo) => {
              if (resTwo.data) {
                history.replace('/logout');
              }
            });
        } else {
          alert('Could not revoke refresh token');
        }
      })
      .catch((e) => {
        console.log('Revoke error', e);
        alert('Could not revoke refresh token (catch)');
      });

    return () => {
      changeAdminRestCancelToken.cancel();
      revokeRefreshTokenCancelToken.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId]);

  return (
    <Style>
      <h3>Admin tools (CMD + K)</h3>

      <div className="at-section">
        <h4>Act as:</h4>
        <SelectStyle>
          <Select
            value={selectedRestaurantId}
            onChange={(val: any) => setSelectedRestaurantId(val.value)}
            options={[
              { value: 0, label: 'None' },
              ...allRestaurants
                .sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .map((x) => ({ value: x.id, label: x.name })),
            ]}
            placeholder="Restaurants"
            searchable
          />
        </SelectStyle>
      </div>
    </Style>
  );
};

export default withRouter(AdminTools);
