import styled from 'styled-components';
import theme from '../../styles/theme';

const Message = styled.div`
  display: flex;
  min-width: 280px;
  min-height: 80px;
  margin-bottom: 16px;
  background-color: ${theme.colors.neutral3};
  animation-name: showMessage;
  animation-duration: 0.3s;
  z-index: 999999999;

  @keyframes showMessage {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      width: 64px;
      border: 1px solid ${theme.colors.neutral1};
      i {
        font-size: 36px;
      }
    }
    &:last-child {
      flex-direction: column;
      max-width: 216px;
      padding: 8px 8px 8px 16px;

      * {
        width: 100%;
        padding: 0px;
        margin: 0px;
      }
      b {
        margin-bottom: 3px;
      }
      p {
        font-size: 12px;
      }
    }
  }
`;

export default Message;
