import * as firebase from 'firebase/app';
import 'firebase/auth';
import React, { Component } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import { inject, observer } from 'mobx-react';
import { UserAPINS } from '../api/user.api';
import { IRootStore } from '../stores/RootStore';
import { UserRole } from '../stores/UserStore';
import Utils from '../utils';
import Button from './Button';
import Input from './Input';
import SelectStyle from './Select.styled';

const TableStyle = styled.table`
  width: 100%;
  margin-bottom: 24px;

  th {
    text-align: left;
  }

  tbody {
    tr > td {
      height: 50px;
    }

    td.role {
      > div {
        width: 152px;
        margin-bottom: 0px;
      }
    }
  }
`;

type OptionItem = {
  label: string;
  value: any;
};

type Props = {
  users: UserAPINS.IUserAdminModelWithPassword[];
  onChange: (users: UserAPINS.IUserAdminModelWithPassword[]) => void;
  store?: IRootStore;
};

type State = {
  name: string;
  email: string;
  password: string;
  role: UserAPINS.UserAdminRole;
  passwordResetEmailLoading: string | null;
};

@inject('store')
@observer
export default class Users extends Component<Props, State> {
  allUserRoles: OptionItem[];

  constructor(props: Props) {
    super(props);

    this.allUserRoles = new Utils()
      .getAllUserRoles()
      .filter((x) => x.name !== 'Admin')
      .map((x) => ({ label: x.name, value: x.id }));

    this.state = {
      name: '',
      email: '',
      password: '',
      role: 3,
      passwordResetEmailLoading: null,
    };
  }

  addNewUser() {
    const newUser: UserAPINS.IUserAdminModelWithPassword = {
      id: -1,
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      role: this.state.role,
      firebaseUId: '',
      isDeleted: false,
      restaurantId: -1,
    };

    this.props.onChange([...this.props.users, newUser]);

    this.setState({
      name: '',
      email: '',
      password: '',
      role: UserAPINS.UserAdminRole.Manager,
    });
  }

  render() {
    const { email, name, password, passwordResetEmailLoading } = this.state;
    const isEmailValid = /\S+@\S+\.\S+/.test(email);
    const addButtonEnabled = name.length > 0 && isEmailValid && password.length >= 6;

    const { proxyRole } = this.props.store!.RestaurantStore.user!;

    const isAdmin = proxyRole === UserRole.Admin;
    const isManager = proxyRole === UserRole.Manager;

    return (
      <div>
        <TableStyle>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Password</th>
              <th>Role</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.users.map((user) => (
              <tr key={`tr-${user.email}`}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>***</td>
                <td className="role">
                  {this.allUserRoles.find((x) => x.value === user.role)?.label}
                </td>
                <td>
                  {isAdmin ||
                    (isManager && (
                      <div
                        style={{
                          cursor: 'pointer',
                          opacity: passwordResetEmailLoading === user.email ? 0.2 : 1,
                        }}
                        onClick={async () => {
                          try {
                            if (
                              !!passwordResetEmailLoading &&
                              passwordResetEmailLoading === user.email
                            ) {
                              return;
                            }
                            if (
                              confirm(`Do you wish to send password reset email to ${user.email}?`)
                            ) {
                              this.setState({
                                passwordResetEmailLoading: user.email,
                              });
                              await firebase.auth().sendPasswordResetEmail(user.email);
                              this.setState({
                                passwordResetEmailLoading: null,
                              });
                              alert('Password reset email successfully sent');
                            }
                          } catch (e) {
                            console.log('Could not send password reset email:', e);
                            this.setState({
                              passwordResetEmailLoading: null,
                            });
                            alert('Could not send password reset email');
                          }
                        }}
                      >
                        <i className="material-icons">lock</i>
                      </div>
                    ))}
                </td>
              </tr>
            ))}
            <tr key="tr-new-user">
              <td>
                <Input
                  value={this.state.name}
                  placeholder="Name"
                  onChange={(v) => this.setState({ name: v })}
                  hideErrorMsg
                />
              </td>
              <td>
                <Input
                  value={this.state.email}
                  type="email"
                  placeholder="Email"
                  onChange={(v) => this.setState({ email: v })}
                  hideErrorMsg
                />
              </td>
              <td>
                <Input
                  value={this.state.password}
                  placeholder="Password"
                  onChange={(v) => this.setState({ password: v })}
                  hideErrorMsg
                />
              </td>
              <td className="role">
                <SelectStyle>
                  <Select
                    value={this.state.role}
                    onChange={(v: any) =>
                      this.setState({
                        role: v.value,
                      })
                    }
                    options={this.allUserRoles}
                    clearable={false}
                  />
                </SelectStyle>
              </td>
            </tr>
          </tbody>
        </TableStyle>
        <Button onClick={() => this.addNewUser()} disabled={!addButtonEnabled}>
          Add User
        </Button>
      </div>
    );
  }
}
