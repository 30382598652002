import moment, * as Moment from 'moment-timezone';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import _ from 'underscore';
import BookingStatusType from '../../enums/BookingStatusType';
import theme from '../../styles/theme';

const Calendar = styled.div`
  position: relative;
  animation: din-fadein-1 1s cubic-bezier(0.14, 1.07, 0.68, 1.06);
  clear: both;

  .grid-container {
    position: relative;
  }
`;

const DaysOfWeek = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-size: 12px;

  & > div {
    padding: 8px;
  }
`;

const NoShow = styled.div`
  ${(props: { hasValue: boolean }) =>
    props.hasValue &&
    css`
      color: #ef5350;
    `};
`;

const DataGrid = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(${(props: { weeksCount: number }) => props.weeksCount}, 220px);
`;

const DayDataItemStyle = styled.div`
  position: relative;
  font-size: 12px;
  padding: 16px 0px 6px 6px;
  text-align: left;
  line-height: 16px;
  transition: all 0.0777s ease-in-out;
  overflow: auto;
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05);
  }
`;

const CalendarGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(${(props: { weeksCount: number }) => props.weeksCount}, 220px);
  border-top: 1px solid ${theme.colors.neutral2};
  border-right: 1px solid ${theme.colors.neutral2};

  & > div {
    border: 1px solid ${theme.colors.neutral2};
    border-top: none;
    border-right: none;
    text-align: right;
    padding: 5px 6px;
    font-size: 11px;
    font-weight: 400;
  }
`;

const Day = styled.div`
  ${(props: { isInMonth: boolean }) =>
    !props.isInMonth &&
    css`
      color: #e8e6e6;
    `};
`;

type DayDataItem = {
  date: Moment.Moment;
  fpName: string;
  data: {
    guests: number;
    reservations: number;
    noShows: number;
  };
};

type Props = {
  data: {
    reservations: Array<{
      id: number;
      statusId: BookingStatusType;
      guests: number;
      date: moment.Moment;
      floorPlanName: string;
    }>;
    calendarDays: moment.Moment[];
    selectedDate: moment.Moment;
  } | null;
  changeDate: (newDate: Moment.Moment) => void;
};

export default class ReservationCalendar extends Component<Props> {
  prepareReservationData(): DayDataItem[][] {
    if (this.props.data) {
      const { reservations, calendarDays } = this.props.data;

      return calendarDays.map((day) => {
        const dayData: DayDataItem[] = [];
        let hasData = false;

        const fps = _.groupBy(
          reservations.filter((x) => x.date.isSame(day, 'day')),
          (x) => x.floorPlanName,
        );

        _.mapObject(fps, (r) => {
          const item: DayDataItem = {
            date: day,
            fpName: r[0].floorPlanName,
            data: {
              reservations: 0,
              guests: 0,
              noShows: 0,
            },
          };

          item.data.reservations = r.filter((x) => x.statusId !== 9).length;
          item.data.noShows = r.filter((x) => x.statusId === 9).length;
          if (r.length > 0) {
            item.data.guests = r
              .filter((x) => x.statusId !== 9)
              .map((x) => x.guests)
              .reduce((sum: number, x: number) => sum + x, 0);
          }

          dayData.push(item);
          hasData = true;
        });

        if (!hasData) {
          dayData.push({
            date: day,
            fpName: '',
            data: {
              reservations: 0,
              guests: 0,
              noShows: 0,
            },
          });
        }

        return dayData;
      });
    }

    return [];
  }

  renderCalendarDays() {
    if (this.props.data) {
      const { selectedDate, calendarDays } = this.props.data;
      return calendarDays.map((day) => (
        <Day isInMonth={day.isSame(selectedDate, 'month')} key={day.format('YYYY-MM-DD')}>
          {day.format('D') === '1' ? day.format('MMM D') : day.format('D')}
        </Day>
      ));
    }
    return [];
  }

  render() {
    const calendarDays = this.renderCalendarDays();
    const rowsInCal = calendarDays.length / 7;
    const data = this.prepareReservationData();

    return (
      <Calendar>
        <DaysOfWeek>
          <div>Sun</div>
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thu</div>
          <div>Fri</div>
          <div>Sat</div>
        </DaysOfWeek>

        <div className="grid-container">
          <CalendarGrid weeksCount={rowsInCal}>{this.renderCalendarDays()}</CalendarGrid>
          <DataGrid weeksCount={rowsInCal}>
            {data.map((r, i) => {
              return (
                <DayDataItemStyle
                  key={`day-data-${i}`}
                  onClick={() => this.props.changeDate(r[0].date)}
                >
                  {r
                    .sort((a, b) => (a.fpName < b.fpName ? 1 : -1))
                    .map((x, j) => (
                      <div key={`fp-day-data-${j}`}>
                        <u>
                          <b>{x.fpName}</b>
                        </u>
                        {x.data.reservations > 0 && (
                          <div>{x.data.reservations}&nbsp;&nbsp;Bookings</div>
                        )}
                        {x.data.guests > 0 && <div>{x.data.guests}&nbsp;&nbsp;Guests</div>}
                        {x.data.noShows > 0 && (
                          <NoShow hasValue={x.data.noShows > 0}>
                            {x.data.noShows}&nbsp;&nbsp;No Shows
                          </NoShow>
                        )}
                      </div>
                    ))}
                </DayDataItemStyle>
              );
            })}
          </DataGrid>
        </div>
      </Calendar>
    );
  }
}
