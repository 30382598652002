import * as firebase from 'firebase/app';
import 'firebase/auth';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { IRootStore } from '../stores/RootStore';

type State = {
  isRedirect: boolean;
};

type Props = {
  store?: IRootStore;
};

@inject('store')
@observer
class Logout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isRedirect: false,
    };
  }

  componentDidMount() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        this.props.store!.RestaurantStore.setUser(null);
        this.setState({ isRedirect: true });
      })
      .catch(() => {
        // An error happened.
      });
  }

  render() {
    if (this.state.isRedirect) {
      return <Redirect to="/login" />;
    }

    return <h3>Logging out... Uno momento!</h3>;
  }
}

export default Logout;
