import axios, { CancelTokenSource } from 'axios';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  CountryCodeType,
  EventTranslationType,
  getEventTranslations,
  saveEventTranslations,
} from '../../../../api/translation.api';
import { IRootStore } from '../../../../stores/RootStore';
import { FlashMessageType } from '../../../../stores/UIStore';
import { stringEmptyOrWhitespaceOnly } from '../../../../utils/text.utils';
import { ComputeLanguageFunc, EventStateType, SaveTranslationFunc } from '../../Translator';
import EventForm from '../forms/EventForm';
import TranslationDashboard from '../TranslationDashboard';

const getInitialState = (eventId: number) => {
  return {
    id: -1,
    eventId,
    name: '',
    shortDescription: '',
    description: '',
  };
};

const getStrings = (et: EventTranslationType): Omit<EventTranslationType, 'id' | 'eventId'> => ({
  name: stringEmptyOrWhitespaceOnly(et.name) ? '' : et.name,
  shortDescription: stringEmptyOrWhitespaceOnly(et.shortDescription) ? null : et.shortDescription,
  description:
    et.description && et.description.replace(/<(.|\n)*?>/g, '').trim().length === 0
      ? null
      : et.description,
});

const EventTranslator = ({
  store,
  onSave,
  computeLanguages,
}: {
  store?: IRootStore;
  onSave: SaveTranslationFunc;
  computeLanguages: ComputeLanguageFunc;
}) => {
  const match = useRouteMatch<{ eventId: string }>();
  const eventId = parseInt(match.params.eventId, 10);

  const [eventTranslations, setEventTranslations] = useState<EventStateType>({
    en: getInitialState(eventId),
    is: getInitialState(eventId),
    es: getInitialState(eventId),
    nb: getInitialState(eventId),
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentServerData, setCurrentServerData] = useState<EventStateType | null>(null);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    fetchEventTranslations(cancelToken);

    return () => cancelToken.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEventTranslations = (cancelToken: CancelTokenSource) => {
    getEventTranslations(parseInt(match.params.eventId, 10), cancelToken)
      .then((res) => {
        const translations = res.data;
        Object.keys(translations).map((c) => {
          const code = c as CountryCodeType;
          if (!translations[code]) {
            // Initialise empty state
            translations[code] = { ...getInitialState(eventId) };
            return;
          }

          // Make sure that only id and translation strings are in the state
          translations[code] = {
            id: translations[code]!.id,
            eventId: translations[code]!.eventId,
            ...getStrings(translations[code]!),
          };
        });

        setEventTranslations(translations as EventStateType);
        setCurrentServerData(JSON.parse(JSON.stringify(translations)));
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not fetch translations',
          });
        }
        setLoading(false);
        setError(true);
      });
  };

  console.log(eventTranslations);

  if (loading) {
    return <div>Loading..</div>;
  }

  if (error) {
    return <div>Something went wrong</div>;
  }

  return (
    <TranslationDashboard
      title={eventTranslations.en?.name ?? '(N/A)'}
      languages={computeLanguages(eventTranslations, getStrings)}
      dashboardType="event"
      saveConfig={{
        onSave: () =>
          onSave(eventTranslations, getStrings, saveEventTranslations).then(() => {
            const cancelToken = axios.CancelToken.source();
            fetchEventTranslations(cancelToken);
          }),
        unsavedChanges: JSON.stringify(currentServerData) !== JSON.stringify(eventTranslations),
      }}
    >
      <EventForm translationState={[eventTranslations, setEventTranslations]} />
    </TranslationDashboard>
  );
};

export default inject('store')(observer(EventTranslator));
