import React, { Component } from 'react';
import styled from 'styled-components';
import { BookingPrintType } from './PrintBooking';
import { SelectedState, SelectedType } from './SelectedState';

const Container = styled.div`
  font-size: 12px;

  @media print {
    margin: 24px;
  }

  > div.wrap {
    display: flex;
    flex-wrap: wrap;
  }
  > div.notes {
    padding-right: 24px;
  }
`;

const BookingItem = styled.div`
  flex-basis: 25%;
  padding: 16px;
  white-space: pre-wrap;
  margin-bottom: 16px;

  @media screen and (max-width: 767px) {
    flex-basis: 33%;
  }

  > label {
    color: #999;
    display: inline-block;
    padding-bottom: 4px;
  }
`;

type Props = {
  selectedProperties: SelectedState;
  booking: BookingPrintType;
  ref: any;
};

class PrintBookingData extends Component<Props> {
  renderBookingProperty(item: string) {
    if (item === SelectedType.GuestNotes || item === SelectedType.InternalNotes) {
      return false;
    }
    const { selectedProperties: selected, booking } = this.props;
    return (
      selected[item].value && (
        <BookingItem>
          <label>{selected[item].title}</label>
          <div>{booking && booking[item]}</div>
        </BookingItem>
      )
    );
  }

  render() {
    const { selectedProperties: selected, booking } = this.props;
    const { value: guestNoteSelected } = selected.guestNotes;
    const { value: internalNoteSelected } = selected.internalNotes;
    return (
      <Container>
        <div className="wrap">
          {['id', 'name', 'start', 'tables', 'guests'].map((item) => (
            <BookingItem key={item}>
              <label>{item.charAt(0).toUpperCase() + item.slice(1)}</label>
              <div>{booking && booking[item]}</div>
            </BookingItem>
          ))}
          {Object.keys(selected).map((item: string) => (
            <React.Fragment key={item}>{this.renderBookingProperty(item)}</React.Fragment>
          ))}
        </div>
        <div className="notes">
          {guestNoteSelected && (
            <BookingItem>
              <label>{selected.guestNotes.title}</label>
              <div>{booking && booking.guestNotes}</div>
            </BookingItem>
          )}
          {internalNoteSelected && (
            <BookingItem>
              <label>{selected.internalNotes.title}</label>
              <div>{booking && booking.internalNotes}</div>
            </BookingItem>
          )}
        </div>
      </Container>
    );
  }
}

export default PrintBookingData;
