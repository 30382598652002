import 'react-select/dist/react-select.css';
import styled from 'styled-components';
import theme from '../styles/theme';

const SearchStyle = styled.div`
  margin-bottom: 29px;
  font-size: 12px;
  color: ${theme.colors.dark1};
  &,
  & * {
    box-shadow: none !important;
  }
  .Select-control {
    height: 40px;
    border-radius: 3px;
  }
  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    height: 40px;
    line-height: 40px;
  }
  .Select,
  .Select.is-pseudo-focused,
  .Select:focus,
  input:focus {
    border: none;
  }
  .Select.is-focused {
    outline: rgb(94, 158, 215) auto 5px;
  }
  .Select-control {
    border: 1px solid ${theme.colors.neutral4} !important;
  }
  .Select-arrow-zone {
    width: 32px;
    padding-right: 0px !important;
    border-left: 1px solid ${theme.colors.neutral4};
    background-color: #fff;
  }
  .Select-option.is-focused {
    background-color: ${theme.colors.neutral1};
  }
  .Select--multi {
    .Select-value {
      background-color: ${theme.colors.brand2} !important;
      border-color: ${theme.colors.brand1} !important;
      color: #fff !important;
    }
    .Select-value-icon {
      border-right: 1px solid ${theme.colors.brand1} !important;
    }
    .Select--multi .Select-value-icon:hover,
    .Select--multi .Select-value-icon:focus {
      background-color: ${theme.colors.danger} !important;
      color: #fff !important;
    }
  }
`;

export default SearchStyle;
