import styled from 'styled-components';

const FormWrapper = styled.div`
  textarea {
    height: 140px;
  }

  div.ql-container {
    height: 240px;
  }
`;

export default FormWrapper;
