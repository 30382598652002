import React from 'react';
import styled from 'styled-components';
import { SelectedType } from './SelectedState';

const TableStyled = styled.table`
  width: 100%;
  margin: 0;
  user-select: none;
  overflow: auto;
  border-spacing: 0px;
  font-size: 12px;

  > thead > tr > th {
    padding-left: 16px;
    text-align: left;
    color: #999;
    background-color: #fff;
  }
`;

const RowStyled = styled.tr`
  background-color: ${(props: { lightBackground: boolean }) =>
    props.lightBackground ? '#FFF' : '#f3f3f3'};
  color: #1c1c1c;

  > td {
    vertical-align: middle;
  }
  .data {
    margin: 16px 8px;
  }
`;

const TableData = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
`;

const NotesData = styled.div`
  padding: 16px;
  .label {
    color: #999;
    padding-bottom: 4px;
  }
  .content {
    white-space: pre-wrap;
  }
`;

type Props = {
  columns: string[];
  data: Array<{ id: number | string; [key: string]: any }>;
  ref: any;
  headerJsx: () => JSX.Element;
};

class PrintDateTable extends React.Component<Props> {
  render() {
    const { columns, data, headerJsx } = this.props;
    const cols = new Set(columns);
    return (
      <div>
        {headerJsx && typeof headerJsx === 'function' && headerJsx()}
        <TableStyled>
          <thead>
            <tr>
              {Array.from(cols).map((col) => (
                <th key={col}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <React.Fragment key={row.id}>
                <RowStyled lightBackground={index % 2 === 0}>
                  {Object.keys(row).map(
                    (rowItem: string) =>
                      rowItem !== SelectedType.Id &&
                      rowItem !== SelectedType.GuestNotes &&
                      rowItem !== SelectedType.InternalNotes &&
                      rowItem !== SelectedType.StatusId && (
                        <td key={rowItem}>
                          <TableData className="data">{row[rowItem]}</TableData>
                        </td>
                      ),
                  )}
                </RowStyled>
                <RowStyled
                  lightBackground={index % 2 === 0}
                  style={{ display: row.guestNotes || row.internalNotes ? 'table-row' : 'none' }}
                >
                  <td colSpan={columns.length}>
                    {row.guestNotes && (
                      <NotesData>
                        <div className="label">Guest notes:</div>
                        <div className="content">{row.guestNotes}</div>
                      </NotesData>
                    )}
                    {row.internalNotes && (
                      <NotesData>
                        <div className="label">Internal notes:</div>
                        <div className="content">{row.internalNotes}</div>
                      </NotesData>
                    )}
                  </td>
                </RowStyled>
              </React.Fragment>
            ))}
          </tbody>
        </TableStyled>
      </div>
    );
  }
}

export default PrintDateTable;
