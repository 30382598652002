import React, { Component } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';

const NumberStepperStyle = styled.div`
  display: flex;
  align-items: center;

  & > * {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border: none;
    text-align: center;

    &:nth-child(2) {
      width: 48px;
    }
    &:nth-child(1),
    &:nth-child(3) {
      width: 32px;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:first-child,
    &:last-child {
      background-color: ${theme.colors.dark1};
      user-select: none;
      i {
        font-size: 12px;
        color: #fff;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    &:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
`;

type Props = {
  onChange: (newValue: any) => void;
  value: number;
};

type State = {
  value: number;
  min: number;
  max: number;
  stepValue: number;
};

export default class NumberStepper extends Component<Props, State> {
  static defaultProps = {
    value: 1,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      value: parseInt(props.value.toString(), 10),
      min: 1,
      max: 100,
      stepValue: 1,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    this.setState({
      value: parseInt(newProps.value.toString(), 10),
    });
  }

  onChange(value: any) {
    this.setState(
      {
        value: parseInt(value, 10),
      },
      () => this.props.onChange(this.state.value),
    );
  }

  updateStep(type: any) {
    let { value } = { ...this.state };
    const { stepValue, max, min } = { ...this.state };

    if (type === 'increment') {
      value = value + stepValue <= max ? value + stepValue : max;
    } else {
      value = value - stepValue >= min ? value - stepValue : min;
      value = value - stepValue >= max ? max : value;
    }

    this.setState({ value }, () => this.props.onChange(value));
  }

  render() {
    return (
      <NumberStepperStyle>
        <div onClick={() => this.updateStep('decrement')} role="button" tabIndex={-1}>
          <i className="material-icons">remove</i>
        </div>
        <input
          type="number"
          min={this.state.min}
          max={this.state.max}
          value={this.state.value}
          onChange={(e) => this.onChange(e.target.value)}
        />
        <div onClick={() => this.updateStep('increment')} role="button" tabIndex={-1}>
          <i className="material-icons">add</i>
        </div>
      </NumberStepperStyle>
    );
  }
}
