import React, { Component } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';

const CheckboxStyle = styled.div`
  user-select: none;
  font-size: 12px;

  &:hover,
  & *:hover {
    cursor: pointer;
  }

  input {
    display: none;
  }
  label {
    position: relative;
    display: flex;
    align-items: center;
  }
  label span {
    position: relative;
    display: inline-block;
    height: 16px;
    width: 16px;
    margin-right: 12px;

    &::before {
      content: 'check_box_outline_blank';
      position: absolute;
      top: -4px;
      left: 0;
      font-family: 'Material Icons';
      font-size: 24px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: antialiased !important;
      color: ${theme.colors.dark2};
    }
  }
  input:checked + label span::before {
    content: 'check_box';
    color: ${theme.colors.brand1};
  }
`;

type Props = {
  name: string;
  value: string | number;
  title: string;
  onChange: (isChecked: boolean) => void;
  isChecked: boolean;
  tooltip: string;
};

type State = {
  isChecked: boolean;
};

export default class Checkbox extends Component<Props, State> {
  static defaultProps = {
    value: '',
    isChecked: false,
    tooltip: '',
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isChecked: !!props.isChecked,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    this.setState({
      isChecked: newProps.isChecked,
    });
  }

  onChange() {
    const { isChecked } = { ...this.state };
    this.setState(
      {
        isChecked: !isChecked,
      },
      () => this.props.onChange(this.state.isChecked),
    );
  }

  render() {
    return (
      <CheckboxStyle
        data-tooltip={this.props.tooltip ? this.props.tooltip : null}
        className="checkbox"
        tabIndex={0}
      >
        <input
          id={this.props.name}
          name={this.props.name}
          value={this.props.value}
          type="checkbox"
          checked={this.state.isChecked}
          onChange={() => this.onChange()}
        />
        <label htmlFor={this.props.name}>
          <span />
          {this.props.title}
        </label>
      </CheckboxStyle>
    );
  }
}
