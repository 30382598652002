import * as Moment from 'moment-timezone';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled, { css } from 'styled-components';
import theme from '../styles/theme';
import Label from './Label.styled';

const DatepickerStyle = styled.div`
  margin-bottom: 16px;
  z-index: 9999999;

  * {
    user-select: none;
  }

  label {
    margin-bottom: 0px;
  }
  .react-datepicker {
    width: 100%;
    border: 0px !important;
    border-radius: 0px;

    .react-datepicker__month-container {
      width: 100%;
    }
    .react-datepicker__header {
      width: 100%;
      background-color: transparent;
      border-bottom: 1px solid #ebebeb;
    }
    .react-datepicker__week,
    .react-datepicker__day-names {
      display: flex;
      align-items: center;
      > div {
        flex-basis: ${100 / 7}%;
      }
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    display: flex;
  }
  .react-datepicker__close-icon {
    position: absolute;
    top: 6px;
    right: 61px;
    z-index: 1001;
    &::after {
      background: none;
      background-color: ${theme.colors.neutral2};
      color: ${theme.colors.dark1};
    }
  }
  .react-datepicker-popper {
    z-index: 99999999;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: ${theme.colors.brand1};
  }
  input {
    width: 100%;
    border-radius: 3px;
    border: 1px solid ${theme.colors.neutral4};
    padding-top: 0px;
    height: 40px;
    font-size: 12px;
    padding-left: 8px;
    ${(props: { hasLabel: boolean }) =>
      props.hasLabel &&
      css`
        margin-top: 8px;
      `}
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    padding: 0px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding: 8px 5px;
    text-align: center;
  }
  .react-datepicker__today-button {
    background-color: ${theme.colors.neutral3};
    border-top: 1px solid #ebebeb;
  }
`;

type Props = {
  value: any;
  title: string;
  isClearable: boolean;
  onChange: (newDate: any) => void;
  minDate: any;
  showTimeSelect: boolean;
  timeIntervals: number;
  minTime: any;
  maxTime: any;
  includeTimes: any[];
  inline: boolean;
  onMonthChange: (newMonth: any) => void;
};

export default class Datepicker extends Component<Props> {
  static defaultProps = {
    value: {},
    title: '',
    isClearable: false,
    minDate: undefined,
    showTimeSelect: false,
    timeIntervals: undefined,
    minTime: undefined,
    maxTime: undefined,
    includeTimes: undefined,
    inline: false,
    onMonthChange: () => {},
  };

  render() {
    return (
      <DatepickerStyle className="datepicker" hasLabel={!!this.props.title}>
        <Label>{this.props.title}</Label>
        <DatePicker
          selected={this.props.value}
          onChange={(date: Moment.Moment) => this.props.onChange(date)}
          isClearable={this.props.isClearable}
          minDate={this.props.minDate}
          dateFormat={this.props.showTimeSelect ? 'MMM D, YYYY HH:mmA' : 'LL'}
          timeFormat="HH:mm"
          shouldCloseOnSelect={false}
          showTimeSelect={this.props.showTimeSelect}
          timeIntervals={this.props.timeIntervals}
          minTime={this.props.minTime}
          maxTime={this.props.maxTime}
          includeTimes={this.props.includeTimes}
          inline={this.props.inline}
          onMonthChange={(newDate: Moment.Moment) => this.props.onMonthChange(newDate)}
          todayButton="Today"
        />
      </DatepickerStyle>
    );
  }
}
