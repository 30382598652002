import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import styled from 'styled-components';
import { IRootStore } from '../../stores/RootStore';

import * as Moment from 'moment-timezone';
import SurveyDetails from '../../pages/Archive/Satisfaction/SurveyDetails';
import BookingPrintDate from '../../pages/Booking/Print/PrintDate';
import { ModalConductor as ModalConductorNS } from '../../stores/UIStore';
import OpeningHoursOverride from '../OpeningHoursOverride';
import RestaurantNote from '../RestaurantNote';
import BookingModal from './Booking';

const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999999;
  opacity: 0;

  animation: modalOpacityFadeIn 111ms cubic-bezier(0.14, 1.24, 1, 1) forwards;
  @keyframes modalOpacityFadeIn {
    from {
      opacity: 0;
      will-change: opacity;
    }
    to {
      opacity: 1;
      will-change: opacity;
    }
  }

  .mc-wrapper-ref {
    width: 100%;
    height: 100%; /* To enable modal full height in mobile */
    @media screen and (min-width: 768px) {
      width: auto;
      height: auto;
    }
    .mc-content {
      display: block;
      width: 100%;
      height: 100%;
      max-height: calc(100vh - 32px);
      margin: 0px;
      overflow: auto;
      background-color: #fff;
      box-shadow: 0px 0px 19px 1px rgba(173, 173, 173, 0.25);
      @media screen and (min-width: 768px) {
        width: auto;
        height: ${(props: { fullHeight: boolean }) => (props.fullHeight ? '100vh' : 'auto')};
        margin: 16px;
        border-radius: 10px;
      }
    }
  }
`;

type Props = {
  store?: IRootStore;
};

type State = {
  fullHeight: boolean;
};

@inject('store')
@observer
export default class ModalConductor extends Component<Props, State> {
  wrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleEscClick = this.handleEscClick.bind(this);

    this.state = {
      fullHeight:
        this.props.store!.UIStore.modalConductor.type ===
        ModalConductorNS.Type.OpeningHoursOverride,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.handleEscClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleEscClick);
  }

  handleClickOutside(event: MouseEvent) {
    const target = event.target as any;
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(target) &&
      !target.className.toLowerCase().includes('select')
    ) {
      this.closeMe();
    }
  }

  handleEscClick(e: KeyboardEvent) {
    if (e.keyCode === 27) {
      this.closeMe();
    }
  }

  closeMe() {
    this.props.store!.UIStore.closeModal(true);
  }

  render() {
    const modalType = this.props.store!.UIStore.modalConductor.type;

    let modalJsx = <div></div>;
    switch (modalType) {
      case ModalConductorNS.Type.RestaurantNote: {
        const data = this.props.store!.UIStore.modalConductor as ModalConductorNS.IRestaurantNote;
        modalJsx = (
          <RestaurantNote date={data.date} onSave={(newNote: string) => data.onSave(newNote)} />
        );
        break;
      }
      case ModalConductorNS.Type.Booking: {
        const data = this.props.store!.UIStore.modalConductor as ModalConductorNS.IBooking;

        modalJsx = (
          <BookingModal
            bookingId={data.bookingId}
            date={data.date}
            preselectedTableId={data.preselectedTableId}
            preselectedTime={data.preselectedTime}
            isWalkIn={data.isWalkIn ? data.isWalkIn : false}
            onSave={(newDate: Moment.Moment) => data.onSave(newDate)}
          />
        );
        break;
      }
      case ModalConductorNS.Type.BookingPrintDate: {
        const data = this.props.store!.UIStore.modalConductor as ModalConductorNS.IBookingPrintDate;
        modalJsx = <BookingPrintDate date={data.date} />;
        break;
      }
      case ModalConductorNS.Type.OpeningHoursOverride: {
        const data = this.props.store!.UIStore
          .modalConductor as ModalConductorNS.IOpeningHoursOverride;
        modalJsx = <OpeningHoursOverride date={data.date} onSave={() => data.onSave()} />;
        break;
      }
      case ModalConductorNS.Type.SurveyDetails: {
        const data = this.props.store!.UIStore.modalConductor as ModalConductorNS.ISurveyDetails;
        modalJsx = <SurveyDetails id={data.id} />;
        break;
      }
      default:
        throw new Error('Modal does not exists');
    }

    return (
      <ModalContainer fullHeight={this.state.fullHeight}>
        <div ref={this.wrapperRef} className="mc-wrapper-ref">
          <div className="mc-content">{modalJsx}</div>
        </div>
      </ModalContainer>
    );
  }
}
