import styled from 'styled-components';
import { getAllStatuses } from '../utils';

const Status = styled.div`
  display: inline-block;
  font-size: 10px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: ${(props: { statusId: number }) =>
      getAllStatuses().find((x) => x.id === props.statusId)!.color};
  }
`;

export default Status;
