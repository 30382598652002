import { inject } from 'mobx-react';
import moment from 'moment-timezone';
import React, { useRef, useState } from 'react';
import Print from 'react-to-print';
import styled from 'styled-components';
import { AvailabilityTableType } from '../../../api/booking.api';
import Checkbox from '../../../components/CheckboxNew';
import Button from '../../../components/common/Button';
import { BookingModalStateType } from '../../../components/ModalConductor/Booking';
import RootStore from '../../../stores/RootStore';
import { getAllStatuses } from '../../../utils';
import PrintBookingData from './PrintBookingData';
import { SelectedType, useSelectedState } from './SelectedState';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    height: 528px;
  }

  > div.booking-items {
    padding: 8px;
    overflow-y: auto;
  }

  .print-selection-and-button {
    display: flex;
    min-height: 48px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    padding: 0px 24px;

    @media print {
      display: none;
    }

    .print-selection {
      display: flex;
      flex-wrap: wrap;

      .checkbox {
        margin-right: 8px;
      }
    }

    .print-button-container {
      display: flex;
      height: 100%;
      padding: 8px 0px;
      align-items: center;

      > button {
        height: 32px;

        > div {
          display: flex;
          align-items: center;

          i {
            display: block;
            margin-right: 8px;
            font-size: 16px;
          }
        }
      }
    }
  }
`;

export type BookingPrintType = {
  id: number;
  start: string;
  guests: number;
  name: string | null;
  email: string | null;
  phone: string | null;
  guestNotes: string | null;
  internalNotes: string | null;
  status: string;
  created: string;
  updated: string;
  tables?: string;
  eventName?: string | null;
  [key: string]: any;
};

type Props = {
  store?: RootStore;
  booking: BookingModalStateType;
  selectedTableIds: number[];
  allTables: AvailabilityTableType[];
};

const PrintBooking = ({ booking, selectedTableIds, allTables }: Props) => {
  const [state, dispatch] = useSelectedState();
  const [btnLoading, setBtnLoading] = useState(false);
  const dataRef = useRef() as any;

  const tables = allTables.filter((t) => selectedTableIds.includes(t.id));
  const status = getAllStatuses().find((s) => s.id === booking.statusId)!.name;

  const bookingToPrint: BookingPrintType = {
    id: booking.bookingId || 0,
    start: booking.start.format('ddd, MMM Do YYYY, HH:mm'),
    guests: booking.guests,
    name: booking.bookingName,
    email: booking.bookingEmail,
    phone: booking.bookingPhone,
    guestNotes: booking.bookingSpecialRequest,
    internalNotes: booking.bookingInternalNotes,
    status,
    created: moment(booking.created!, 'YYYY-MM-DDTHH:mm').format('ddd, MMM Do YYYY, HH:mm'),
    updated: moment(booking.updated!, 'YYYY-MM-DDTHH:mm').format('ddd, MMM Do YYYY, HH:mm'),
    tables: tables.map((x) => x.name).join(', '),
    eventName: booking.eventName,
  };

  const selectedState = { ...state };

  return (
    <Container>
      <div className="print-selection-and-button">
        <div className="print-selection">
          {Object.keys(selectedState).map((item) => (
            <Checkbox
              id={item}
              key={item}
              title={selectedState[item].title}
              checked={selectedState[item].value}
              onChange={() => dispatch({ type: item as SelectedType })}
            />
          ))}
        </div>
        <div className="print-button-container">
          <Print
            trigger={() => (
              <Button id="btn-print-save" onClick={() => {}} isLoading={btnLoading}>
                <i className="material-icons">print</i>Print
              </Button>
            )}
            content={() => dataRef.current}
            onBeforePrint={() => setBtnLoading(true)}
            onAfterPrint={() => setBtnLoading(false)}
          />
        </div>
      </div>
      <div className="booking-items">
        <PrintBookingData
          selectedProperties={selectedState}
          booking={bookingToPrint}
          ref={dataRef}
        />
      </div>
    </Container>
  );
};

export default inject('store')(PrintBooking);
