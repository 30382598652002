import React, { FunctionComponent, useEffect, useState } from 'react';

import axios from 'axios';
import moment from 'moment-timezone';
import { RouteComponentProps } from 'react-router-dom';
import ReactTable from 'react-table';
import styled from 'styled-components';
import CustomerAPI from '../../api/customer.api';
import { CustomerType } from '../../api/customer.api';
import CSVIcon from '../../assets/csv-file-format-extension.svg';
import Checkbox from '../../components/CheckboxNew';
import Button from '../../components/common/Button';
import ReactTableStyle from '../../components/ReactTable.styled';

const api = new CustomerAPI();

const columns = [
  {
    Header: 'ID',
    headerClassName: 'col-id',
    accessor: 'id',
    className: 'col-id',
  },
  {
    Header: 'Name',
    headerClassName: 'col-name',
    accessor: 'firstName',
    className: 'col-name',
    filterable: true,
  },
  {
    Header: 'Phone',
    headerClassName: 'col-phone',
    accessor: 'phone',
    className: 'col-phone',
    filterable: true,
  },
  {
    Header: 'Allow Newsletters',
    headerClassName: 'col-allow-restaurant-emails',
    accessor: 'allowRestaurantEmails',
    className: 'col-allow-restaurant-emails',
    Cell: (props: { value: boolean }) => (props.value ? 'Yes' : 'No'),
  },
  {
    Header: 'Created',
    headerClassName: 'col-date',
    accessor: 'created',
    className: 'col-date',
    Cell: (props: { value: moment.MomentInput }) => moment(props.value).format('YYYY-MM-DD HH:mm'),
  },
  {
    Header: 'Updated',
    headerClassName: 'col-date',
    accessor: 'updated',
    className: 'col-date',
    Cell: (props: { value: moment.MomentInput }) => moment(props.value).format('YYYY-MM-DD HH:mm'),
  },
];

const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  a {
    display: flex;
    align-items: center;

    object {
      width: 16px;
      margin-right: 4px;
    }
  }
`;

type IProps = RouteComponentProps<any>;

const Customers: FunctionComponent<IProps> = ({ history }) => {
  const [customers, setCustomers] = useState<CustomerType[] | null>(null);
  const [filteredCustomers, setFilteredCustomers] = useState<CustomerType[] | null>(null);
  const [showAllowEmailsOnly, setShowAllowEmailsOnly] = useState<boolean>(false);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    api
      .getCustomers(cancelToken)
      .then((res) => {
        setCustomers(res.data);
        setFilteredCustomers(res.data);
      })
      .catch((error) => console.error(error));

    return () => cancelToken.cancel();
  }, []);

  useEffect(() => {
    if (showAllowEmailsOnly && customers) {
      setFilteredCustomers(customers.filter((x) => x.allowRestaurantEmails));
    } else {
      setFilteredCustomers(customers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllowEmailsOnly]);

  if (filteredCustomers === null) {
    return <div>Loading...</div>;
  }

  const renderCustomerCSV = () => {
    let customersStr = '';
    filteredCustomers.forEach((c) => {
      customersStr += `${c.id},${encodeURIComponent(c.firstName || '')},${encodeURIComponent(
        c.phone,
      )},${encodeURIComponent(c.email || '')}\n`;
    });

    return customersStr;
  };

  return (
    <div>
      <h1>Customers</h1>
      <Button
        id="btn-create-customer"
        onClick={() => history.push('/customer/create')}
        className="btn-cta btn-new-restaurant"
      >
        New Customer
      </Button>
      <sub>Here you can see all your customers.</sub>

      <Options>
        <div>
          <Checkbox
            id="chkAllowRestaurantEmails"
            key="chkAllowRestaurantEmails"
            title="Show only customers that've allowed newsletters"
            checked={showAllowEmailsOnly}
            onChange={() => setShowAllowEmailsOnly(!showAllowEmailsOnly)}
          />
        </div>

        <div>
          <a download="customers.csv" href={`data:application/octet-stream,${renderCustomerCSV()}`}>
            <object className="icon-csv" data={CSVIcon} type="image/svg+xml" aria-label="CSV" />
            Export to CSV
          </a>
        </div>
      </Options>

      <ReactTableStyle>
        <ReactTable
          data={filteredCustomers}
          columns={columns}
          resizable={false}
          defaultFilterMethod={(filter: any, row: any) => {
            const id = filter.pivotId || filter.id;
            if (id === 'id') {
              return row[id] !== undefined ? String(row[id]).startsWith(filter.value) : true;
            }
            return row[id] !== undefined
              ? String(row[id])
                  .toLowerCase()
                  .trim()
                  .replace(/\s/g, '')
                  .includes(filter.value.toLowerCase().trim().replace(/\s/g, ''))
              : true;
          }}
          getTdProps={(state: any, rowInfo: any) => ({
            onClick: () => {
              history.push(`customer/${rowInfo.original.id}`);
            },
          })}
        />
      </ReactTableStyle>
    </div>
  );
};

export default Customers;
