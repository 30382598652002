import React from 'react';
import styled from 'styled-components';
import { getAllStatuses } from '../utils';

const Status = styled.div`
  display: flex;
  align-items: center;
  font-size: 1em;
  margin-right: 1em;
  margin-bottom: 1em;

  &::before {
    content: '';
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
    background-color: ${(props: { color: string }) => props.color};
    box-shadow: 0 0 1px 0px #e8e8e8;
  }
`;

const StatusColors = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const StatusList = () => (
  <StatusColors className="status-color-list">
    {getAllStatuses().map((x) => (
      <Status key={`status-${x.id}`} color={x.color}>
        {x.name}
      </Status>
    ))}
  </StatusColors>
);

export default StatusList;
