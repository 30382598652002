import { AxiosResponse, CancelTokenSource } from 'axios';
import { PaymentLinkDeliveryType } from '../enums/Payment';
import API from './api';

enum PaymentType {
  None = 0,
  NoShow = 1,
  PrePayment = 2,
}

export type ChargableBooking = {
  bookingId: number;
  bookingName: string;
  bookingPhone: string;
  paymentType: PaymentType;
  paymentDate: string | null;
  paymentAmount: number;
  paymentLinkSentDate: string | null;
};

export interface IPaymentLinkInfoForBooking {
  expires: string | null;

  paymentLinkSentDate: string | null;

  isCardRegistered: boolean;
  cardRegisteredDate: string | null;

  hasPaidNoShow: boolean;
  hasPaidPrePayment: boolean;

  paymentDateNoShow: string | null;
  paymentDatePrePayment: string | null;

  amountNoShow: number | null;
  amountPrePayment: number | null;
}

type SendPaymentLinkPayload = {
  bookingId: number;

  deliveryType: PaymentLinkDeliveryType;
  paymentType: PaymentType;
  expiryMinutes: number;
  feePerGuest: number;
  guests: number;

  email: string | undefined;
  phone: string | undefined;
};

export default class PaymentAPI extends API {
  getPaymentInfoForBooking(
    bookingId: number,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<IPaymentLinkInfoForBooking | null>> {
    return this._get(`/payment/getpaymentinfoforbooking/${bookingId}`, cancelToken);
  }

  sendPaymentLink(
    data: SendPaymentLinkPayload,
    cancelToken: CancelTokenSource,
  ): Promise<
    AxiosResponse<{
      paymentLinkSentDate: string;
      expires: string | null;
      newAmount: number;
    }>
  > {
    return this._post('/payment/sendpaymentlink', data, cancelToken);
  }
}
