import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import BookingAPI from '../../api/booking.api';
import { getAllStatuses, getStatusButtonsFromStatusId, StatusItem } from '../../utils';

const Style = styled.div`
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  box-shadow: 0px 0px 13px -5px rgba(0, 0, 0, 0.4);

  .lcsb-header {
    padding: 8px;
    .lcsb-icon-close {
      font-size: 14px;
    }
  }

  .lcsb-buttons {
    padding: 8px 32px 24px 32px;
  }

  .lcsb-no-actions {
    padding: 8px 8px 32px 8px;

    font-size: 12px;
    text-align: center;
    font-weight: 400;
    color: #1c1c1c;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 32px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  background-color: ${(props: { bgColor: string }) => props.bgColor};
  color: ${(props: { color: string }) => props.color};
  border: 0px;
  border-radius: 3px;
`;

type Props = {
  bookingId: number;
  statusId: number;
  onUpdated: (bookingId: number, newStatusAndEnd: { statusId: number; bookingEnd: string }) => void;
  onClose: () => void;
};

const bookingApi = new BookingAPI();

const ListCollapsableStatusBox: FunctionComponent<Props> = ({
  statusId,
  bookingId,
  onUpdated,
  onClose,
}) => {
  const [statusButtons, setStatusButtons] = useState<StatusItem[] | null>(null);

  useEffect(() => {
    document.addEventListener('keydown', handleEscClick);

    return () => {
      document.removeEventListener('keydown', handleEscClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const statusesToShow = getStatusButtonsFromStatusId(statusId);
    const allStatuses = getAllStatuses();

    setStatusButtons(statusesToShow.map((sts) => allStatuses.find((as) => as.id === sts)!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEscClick = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  if (statusButtons === null) {
    return null;
  }

  return (
    <Style>
      <div className="lcsb-header">
        <a onClick={() => onClose()}>
          <i className="material-icons lcsb-icon-close">close</i>
        </a>
      </div>

      {statusButtons.length === 0 ? (
        <div className="lcsb-no-actions">No actions available.</div>
      ) : (
        <div className="lcsb-buttons">
          {statusButtons.map((x) => (
            <Button
              key={`lcsb-status-btn-${x.id}`}
              bgColor={x.color}
              color={x.contrastColor}
              onClick={() => {
                bookingApi
                  .updateBookingStatus(bookingId, x.id)
                  .then((res) => {
                    const { statusId: sId, bookingEnd } = res.data.value;
                    onUpdated(bookingId, { statusId: sId, bookingEnd });
                    onClose();
                  })
                  .catch(() => {
                    alert('Could not update status');
                  });
              }}
            >
              {x.name}
            </Button>
          ))}
        </div>
      )}
    </Style>
  );
};

export default ListCollapsableStatusBox;
