import React, { Component } from 'react';
import styled from 'styled-components';

import { IBookingDashboard } from '../../api/dashboard.api';
import Checkbox from '../../components/CheckboxNew';
import BookingStatusType from '../../enums/BookingStatusType';
import { getAllStatuses } from '../../utils';
import ListItem from './ListItem';

const Container = styled.table`
  width: 100%;
  border-spacing: 0px;

  .viewMode {
    text-transform: capitalize;
    padding-left: 8px;
  }
`;

type BookingUpdateProperty = 'statusId' | 'paymentDate' | 'booking';

type Props = {
  bookings: IBookingDashboard[];
  viewMode: string;
  onBookingUpdated: (bookingId: number, newValue: any, type: BookingUpdateProperty) => void;
};
type State = {
  allStatuses: Array<{ label: string; value: number }>;
  hideCompleted: boolean;
};

const LIST_HIDE_COMPLETED_KEY = 'list-hide-completed';

class List extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const allStatuses = getAllStatuses();
    const hideCompleted = localStorage.getItem(LIST_HIDE_COMPLETED_KEY) === 'true' ? true : false;

    this.state = {
      allStatuses: allStatuses
        .filter((x) => x.id !== 1)
        .map((x) => ({
          label: x.name,
          value: x.id,
        })),
      hideCompleted,
    };
  }

  shouldComponentUpdate(nextProps: Props) {
    return !!nextProps.bookings;
  }

  render() {
    if (!this.props.bookings) {
      return null;
    }

    return (
      <>
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h3 className="viewMode">{this.props.viewMode}</h3>
          <div>
            <Checkbox
              id={'chk-hide-completed'}
              title={'Hide Completed'}
              checked={this.state.hideCompleted}
              onChange={() => {
                localStorage.setItem(
                  LIST_HIDE_COMPLETED_KEY,
                  JSON.stringify(!this.state.hideCompleted),
                );
                this.setState({
                  hideCompleted: !this.state.hideCompleted,
                });
              }}
            />
          </div>
        </div>
        <Container>
          <tbody>
            {this.props.bookings
              .filter((x) =>
                this.state.hideCompleted && x.statusId === BookingStatusType.Completed
                  ? false
                  : true,
              )
              .map((x) => (
                <ListItem
                  key={x.id}
                  booking={x}
                  statusOptions={this.state.allStatuses}
                  onBookingUpdated={this.props.onBookingUpdated}
                />
              ))}
          </tbody>
        </Container>
      </>
    );
  }
}
export default List;
