import React, { Component } from 'react';
import TelInput from 'react-telephone-input/lib/withStyles';
import styled, { css } from 'styled-components';
import uuidv4 from 'uuid/v4';

import theme from '../../styles/theme';
import Form from './Form';

// tslint:disable-next-line: no-var-requires
const Flags = require('../../assets/flags.png');

type SProps = { isDisabled: boolean; inputState: string };

const InputStyle = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;

  .input-box {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    border: 0px;
    pointer-events: none;

    input {
      width: 100%;
      height: 100%;
      padding: 0px 16px;
      border: 0px;
      font-size: 12px;
      pointer-events: all;

      ${(props: SProps) =>
        props.isDisabled &&
        css`
          &:hover {
            cursor: not-allowed;
          }
        `};

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }
      ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: ${theme.colors.neutral4};
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: ${theme.colors.neutral4};
        opacity: 1;
      }
      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: ${theme.colors.neutral4};
        opacity: 1;
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${theme.colors.neutral4};
      }
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${theme.colors.neutral4};
      }

      ::placeholder {
        /* Most modern browsers support this now. */
        color: ${theme.colors.neutral4};
      }
      &[type='time']::-webkit-clear-button {
        display: none;
      }
    }

    &::after {
      position: absolute;
      bottom: 50%;
      right: 8px;
      transform: translateY(50%);
      font-family: 'Material Icons';
      -webkit-font-feature-settings: 'liga';
      font-size: 18px;
      animation: fadeIn 0.333s ease-in;
      @keyframes fadeIn {
        from {
          bottom: 30%;
          opacity: 0;
        }
        to {
          bottom: 50%;
          opacity: 1;
        }
      }
    }

    ${(props: SProps) =>
      props.inputState === 'valid' &&
      css`
        &::after {
          content: 'check_circle';
          color: ${theme.colors.success};
        }
      `};

    ${(props: SProps) =>
      props.inputState === 'invalid' &&
      css`
        &::after {
          content: 'error';
          color: ${theme.colors.danger};
        }
      `};
  }

  .react-tel-input {
    display: flex;
    height: 100% !important;
    width: 100% !important;
    pointer-events: none;

    input[type='text'],
    input[type='tel'] {
      height: 100% !important;
      border: 0px;
      box-shadow: none;
      pointer-events: all;
    }
    .selected-flag {
      height: 100% !important;
      z-index: 0;
    }
    .flag-dropdown {
      position: relative;
      width: 48px;
      flex-shrink: 0;
      pointer-events: all;

      border: 0px;
      background-color: #fff !important;
      border-left: 1px solid #ebebeb;

      &:focus {
        outline: inherit;
      }

      .selected-flag {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;

        .arrow {
          display: none;
        }
      }

      .country-list {
        left: 0px;
        user-select: none;
      }
    }
  }
`;

type InputState = 'valid' | 'invalid' | 'neutral';
type InputType = 'text' | 'email' | 'tel' | 'number' | 'time';

type Props = {
  title: string | null;
  value: string;
  type: InputType;
  onChange: (newText: any) => void;
  isValid: boolean | undefined;
  isRequired: boolean;
  isDisabled: boolean;
  placeholder: string;
  id: string;
  icon?: any;
  maxLength?: number;
  defaultCountry?: string;
};

type State = {
  hasEdited: boolean;
  hasBlurred: boolean;
  id: string;
};

class Input extends Component<Props, State> {
  static defaultProps = {
    type: 'text',
    value: '',
    isRequired: false,
    isDisabled: false,
    placeholder: '',
    title: null,
    isValid: undefined,
    defaultCountry: 'is',
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      hasEdited: false,
      hasBlurred: false /* This means user has focused and then blurred */,
      id: props.id || uuidv4(),
    };
  }

  getInputState(isValid: boolean | undefined): InputState {
    if (isValid === undefined) {
      return 'neutral';
    } else if (!isValid) {
      if (!this.state.hasBlurred) {
        return 'neutral'; // Don't show red until user leaves
      }
      return 'invalid';
    }
    return this.state.hasEdited ? 'valid' : 'neutral'; // Don't show green unless user has edited
  }

  render() {
    const { title, type, isRequired, isDisabled } = this.props;
    let { isValid, value, placeholder } = this.props;
    const htmlFor = `inp-${this.state.id}`;

    value = value || '';
    placeholder = placeholder || '';

    // Hardcoded validations below:
    if (isRequired || value) {
      if (type === 'email') {
        isValid = /\S+@\S+\.\S+/.test(value);
      } /* else if (type === 'tel') {
        isValid = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/.test(value.replace(/\D/g, ''));
      } */
    }

    return (
      <Form>
        <InputStyle inputState={this.getInputState(isValid)} isDisabled={isDisabled} tabIndex={-1}>
          <div className="input-box" tabIndex={-1}>
            {type === 'tel' ? (
              <TelInput
                title={title}
                placeholder={placeholder}
                defaultCountry={this.props.defaultCountry || 'is'}
                flagsImagePath={Flags}
                value={value}
                name={htmlFor}
                onChange={(val: any) => {
                  this.setState({ hasEdited: true });
                  this.props.onChange(val);
                }}
                onBlur={() => this.setState({ hasBlurred: true })}
                type="tel"
                disabled={isDisabled}
              />
            ) : (
              <input
                id={htmlFor}
                name={htmlFor}
                placeholder={placeholder || title || ''}
                value={value}
                type={type}
                tabIndex={0}
                onChange={(e) => {
                  this.setState({ hasEdited: true });
                  this.props.onChange(e.target.value);
                }}
                onBlur={() => this.setState({ hasBlurred: true })}
                disabled={isDisabled}
                maxLength={this.props.maxLength}
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck={false}
              />
            )}
          </div>
        </InputStyle>
        {this.props.icon && <div>{this.props.icon}</div>}
      </Form>
    );
  }
}

export default Input;
