import React from 'react';

const SpanishFlagIcon = ({ size, onClick }: { size: number; onClick?: () => void }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" onClick={onClick}>
    <g clipPath="url(#prefix__clip0)">
      <path
        d="M10 20a9.98 9.98 0 007.876-3.842H12.61C11.93 18.424 11.55 20 10 20zM9.407 19.983H9.4h.006zM12.61 3.842h5.266A9.98 9.98 0 0010 0c1.55 0 1.93 1.575 2.61 3.842zM9.401.018h.006H9.4z"
        fill="#C42126"
      />
      <path
        d="M17.877 3.842h-5.268c1.063 3.543 1.063 8.774 0 12.316h5.267A9.952 9.952 0 0020 10c0-2.324-.794-4.46-2.123-6.158z"
        fill="#FFC40C"
      />
      <path d="M10 20zM9.407.018z" fill="#C42126" />
      <path
        d="M13.345 16.158H2.124a9.982 9.982 0 007.277 3.824h.006c.196.012.394.018.593.018 1.55 0 2.665-1.576 3.345-3.842zM2.124 3.842h11.22C12.665 1.575 11.55 0 10 0c-.2 0-.397.006-.593.018H9.4a9.982 9.982 0 00-7.277 3.824z"
        fill="#D32030"
      />
      <path
        d="M.001 10c0 2.324.795 4.46 2.124 6.158h11.22c1.064-3.543 1.064-8.773 0-12.316H2.126A9.954 9.954 0 00.001 10z"
        fill="#FFD034"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SpanishFlagIcon;
