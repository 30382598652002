import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import BookingAPI from '../../api/booking.api';
import CustomerAPI from '../../api/customer.api';

import { inject, observer } from 'mobx-react';
import { Col, Row } from 'react-flexbox-grid';
import Button from '../../components/common/Button';
import { FormGroup } from '../../components/FormGroup.styled';
import Input from '../../components/Input';
import { IRootStore } from '../../stores/RootStore';
import { FlashMessageType } from '../../stores/UIStore';

type Customer = {
  id?: number;
  restaurantId?: number;
  email?: string;
  firstName: string;
  lastName?: string;
  phone: string;
  address?: string;
  city?: string;
  zipCode?: string;
  country?: string;
  restaurantNotes?: string;
};

type State = {
  mode: 'edit' | 'create';
  customer: Customer;
  allCities: any[];
};

interface IProps extends RouteComponentProps<any> {
  store: IRootStore;
}

@inject('store')
@observer
class CustomerPage extends Component<IProps, State> {
  api: any;
  reservationApi: any;

  constructor(props: IProps) {
    super(props);

    const { customerId } = props.match.params;
    const mode = Number.isInteger(parseInt(customerId, 10)) ? 'edit' : 'create';

    this.api = new CustomerAPI();
    this.reservationApi = new BookingAPI();

    this.state = {
      mode,
      customer: {
        id: mode === 'edit' ? customerId : undefined,
        restaurantId: undefined,
        email: undefined,
        firstName: '',
        lastName: undefined,
        phone: '',
        address: undefined,
        city: undefined,
        zipCode: undefined,
        country: undefined,
        restaurantNotes: undefined,
      },
      allCities: [],
    };
  }

  UNSAFE_componentWillMount() {
    // If edit mode then we get customer
    const isEditMode = this.state.mode === 'edit';
    if (isEditMode) {
      this.props.store.UIStore.setIsLoading(true);

      this.api
        .getCustomerById(this.state.customer.id)
        .then((response: any) => {
          const cust: Customer = response.data;
          this.setState({ customer: cust });
          this.props.store.UIStore.setIsLoading(false);
        })
        .catch(() => {
          this.props.store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Error fetching customer.',
            timeout: 10000,
          });
          this.props.store.UIStore.setIsLoading(false);
        });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleInputChange(newValue: string, propertyName: string) {
    const obj = Object.assign({}, this.state.customer, {
      [propertyName]: newValue,
    });
    this.setState({
      customer: obj,
    });
  }

  saveCustomer() {
    const { customer } = { ...this.state };
    if (this.state.mode === 'edit') {
      this.api
        .updateCustomer(customer.id, customer)
        .then(() => {
          this.props.store!.UIStore.addFlashMessage({
            type: FlashMessageType.Success,
            text: 'Customer updated.',
          });
          this.props.history.push('/customer');
        })
        .catch(() => {
          this.props.store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Error updating customer.',
            timeout: 10000,
          });
        });
    } else {
      this.api
        .createCustomer(customer)
        .then(() => {
          this.props.history.push('/customer');
          this.props.store!.UIStore.addFlashMessage({
            type: FlashMessageType.Success,
            text: 'Customer created.',
          });
        })
        .catch(() => {
          this.props.store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not create customer.',
            timeout: 10000,
          });
        });
    }
  }

  render() {
    const isEditMode = this.state.mode === 'edit';
    const { firstName, lastName, phone, email, restaurantNotes } = this.state.customer;

    let pageTitle = firstName && lastName ? `${lastName}, ${firstName}` : '---';
    pageTitle = firstName && !lastName ? firstName : pageTitle;
    pageTitle = isEditMode ? pageTitle : 'New Customer';

    const buttonTitle = isEditMode ? 'Save' : 'Create';
    const subText = isEditMode
      ? `Here you can view and update details about ${lastName ? `${lastName},` : ''} ${firstName}.`
      : 'Create a new customer.';

    return (
      <div>
        <h1>{pageTitle}</h1>
        <Button
          id="btn-save-customer"
          isLoading={this.props.store.UIStore.isLoading}
          onClick={() => this.saveCustomer()}
          className="btn-cta btn-save"
        >
          {buttonTitle}
        </Button>
        <sub>{subText}</sub>
        <Row>
          <Col xs={4}>
            <h3>General</h3>
            <FormGroup>
              <Input
                title="Phone"
                type="tel"
                value={phone}
                onChange={(x) => this.handleInputChange(x, 'phone')}
                isDisabled
              />
              <Input
                title="Full name"
                type="text"
                value={firstName}
                onChange={(x) => this.handleInputChange(x, 'firstName')}
                isValid={firstName.length > 3}
                isDisabled
              />
              <Input
                title="Email"
                type="email"
                value={email || ''}
                onChange={(x) => this.handleInputChange(x, 'email')}
                isDisabled
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <h3>Notes</h3>
            <FormGroup>
              <Input
                title="Restaurant notes (not visible to customer)"
                type="text"
                value={restaurantNotes || ''}
                onChange={(x) => this.handleInputChange(x, 'restaurantNotes')}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(CustomerPage);
