import axios, { CancelTokenSource } from 'axios';
import { inject } from 'mobx-react';
import moment from 'moment-timezone';
import React, { FC, useEffect, useState } from 'react';
import ReactTable from 'react-table';
import styled from 'styled-components';
import BookingAPI, { IWaitingListBooking } from '../api/booking.api';
import ReactTableStyle from '../components/ReactTable.styled';
import RootStore from '../stores/RootStore';
import { FlashMessageType, ModalConductor } from '../stores/UIStore';

const Style = styled.div``;

const columns = [
  {
    Header: 'ID',
    headerClassName: 'col-id',
    accessor: 'id',
    className: 'col-id',
    filterable: true,
  },
  {
    Header: 'Arrival',
    headerClassName: 'col-arrival',
    accessor: 'start',
    className: 'col-arrival',
    Cell: (props: { value: moment.MomentInput }) =>
      moment(props.value).format('ddd, D MMM YYYY HH:mm'),
  },
  {
    Header: 'Guests',
    headerClassName: 'col-guests',
    accessor: 'guests',
    className: 'col-guests',
  },
  {
    Header: 'Customer',
    accessor: 'bookingName',
    filterable: true,
  },
  {
    Header: 'Phone',
    headerClassName: 'col-phone',
    accessor: 'bookingPhone',
    className: 'col-phone',
    filterable: true,
  },
  {
    Header: 'Created',
    headerClassName: 'col-date',
    accessor: 'created',
    className: 'col-date',
    Cell: (props: { value: moment.MomentInput }) => moment(props.value).format('YYYY-MM-DD HH:mm'),
  },
  {
    Header: 'Updated',
    headerClassName: 'col-date',
    accessor: 'updated',
    className: 'col-date',
    Cell: (props: { value: moment.MomentInput }) => moment(props.value).format('YYYY-MM-DD HH:mm'),
  },
];

const bookingApi = new BookingAPI();
let fetchBookingsCancelToken: CancelTokenSource | null = null;

type Props = {
  store?: RootStore;
};

const WaitingList: FC<Props> = ({ store }) => {
  const [waitingListBookings, setWaitingListBookings] = useState<IWaitingListBooking[]>([]);

  useEffect(() => {
    fetchWaitingListBookings();

    return () => {
      if (fetchBookingsCancelToken) {
        fetchBookingsCancelToken.cancel();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWaitingListBookings = () => {
    if (fetchBookingsCancelToken) {
      fetchBookingsCancelToken.cancel();
    }

    fetchBookingsCancelToken = axios.CancelToken.source();

    bookingApi
      .getUpcomingWaitingListBookings(fetchBookingsCancelToken)
      .then((res) => setWaitingListBookings(res.data))
      .catch((e) => {
        if (!axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Error fetching waiting list bookings',
          });
        }
      });
  };

  return (
    <Style className="container">
      <h1>Waiting List</h1>
      <sub>Upcoming customers on a waiting list</sub>

      <ReactTableStyle>
        <ReactTable
          data={waitingListBookings}
          columns={columns}
          resizable={false}
          defaultFilterMethod={(filter: any, row: any) => {
            const id = filter.pivotId || filter.id;
            if (id === 'id') {
              return row[id] !== undefined ? String(row[id]).startsWith(filter.value) : true;
            }
            return row[id] !== undefined
              ? String(row[id])
                  .toLowerCase()
                  .trim()
                  .replace(/\s/g, '')
                  .includes(filter.value.toLowerCase().trim().replace(/\s/g, ''))
              : true;
          }}
          getTdProps={(state: any, rowInfo: any) => ({
            onClick: () => {
              store!.UIStore.openModal({
                type: ModalConductor.Type.Booking,
                bookingId: rowInfo.original.id,
                onSave: () => fetchWaitingListBookings(),
              });
            },
          })}
        />
      </ReactTableStyle>
    </Style>
  );
};

export default inject('store')(WaitingList);
