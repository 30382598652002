import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { LanguageType } from '../../../api/translation.api';
import Flag from '../../../components/Flag';
import { TranslationContext } from '../Translator';
import Progress from './Progress';

const LanguageItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0px 8px;
  border-radius: 3px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  > div.flag-and-language {
    display: flex;
    align-items: center;

    svg:first-child {
      margin-right: 8px;
    }
  }

  ${({ isSelected }: { isSelected: boolean }) =>
    isSelected &&
    css`
      background-color: #ebebeb;
    `};
`;

const LanguageSelector = ({ languages }: { languages: LanguageType[] }) => {
  const {
    currentLanguage: [language, setLanguage],
  } = useContext(TranslationContext);
  console.log('TRANS');
  console.log(languages);
  return (
    <>
      {languages.map((item) => (
        <LanguageItem
          key={item.countryCode}
          isSelected={item.countryCode === language}
          onClick={() => setLanguage(item.countryCode)}
        >
          <div className="flag-and-language">
            <Flag code={item.countryCode} size={20} />
            <div>{item.language}</div>
          </div>
          <Progress ratio={item.completedRatio} />
        </LanguageItem>
      ))}
    </>
  );
};

export default LanguageSelector;
