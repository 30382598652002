import React, { Component } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import Customer from './Customer';
import Customers from './Customers';

type IProps = RouteComponentProps<any>;

class CustomerRoutes extends Component<IProps> {
  render() {
    return (
      <div className="container">
        <Switch>
          <Route path={`${this.props.match.url}/:customerId`} component={Customer} exact />
          <Route path={this.props.match.url} component={Customers} exact />
        </Switch>
      </div>
    );
  }
}

export default CustomerRoutes;
