import { useReducer } from 'react';

export enum SelectedType {
  Id = 'id',
  Email = 'email',
  Phone = 'phone',
  GuestNotes = 'guestNotes',
  InternalNotes = 'internalNotes',
  Status = 'status',
  StatusId = 'statusId',
  Created = 'created',
  Updated = 'updated',
  EventName = 'eventName',
}

export type SelectedState = {
  email: { value: boolean; title: string };
  phone: { value: boolean; title: string };
  guestNotes: { value: boolean; title: string };
  internalNotes: { value: boolean; title: string };
  status: { value: boolean; title: string };
  created: { value: boolean; title: string };
  updated: { value: boolean; title: string };
  eventName: { value: boolean; title: string };
  [key: string]: { value: boolean; title: string };
};

export const useSelectedState = (): [SelectedState, React.Dispatch<{ type: SelectedType }>] => {
  const initialState: SelectedState = {
    email: { value: false, title: 'Email' },
    phone: { value: false, title: 'Phone' },
    guestNotes: { value: false, title: 'Guest notes' },
    internalNotes: { value: false, title: 'Internal notes' },
    status: { value: false, title: 'Status' },
    created: { value: false, title: 'Created' },
    updated: { value: false, title: 'Updated' },
    eventName: { value: false, title: 'Event' },
  };

  const reducer = (state: SelectedState, action: { type: SelectedType }) => {
    switch (action.type) {
      case SelectedType.Email:
        return {
          ...state,
          email: { ...state.email, value: !state.email.value },
        };
      case SelectedType.Phone:
        return {
          ...state,
          phone: { ...state.phone, value: !state.phone.value },
        };
      case SelectedType.GuestNotes:
        return {
          ...state,
          guestNotes: { ...state.guestNotes, value: !state.guestNotes.value },
        };
      case SelectedType.InternalNotes:
        return {
          ...state,
          internalNotes: {
            ...state.internalNotes,
            value: !state.internalNotes.value,
          },
        };
      case SelectedType.Status:
        return {
          ...state,
          status: { ...state.status, value: !state.status.value },
        };
      case SelectedType.Created:
        return {
          ...state,
          created: { ...state.created, value: !state.created.value },
        };
      case SelectedType.Updated:
        return {
          ...state,
          updated: { ...state.updated, value: !state.updated.value },
        };
      case SelectedType.EventName:
        return {
          ...state,
          eventName: { ...state.eventName, value: !state.eventName.value },
        };
      default:
        return state;
    }
  };

  const [selState, dispatch] = useReducer(reducer, initialState);
  return [selState, dispatch];
};
