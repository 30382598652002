import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import styled from 'styled-components';

import axios, { CancelTokenSource } from 'axios';
import { getAllRestaurants } from '../../api/restaurant.api';
import Button from '../../components/Button';
import theme from '../../styles/theme';

const TableStyle = styled.table`
  width: 100%;
  border-spacing: 0px;

  th,
  td {
    padding: 8px;
  }
  th {
    text-align: left;
  }

  tbody {
    tr:nth-child(even) {
      &,
      &::after {
        background-color: ${theme.colors.neutral1};
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};
type State = {
  restaurants: Array<{ id: number; name: string; email: string }>;
};

export default class Restaurants extends Component<Props, State> {
  cancelToken: CancelTokenSource;

  constructor(props: Props) {
    super(props);

    this.state = {
      restaurants: [],
    };

    this.cancelToken = axios.CancelToken.source();
  }

  componentDidMount() {
    getAllRestaurants(this.cancelToken)
      .then((res) => {
        this.setState({
          restaurants: res.data,
        });
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          alert('Error fetching restaurants.');
        }
      });
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  render() {
    return (
      <div>
        <h1>Restaurants</h1>
        <Button to="/restaurant/create" className="btn-cta btn-new-restaurant">
          New Restaurant
        </Button>
        <sub>Here you can see all restaurants that are using Dineout.</sub>

        <Grid fluid>
          <Row>
            <Col xs={9}>
              <TableStyle>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.restaurants.map((item) => (
                    <tr key={`tr-${item.id}`}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                    </tr>
                  ))}
                </tbody>
              </TableStyle>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
