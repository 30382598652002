import { AxiosResponse, CancelTokenSource } from 'axios';
import * as Moment from 'moment-timezone';
import API from './api';
import { CountryCodeType } from './translation.api';
import { UserAPINS } from './user.api';

export namespace RestaurantAPINS {
  export type RestaurantPreferencesModel = {
    isBookingNameMandatory: boolean;
    isBookedByNameMandatory: boolean;
  };

  export enum RestaurantExclusiveType {
    RestaurantOfTheMonth = 1,
    Hero = 2,
    Featured = 3,
    Promoted = 4,
  }

  export type RestaurantExclusiveModel = {
    id: number;
    restaurantId: number;
    type: RestaurantExclusiveType;
    imgUrlBig: string | null;
    imgUrlTablet: string | null;
    imgUrlMobile: string | null;
  };

  export enum RestaurantImageType {
    Cover = 1,
    Logo = 2,
    Misc = 3,
    ThermalPrinterLogo = 4,
  }

  export interface IRestaurantImageModel {
    id: number;
    restaurantId: number;
    publicId: string;
    url: string;
    type: RestaurantImageType;
  }

  export interface IBaseRestaurantModel {
    id: number;
    name: string;
    type: RestaurantType;

    email: string;
    slug: string;
    phone: string;
    website: string;
    priceCategoryId: number;
    ssn: string | null;

    about: string;
    info: string;

    gaTrackingId: string | null;
    fbPixelId: string | null;

    created: string;
    updated: string | null;

    isPublished: boolean;
    isDeleted: boolean;
  }

  export interface IRestaurantReservableModel extends IBaseRestaurantModel {
    onlineDurationMinutes: number;
    cancellationCutoffMinutes: number | null;
    noShowFee: number | null;
    onlineCancellationDisabled: boolean;

    smsConfirmation: boolean;
    smsReminder: number | null;

    onlineMinGuests: number | null;
    onlineMaxGuests: number;
    onlineMaxGuestsText: string | null;
    bookingIntervalMinutes: number;
    sendSurveys: boolean;
    redirectUrl: string | null;
    preferences: RestaurantPreferencesModel;
    emailNotificationRecipient: EmailNotificationRecipientType;
    onlineMenuMandatory: boolean;
    requirePolicyAccept: boolean;
    policyText: string | null;
    note: string | null;
    country: CountryCodeType;
    vatNumber: string | null;
  }

  export type RestaurantPostPutModel = {
    restaurant: RestaurantAPINS.IRestaurantReservableModel;
    locations: RestaurantAPINS.RestaurantLocationModel[];
    categories: RestaurantAPINS.RestaurantCategoryRestaurantModel[];
    users: UserAPINS.IUserAdminModel[];
    openingHours: RestaurantAPINS.RestaurantOpeningHoursModel[];
  };

  export interface IRestaurantPlaceModel extends IBaseRestaurantModel {
    locations: IRestaurantLocationModelWithMeta[];
    images: IRestaurantImageModel[];
    categoryIds: number[];

    nameEn: string;
    nameIs: string;
    aboutEn: string;
    aboutIs: string;
  }

  export interface IRestaurantPlacePostPutModel extends RestaurantAPINS.IRestaurantPlaceModel {
    logoImageBase64: string | null;
    coverImageBase64: string | null;
    miscImagesBase64: string[];
    hasUpdatedImages: boolean;
  }

  export interface IRestaurantLocationModelWithMeta {
    location: RestaurantLocationModel;
    openingHours: RestaurantOpeningHoursModel;
  }

  export type RestaurantOpeningHoursModel = {
    id: number;
    restaurantLocationId: number;
    sundayFrom: string | null;
    sundayTo: string | null;
    mondayFrom: string | null;
    mondayTo: string | null;
    tuesdayFrom: string | null;
    tuesdayTo: string | null;
    wednesdayFrom: string | null;
    wednesdayTo: string | null;
    thursdayFrom: string | null;
    thursdayTo: string | null;
    fridayFrom: string | null;
    fridayTo: string | null;
    saturdayFrom: string | null;
    saturdayTo: string | null;
  };

  export type RestaurantLocationModel = {
    id: number;
    restaurantId: number;

    cityId: number;
    address: string;

    lat: number | null;
    lon: number | null;

    phone: string;
    timeZone: string;
  };

  export type RestaurantCategoryRestaurantModel = {
    id: number;
    restaurantCategoryId: number;
    restaurantId: number;
    order: number;
  };

  export enum RestaurantType {
    Restaurant = 1,
    WalkIn = 2,
    Bar = 3,
    CafeBakery = 4,
  }

  export enum PricingCategory {
    $ = 1,
    $$ = 2,
    $$$ = 3,
    $$$$ = 4,
  }

  export enum EmailNotificationRecipientType {
    None = 0,
    Manager = 1,
    Staff = 2,
    Everyone = 9,
  }
}

const api = new API();

export const getAllPlaces: (
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<RestaurantAPINS.IBaseRestaurantModel[]>> = (cancelToken) => {
  return api._get('/restaurant/place', cancelToken);
};

export const getPlaceById: (
  restaurantId: number,
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<RestaurantAPINS.IRestaurantPlaceModel>> = (
  restaurantId,
  cancelToken,
) => {
  return api._get(`/restaurant/place/${restaurantId}`, cancelToken);
};

export const createPlace: (
  data: RestaurantAPINS.IRestaurantPlacePostPutModel,
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse> = (data, cancelToken) => {
  return api._post('/restaurant/place', data, cancelToken);
};

export const updatePlace: (
  data: RestaurantAPINS.IRestaurantPlacePostPutModel,
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse> = (data, cancelToken) => {
  return api._put('/restaurant/place', data, cancelToken);
};

export const getAllRestaurants: (
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<Array<{ id: number; name: string; email: string }>>> = (cancelToken) => {
  return api._get('/restaurant', cancelToken);
};

export const createRestaurant: (
  data: RestaurantAPINS.RestaurantPostPutModel,
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<boolean>> = (data, cancelToken) => {
  return api._post('/restaurant', data, cancelToken);
};

export const updateRestaurant: (
  data: RestaurantAPINS.RestaurantPostPutModel,
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse> = (data, cancelToken) => {
  return api._put('/restaurant', data, cancelToken);
};

export const getRestaurant: (cancelToken: CancelTokenSource) => Promise<
  AxiosResponse<{
    restaurant: RestaurantAPINS.IRestaurantReservableModel;
    locations: RestaurantAPINS.RestaurantLocationModel[];
    openingHours: RestaurantAPINS.RestaurantOpeningHoursModel[];
    categories: RestaurantAPINS.RestaurantCategoryRestaurantModel[];
    images: RestaurantAPINS.IRestaurantImageModel[];
    users: UserAPINS.IUserAdminModel[];
    allRules: Array<{ id: number; name: string }>;
    dayOfWeekRules: Array<{ dayOfWeek: number; ruleId: number }>;
  }>
> = (cancelToken) => {
  return api._get('/restaurant/single', cancelToken);
};

export default class RestaurantAPI extends API {
  /* Gets own restaurant */
  getRestaurant() {
    return this._get('/restaurant/0');
  }

  deleteRestaurant(id: number) {
    return this._delete(`/restaurant/${id}`);
  }

  getOpeningHoursIntervals(date: string) {
    if (date) {
      return this._get(`/restaurant/openinghours/?date=${date}`);
    }
    return this._get('/restaurant/openinghours/?date=today');
  }

  getOpeningHoursFromTo(date: string) {
    return this._get(`/restaurant/openinghoursfromto/?date=${date}`);
  }

  requiresNoShowFee(restaurantId: number, cancelToken: CancelTokenSource) {
    return this._get(`/restaurant/${restaurantId}/requiresnoshowfee`, cancelToken);
  }

  createOverride(overrideTypeId: number, date: Moment.Moment) {
    return this._post(`/restaurant/override/${overrideTypeId}/${date.format('YYYY-MM-DD')}`, null);
  }

  getOverrideAndRuleByDate(date: Moment.Moment) {
    return this._get(`/restaurant/overrideandrule/${date.format('YYYY-MM-DD')}`);
  }

  setSingleDateRule(ruleId: number, date: Moment.Moment) {
    return this._post(`/restaurant/rule/singledate/${ruleId}/${date.format('YYYY-MM-DD')}`, null);
  }

  deleteSingleDateRule(date: Moment.Moment) {
    return this._delete(`/restaurant/rule/singledate/${date.format('YYYY-MM-DD')}`);
  }

  getRestaurantNote(date: Moment.Moment) {
    return this._get(`/restaurant/note/${date.format('YYYY-MM-DD')}`);
  }

  updateRestaurantNote(date: Moment.Moment, note: string) {
    return this._put(`/restaurant/note/${date.format('YYYY-MM-DD')}`, {
      note,
    });
  }

  getExclusiveRestaurants(cancelToken: CancelTokenSource): Promise<
    AxiosResponse<{
      restaurants: Array<{ id: number; name: string }>;
      exclusives: RestaurantAPINS.RestaurantExclusiveModel[];
    }>
  > {
    return this._get('/restaurantexclusive', cancelToken);
  }

  updateExclusives(exclusives: Array<Omit<RestaurantAPINS.RestaurantExclusiveModel, 'id'>>) {
    return this._put('/restaurantexclusive', exclusives);
  }

  getRestaurantAdminInfo(cancelToken: CancelTokenSource): Promise<
    AxiosResponse<{
      id: number;
      name: string;
      onlineDurationMinutes: number;
      noShowFee: number | null;
      hasPaymentKey: boolean;
      smsConfirmation: boolean;
      preferences: RestaurantAPINS.RestaurantPreferencesModel | null;
      country: 'is' | 'es' | 'nb'; // TODO: change nb to no
      timeZone: string;
    }>
  > {
    return this._get('/restaurant/admininfo', cancelToken);
  }

  saveOpeningHoursOverride(
    date: string,
    times: Array<{ item1: string; item2: string }>,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<boolean>> {
    return this._post(
      '/restaurant/openinghoursoverride',
      {
        date,
        times,
      },
      cancelToken,
    );
  }

  getOpeningHoursOverride(
    date: string,
    cancelToken: CancelTokenSource,
  ): Promise<
    AxiosResponse<
      Array<{
        id: number;
        from: string;
        to: string;
        date: string;
        restaurantId: number;
      }>
    >
  > {
    return this._get(`/restaurant/openinghoursoverride/${date}`, cancelToken);
  }
}
