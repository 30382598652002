import axios, { CancelTokenSource } from 'axios';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import FlashMessage from './components/FlashMessage';
import Loading from './components/Loading';
import ModalConductor from './components/ModalConductor';
import Navbar from './components/Navbar';
import PageNotFound from './pages/404';
import Activity from './pages/Activity';
import Archive from './pages/Archive';
import Booking from './pages/Booking';
import Customer from './pages/Customer';
import Dashboard from './pages/Dashboard';
import Event from './pages/Event';
import Exclusive from './pages/Exclusive';
import FloorPlan from './pages/FloorPlan';
import Restaurant from './pages/Restaurant/';
import Rule from './pages/Rule';

import RestaurantAPI from './api/restaurant.api';
import UserAPI from './api/user.api';
import AdminTools from './components/AdminTools';
import SatisfactionSurveyPage from './pages/Archive/Satisfaction/SatisfactionSurveyPage';
import Menu from './pages/Menu';
import RestaurantBilling from './pages/RestaurantBilling';
import Translate from './pages/Translate';
import WaitingList from './pages/WaitingList';
import { IRootStore } from './stores/RootStore';
import { ModalConductor as ModalConductorNS } from './stores/UIStore';
import { UserRole } from './stores/UserStore';

const ProxyBanner = styled.div`
  background-color: #ef5350;
  color: #fff;
  text-align: center;
  padding-bottom: 5px;
  font-size: 12px;
`;

// eslint-disable-next-line @typescript-eslint/ban-types
type State = {};
type Props = {
  store: IRootStore;
};

@inject('store')
@observer
export default class Layout extends Component<Props, State> {
  api: UserAPI;
  restaurantApi: RestaurantAPI;
  restAdminInfoCancelToken?: CancelTokenSource;

  constructor(props: Props) {
    super(props);

    this.api = new UserAPI();
    this.restaurantApi = new RestaurantAPI();
    this.toggleAdminTools = this.toggleAdminTools.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.toggleAdminTools);

    this.restAdminInfoCancelToken = axios.CancelToken.source();
    this.restaurantApi
      .getRestaurantAdminInfo(this.restAdminInfoCancelToken)
      .then((res) => {
        this.props.store.RestaurantStore.setRestaurant(res.data);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          throw Error('Could not fetch restaurant info for admin.');
        }
      });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.toggleAdminTools);

    if (this.restAdminInfoCancelToken) {
      this.restAdminInfoCancelToken.cancel();
    }
  }

  toggleAdminTools(e: KeyboardEvent) {
    // CMD + K for admin tools
    if (e.keyCode === 75 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      this.props.store!.UIStore.toggleAdminTools();
    }
  }

  render() {
    const {
      RestaurantStore: { user, restaurant },
      UIStore: {
        modalConductor: { type: modalConductorType },
        isBodyScrollDisabled,
        adminToolsOpen,
      },
    } = this.props.store;
    /**
     * isRestaurantReady checks if restaurant info has been fetched
     * If proxy role is not admin then we wait for restaurant info
     * If proxy role IS admin then we don't care about restaurant info
     */
    const isRestaurantReady =
      (user && user.proxyRole !== UserRole.Admin && restaurant) ||
      (user && user.proxyRole === UserRole.Admin);

    if (!user || !isRestaurantReady) {
      return <Loading isLoading />;
    }

    return (
      <div
        style={{
          overflow: isBodyScrollDisabled ? 'hidden' : 'auto',
          border: user.proxyRole !== user.role ? '5px solid #EF5350' : '0px',
        }}
      >
        {modalConductorType !== ModalConductorNS.Type.None && <ModalConductor />}
        {user.proxyRole !== user.role && <ProxyBanner>Acting as {restaurant!.name}</ProxyBanner>}
        <Navbar />
        <FlashMessage />
        {adminToolsOpen && <AdminTools user={user} />}
        <main>
          <Switch>
            <Route
              path="/"
              exact
              component={user.proxyRole === UserRole.Admin ? Restaurant : Dashboard}
            />
            <Route path="/restaurant" component={Restaurant} />
            <Route path="/surveys" component={SatisfactionSurveyPage} />
            <Route path="/customer" component={Customer} />
            <Route path="/bookings" component={Booking} />
            <Route path="/waitinglist" component={WaitingList} />
            <Route path="/floorplan" component={FloorPlan} />
            <Route path="/billing" component={RestaurantBilling} />
            <Route path="/activity" component={Activity} />
            <Route path="/admanagement" component={Exclusive} />
            <Route path="/rule" component={Rule} />
            <Route path="/event" component={Event} />
            <Route path="/menu" component={Menu} />
            <Route path="/archive" component={Archive} />
            <Route path="/translate" component={Translate} />
            <Route component={PageNotFound} />
          </Switch>
        </main>
      </div>
    );
  }
}
