import Axios from 'axios';
import { inject } from 'mobx-react';
import moment from 'moment-timezone';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { fetchSurveyAnswer } from '../../../api/survey.api';
import { SurveyNS } from '../../../api/survey.api';
import Rating from '../../../components/Rating';
import RootStore from '../../../stores/RootStore';
import { FlashMessageType } from '../../../stores/UIStore';

const Style = styled.div`
  width: 544px;

  .details {
    padding: 32px;

    > div {
      margin-bottom: 8px;
    }

    .answers,
    .note {
      margin-top: 24px;
    }

    b {
      font-weight: 500;
    }
    p {
      margin: 8px 0px;
      padding: 0px;
      white-space: pre-wrap;
    }
  }
`;

const Header = styled.div`
  display: flex;
  height: 72px;
  padding: 0px 32px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  overflow: auto;

  font-style: normal;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 0.666px;
  text-transform: uppercase;

  > div:first-child {
    display: flex;
    height: 100%;
    align-items: center;

    .db-header-title {
      font-weight: 600;
      padding-right: 32px;
      user-select: none;
    }
  }

  i {
    display: none;
    color: #999999;

    @media screen and (min-width: 768px) {
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

const SurveyDetails: FC<{ id: number; store?: RootStore }> = ({ id, store }) => {
  const [survey, setSurvey] = useState<SurveyNS.SurveyAnswerType | null>(null);

  useEffect(() => {
    const cancelToken = Axios.CancelToken.source();

    fetchSurveyAnswer(id, cancelToken)
      .then((res) => {
        setSurvey(res.data);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Error fetching satisfaction survey answer.',
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Style>
      <Header>
        <div>
          <div className="db-header-title">Survey details</div>
        </div>
        <i className="material-icons" onClick={() => store!.UIStore.closeModal(true)}>
          close
        </i>
      </Header>

      {!survey ? (
        <div>Loading...</div>
      ) : (
        <div className="details">
          <div>
            <b>Name:</b> {survey.bookingName}
          </div>
          <div>
            <b>Visit:</b> {moment(survey.start).format('YYYY-MM-DD HH:mm')}
          </div>
          <div>
            <b>Completed:</b> {moment(survey.created).format('YYYY-MM-DD HH:mm')}
          </div>

          <div className="answers">
            <div>
              How was the food?: <Rating stars={survey.foodRating} />
            </div>
            <div>
              How was the service?: <Rating stars={survey.serviceRating} />
            </div>
            <div>
              How was the atmosphere?: <Rating stars={survey.atmosphereRating} />
            </div>
          </div>

          <div className="note">
            <div>
              <b>Note:</b>
            </div>
            <p>{survey.comment}</p>
          </div>
        </div>
      )}
    </Style>
  );
};

export default inject('store')(SurveyDetails);
