import styled from 'styled-components';

export const FormGroup = styled.div`
  margin-bottom: 42px;
  .input-container {
    margin-top: 12px;
    padding-bottom: 12px;
  }
`;

export const GroupItem = styled.div`
  margin-top: 12px;
  padding-bottom: 12px;

  .input-container {
    margin-top: 0px;
    padding-bottom: 0px;
  }
`;
