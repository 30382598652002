import UIStore from './UIStore';
import RestaurantStore from './UserStore';

export interface IRootStore {
  UIStore: UIStore;
  RestaurantStore: RestaurantStore;
}

export default class RootStore implements IRootStore {
  UIStore: UIStore;
  RestaurantStore: RestaurantStore;

  constructor() {
    this.RestaurantStore = new RestaurantStore();
    this.UIStore = new UIStore(this.RestaurantStore);
  }
}
