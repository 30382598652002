import axios from 'axios';
import { inject } from 'mobx-react';
import moment from 'moment-timezone';
import * as Moment from 'moment-timezone';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import RestaurantAPI from '../api/restaurant.api';
import SelectWithStyles from '../components/common/Select';
import RootStore from '../stores/RootStore';
import { FlashMessageType } from '../stores/UIStore';
import Button from './common/Button';
import Label from './common/Label';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .oho-body {
    flex: 1;
    padding: 32px;

    > label {
      display: block;
      margin-bottom: 16px;
    }

    .oho-new-item {
      display: flex;
      > div {
        flex: 1;
        &:first-child {
          margin-right: 8px;
        }
        &:last-child {
          display: flex;
          flex: 0;
          margin-left: 8px;
          align-items: center;
        }
      }
      #oho-btn-add {
        margin-top: 32px;
        width: 104px;
      }
    }

    .oho-selected-overrides {
      margin-top: 32px;

      h3 {
        margin-bottom: 8px;
      }
      sub {
        font-size: 12px;
        color: #999;
      }
      ul {
        list-style-type: none;
        padding: 0px;

        li {
          width: 296px;
          padding: 8px;
          margin-bottom: 8px;

          border: 1px solid #e8e8e8;
          border-radius: 3px;
          background-color: #fff;

          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;

            a {
              display: block;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .oho-buttons {
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 0px;
    margin: 0px;
    padding: 0px;
    z-index: 1;

    @media screen and (min-width: 768px) {
      position: relative;
      padding: 16px 32px 16px 32px;
      border-top: 1px solid #ebebeb;
    }

    > div {
      display: flex;
      align-items: center;

      button {
        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  height: 72px;
  padding: 0px 32px;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  border-bottom: 1px solid #ebebeb;
  overflow: auto;

  font-style: normal;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 0.666px;
  text-transform: uppercase;

  > div:first-child {
    display: flex;
    height: 100%;
    align-items: center;

    .db-header-title {
      font-weight: 600;
      padding-right: 32px;
      user-select: none;
    }
  }

  i {
    display: none;
    color: #999999;

    @media screen and (min-width: 768px) {
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

type Props = {
  store?: RootStore;
  date: Moment.Moment;
  onSave: () => void;
};

const restaurantApi = new RestaurantAPI();

const OpeningHoursOverride: FC<Props> = ({ store, date, onSave }) => {
  const [fromSelected, setFromSelected] = useState<Moment.Moment>(
    date.clone().set({ hours: 12, minutes: 0, seconds: 0, milliseconds: 0 }),
  );
  const [toSelected, setToSelected] = useState<Moment.Moment>(
    date.clone().set({ hours: 12, minutes: 0, seconds: 0, milliseconds: 0 }),
  );

  const [timeOptions, setTimeOptions] = useState<Array<{ label: string; value: string }>>([]);
  const [selectedTimes, setSelectedTimes] = useState<
    Array<{ from: Moment.Moment; to: Moment.Moment }>
  >([]);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const saveCancelToken = axios.CancelToken.source();

  const [isFetchingInitial, setIsFetchingInitial] = useState(true);

  const isAddDisabled = () => {
    return fromSelected.isSame(toSelected) || !fromSelected.isBefore(toSelected);
  };

  // Fetch opening hours override
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    restaurantApi
      .getOpeningHoursOverride(date.format(moment.defaultFormatUtc), cancelToken)
      .then((res) => {
        const times = res.data.map((x) => {
          const dateParts = x.date.split('T');
          const from = moment(`${dateParts[0]}T${x.from}`, moment.defaultFormatUtc);
          const to = moment(`${dateParts[0]}T${x.to}`, moment.defaultFormatUtc);
          return { from, to };
        });
        setSelectedTimes(times);
        setIsFetchingInitial(false);
      })
      .catch((e) => {
        setIsFetchingInitial(false);
        if (!axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not fetch overrides for this date.',
          });
        }
      });

    return () => cancelToken.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Init time data
  useEffect(() => {
    const begin = moment().set({ hours: 8, minutes: 0, seconds: 0, milliseconds: 0 });
    const end = moment().set({ hours: 23, minutes: 45, seconds: 0, milliseconds: 0 });
    const times: string[] = [];

    while (begin.isSameOrBefore(end)) {
      times.push(begin.format('HH:mm'));
      begin.add(15, 'minutes');
    }

    setTimeOptions(times.map((x) => ({ label: x, value: x })));
  }, []);

  return (
    <Style>
      <Header>
        <div>
          <div className="db-header-title">Opening Hours @ {date.format('LL')}</div>
        </div>
        <i className="material-icons" onClick={() => store!.UIStore.closeModal(true)}>
          close
        </i>
      </Header>
      <div className="oho-body">
        <label>
          Please note that this will override opening hours configured in restaurant settings.
        </label>

        <div className="oho-new-item">
          <div>
            <Label>From</Label>
            <SelectWithStyles
              id="oho-from"
              onChange={(val) => {
                const value: string = val.value;
                const hhmm = value.split(':');
                setFromSelected(
                  date
                    .clone()
                    .set({ hours: parseInt(hhmm[0], 10), minutes: parseInt(hhmm[1], 10) }),
                );
              }}
              options={timeOptions}
              value={fromSelected.format('HH:mm')}
              icon={<i className="material-icons">access_time</i>}
              autoFocus
            />
          </div>
          <div>
            <Label>To</Label>
            <SelectWithStyles
              id="oho-to"
              onChange={(val) => {
                const value: string = val.value;
                const hhmm = value.split(':');
                setToSelected(
                  date
                    .clone()
                    .set({ hours: parseInt(hhmm[0], 10), minutes: parseInt(hhmm[1], 10) }),
                );
              }}
              options={timeOptions}
              value={toSelected.format('HH:mm')}
              icon={<i className="material-icons">access_time</i>}
            />
          </div>
          <div>
            <Button
              id="oho-btn-add"
              onClick={() => {
                const alreadySelected = selectedTimes.filter(
                  (x) =>
                    x.from.format('HH:mm') === fromSelected.format('HH:mm') &&
                    x.to.format('HH:mm') === toSelected.format('HH:mm'),
                );
                if (alreadySelected.length === 0) {
                  const newSelected = [
                    ...selectedTimes,
                    { from: fromSelected.clone(), to: toSelected.clone() },
                  ].sort((a, b) => (b.from.isSameOrBefore(a.from) ? 0 : -1));

                  setSelectedTimes(newSelected);

                  // Change from/to for better UX
                  const diff = toSelected.diff(fromSelected, 'minutes');
                  const newTo = toSelected.clone().add(diff, 'minutes');
                  setFromSelected(toSelected);
                  setToSelected(newTo);
                }
              }}
              disabled={isAddDisabled()}
            >
              Add
            </Button>
          </div>
        </div>

        <div className="oho-selected-overrides">
          {isFetchingInitial ? (
            <div>Loading...</div>
          ) : (
            <>
              <h3>Selected overrides</h3>
              <ul>
                {selectedTimes.map((x) => (
                  <li key={`oho-st-${x.from.format('HH:mm')}-${x.to.format('HH:mm')}`}>
                    <div>
                      <div>
                        {x.from.format('HH:mm')} - {x.to.format('HH:mm')}
                      </div>
                      <div>
                        <a
                          onClick={() =>
                            setSelectedTimes(
                              selectedTimes.filter(
                                (st) =>
                                  !(
                                    st.from.format('HH:mm') === x.from.format('HH:mm') &&
                                    st.to.format('HH:mm') === x.to.format('HH:mm')
                                  ),
                              ),
                            )
                          }
                        >
                          <i className="material-icons">close</i>
                        </a>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              {selectedTimes.length === 0 && <sub>Nothing selected...</sub>}
            </>
          )}
        </div>
      </div>
      <div className="oho-buttons">
        <div>
          <Button
            id="oho-btn-cancel"
            className="oho-btn-cancel"
            secondary
            onClick={() => store!.UIStore.closeModal()}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            id="oho-btn-save"
            className="oho-btn-save"
            isLoading={isSaving}
            onClick={() => {
              setIsSaving(true);
              restaurantApi
                .saveOpeningHoursOverride(
                  date.format(moment.defaultFormatUtc),
                  selectedTimes.map((x) => ({
                    item1: x.from.format('HH:mm:ss'),
                    item2: x.to.format('HH:mm:ss'),
                  })),
                  saveCancelToken,
                )
                .then(() => {
                  setIsSaving(false);
                  store!.UIStore.addFlashMessage({
                    type: FlashMessageType.Success,
                    text: 'Opening hours override successfully saved.',
                  });
                  onSave();
                  store!.UIStore.closeModal();
                })
                .catch((e) => {
                  if (!axios.isCancel(e)) {
                    store!.UIStore.addFlashMessage({
                      type: FlashMessageType.Error,
                      text: 'Could not save opening hours override.',
                    });
                  }
                  setIsSaving(false);
                });
            }}
            disabled={isSaving || isFetchingInitial}
          >
            Save
          </Button>
        </div>
      </div>
    </Style>
  );
};

export default inject('store')(OpeningHoursOverride);
