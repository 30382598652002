import axios from 'axios';
import { inject } from 'mobx-react';
import * as Moment from 'moment-timezone';
import moment from 'moment-timezone';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import PaymentAPI, {
  IPaymentLinkInfoForBooking as IBookingPaymentHistory,
} from '../../../api/payment.api';
import { PaymentLinkDeliveryType, PaymentType } from '../../../enums/Payment';
import RootStore from '../../../stores/RootStore';
import { FlashMessageType } from '../../../stores/UIStore';
import theme from '../../../styles/theme';
import {
  canPhoneNumberReceiveSMS,
  isEmailValid as validateEmail,
  isPhoneValid,
  thousandSeperator,
} from '../../../utils';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Label from '../../common/Label';
import PaymentLinkForm from '../../PaymentLinkForm';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 12px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  > div:first-child {
    padding: 0px 16px 16px 16px;

    &.disabled {
      opacity: 0.5;
      & * {
        pointer-events: none !important;
      }
      &:hover {
        cursor: not-allowed;
      }
    }

    .sms-reminder-container > *:first-child {
      flex-basis: ${(100 / 3) * 2 + 100}%;
    }

    @media screen and (min-width: 768px) {
      flex-basis: 50%;
      padding: 0px 32px 32px 32px;
    }
  }
  > div:last-child {
    padding: 0px 16px 16px 16px;

    @media screen and (min-width: 768px) {
      flex-basis: 50%;
      padding: 0px 32px 32px 32px;
      border-left: 1px solid #ebebeb;
    }

    .ps-history-placeholder {
      color: #999;
    }

    .ps-status-item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      span {
        display: block;
        margin-left: 8px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 11px;

        &.has-paid,
        &.card-registered,
        &.booking-expired,
        &.amount {
          color: #fff;
          border-radius: 8px;
          padding: 4px;
        }

        &.has-paid {
          background-color: ${theme.colors.success};
        }

        &.card-registered {
          background-color: ${theme.colors.brand1};
        }

        &.booking-expired {
          background-color: ${theme.colors.warning};
        }

        &.amount {
          background-color: #fff;
          border: 1px solid #e8e8e8;
          color: #1c1c1c;
        }
      }
    }
  }

  .sms-header {
    display: flex;
    align-items: center;
    height: 64px;
    flex-shrink: 0;

    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 12px;
    letter-spacing: 0.666px;
    text-transform: uppercase;
  }

  .sms-inline-group {
    display: flex;
    > *:first-child {
      margin-right: 16px;
    }
  }

  .ps-link-form {
    > div {
      display: flex;
      width: 100%;

      > div {
        flex: 1;
        margin-right: 8px;
      }
    }
  }
`;

const Note = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;

  font-size: 12px;
  border-radius: 3px;
  color: #999;
`;

enum SenderState {
  Loading,
  Ready,
  SendingSMS,
  SendingEmail,
}

type Props = {
  store?: RootStore;
  bookingId: number;
  bookingPhone: string;
  bookingEmail: string;
  guests: number;
  feePerGuest: number;
};

const paymentApi = new PaymentAPI();

const PaymentSender: FunctionComponent<Props> = ({
  store,
  bookingId,
  bookingPhone,
  bookingEmail,
  guests,
  feePerGuest,
}) => {
  const [senderState, setSenderState] = useState<SenderState>(SenderState.Loading);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hasPaymentKey = useMemo(() => store!.RestaurantStore.restaurant!.hasPaymentKey, []);

  // Inputs
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(bookingPhone);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>(bookingEmail);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);

  // Existing payment link info
  const [paymentHistory, setPaymentHistory] = useState<IBookingPaymentHistory | null>(null);
  const [currentBookingExpiryDate, setCurrentBookingExpiryDate] = useState<Moment.Moment | null>(
    null,
  );
  const [disableSendingPaymentLink, setDisableSendingPaymentLink] = useState<boolean>(false);

  // Payment link settings vars
  const [paymentLinkMinutes, setPaymentLinkMinutes] = useState<number>(1440); // 24 hrs
  const [paymentLinkDeliveryType, setPaymentLinkDeliveryType] = useState<PaymentLinkDeliveryType>(
    PaymentLinkDeliveryType.Email,
  );
  const [paymentLinkFeePerGuest, setPaymentLinkFeePerGuest] = useState<number | null>(null);
  const [paymentLinkType, setPaymentLinkType] = useState<PaymentType>(PaymentType.NoShow);

  useEffect(() => {
    if (!hasPaymentKey) {
      setDisableSendingPaymentLink(true);
    } else if (paymentHistory) {
      setDisableSendingPaymentLink(
        paymentHistory.hasPaidPrePayment ||
          paymentHistory.hasPaidNoShow ||
          paymentHistory.isCardRegistered,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentHistory]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    paymentApi
      .getPaymentInfoForBooking(bookingId, cancelToken)
      .then((res) => {
        if (res.data) {
          setPaymentHistory(res.data);
          setPaymentLinkFeePerGuest(null);

          // Payment type is initiated as NoShow so change to PrePayment if pre payment payment link has been sent before (To prefill dropdown)
          if (res.data.amountPrePayment) {
            setPaymentLinkType(PaymentType.PrePayment);
          }
        } else {
          setPaymentLinkFeePerGuest(feePerGuest); // Init to default restaurant setting if payment link has not been sent
        }

        setSenderState(SenderState.Ready);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Error fetching payment info.',
          });
        }
      });

    return () => cancelToken.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentHistory) {
      setCurrentBookingExpiryDate(
        paymentHistory.expires ? moment(paymentHistory.expires, moment.defaultFormatUtc) : null,
      );
    }
  }, [paymentHistory]);

  useEffect(() => {
    setIsPhoneNumberValid(
      isPhoneValid(phoneNumber || '') && canPhoneNumberReceiveSMS(phoneNumber || ''),
    );
    setIsEmailValid(validateEmail(email || ''));
  }, [phoneNumber, email]);

  const sendPaymentLink = (deliveryType: PaymentLinkDeliveryType) => {
    const data = {
      bookingId,

      deliveryType,
      paymentType: paymentLinkType,
      expiryMinutes: paymentLinkMinutes,
      feePerGuest: paymentLinkFeePerGuest!,
      guests,

      email,
      phone: phoneNumber,
    };

    const cancelToken = axios.CancelToken.source();

    paymentApi
      .sendPaymentLink(data, cancelToken)
      .then((res) => {
        setSenderState(SenderState.Ready);
        setCurrentBookingExpiryDate(
          res.data.expires ? moment(res.data.expires, moment.defaultFormatUtc) : null,
        );

        setPaymentHistory({
          paymentLinkSentDate: res.data.paymentLinkSentDate,
          expires: res.data.expires,
          amountNoShow: paymentLinkType === PaymentType.NoShow ? res.data.newAmount : null,
          amountPrePayment: paymentLinkType === PaymentType.PrePayment ? res.data.newAmount : null,

          hasPaidNoShow: paymentHistory ? paymentHistory.hasPaidNoShow : false,
          hasPaidPrePayment: paymentHistory ? paymentHistory.hasPaidPrePayment : false,
          isCardRegistered: paymentHistory ? paymentHistory.isCardRegistered : false,
          cardRegisteredDate: paymentHistory ? paymentHistory.cardRegisteredDate : null,
          paymentDateNoShow: paymentHistory ? paymentHistory.paymentDateNoShow : null,
          paymentDatePrePayment: paymentHistory ? paymentHistory.paymentDatePrePayment : null,
        });

        store!.UIStore.addFlashMessage({
          type: FlashMessageType.Success,
          text: 'Payment link sent.',
        });
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not send payment link.',
          });
        }
        setSenderState(SenderState.Ready);
      });
  };

  const paymentLinkTypeOptions = () => {
    const options = [
      { value: PaymentType.NoShow, label: 'No show' },
      { value: PaymentType.PrePayment, label: 'Pre payment' },
    ];

    if (!paymentHistory || !paymentHistory.paymentLinkSentDate) {
      return options;
    }

    return [
      ...(paymentHistory.amountNoShow || paymentHistory.amountNoShow === 0 ? [options[0]] : []),
      ...(paymentHistory.amountPrePayment || paymentHistory.amountPrePayment === 0
        ? [options[1]]
        : []),
    ];
  };

  if (SenderState.Loading) {
    return <div>Loading...</div>;
  }

  return (
    <Style>
      <div className={disableSendingPaymentLink ? 'disabled' : ''}>
        <div className="sms-header">Send payment link</div>

        <div className="ps-link-form">
          <PaymentLinkForm
            paymentLinkMinutes={paymentLinkMinutes}
            updatePaymentLinkMinutes={setPaymentLinkMinutes}
            paymentLinkDeliveryType={paymentLinkDeliveryType}
            updatePaymentLinkDeliveryType={setPaymentLinkDeliveryType}
            paymentLinkType={paymentLinkType}
            updatePaymentLinkType={setPaymentLinkType}
            feePerGuest={paymentLinkFeePerGuest}
            updateFeePerGuest={setPaymentLinkFeePerGuest}
            hideSendType
            paymentLinkTypeOptions={paymentLinkTypeOptions()}
          />
        </div>

        <div>
          <Label>SMS</Label>
          <div className="sms-inline-group sms-reminder-container">
            <Input
              id="sms-reminder-phone"
              value={phoneNumber}
              type="tel"
              onChange={(phn) => setPhoneNumber(phn)}
              placeholder="Customer's mobile"
            />
            <Button
              id="sms-btn-reminder-send"
              onClick={() => {
                if (
                  !disableSendingPaymentLink &&
                  isPhoneNumberValid &&
                  senderState === SenderState.Ready
                ) {
                  setSenderState(SenderState.SendingSMS);
                  sendPaymentLink(PaymentLinkDeliveryType.SMS);
                }
              }}
              isLoading={senderState === SenderState.SendingSMS}
              disabled={
                disableSendingPaymentLink ||
                !paymentLinkFeePerGuest ||
                paymentLinkFeePerGuest <= 0 ||
                isNaN(paymentLinkFeePerGuest) ||
                !isPhoneNumberValid ||
                senderState !== SenderState.Ready
              }
            >
              {senderState === SenderState.SendingSMS ? 'Sending...' : 'Send'}
            </Button>
          </div>
          <Note>
            <span>Note: some carriers block messages containing links</span>
          </Note>
        </div>
        <div>
          <Label>Email</Label>
          <div className="sms-inline-group sms-reminder-container">
            <Input
              id="sms-reminder-phone"
              value={email}
              type="email"
              onChange={(eml) => setEmail(eml)}
              placeholder="Customer's email"
            />
            <Button
              id="sms-btn-reminder-send"
              onClick={() => {
                if (
                  !disableSendingPaymentLink &&
                  isEmailValid &&
                  senderState === SenderState.Ready
                ) {
                  setSenderState(SenderState.SendingEmail);
                  sendPaymentLink(PaymentLinkDeliveryType.Email);
                }
              }}
              isLoading={senderState === SenderState.SendingEmail}
              disabled={
                disableSendingPaymentLink ||
                !paymentLinkFeePerGuest ||
                paymentLinkFeePerGuest <= 0 ||
                isNaN(paymentLinkFeePerGuest) ||
                !isEmailValid ||
                senderState !== SenderState.Ready
              }
            >
              {senderState === SenderState.SendingEmail ? 'Sending...' : 'Send'}
            </Button>
          </div>
          <Note>
            <span>Note: payment emails might end up in SPAM folder</span>
          </Note>
        </div>
      </div>

      <div>
        <div className="sms-header">History</div>
        {!paymentHistory ? (
          <div className="ps-history-placeholder">Nothing here yet...</div>
        ) : (
          <>
            {paymentHistory.paymentLinkSentDate && paymentHistory.amountNoShow && (
              <div className="ps-status-item">
                <div>
                  {moment(paymentHistory.paymentLinkSentDate, moment.defaultFormatUtc).format(
                    'MMM DD, YYYY HH:mm',
                  )}
                </div>
                <span>Payment link sent for no show</span>
                <span className="amount">{`${paymentHistory.amountNoShow || ''} ISK (total)`}</span>
              </div>
            )}
            {paymentHistory.paymentLinkSentDate && paymentHistory.amountPrePayment && (
              <div className="ps-status-item">
                <div>
                  {moment(paymentHistory.paymentLinkSentDate, moment.defaultFormatUtc).format(
                    'MMM DD, YYYY HH:mm',
                  )}
                </div>
                <span>Payment link sent for pre payment</span>
                <span className="amount">{`${
                  paymentHistory.amountPrePayment || ''
                } ISK (total)`}</span>
              </div>
            )}
            {currentBookingExpiryDate && currentBookingExpiryDate.isBefore(moment()) && (
              <div className="ps-status-item">
                <div>{currentBookingExpiryDate.format('MMM DD, YYYY HH:mm')}</div>
                <span className="booking-expired">Expired</span>
              </div>
            )}
            {paymentHistory.isCardRegistered && paymentHistory.cardRegisteredDate && (
              <div className="ps-status-item">
                <div>
                  {moment(paymentHistory.cardRegisteredDate, moment.defaultFormatUtc).format(
                    'MMM DD, YYYY HH:mm',
                  )}
                </div>
                <span className="card-registered">Card Registered</span>
              </div>
            )}
            {paymentHistory.hasPaidNoShow && paymentHistory.paymentDateNoShow && (
              <div className="ps-status-item">
                <div>
                  {moment(paymentHistory.paymentDateNoShow, moment.defaultFormatUtc).format(
                    'MMM DD, YYYY HH:mm',
                  )}
                </div>
                <span className="has-paid">Paid No-Show</span>
                <span className="amount">
                  {thousandSeperator(paymentHistory.amountNoShow || 0)} ISK (total)
                </span>
              </div>
            )}
            {paymentHistory.hasPaidPrePayment && paymentHistory.paymentDatePrePayment && (
              <div className="ps-status-item">
                <div>
                  {moment(paymentHistory.paymentDatePrePayment, moment.defaultFormatUtc).format(
                    'MMM DD, YYYY HH:mm',
                  )}
                </div>
                <span className="has-paid">Paid Pre-Payment</span>
                <span className="amount">
                  {thousandSeperator(paymentHistory.amountPrePayment || 0)} ISK (total)
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </Style>
  );
};

export default inject('store')(PaymentSender);
