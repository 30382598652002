import axios, { CancelTokenSource } from 'axios';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  CountryCodeType,
  getMenuTranslations,
  MenuTranslationType,
  saveMenuTranslations,
} from '../../../../api/translation.api';
import { IRootStore } from '../../../../stores/RootStore';
import { FlashMessageType } from '../../../../stores/UIStore';
import { stringEmptyOrWhitespaceOnly } from '../../../../utils/text.utils';
import { ComputeLanguageFunc, MenuStateType, SaveTranslationFunc } from '../../Translator';
import MenuForm from '../forms/MenuForm';
import TranslationDashboard from '../TranslationDashboard';

const getInitFormState = (menuId: number) => {
  return {
    id: -1,
    menuId,
    name: '',
    shortDescription: '',
    completedRatio: 0,
  };
};

const getStrings = (m: MenuTranslationType): Omit<MenuTranslationType, 'id' | 'menuId'> => ({
  name: stringEmptyOrWhitespaceOnly(m.name) ? '' : m.name,
  shortDescription: stringEmptyOrWhitespaceOnly(m.shortDescription) ? null : m.shortDescription,
});

const MenuTranslator = ({
  store,
  onSave,
  computeLanguages,
}: {
  store?: IRootStore;
  onSave: SaveTranslationFunc;
  computeLanguages: ComputeLanguageFunc;
}) => {
  const match = useRouteMatch<{ menuId: string }>();
  const menuId = parseInt(match.params.menuId, 10);

  const [menuTranslations, setMenuTranslations] = useState<MenuStateType>({
    en: getInitFormState(menuId),
    is: getInitFormState(menuId),
    es: getInitFormState(menuId),
    nb: getInitFormState(menuId),
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentServerData, setCurrentServerData] = useState<MenuStateType | null>(null);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    fetchMenuTranslations(cancelToken);

    return () => cancelToken.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMenuTranslations = (cancelToken: CancelTokenSource) => {
    getMenuTranslations(parseInt(match.params.menuId, 10), cancelToken)
      .then((res) => {
        const translations = res.data;
        Object.keys(translations).map((c) => {
          const code = c as CountryCodeType;
          if (!translations[code]) {
            // Initialise empty state
            translations[code] = { ...getInitFormState(menuId) };
            return;
          }

          // Make sure that only id and translation strings are in the state
          translations[code] = {
            id: translations[code]!.id,
            menuId: translations[code]!.menuId,
            ...getStrings(translations[code]!),
          };
        });

        setMenuTranslations(translations as MenuStateType);
        setCurrentServerData(JSON.parse(JSON.stringify(translations)));
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not fetch translations',
          });
        }
        setLoading(false);
        setError(true);
      });
  };

  if (loading) {
    return <div>Loading..</div>;
  }

  if (error) {
    return <div>Something went wrong</div>;
  }

  return (
    <TranslationDashboard
      title={menuTranslations.en.name}
      languages={computeLanguages(menuTranslations, getStrings)}
      dashboardType="menu"
      saveConfig={{
        onSave: () =>
          onSave(menuTranslations, getStrings, saveMenuTranslations).then(() => {
            const cancelToken = axios.CancelToken.source();
            fetchMenuTranslations(cancelToken);
          }),
        unsavedChanges: JSON.stringify(currentServerData) !== JSON.stringify(menuTranslations),
      }}
    >
      <MenuForm translationState={[menuTranslations, setMenuTranslations]} />
    </TranslationDashboard>
  );
};

export default inject('store')(observer(MenuTranslator));
