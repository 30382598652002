import { inject } from 'mobx-react';
import * as Moment from 'moment-timezone';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import Print from 'react-to-print';
import styled from 'styled-components';
import BookingAPI from '../../../api/booking.api';
import Checkbox from '../../../components/CheckboxNew';
import Button from '../../../components/common/Button';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import RootStore from '../../../stores/RootStore';
import { FlashMessageType } from '../../../stores/UIStore';
import { getAllStatuses } from '../../../utils';
import Table from './PrintDateTable';
import { SelectedType, useSelectedState } from './SelectedState';

const Header = styled.div`
  display: flex;
  height: 72px;
  padding: 0px 32px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  overflow: auto;

  font-style: normal;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 0.666px;
  text-transform: uppercase;

  @media print {
    display: none;
  }

  > div:first-child {
    display: flex;
    height: 100%;
    align-items: center;

    .header-title {
      display: flex;
      align-items: center;
      font-weight: 600;
      padding-right: 32px;
      user-select: none;

      i {
        display: block;
        margin-right: 8px;
      }
    }

    .header-info {
      display: flex;
      align-items: center;
      font-size: 12px;
    }

    .header-tabs {
      display: flex;
      height: 100%;
    }
  }

  i {
    display: none;
    color: #999999;

    @media screen and (min-width: 768px) {
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

const Ldng = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div`
  display: flex;
  flex-basis: 100%;
`;

const Container = styled.div`
  display: flex;
  width: 100vw;
  overflow: hidden;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    max-width: 1120px;
    min-height: 496px;
    height: calc(100vh - 32px);
    width: calc(100vw - 32px);
  }
  @media screen and (max-width: 767px) {
    height: 100vh;
  }
  .print-selection-and-button {
    display: flex;
    min-height: 48px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    padding: 0px 24px;

    @media print {
      display: none;
    }

    .print-selection {
      display: flex;
      flex-wrap: wrap;

      .checkbox {
        margin-right: 8px;
      }
    }

    .print-button-container {
      display: flex;
      height: 100%;
      padding: 8px 0px;
      align-items: center;

      > button {
        height: 32px;

        > div {
          display: flex;
          align-items: center;

          i {
            display: block;
            margin-right: 8px;
            font-size: 16px;
          }
        }
      }
    }
  }
  .print-date-table-container {
    margin-top: 8px;
    padding: 8px;
  }
  > div.scroll {
    flex-basis: 70%;
    flex-grow: 1;
    width: 100%;
    overflow-y: auto;
  }
`;

const Dot = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0px 8px;
  border-radius: 50%;
  background-color: #ebebeb;
`;

const PrintHeader = styled.div`
  display: flex;
  display: none;
  margin: 16px 0px 16px 8px;
  font-size: 16px;
  > span {
    margin-right: 16px;
  }
  @media print {
    display: block;
  }
`;

export type BookingPrintDateType = {
  id: number;
  name: string;
  start: string;
  tables: string;
  guests: number;
  email?: string;
  phone?: string;
  guestNotes?: string;
  internalNotes?: string;
  status?: string;
  created?: string;
  updated?: string;
  eventName?: string;
  [key: string]: string | number | Date | undefined;
};

type Props = {
  store?: RootStore;
  date: Moment.Moment;
};

const PrintDate = ({ store, date }: Props) => {
  const [selState, dispatch] = useSelectedState();
  const [btnLoading, setBtnLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [bookings, setBookings] = useState<BookingPrintDateType[]>([]);
  const tableRef = useRef() as any;

  const fetchBookings = () => {
    const statusList = getAllStatuses();
    return new BookingAPI()
      .getBookingsForPrint(date)
      .then((res) => {
        const { data: bkns } = res;
        const bknsState = bkns.map((b: any) => {
          let status = '';
          const statusRes = statusList.find((x) => x.id === b.status);
          if (statusRes) {
            status = statusRes.name;
          }
          return {
            id: b.id,
            name: b.bookingName,
            start: moment(b.start).format('HH:mm'),
            tables: b.tables
              ? b.tables.map((t: { id: number; name: string }) => t.name).join(', ')
              : [],
            guests: b.guests,
            email: b.bookingEmail,
            phone: b.bookingPhone,
            guestNotes: b.bookingSpecialRequest,
            internalNotes: b.bookingInternalNotes,
            status,
            statusId: b.status,
            created: moment(b.created).format('ddd, MMM Do, HH:mm'),
            updated: moment(b.updated).format('ddd, MMM Do, HH:mm'),
            eventName: b.eventName,
          };
        });
        setBookings(bknsState);
        setDataLoading(false);
      })
      .catch(() => {
        setDataLoading(false);
        store!.UIStore.addFlashMessage({
          type: FlashMessageType.Error,
          text: 'Error fetching bookings',
          timeout: 5000,
        });
      });
  };

  useEffect(() => {
    fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedState = { ...selState };

  const customCols = Object.keys(selectedState)
    .filter(
      (item) =>
        selectedState[item].value &&
        item !== SelectedType.GuestNotes &&
        item !== SelectedType.InternalNotes,
    )
    .map((selectedItem) => selectedState[selectedItem].title);

  const data: BookingPrintDateType[] = JSON.parse(JSON.stringify(bookings));
  data.forEach((x, index) => {
    Object.keys(x).map((y) => {
      if (selectedState[y] && !selectedState[y].value) {
        delete data[index][y];
      }
    });
    return x;
  });

  if (dataLoading) {
    return (
      <Ldng>
        <LoadingSpinner />
      </Ldng>
    );
  }

  return (
    <Wrapper>
      <Container>
        <Header>
          <div>
            <div className="header-title">
              <i className="material-icons">print</i>
              Print
            </div>
            <div className="header-info">
              <div>{date.format('dddd, MMMM Do YYYY')}</div>
              <span>
                <Dot />
              </span>
              <div>{`${
                bookings.filter((b) => b.statusId !== 1 && b.statusId !== 9).length
              } bookings`}</div>
              <span>
                <Dot />
              </span>
              <div>{`${bookings
                .filter((b) => b.statusId !== 1 && b.statusId !== 9)
                .reduce(
                  (acc: number, b: BookingPrintDateType) => (acc += b.guests),
                  0,
                )} guests`}</div>
            </div>
          </div>
          <i className="material-icons" onClick={() => store!.UIStore.closeModal(true)}>
            close
          </i>
        </Header>
        <div className="print-selection-and-button">
          <div className="print-selection">
            {Object.keys(selectedState).map((item) => (
              <Checkbox
                id={`chk-print-${item}`}
                key={item}
                title={selectedState[item].title}
                checked={selectedState[item].value}
                onChange={() => dispatch({ type: item as SelectedType })}
              />
            ))}
          </div>
          <div className="print-button-container">
            <Print
              trigger={() => (
                <Button id="btn-print-save" onClick={() => {}} isLoading={btnLoading}>
                  <i className="material-icons">print</i>Print
                </Button>
              )}
              content={() => tableRef.current}
              onBeforePrint={() => setBtnLoading(true)}
              onAfterPrint={() => setBtnLoading(false)}
            />
          </div>
        </div>
        <div className="print-date-table-container scroll">
          <Table
            columns={['Name', 'Start', 'Tables', 'Guests', ...customCols]}
            data={data}
            ref={tableRef}
            headerJsx={() => (
              <PrintHeader>
                <span>{date.format('dddd, MMMM Do YYYY')}</span>
                <span>
                  <Dot />
                </span>
                <span>{`${bookings.filter((b) => b.status !== 'No show').length} bookings`}</span>
                <span>
                  <Dot />
                </span>
                <span>{`${bookings
                  .filter((b) => b.status !== 'No show')
                  .reduce(
                    (acc: number, b: BookingPrintDateType) => (acc += b.guests),
                    0,
                  )} guests`}</span>
              </PrintHeader>
            )}
          />
        </div>
      </Container>
    </Wrapper>
  );
};

export default inject('store')(PrintDate);
