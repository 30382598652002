import axios, { CancelTokenSource } from 'axios';
import { inject } from 'mobx-react';
import moment from 'moment-timezone';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import BookingAPI from '../../../api/booking.api';
import SmsAPI, { sendCustomSMS, SMSItem } from '../../../api/sms.api';
import { SMSType } from '../../../enums/SMSType';
import { IRootStore } from '../../../stores/RootStore';
import { FlashMessageType } from '../../../stores/UIStore';
import { canPhoneNumberReceiveSMS, isEmailValid, isPhoneValid } from '../../../utils';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Label from '../../common/Label';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 12px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  > div:first-child {
    padding: 0px 16px 16px 16px;

    .notify-reminder-container > *:first-child {
      flex-basis: ${(100 / 3) * 2 + 100}%;
    }

    @media screen and (min-width: 768px) {
      flex-basis: 50%;
      padding: 0px 32px 32px 32px;
    }
  }
  > div:last-child {
    padding: 0px 16px 16px 16px;

    @media screen and (min-width: 768px) {
      flex-basis: 50%;
      padding: 0px 32px 32px 32px;
      border-left: 1px solid #ebebeb;
    }

    .notify-history-item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      span {
        display: block;
        margin-left: 8px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 11px;
      }
    }
  }

  .notify-header {
    display: flex;
    align-items: center;
    height: 64px;
    flex-shrink: 0;

    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 12px;
    letter-spacing: 0.666px;
    text-transform: uppercase;
  }

  .notify-inline-group {
    display: flex;
    > *:first-child {
      margin-right: 16px;
    }
  }
`;

type SmsHistoryResponse = {
  data: {
    booking: { id: number; bookingPhone: string };
    sms: SMSItem[];
  };
};

enum SendingState {
  Default,
  Sending,
}

type Props = {
  store?: IRootStore;
  bookingId: number;
  bookingEmail: string;
  bookingPhone: string;
};

const smsApi = new SmsAPI();
const bookingApi = new BookingAPI();

let sendReminderCancelToken: CancelTokenSource | null = null;
let sendConfirmationCancelToken: CancelTokenSource | null = null;
let customSMSToken: CancelTokenSource | null = null;
let smsHistoryCT: CancelTokenSource | null = null;

const Notify: FC<Props> = ({ store, bookingId, bookingEmail, bookingPhone }) => {
  const [phoneNumber, setPhoneNumber] = useState<string>(bookingPhone || '+354');
  const [customSMSText, setCustomSMSText] = useState<string>('');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(false);

  const [smsHistory, setSmsHistory] = useState<SMSItem[] | null>(null);
  const [smsReminderState, setSmsReminderState] = useState<SendingState>(SendingState.Default);
  const [customSMSState, setCustomSMSState] = useState<SendingState>(SendingState.Default);

  const [email, setEmail] = useState<string>(bookingEmail);
  const [isEmailAddrValid, setIsEmailAddrValid] = useState<boolean>(false);
  const [emailSendState, setEmailSendState] = useState<SendingState>(SendingState.Default);

  const fetchHistory = () => {
    if (smsHistoryCT) {
      smsHistoryCT.cancel();
    }

    smsHistoryCT = axios.CancelToken.source();

    smsApi.getSmsHistoryWithBookingMeta(bookingId, smsHistoryCT).then((res: SmsHistoryResponse) => {
      setSmsHistory(
        res.data.sms.filter(
          (x) =>
            x.type === SMSType.Confirmation ||
            x.type === SMSType.Reminder ||
            x.type === SMSType.Custom,
        ),
      );
    });
  };

  useEffect(() => {
    fetchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Cancel tokens on unmount
  useEffect(() => {
    return () => {
      if (sendReminderCancelToken) {
        sendReminderCancelToken.cancel();
      }
      if (sendConfirmationCancelToken) {
        sendConfirmationCancelToken.cancel();
      }
      if (smsHistoryCT) {
        smsHistoryCT.cancel();
      }
    };
  }, []);

  useEffect(() => {
    setIsPhoneNumberValid(isPhoneValid(phoneNumber) && canPhoneNumberReceiveSMS(phoneNumber));
    setIsEmailAddrValid(isEmailValid(email));
  }, [phoneNumber, email]);

  const sendEmailConfirmation = () => {
    if (sendConfirmationCancelToken) {
      sendConfirmationCancelToken.cancel();
    }
    sendConfirmationCancelToken = axios.CancelToken.source();

    setEmailSendState(SendingState.Sending);

    bookingApi
      .reSendConfirmationEmail(bookingId, email, sendConfirmationCancelToken)
      .then((res) => {
        setEmailSendState(SendingState.Default);

        if (res.data) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Success,
            text: 'Email confirmation sent.',
          });
        } else {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not send email confirmation.',
          });
        }
      })
      .catch((e) => {
        setEmailSendState(SendingState.Default);
        if (!axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not send email confirmation.',
          });
        }
      });
  };

  const sendReminderSms = () => {
    if (sendReminderCancelToken) {
      sendReminderCancelToken.cancel();
    }
    sendReminderCancelToken = axios.CancelToken.source();

    setSmsReminderState(SendingState.Sending);

    smsApi
      .sendReminderSmsWithPhoneNumberByBookingId(bookingId, phoneNumber, sendReminderCancelToken)
      .then((res: { data: { success: boolean } }) => {
        const { success } = res.data;
        setSmsReminderState(SendingState.Default);
        fetchHistory();

        if (success) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Success,
            text: 'SMS reminder successfully sent.',
          });
        } else {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not send SMS reminder.',
          });
        }
      })
      .catch((e) => {
        setSmsReminderState(SendingState.Default);

        if (!axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not send SMS reminder.',
          });
        }
      });
  };

  const sendCustom = () => {
    setCustomSMSState(SendingState.Sending);

    if (customSMSToken) {
      customSMSToken.cancel();
    }

    customSMSToken = axios.CancelToken.source();

    sendCustomSMS(bookingId, phoneNumber, customSMSText, customSMSToken)
      .then((res) => {
        const { success } = res.data;
        setCustomSMSState(SendingState.Default);
        fetchHistory();

        if (success) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Success,
            text: 'Custom SMS successfully sent.',
          });
        } else {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not send custom SMS.',
          });
        }
      })
      .catch(() =>
        store!.UIStore.addFlashMessage({
          type: FlashMessageType.Error,
          text: 'Error sending custom SMS.',
        }),
      );
  };

  const getSMSHistoryType = (type: number) => {
    switch (type) {
      case 1:
        return 'confirmation';
      case 2:
        return 'reminder';
      case 4:
        return 'custom';
    }

    return 'N/A';
  };

  return (
    <Style>
      <div>
        <div className="notify-header">Notify customer</div>
        <div>
          <Label>SMS Reminder</Label>
          <div className="notify-inline-group notify-reminder-container">
            <Input
              id="notify-reminder-phone"
              value={phoneNumber}
              type="tel"
              onChange={(phone) => setPhoneNumber(phone)}
              placeholder="Customer's mobile"
            />
            <Button
              id="notify-btn-reminder-send"
              onClick={() => {
                if (isPhoneNumberValid && smsReminderState !== SendingState.Sending) {
                  sendReminderSms();
                }
              }}
              isLoading={smsReminderState === SendingState.Sending}
              disabled={!isPhoneNumberValid || smsReminderState === SendingState.Sending}
            >
              {smsReminderState === SendingState.Sending ? 'Sending...' : 'Send'}
            </Button>
          </div>
        </div>

        <div>
          <Label>Email Confirmation</Label>
          <div className="notify-inline-group notify-reminder-container">
            <Input
              id="notify-email-confirmation"
              value={email}
              type="email"
              onChange={(emailAddr) => setEmail(emailAddr)}
              placeholder="Customer's email"
            />
            <Button
              id="notify-btn-email-confirmation-send"
              onClick={() => {
                if (isEmailAddrValid && emailSendState !== SendingState.Sending) {
                  sendEmailConfirmation();
                }
              }}
              isLoading={emailSendState === SendingState.Sending}
              disabled={!isEmailAddrValid || emailSendState === SendingState.Sending}
            >
              {emailSendState === SendingState.Sending ? 'Sending...' : 'Send'}
            </Button>
          </div>
        </div>

        <div>
          <Label>Custom SMS</Label>
          <div
            className="notify-inline-group notify-reminder-container"
            style={{ marginBottom: 8 }}
          >
            <Input
              id="notify-reminder-phone"
              value={phoneNumber}
              type="tel"
              onChange={(phone) => setPhoneNumber(phone)}
              placeholder="Customer's mobile"
            />
            <Button
              id="notify-btn-reminder-send"
              onClick={() => {
                if (isPhoneNumberValid && customSMSState !== SendingState.Sending) {
                  sendCustom();
                }
              }}
              isLoading={customSMSState === SendingState.Sending}
              disabled={!isPhoneNumberValid || customSMSState === SendingState.Sending}
            >
              {customSMSState === SendingState.Sending ? 'Sending...' : 'Send'}
            </Button>
          </div>
          <Input
            id="notify-custom-sms-text"
            value={customSMSText}
            type="text"
            onChange={(text) => setCustomSMSText(text)}
            placeholder="Custom SMS text"
          />
        </div>
      </div>

      <div>
        <div className="notify-header">SMS history</div>
        {smsHistory ? (
          smsHistory.length === 0 ? (
            <div style={{ color: '#999' }}>No messages have been sent.</div>
          ) : (
            smsHistory
              .filter((x) => x.type === 1 || x.type === 2 || x.type === 4)
              .map((x, i) => (
                <div className="notify-history-item" key={`notify-${i}`}>
                  <div>
                    {x.sentDate
                      ? moment(x.sentDate, 'YYYY-MM-DDTHH:mm').format('MMM DD, YYYY HH:mm')
                      : 'unsent'}
                  </div>
                  <span>{getSMSHistoryType(x.type)}</span>
                </div>
              ))
          )
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </Style>
  );
};

export default inject('store')(Notify);
