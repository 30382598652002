import styled from 'styled-components';

const ReactTableStyle = styled.div`
  .ReactTable {
    border: 0px;
    font-size: 12px;

    .rt-table {
      /* HEAD */
      .rt-thead {
        box-shadow: none;

        /* HEADERS */
        &.-header {
          .rt-tr {
            .rt-th {
              &.-sort-asc,
              &.-sort-desc {
                box-shadow: none;

                > div {
                  display: flex;
                  align-items: center;

                  &::after {
                    margin-left: 4px;
                    font-family: 'Material Icons';
                    font-size: 20px;
                    text-transform: none;
                  }
                }
              }

              &.-sort-asc {
                > div {
                  &::after {
                    content: 'arrow_drop_down';
                  }
                }
              }

              &.-sort-desc {
                > div {
                  &::after {
                    content: 'arrow_drop_up';
                  }
                }
              }
            }
          }
        }

        /* SEARCH INPUTS */
        &.-filters {
          margin-bottom: 16px;
          border: 0px;

          .rt-tr {
            .rt-th {
              input {
                border: 1px solid #e8e8e8;
              }
            }
          }
        }

        .rt-tr {
          .rt-th {
            display: flex;
            height: 48px;
            align-items: center;
            justify-content: center;

            color: #b6b6b6;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            border: 0px;
          }
        }
      }

      /* BODY */
      .rt-tbody {
        .rt-tr-group {
          border-bottom: 1px solid #e8e8e8;

          .rt-tr {
            display: flex;
            height: 40px;
            align-items: center;

            &.-even {
              background-color: #fbfbfb;
            }

            .rt-td {
              border: 0px;
            }

            &:hover {
              cursor: pointer;
              background-color: #f7f7f7;
              transition: background-color 111ms linear;
            }
          }
        }
      }
    }
  }
`;

export default ReactTableStyle;
