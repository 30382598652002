import { AxiosResponse, CancelTokenSource } from 'axios';
import * as Moment from 'moment-timezone';
import API from './api';

const api = new API();

export enum RestaurantBillingBookingType {
  PerBooking = 1,
  PerGuest = 2,
}

export type RestaurantBillingModel = {
  restaurantId: number;
  companyName: string;
  ssn: string;
  isActive: boolean;

  bookingType: RestaurantBillingBookingType;
  bookingAmount: number;
  subscriptionAmount: number;
  smsAmount: number;
  adHeroAmount: number;
  adROTMAmount: number;
  adTopAmount: number;
  adPromotedAmount: number;

  orderUnpaidCommissionPct: number;
  hardwareOneTimeFee: number;
  hardwareMonthly: number;
  qrOneTimeFee: number;
  websiteMonthly: number;
  websiteOneTimeFee: number;
};

export const getAllRestaurantBillings: (
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<Array<RestaurantBillingModel & { restaurantName: string }>>> = (
  cancelToken,
) => {
  return api._get('/restaurantbilling', cancelToken);
};

export const updateRestaurantBillings: (
  billings: RestaurantBillingModel[],
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<boolean>> = (billings, cancelToken) => {
  return api._put('/restaurantbilling', billings, cancelToken);
};

export const exportTicks: (
  date: Moment.Moment,
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<string>> = (date, cancelToken) => {
  return api._get(`/restaurantbilling/exportticks/${date.format('YYYY-MM-DD')}`, cancelToken);
};
