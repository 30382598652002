import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

const Style = styled.div`
  display: flex;
  align-items: center;
  color: #ffbe00;

  i {
    font-size: 20px;
  }
`;

const Rating: FC<{ stars: number }> = ({ stars }) => {
  const fullStars = useMemo(() => {
    return Math.floor(stars);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const halfStars = useMemo(() => {
    return Number.isInteger(stars) ? 0 : 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const emptyStars = useMemo(() => {
    return 5 - (fullStars + halfStars);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Style title={`${stars} stars`}>
      {Array(fullStars)
        .fill(0)
        .map((_, i) => (
          <i key={`star-${i}`} className="material-icons">
            star
          </i>
        ))}
      {halfStars === 1 ? <i className="material-icons">star_half</i> : null}
      {Array(emptyStars)
        .fill(0)
        .map((_, i) => (
          <i key={`star-border-${i}`} className="material-icons">
            star_border
          </i>
        ))}
    </Style>
  );
};

export default Rating;
