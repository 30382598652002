import { action, computed, observable } from 'mobx';
import * as Moment from 'moment-timezone';
import uuidv4 from 'uuid/v4';
import RestaurantStore, { UserRole } from './UserStore';

/**
 * MODAL CONDUCTOR
 */

export namespace ModalConductor {
  export enum Type {
    None = 'None',
    RestaurantNote = 'RestaurantNote',
    Booking = 'Booking',
    BookingPrintDate = 'BookingPrintDate',
    OpeningHoursOverride = 'OpeningHoursOverride',
    SurveyDetails = 'SurveyDetails',
  }

  export interface INone {
    type: Type.None;
  }

  export interface IBooking {
    type: Type;
    bookingId?: number;
    date?: Moment.Moment;
    preselectedTime?: Moment.Moment;
    preselectedTableId?: number;
    isWalkIn?: boolean;
    onSave: (newDate: Moment.Moment) => void;
  }

  export interface IRestaurantNote {
    type: Type;
    date: Moment.Moment;
    onSave: (newNote: string) => void;
  }

  export interface IBookingPrintDate {
    type: Type;
    date: Moment.Moment;
  }

  export interface IOpeningHoursOverride {
    type: Type;
    date: Moment.Moment;
    onSave: () => void;
  }

  export interface ISurveyDetails {
    type: Type;
    id: number;
  }

  export interface IWaitingListTimes {
    type: Type;
    date: Moment.Moment;
  }

  export type _Type =
    | IBooking
    | IRestaurantNote
    | IBookingPrintDate
    | IOpeningHoursOverride
    | ISurveyDetails
    | INone;
}

/**
 * FLASH MESSAGE
 */
export enum FlashMessageType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export interface IFlashMessage {
  id?: string;
  type: FlashMessageType;
  title?: string;
  text: string;
  timeout?: number;
}

export default class UIStore {
  @observable isLoading: boolean;
  @observable flashMessages: IFlashMessage[];
  @observable modalConductor: ModalConductor._Type;
  @observable hasUnsavedChanges: boolean;
  @observable adminToolsOpen: boolean;

  userStore: RestaurantStore;

  constructor(userStore: RestaurantStore) {
    this.isLoading = false;
    this.flashMessages = [];
    this.modalConductor = {
      type: ModalConductor.Type.None,
    } as ModalConductor.INone;
    this.hasUnsavedChanges = false;
    this.adminToolsOpen = false;

    this.userStore = userStore;
  }

  @action
  toggleAdminTools() {
    if (this.userStore.user && this.userStore.user.role === UserRole.Admin) {
      this.adminToolsOpen = !this.adminToolsOpen;
    }
  }

  @computed
  get isBodyScrollDisabled(): boolean {
    return this.modalConductor.type !== ModalConductor.Type.None;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  addFlashMessage(flashMessage: IFlashMessage) {
    const msgId = uuidv4();
    const timeout = flashMessage.timeout ? flashMessage.timeout : 4000;

    this.flashMessages.push({
      id: msgId,
      timeout,
      ...flashMessage,
    });

    // Remove flash message after timeout
    setTimeout(() => {
      this.removeFlashMessage(msgId);
    }, timeout);
  }

  @action
  removeFlashMessage(msgId: string) {
    const i = this.flashMessages.findIndex((x) => x.id === msgId);
    if (i >= 0) {
      this.flashMessages.splice(i, 1);
    }
  }

  @action
  openModal(modalType: ModalConductor._Type) {
    this.modalConductor = modalType;
  }

  @action
  closeModal(promptUnsavedChanges = false) {
    /* Prompt for unsaved changes */
    if (promptUnsavedChanges && this.hasUnsavedChanges) {
      if (confirm('You have unsaved changes. Discard changes?')) {
        this.modalConductor = {
          type: ModalConductor.Type.None,
        };
      }
    } else {
      this.modalConductor = {
        type: ModalConductor.Type.None,
      };
    }
  }

  @action
  setHasUnsavedChanges(hasUnsavedChanges: boolean) {
    this.hasUnsavedChanges = hasUnsavedChanges;
  }
}
