import moment from 'moment-timezone';
import * as Moment from 'moment-timezone';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import theme from '../styles/theme';

const DateScrollerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  .btn-today {
    margin-right: 16px;
    &:hover {
      cursor: pointer;
    }
  }
`;

const DateScrollerStyle = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  font-weight: 300;

  & > div {
    /* Selected date label */
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  & > * {
    user-select: none;
    &:hover {
      cursor: pointer;
    }
  }
  & > i {
    &:hover {
      background-color: ${theme.colors.neutral2};
    }
  }
`;

const DateModal = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(24, 25, 31, 0.7);
  z-index: 999999;

  .content {
    /* Modal content */
    padding: 16px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    ${DateScrollerStyle} {
      margin-top: 8px;
      margin-bottom: 8px;
      justify-content: center;
    }
  }
`;

const Calendar = styled.div`
  & > div {
    display: grid;
    grid-template-columns: repeat(7, 50px);
    grid-auto-rows: minmax(50px, 50px);
    align-content: center;
    align-items: center;
    text-align: center;

    &:first-child {
      /* Day names row */
      text-transform: uppercase;
      font-weight: 500;
      user-select: none;
    }
  }
`;

const MonthCalendar = styled.div`
  & > div {
    display: grid;
    grid-template-columns: repeat(4, 62px);
    grid-auto-rows: minmax(62px, 62px);
    align-content: center;
    align-items: center;
    text-align: center;

    &:first-child {
      /* Month names row */
      text-transform: uppercase;
      font-weight: 500;
      user-select: none;
    }
  }
`;

type SDayProps = { isSelected: boolean; isToday: boolean; isOutsideMonth: boolean };

const Day = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  user-select: none;

  &:hover {
    background-color: ${theme.colors.neutral1};
    cursor: pointer;
  }

  ${(props: SDayProps) =>
    props.isSelected &&
    css`
      background-color: #ffab66 !important;
      color: #fff !important;
    `};

  ${(props: SDayProps) =>
    props.isToday &&
    css`
      background-color: ${theme.colors.neutral2};
    `};

  ${(props: SDayProps) =>
    props.isOutsideMonth &&
    css`
      color: ${theme.colors.neutral4};
    `};
`;

type SMonthType = { isSelected: boolean; isToday: boolean };

const Month = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  user-select: none;

  &:hover {
    background-color: ${theme.colors.neutral1};
    cursor: pointer;
  }

  ${(props: SMonthType) =>
    props.isSelected &&
    css`
      background-color: #ffab66 !important;
      color: #fff !important;
    `};

  ${(props: SMonthType) =>
    props.isToday &&
    css`
      background-color: ${theme.colors.neutral2};
    `};
`;

type Props = {
  onUpdate: (newDate: Moment.Moment) => void;
  mode: string;
  selectedDate: Moment.Moment;
};

type State = {
  selectedDate: Moment.Moment;
  modalDate: Moment.Moment;
  isDateModalOpen: boolean;
};

export default class DateScroller extends Component<Props, State> {
  static defaultProps = {
    mode: 'day',
    value: moment(),
  };

  wrapperRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedDate: props.selectedDate,
      modalDate: moment(),
      isDateModalOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', (e: any) => this.handleClickOutside(e));
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { selectedDate } = nextProps;
    if (selectedDate.isSame(this.state.selectedDate)) {
      return;
    }

    this.setState({ selectedDate });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', (e: any) => this.handleClickOutside(e));
  }

  setToday() {
    this.setState({ selectedDate: moment() }, () => {
      this.props.onUpdate(this.state.selectedDate);
    });
  }

  // For closing on click outside
  setWrapperRef(node: any) {
    this.wrapperRef = node;
  }

  // Close modal if clicked outside
  handleClickOutside(event: any) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isDateModalOpen: false });
    }
  }

  navigateWithArrow(direction: string) {
    const { selectedDate } = this.state;
    const { mode } = this.props;
    const newDate = selectedDate.clone();

    if (direction === 'prev') {
      newDate.subtract(1 as any, mode);
    } else {
      newDate.add(1 as any, mode);
    }

    if (this.props.mode === 'month') {
      newDate.startOf('month');
    }

    this.setState({ selectedDate: newDate }, () => {
      this.props.onUpdate(this.state.selectedDate);
    });
  }

  navigateMonth(direction: string) {
    const { modalDate } = this.state;
    const newDate = modalDate.clone();

    if (direction === 'prev') {
      newDate.subtract(1, 'month');
    } else {
      newDate.add(1, 'month');
    }

    if (this.props.mode === 'month') {
      newDate.startOf('month');
    }

    this.setState({ modalDate: newDate });
  }

  navigateYear(direction: string) {
    const { modalDate } = this.state;
    const newDate = modalDate.clone();

    if (direction === 'prev') {
      newDate.subtract(1, 'year');
    } else {
      newDate.add(1, 'year');
    }

    this.setState({ modalDate: newDate });
  }

  updateCalendarDate(date: Moment.Moment) {
    const newDate = date;
    if (this.props.mode === 'month') {
      newDate.startOf('month');
    }
    this.setState({ selectedDate: newDate, isDateModalOpen: false }, () => {
      this.props.onUpdate(this.state.selectedDate);
    });
  }

  generateDayModal() {
    const { modalDate, selectedDate } = this.state;

    let firstSundayOfCal = modalDate.clone().startOf('month');
    firstSundayOfCal =
      firstSundayOfCal.format('ddd') === 'Sun'
        ? firstSundayOfCal
        : modalDate.clone().startOf('month').isoWeekday(0);
    const lastDayOfCal = modalDate.clone().endOf('month').isoWeekday(0).endOf('week');

    const daysInMonth = [firstSundayOfCal];
    const dateIterator = firstSundayOfCal.clone();
    while (dateIterator.add(1, 'day').diff(lastDayOfCal) < 0) {
      daysInMonth.push(dateIterator.clone());
    }

    return (
      <DateModal>
        <div ref={(node: any) => this.setWrapperRef(node)}>
          <div className="content">
            <DateScrollerStyle>
              <i
                className="material-icons"
                onClick={() => this.navigateMonth('prev')}
                role="button"
                tabIndex={0}
              >
                keyboard_arrow_left
              </i>
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  this.setState({ isDateModalOpen: true });
                }}
              >
                {modalDate.format('MMMM, YYYY')}
              </div>
              <i
                className="material-icons"
                onClick={() => this.navigateMonth('next')}
                role="button"
                tabIndex={0}
              >
                keyboard_arrow_right
              </i>
            </DateScrollerStyle>
            <Calendar>
              <div>
                <div>Sun</div>
                <div>Mon</div>
                <div>Tue</div>
                <div>Wed</div>
                <div>Thu</div>
                <div>Fri</div>
                <div>Sat</div>
              </div>
              <div>
                {daysInMonth.map((day) => (
                  <Day
                    onClick={() => this.updateCalendarDate(day)}
                    isSelected={day.isSame(selectedDate, 'day')}
                    isToday={day.isSame(moment(), 'day')}
                    isOutsideMonth={!day.isSame(modalDate, 'month')}
                    key={`day-${day.format('D MM YY')}`}
                  >
                    {day.format('D')}
                  </Day>
                ))}
              </div>
            </Calendar>
          </div>
        </div>
      </DateModal>
    );
  }

  generateMonthModal() {
    const { modalDate, selectedDate } = this.state;

    const monthsInYear = [];
    const jan = modalDate.clone().month(0).date(1);
    for (let i = 0; i < 12; i++) {
      monthsInYear.push(jan.clone().add(i, 'month'));
    }

    return (
      <DateModal>
        <div ref={(node: any) => this.setWrapperRef(node)}>
          <div className="content">
            <DateScrollerStyle>
              <i
                className="material-icons"
                onClick={() => this.navigateYear('prev')}
                role="button"
                tabIndex={0}
              >
                keyboard_arrow_left
              </i>
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  this.setState({ isDateModalOpen: true });
                }}
              >
                {modalDate.format('YYYY')}
              </div>
              <i
                className="material-icons"
                onClick={() => this.navigateYear('next')}
                role="button"
                tabIndex={0}
              >
                keyboard_arrow_right
              </i>
            </DateScrollerStyle>
            <MonthCalendar>
              <div>
                {monthsInYear.map((month) => (
                  <Month
                    onClick={() => this.updateCalendarDate(month)}
                    isSelected={month.isSame(selectedDate, 'month')}
                    isToday={month.isSame(moment(), 'month')}
                    key={`month-${month.format('MMM')}`}
                  >
                    {month.format('MMM')}
                  </Month>
                ))}
              </div>
            </MonthCalendar>
          </div>
        </div>
      </DateModal>
    );
  }

  renderDateModal() {
    const { isDateModalOpen } = this.state;
    const { mode } = this.props;

    if (isDateModalOpen) {
      if (mode === 'day') {
        return this.generateDayModal();
      } else if (mode === 'month') {
        return this.generateMonthModal();
      }
    }

    return null;
  }

  render() {
    const { selectedDate } = this.state;
    const { mode } = this.props;
    const currentDateLabel = mode.match('day')
      ? selectedDate.format('dddd, MMMM DD, YYYY')
      : selectedDate.format('MMMM, YYYY');

    return (
      <DateScrollerContainer>
        {this.renderDateModal()}
        <div className="btn-today" onClick={() => this.setToday()} role="button" tabIndex={0}>
          Today
        </div>
        <DateScrollerStyle>
          <i
            className="material-icons"
            onClick={() => this.navigateWithArrow('prev')}
            role="button"
            tabIndex={0}
          >
            keyboard_arrow_left
          </i>
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              this.setState({ isDateModalOpen: true, modalDate: selectedDate });
            }}
          >
            {currentDateLabel}
          </div>
          <i
            className="material-icons"
            onClick={() => this.navigateWithArrow('next')}
            role="button"
            tabIndex={0}
          >
            keyboard_arrow_right
          </i>
        </DateScrollerStyle>
      </DateScrollerContainer>
    );
  }
}
