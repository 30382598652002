import React from 'react';
import Page from './';

const SatisfactionSurveyPage = () => {
  return (
    <>
      <div className="container">
        <h1>Surveys</h1>
        <sub>Satisfaction surveys sent out to customers</sub>

        <Page />
      </div>
    </>
  );
};

export default SatisfactionSurveyPage;
