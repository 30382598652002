import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import uuidv4 from 'uuid/v4';
import theme from '../styles/theme';

type SProps = { isDisabled: boolean };

const InputStyle = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 29px;

  label {
    font-size: 12px;
    color: ${theme.colors.dark2};
  }

  textarea {
    width: 100%;
    height: 100%;
    padding: 8px;
    border: none;
    font-size: 12px;
    margin-top: 8px;
    border-radius: 3px;
    border: 1px solid ${theme.colors.neutral4};

    ${(props: SProps) =>
      props.isDisabled &&
      css`
        padding: 0px;
      `};

    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: ${theme.colors.neutral4};
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: ${theme.colors.neutral4};
      opacity: 1;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: ${theme.colors.neutral4};
      opacity: 1;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${theme.colors.neutral4};
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${theme.colors.neutral4};
    }

    ::placeholder {
      /* Most modern browsers support this now. */
      color: ${theme.colors.neutral4};
    }

    ${(props: SProps) =>
      props.isDisabled &&
      css`
        border-color: transparent;
      `};
  }
`;

type Props = {
  title: string;
  value: string;
  onChange: (newText: string) => void;
  isDisabled: boolean;
  placeholder: string;
  rows: number;
  id: string | null;
};

type State = {
  id: string;
};

export default class Input extends Component<Props, State> {
  static defaultProps = {
    id: null,
    isDisabled: false,
    placeholder: '',
    rows: 10,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      id: props.id || uuidv4(),
    };
  }

  render() {
    const { title, isDisabled, placeholder, rows } = this.props;
    let { value } = this.props;
    const htmlFor = `inp-${this.state.id}`;

    value = value || '';

    return (
      <InputStyle isDisabled={isDisabled}>
        <label htmlFor={htmlFor}>
          <div className="input-title">{title}</div>
          <textarea
            id={htmlFor}
            name={htmlFor}
            placeholder={placeholder}
            onChange={(e) => {
              this.props.onChange(e.target.value);
            }}
            disabled={isDisabled}
            rows={rows}
            value={value}
          />
        </label>
      </InputStyle>
    );
  }
}
