import axios from 'axios';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import * as Moment from 'moment-timezone';
import TimePicker from 'rc-time-picker';
import React, { FC, useEffect, useMemo, useReducer, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styled from 'styled-components';
import { FloorPlanAPINS, getFloorPlans } from '../../api/floorplan.api';
import MenuAPI, { IMenuTime, IMenuWithTimes } from '../../api/menu.api';
import Checkbox from '../../components/CheckboxNew';
import Button from '../../components/common/Button';
import Input from '../../components/common/Input';
import Label from '../../components/common/Label';
import Select from '../../components/common/Select';
import TabsStyle from '../../components/Tabs.styled';
import { PaymentType } from '../../enums/Payment';
import { IRootStore } from '../../stores/RootStore';
import { FlashMessageType } from '../../stores/UIStore';
import theme from '../../styles/theme';

const Style = styled.div`
  padding-bottom: 200px;

  #m-delete {
    background-color: ${theme.colors.danger};
    width: 140px;
  }
`;

const TimesWrapper = styled.div`
  margin-top: 16px;
  padding: 8px;
  background-color: #ececec;
  border-radius: 4px;

  .m-times-row {
    display: flex;
    align-items: flex-end;

    .rc-time-picker {
      margin-right: 16px;
    }

    i {
      &:hover {
        cursor: pointer;
      }
    }
  }

  #m-btn-new-time  {
    width: 140px;
    margin-top: 32px;
  }
`;

const MenuFormStyled = styled.div`
  max-width: 512px;

  #chk-menu-is-published {
    margin-bottom: 16px;
  }

  .ql-container {
    height: 296px;
  }

  .payment-config {
    display: flex;
    > div:first-child {
      padding-right: 16px;
      width: 100%;
    }
  }

  h3:not(:first-child) {
    margin-top: 32px;
  }

  .menu-fifty-fifty {
    display: flex;
    > div {
      flex-basis: 50%;
      &:first-child {
        padding-right: 8px;
      }
      &:last-child {
        padding-left: 8px;
      }
    }
  }

  .breather {
    margin-bottom: 16px;
  }
`;

const Required = styled.span`
  color: ${theme.colors.danger};
  padding-left: 4px;
`;

type ViewMode = 'Create' | 'Edit';

type MenuActionType =
  | { type: 'Menu'; payload: IMenuWithTimes }
  | { type: 'Name'; payload: string }
  | { type: 'ShortDescription'; payload: string }
  | { type: 'Description'; payload: string }
  | { type: 'PaymentType'; payload: number }
  | { type: 'PaymentAmount'; payload: number | undefined }
  | { type: 'IsPublished' }
  | { type: 'MinGuests'; payload: number }
  | { type: 'IsExclusive' }
  | { type: 'FloorPlanId'; payload: number }
  | { type: 'Times'; payload: IMenuTime[] }
  | { type: 'OnlineDurationMinutes'; payload: number | undefined };

interface IMenuProps extends RouteComponentProps<{ id?: string | undefined }> {
  store: IRootStore;
}

const Menu: FC<IMenuProps> = ({ match, history, store }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const viewMode: ViewMode = useMemo(() => (match.params.id ? 'Edit' : 'Create'), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hasPaymentKey = useMemo(() => store!.RestaurantStore.restaurant!.hasPaymentKey, []);

  const menuAPI = useMemo(() => new MenuAPI(), []);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMenu, setLoadingMenu] = useState(true);
  const [error, setError] = useState(false);

  const [menuFollowsOpeningHours, setMenuFollowsOpeningHours] = useState(true);

  const initialMenuState: IMenuWithTimes = {
    id: -1,
    name: '',
    shortDescription: '',
    paymentType: PaymentType.None,
    paymentAmount: undefined,
    isPublished: false,
    minGuests: 0,
    isExclusive: false,
    floorPlanId: 0,
    times: [],
    onlineDurationMinutes: undefined,
  };

  const reducer = (menuState: IMenuWithTimes, action: MenuActionType): IMenuWithTimes => {
    switch (action.type) {
      case 'Menu':
        return action.payload;
      case 'Name':
        return { ...menuState, name: action.payload };
      case 'ShortDescription':
        return { ...menuState, shortDescription: action.payload };
      case 'PaymentType':
        return { ...menuState, paymentType: action.payload };
      case 'PaymentAmount':
        let newPaymentAmount = action.payload;
        if (action.payload) {
          newPaymentAmount = action.payload >= 0 ? action.payload : 0;
        }
        return { ...menuState, paymentAmount: newPaymentAmount };
      case 'IsPublished':
        return { ...menuState, isPublished: !menuState.isPublished };
      case 'MinGuests':
        return { ...menuState, minGuests: action.payload };
      case 'IsExclusive':
        return { ...menuState, isExclusive: !menuState.isExclusive };
      case 'FloorPlanId':
        return { ...menuState, floorPlanId: action.payload };
      case 'Times':
        return { ...menuState, times: action.payload };
      case 'OnlineDurationMinutes':
        return { ...menuState, onlineDurationMinutes: action.payload };
      default:
        return menuState;
    }
  };

  const [menu, dispatch] = useReducer(reducer, initialMenuState);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (viewMode === 'Edit') {
      menuAPI
        .getMenu(parseInt(match.params.id!, 10), cancelToken)
        .then((res) => {
          dispatch({ type: 'Menu', payload: res.data });
          setLoadingMenu(false);
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            store!.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Could not fetch menu',
            });
            setLoadingMenu(false);
            setError(true);
          }
        });
    } else {
      setLoadingMenu(false);
    }

    return () => cancelToken.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [floorPlans, setFloorPlans] = useState<FloorPlanAPINS.IFloorPlanWithTableAndComboCount[]>(
    [],
  );
  useEffect(() => {
    const fpCancelToken = axios.CancelToken.source();

    getFloorPlans(fpCancelToken)
      .then((res) => {
        setFloorPlans(res.data);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not fetch floor plans',
          });
          setLoadingMenu(false);
          setError(true);
        }
      });

    return () => fpCancelToken.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createMenu = () => {
    const cancelToken = axios.CancelToken.source();
    setIsLoading(true);
    menuAPI
      .createMenu(menu, cancelToken)
      .then((res) => {
        const { success } = res.data;
        if (success) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Success,
            text: 'Menu successfully created',
          });
          setIsLoading(false);

          history.replace('/menu');
        } else {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Error creating menu',
          });
          setIsLoading(false);
        }
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not create menu',
          });
          setIsLoading(false);
        }
      });
  };

  const editMenu = () => {
    const cancelToken = axios.CancelToken.source();
    setIsLoading(true);
    menuAPI
      .editMenu(menu, cancelToken)
      .then((res) => {
        const { success } = res.data;
        if (success) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Success,
            text: 'Menu successfully updated',
          });
          setIsLoading(false);
          history.push('/menu');
        } else {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Error updating menu',
          });
          setIsLoading(false);
        }
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not update menu',
          });
          setIsLoading(false);
        }
      });
  };

  // If any dates are selected that means menu is not always visible
  useEffect(() => {
    if (menu && menu.times.length > 0) {
      setMenuFollowsOpeningHours(false);
    } else if (menu && menu.times.length === 0) {
      setMenuFollowsOpeningHours(true);
    }
  }, [menu]);

  if (loadingMenu) {
    return <div>Loading..</div>;
  }

  if (error) {
    return <div>There was an error fetching the menu..</div>;
  }

  return (
    <Style>
      <h1>{`${viewMode} Menu`}</h1>
      <Button
        id="m-btn-save"
        onClick={() => {
          if (viewMode === 'Create') {
            createMenu();
          } else {
            editMenu();
          }
        }}
        className="btn-cta"
        isLoading={isLoading}
        disabled={
          !menu.name ||
          !menu.shortDescription ||
          (menu.paymentType !== PaymentType.None && !menu.paymentAmount) ||
          menu.floorPlanId <= 0
        }
      >
        Save
      </Button>
      <sub>Describe the menu</sub>

      <TabsStyle>
        <Tabs>
          <TabList>
            <Tab>Setup</Tab>
            <Tab disabled={!menu.id}>Delete</Tab>
          </TabList>

          <TabPanel>
            <MenuFormStyled>
              <h3>Details</h3>
              <Checkbox
                id="menu-is-published"
                title="Published"
                checked={menu.isPublished}
                onChange={() => dispatch({ type: 'IsPublished' })}
              />

              <div className="breather">
                <Label>
                  Name <Required>*</Required>
                </Label>
                <Input
                  id="menu-name"
                  value={menu.name}
                  onChange={(name: string) => dispatch({ type: 'Name', payload: name })}
                  placeholder="Name"
                  maxLength={200}
                />
              </div>

              <div className="breather">
                <Label>
                  Short description (max 600 characters) <Required>*</Required>
                </Label>
                <textarea
                  value={menu.shortDescription}
                  onChange={(e) => {
                    const payload: string =
                      e.target.value.length > 600 ? e.target.value.substr(0, 600) : e.target.value;
                    dispatch({ type: 'ShortDescription', payload });
                  }}
                  placeholder="Short description"
                  rows={10}
                />
              </div>

              <div className="breather">
                <Label>
                  Floor plan <Required>*</Required>
                </Label>
                <Select
                  id="menu-floor-plan"
                  value={menu.floorPlanId}
                  options={floorPlans.map((x) => ({ value: x.id, label: x.name }))}
                  onChange={(val) => {
                    const newValue: number = val.value;
                    dispatch({ type: 'FloorPlanId', payload: newValue });
                  }}
                  searchable={false}
                />
              </div>

              <h3>Payment</h3>
              <div className="payment-config">
                <div>
                  <Label>Payment type</Label>
                  <Select
                    id="menu-payment-type"
                    value={menu.paymentType}
                    options={[
                      {
                        value: 0,
                        label: 'None',
                      },
                      {
                        value: 1,
                        label: 'No Show',
                      },
                      {
                        value: 2,
                        label: 'PrePayment',
                      },
                    ]}
                    onChange={(val) => {
                      const newValue: number = val.value;
                      dispatch({ type: 'PaymentType', payload: newValue });
                    }}
                    searchable={false}
                    disabled={!hasPaymentKey}
                  />
                </div>

                <div>
                  <Label>Amount p. guest (ISK)</Label>
                  <Input
                    id="menu-payment-amount"
                    value={(menu.paymentAmount && menu.paymentAmount.toString()) || ''}
                    type="number"
                    onChange={(val) => {
                      if (!isNaN(val)) {
                        dispatch({ type: 'PaymentAmount', payload: val as number });
                      }
                    }}
                  />
                </div>
              </div>
              {!hasPaymentKey && (
                <b style={{ color: 'red' }}>Please contact dineout@dineout.is to use payments.</b>
              )}

              <h3>Advanced</h3>
              <div>
                <div className="breather">
                  <Label>Menu availability</Label>
                  <Checkbox
                    id="menu-has-times"
                    title="Menu follows opening hours"
                    checked={menuFollowsOpeningHours}
                    onChange={() => {
                      if (!!menuFollowsOpeningHours) {
                        dispatch({
                          type: 'Times',
                          payload: [{ id: -1, menuId: menu.id, time: '12:00' }],
                        });
                      } else {
                        dispatch({ type: 'Times', payload: [] });
                      }

                      setMenuFollowsOpeningHours(!menuFollowsOpeningHours);
                    }}
                  />

                  <div>
                    {!menuFollowsOpeningHours && (
                      <TimesWrapper>
                        {menu.times
                          .sort((a, b) => b.id - a.id)
                          .map((t) => (
                            <div key={`m-time-${t.id}`} className="m-times-row">
                              <div>
                                <Label>Time from</Label>
                                <TimePicker
                                  placeholder="Time"
                                  value={moment(t.time, 'HH:mm')}
                                  minuteStep={15}
                                  showSecond={false}
                                  onChange={(time: Moment.Moment) => {
                                    const currTime = menu.times.find((mt) => mt.id === t.id);
                                    if (currTime) {
                                      currTime.time = time.format('HH:mm');
                                      dispatch({
                                        type: 'Times',
                                        payload: [
                                          ...menu.times.filter((mt) => mt.id !== t.id),
                                          currTime,
                                        ],
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div>
                                <i
                                  onClick={() =>
                                    dispatch({
                                      type: 'Times',
                                      payload: menu.times.filter((x) => x.id !== t.id),
                                    })
                                  }
                                  className="material-icons"
                                >
                                  remove_circle_outline
                                </i>
                              </div>
                            </div>
                          ))}
                        <Button
                          id="m-btn-new-time"
                          onClick={() => {
                            const newId =
                              menu.times.length > 0
                                ? menu.times.sort((a, b) => a.id - b.id)[0].id - 1
                                : -1;
                            const timesSortedByFromDesc = menu.times.sort(
                              (a, b) =>
                                moment(b.time, moment.defaultFormatUtc).unix() -
                                moment(a.time, moment.defaultFormatUtc).unix(),
                            );
                            let time = '12:00';

                            if (timesSortedByFromDesc.length > 0) {
                              const newTime = moment(timesSortedByFromDesc[0].time, 'HH:mm').add(
                                1,
                                'hour',
                              );
                              time = newTime.format('HH:mm');
                            }
                            dispatch({
                              type: 'Times',
                              payload: [...menu.times, { id: newId, menuId: menu.id, time }],
                            });
                          }}
                        >
                          New time
                        </Button>
                      </TimesWrapper>
                    )}
                  </div>
                </div>

                <div className="breather">
                  <Label>Exclusive menu</Label>
                  <Checkbox
                    id="menu-is-exclusive"
                    title="All guests on booking must select the same menu"
                    checked={menu.isExclusive}
                    onChange={() => dispatch({ type: 'IsExclusive' })}
                  />
                </div>

                <div className="breather">
                  <Label>Minimum guests</Label>
                  <Select
                    id="menu-min-guests"
                    value={menu.minGuests}
                    options={[
                      { value: 0, label: 'None' },
                      { value: 2, label: '2' },
                      { value: 3, label: '3' },
                      { value: 4, label: '4' },
                      { value: 5, label: '5' },
                      { value: 6, label: '6' },
                      { value: 7, label: '7' },
                      { value: 8, label: '8' },
                      { value: 9, label: '9' },
                      { value: 10, label: '10' },
                    ]}
                    onChange={(val) => {
                      const newValue: number = val.value;
                      dispatch({ type: 'MinGuests', payload: newValue });
                    }}
                    searchable={false}
                  />
                </div>

                <div className="breather">
                  <Label>Seating duration</Label>
                  <Select
                    id="m-seating-duration"
                    simpleValue
                    value={menu.onlineDurationMinutes}
                    onChange={(interval: any) =>
                      dispatch({
                        type: 'OnlineDurationMinutes',
                        payload: !!interval ? parseInt(interval, 10) : undefined,
                      })
                    }
                    options={seatingDurations}
                    placeholder="Online duration"
                    clearable
                  />
                </div>
              </div>
            </MenuFormStyled>
          </TabPanel>

          <TabPanel>
            <h3>Delete menu</h3>
            <Button
              id="m-delete"
              isLoading={isLoading}
              onClick={() => {
                if (menu.id && confirm('Are you sure you want to delete this menu?')) {
                  menuAPI
                    .deleteMenu(menu.id)
                    .then((res) => {
                      setIsLoading(true);

                      if (res.data) {
                        store.UIStore.addFlashMessage({
                          type: FlashMessageType.Success,
                          text: 'Menu successfully deleted.',
                        });

                        history.replace('/menu');
                      } else {
                        store.UIStore.addFlashMessage({
                          type: FlashMessageType.Error,
                          text: 'Could not delete menu.',
                        });

                        setIsLoading(false);
                      }
                    })
                    .catch((e) => {
                      if (!axios.isCancel(e)) {
                        store.UIStore.addFlashMessage({
                          type: FlashMessageType.Error,
                          text: 'Error deleting menu.',
                        });
                      }

                      setIsLoading(false);
                    });
                }
              }}
            >
              Delete
            </Button>
          </TabPanel>
        </Tabs>
      </TabsStyle>
    </Style>
  );
};

export default inject('store')(observer(Menu));

const seatingDurations = [
  { value: 15, label: '15 mins' },
  { value: 30, label: '30 mins' },
  { value: 45, label: '45 mins' },
  { value: 60, label: '1 hr' },
  { value: 75, label: '1 ¼ hrs' },
  { value: 90, label: '1 ½ hrs' },
  { value: 105, label: '1 ¾ hrs' },
  { value: 120, label: '2 hrs' },
  { value: 135, label: '2 ¼ hrs' },
  { value: 150, label: '2 ½ hrs' },
  { value: 165, label: '2 ¾ hrs' },
  { value: 180, label: '3 hrs' },
  { value: 195, label: '3 ¼ hrs' },
  { value: 210, label: '3 ½ hrs' },
  { value: 225, label: '3 ¾ hrs' },
];
