import React, { Component } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import Bookings from './Bookings';

type IProps = RouteComponentProps<any>;

class BookingRoutes extends Component<IProps> {
  render() {
    console.log(this.props.match.url);
    return (
      <div className="container">
        <Switch>
          <Route path={this.props.match.url} component={Bookings} exact />
        </Switch>
      </div>
    );
  }
}

export default BookingRoutes;
