import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../styles/theme';

type SProps = { secondary: boolean; disabled: boolean; isLoading: boolean };

const Btn = styled.button`
  position: relative;
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: center;

  background-color: ${theme.colors.success};
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  transition: all 0.1s ease-in-out;
  user-select: none;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #fff;

  &:hover,
  & *:hover {
    cursor: pointer;
  }

  &.btn-cta {
    display: inline-flex;
    position: relative;
    top: -7px;
    left: 16px;
    width: auto;
    padding: 8px 32px;
  }

  /* Secondary */
  ${(props: SProps) =>
    props.secondary &&
    css`
      background-color: #999999;
      color: #fff;
    `}

  /* Disabled */
  ${(props: SProps) =>
    props.disabled &&
    css`
      background: ${theme.colors.neutral4};
      color: #fff;
      &:hover {
        cursor: none;
        pointer-events: none;
      }
    `}

  /* Spinner */
  .button-spinner-container {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .button-spinner {
    animation: rotator 1.4s linear infinite;
    .path {
      stroke-dasharray: 187;
      stroke-dashoffset: 0;
      transform-origin: center;
      animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
    }
  }

  @keyframes colors {
    0% {
      stroke: #fff;
    }
    50% {
      stroke: #fff;
    }
    100% {
      stroke: #fff;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  ${(props: SProps) =>
    props.isLoading &&
    css`
      .button-spinner-container {
        opacity: 1;
        transition: opacity 0.2s ease-in;
      }
      .button-children {
        opacity: 0;
        transition: opacity 0.2s ease-out;
      }
    `};
`;

type Props = {
  id: string;
  onClick: () => void;
  children: any;
  className: string;
  disabled: boolean;
  secondary: boolean;
  isLoading: boolean;
};

export default class Button extends Component<Props> {
  static defaultProps = {
    className: '',
    disabled: false,
    secondary: false,
    isLoading: false,
  };

  onClick() {
    if (this.props.disabled) {
      return;
    }

    this.props.onClick();
  }

  render() {
    return (
      <Btn
        id={this.props.id}
        className={`btn ${this.props.className}`}
        disabled={this.props.disabled || this.props.isLoading}
        secondary={this.props.secondary}
        isLoading={this.props.isLoading}
        type="button"
        onClick={() => this.onClick()}
      >
        <div className="button-children">{this.props.children}</div>
        {this.props.isLoading && (
          <div className="button-spinner-container">
            <svg
              className="button-spinner"
              width="32px"
              height="32px"
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="path"
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
              />
            </svg>
          </div>
        )}
      </Btn>
    );
  }
}
