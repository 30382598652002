import { AxiosResponse, CancelTokenSource } from 'axios';
import API from './api';

const api = new API();

export namespace TranslationAPINS {
  export interface IRestaurantCategoryLanguageModel {
    id: number;
    language: CountryCodeType;
    restaurantCategoryId: number;
    name: string;
  }
}

export type CountryCodeType = 'en' | 'is' | 'es' | 'nb';

export type LanguageType = {
  countryCode: CountryCodeType;
  language: string;
  completedRatio: number;
};

export type PageProgressType = Record<'restaurant' | 'event' | 'menu', number>;

export type PageListItemType = {
  id: number;
  name: string;
  progressRatio: number;
};

export type RestaurantTranslationType = {
  id: number;
  name: string;
  about: string | null;
  info: string | null;
  note: string | null;
  onlineMaxGuestsText: string | null;
  policyText: string | null;
};

export type EventTranslationType = {
  id: number;
  eventId: number;
  name: string;
  shortDescription: string | null;
  description: string | null;
};

export type MenuTranslationType = {
  id: number;
  menuId: number;
  name: string;
  shortDescription: string | null;
};

/*
  RESTAURANT
*/
export const getRestaurantTranslations = (
  cancelToken: CancelTokenSource,
): Promise<AxiosResponse<Record<CountryCodeType, RestaurantTranslationType | null>>> => {
  return api._get('/translation/restaurant', cancelToken);
};

export const saveRestaurantTranslations = (
  translations: Array<RestaurantTranslationType & { language: CountryCodeType }>,
  cancelToken: CancelTokenSource,
): Promise<AxiosResponse<undefined>> => {
  return api._put('/translation/restaurant', translations, cancelToken);
};

/*
  EVENT
*/
export const getEventProgressData: (
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<PageListItemType[]>> = (cancelToken) => {
  return api._get('/translation/event', cancelToken);
};

export const getEventTranslations = (
  eventId: number,
  cancelToken: CancelTokenSource,
): Promise<AxiosResponse<Record<CountryCodeType, EventTranslationType | null>>> => {
  return api._get(`/translation/event/${eventId}`, cancelToken);
};

export const saveEventTranslations = (
  translations: Array<EventTranslationType & { language: CountryCodeType }>,
  cancelToken: CancelTokenSource,
): Promise<AxiosResponse<undefined>> => {
  return api._put('/translation/event', translations, cancelToken);
};

/*
  MENU
*/
export const getMenuProgressData: (
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<PageListItemType[]>> = (cancelToken) => {
  return api._get('/translation/menu', cancelToken);
};

export const getMenuTranslations = (
  menuId: number,
  cancelToken: CancelTokenSource,
): Promise<AxiosResponse<Record<CountryCodeType, MenuTranslationType | null>>> => {
  return api._get(`/translation/menu/${menuId}`, cancelToken);
};

export const saveMenuTranslations = (
  translations: Array<MenuTranslationType & { language: CountryCodeType }>,
  cancelToken: CancelTokenSource,
): Promise<AxiosResponse<undefined>> => {
  return api._put('/translation/menu', translations, cancelToken);
};

/*
  CATEGORIES
*/
export const getAllCategoryTranslations: (cancelToken: CancelTokenSource) => Promise<
  AxiosResponse<
    Array<{
      restaurantCategoryId: number;
      translations: TranslationAPINS.IRestaurantCategoryLanguageModel[];
    }>
  >
> = (cancelToken) => {
  return api._get('/translation/category', cancelToken);
};

export const saveCategoryTranslations = (
  translations: TranslationAPINS.IRestaurantCategoryLanguageModel[],
  cancelToken: CancelTokenSource,
): Promise<AxiosResponse<undefined>> => {
  return api._put('/translation/category', translations, cancelToken);
};

/*
  OTHER
*/
export const getOverallProgress: (
  cancelToken: CancelTokenSource,
) => Promise<AxiosResponse<PageProgressType>> = (cancelToken) => {
  return api._get('/translation/overallprogress', cancelToken);
};
