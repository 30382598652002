export enum PaymentType {
  None = 0,
  NoShow = 1,
  PrePayment = 2,
}

export enum PaymentLinkDeliveryType {
  Email = 0,
  SMS = 1,
}
