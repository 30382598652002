import React, { Component } from 'react';
import styled from 'styled-components';
import LogoFork from '../assets/logo-fork.black.svg';

type SProps = { isLoading: boolean };

const Kids = styled.div`
  height: 100%;
  pointer-events: ${(props: SProps) => (props.isLoading === true ? 'none' : 'all')};
  transition: all 0.3s ease-in-out;
`;

const Overlay = styled.div`
  position: absolute;
  top: 56px;
  left: 0px;
  display: ${(props: SProps) => (props.isLoading === true ? 'block' : 'none')};
  width: 100%;
  height: 1000%;
  /*background: rgba(255,255,255, .3);*/
  background: transparent;
  z-index: 214748364;

  .spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: anim 1s ease-in-out infinite;
    user-select: none;
    transition-timing-function: cubic-bezier(0.75, 0.25, 0.25, 0.75);

    @keyframes anim {
      0% {
        transform: translate(-50%, -50%) scale(0.6);
      }
      50% {
        transform: translate(-50%, -50%) scale(0.69);
      }
      100% {
        transform: translate(-50%, -50%) scale(0.6);
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/ban-types
type State = {};
type Props = {
  isLoading: boolean;
  children: any;
};

export default class Loading extends Component<Props, State> {
  static defaultProps = {
    isLoading: false,
    children: null,
  };

  render() {
    return (
      <div>
        <Overlay isLoading={this.props.isLoading}>
          <object
            className="spinner"
            data={LogoFork}
            type="image/svg+xml"
            aria-label="Dineout fork"
          />
        </Overlay>
        <Kids isLoading={this.props.isLoading}>{this.props.children}</Kids>
      </div>
    );
  }
}
