import * as signalR from '@aspnet/signalr';

type Method = 'BookingNotify';
type Event = 'ReceiveNotification';

interface ICustomWindow extends Window {
  socketConnection: signalR.HubConnection;
}

declare let window: ICustomWindow;

export default class SocketUtils {
  static start(restaurantId: number) {
    console.log('-- Starting dashboard socket conn --');
    const apiUrl = process.env.API_URL ? process.env.API_URL : '';

    window.socketConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrl}/dashboardhub?restaurantId=${restaurantId}`)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    function startConn() {
      console.log('-- Connecting to dashboard hub --');
      window.socketConnection.start().catch(() => {
        setTimeout(() => {
          console.log('-- Could not establish socket connection. Trying again... --');
          startConn();
        }, 5000);
      });
    }

    window.socketConnection.onclose(() => {
      console.log('-- Disconnected from dashboard hub. Trying to reconnect. --');
      startConn();
    });

    startConn();
  }

  static subscribe(eventType: Event, callback: (data: any) => void) {
    window.socketConnection.on(eventType, (data) => {
      callback(data);
    });
  }

  static invoke(method: Method) {
    const m = method.charAt(0).toLowerCase() + method.slice(1); // First letter to lower
    window.socketConnection.invoke(m);
  }
}
