import styled from 'styled-components';
import theme from '../styles/theme';

const Label = styled.label`
  display: block;
  width: 100%;
  margin-bottom: 8px;
  color: ${theme.colors.dark2};
  font-weight: 300;
  font-size: 12px;
`;

export default Label;
