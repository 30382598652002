import React, { useContext } from 'react';
import styled from 'styled-components';
import { TranslationContext } from '../../Translator';

const FormSectionWrapper = styled.div`
  margin-bottom: 32px;

  > div.english-translation-helper {
    margin-top: 8px;
    color: #333;
    font-size: 12px;
    font-weight: bold;

    &.html {
      font-weight: normal;
    }
  }
`;

const FormSection = ({
  children,
  source,
}: {
  children: JSX.Element | JSX.Element[];
  source?: { value: string; isHtml?: boolean };
}) => {
  const {
    currentLanguage: [language],
  } = useContext(TranslationContext);
  return (
    <FormSectionWrapper>
      {children}
      {(() => {
        if (source && language !== 'en') {
          if (source.isHtml) {
            return (
              <>
                <div className="english-translation-helper">English:</div>
                <div
                  className="english-translation-helper html"
                  dangerouslySetInnerHTML={{ __html: source?.value }}
                />
              </>
            );
          }

          return <div className="english-translation-helper">{`English: ${source.value}`}</div>;
        }
        return false;
      })()}
    </FormSectionWrapper>
  );
};

export default FormSection;
