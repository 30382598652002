import styled from 'styled-components';

const Label = styled.label`
  display: flex;
  height: 32px;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 12px;

  color: #999999;
`;

export default Label;
