import React, { useContext } from 'react';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { LanguageType } from '../../../api/translation.api';
import Button from '../../../components/common/Button';
import { TranslationContext } from '../Translator';
import LanguageSelector from './LanguageSelector';

const TranslatorWrapper = styled.div`
  > div.name-and-button {
    display: flex;
    align-items: center;
    margin: 48px 0px;

    > h1 {
      margin: 0px 24px 0px 0px;
      min-height: auto;
    }

    > button {
      width: 128px;
      margin-right: 8px;
    }
  }

  > div.translation-form {
    display: flex;

    > div.language-selector {
      flex: 1 0 35%;
      padding-right: 40px;
    }

    > div.form {
      flex: 1 1 65%;
      padding-left: 40px;
    }
  }
`;

const HelperText = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-weight: 600;
  color: #999;
  margin-bottom: 24px;
`;

const UnsavedText = styled.div`
  font-size: 12px;
  color: #999;
  font-weight: 300;
`;

const TranslationDashboard = ({
  title,
  languages,
  saveConfig: { onSave, unsavedChanges },
  dashboardType,
  children,
}: {
  title: string;
  languages: LanguageType[];
  saveConfig: {
    onSave: () => void;
    unsavedChanges: boolean;
  };
  dashboardType: 'restaurant' | 'event' | 'menu';
  children: JSX.Element;
}) => {
  const {
    saveBtnLoading: [loading],
  } = useContext(TranslationContext);

  return (
    <>
      <Prompt
        message="You have unsaved changes, are you sure you want to continue?"
        when={unsavedChanges}
      />
      <TranslatorWrapper>
        <div className="name-and-button">
          <h1>{title}</h1>
          <Button id="translation-btn-save" onClick={onSave} isLoading={loading}>
            Save
          </Button>
          {unsavedChanges && <UnsavedText>Unsaved changes</UnsavedText>}
        </div>
        <div className="translation-form">
          <div className="language-selector">
            <HelperText>1. Select a language to translate:</HelperText>
            <LanguageSelector languages={languages} />
          </div>
          <div className="form">
            <HelperText>{`2. Translate the ${dashboardType}:`}</HelperText>
            {children}
          </div>
        </div>
      </TranslatorWrapper>
    </>
  );
};

export default TranslationDashboard;
