import { AxiosResponse, CancelTokenSource } from 'axios';
import API from './api';

const api = new API();

export namespace CityAPINS {
  export type CityModel = {
    id: number;
    name: string;
    area: string;
    address: string;
    zipCode: number;
  };
}

export const getAllCities: (
  cancelToken?: CancelTokenSource,
) => Promise<AxiosResponse<CityAPINS.CityModel[]>> = (cancelToken) => {
  return api._get('/city', cancelToken);
};
