import { inject, observer } from 'mobx-react';
import * as Moment from 'moment-timezone';
import moment from 'moment-timezone';
import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import BookingAPI from '../../api/booking.api';
import { DashboardMetaPaymentType, IBookingDashboard } from '../../api/dashboard.api';
import { StatusSelect } from '../../components/Booking';
import Button from '../../components/Button';
import Select from '../../components/common/Select';
import { PaymentType } from '../../enums/Payment';
import { IRootStore } from '../../stores/RootStore';
import { FlashMessageType, ModalConductor } from '../../stores/UIStore';
import theme from '../../styles/theme';
import Utils, { getAllStatuses } from '../../utils';
import SocketUtils from '../../utils/socket';

const PaidMsg = styled.div`
  font-weight: 400;
  color: ${theme.colors.success};
  margin-right: 8px;
`;

const ListItemStyle = styled.tr`
  background-color: transparent;
  outline: none;
  transition: opacity 111ms linear;

  &:nth-child(even) {
    background-color: #fff;
  }

  &:hover {
    cursor: pointer;
  }

  td {
    padding: 8px 4px;
    border-bottom: 1px solid #ebebeb;

    &:first-child {
      padding-left: 0px;
    }
    &:last-child {
      padding-right: 0px;
    }
  }
  .list-time {
    width: 56px;
    max-width: 56px;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  }
  .list-status {
    width: 120px;
    max-width: 120px;

    > div {
      > div {
        height: 32px;

        .Select-multi-value-wrapper {
          .Select-value-label {
            position: relative;
            display: flex !important;
            align-items: center;
            padding: 0px 8px !important;
            justify-content: center;
            font-size: 9px !important;
            text-align: center;

            &:hover {
              box-shadow: 0 0 23px -3px ${(props: { statusColor: string }) => props.statusColor} !important;
              transition: box-shadow 111ms linear;
            }
          }
        }
        .Select-arrow-zone {
          display: none !important;
        }
      }
    }
  }
  .list-name {
    white-space: nowrap;
  }
  .list-guests {
    color: #999999;
  }
  .list-icons {
    i {
      font-size: 16px;
      color: #999999;
    }
  }
  .list-tables {
  }
  .list-texts {
    > div {
      ${(props: { isCompleted: boolean }) =>
        props.isCompleted &&
        css`
          height: 0px;
          overflow: hidden;
        `};
      > div {
        color: #999999;
      }
      &:not(:first-child):last-child {
        /* Give internal notes some space */
        margin-top: 8px;
      }
    }
  }
  .list-actions {
    > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn {
        margin-right: 4px;

        &:last-child {
          margin-right: 0px;
        }

        &:hover {
          transform: all 0.6s ease-in-out;
          &.arrived button {
            background-color: #3dadec;
          }
          &.completed button {
            background-color: #333542;
          }
          &.cancelled button {
            background-color: #828282;
          }
          &.charge button {
            background-color: #ea6c6c;
          }
        }
        button {
          width: 80px;
          background: none;
          cursor: pointer;
          font-size: 9px;
          padding: 8px;
          > div.amount {
            margin-top: 2px;
          }
        }
        &.arrived button {
          background-color: #2d9cdb;
        }
        &.completed button {
          background-color: #18191f;
        }
        &.cancelled button {
          background-color: #686868;
        }
        &.charge button {
          background-color: #eb5757;
          > div:nth-child(2) {
            font-size: 8px;
            margin-top: 2px;
          }
        }
      }
    }
  } /* .list-actions ends */
`;

type BookingUpdateProperty = 'statusId' | 'paymentDate' | 'booking';

interface IProps extends RouteComponentProps<any> {
  store?: IRootStore;
  booking: IBookingDashboard;
  statusOptions: Array<{ label: string; value: number }>;
  onBookingUpdated: (bookingId: number, newValue: any, type: BookingUpdateProperty) => void;
}

type State = {
  statusLoading: number;
  paymentLoading: boolean;
};

@inject('store')
@observer
class ListItem extends Component<IProps, State> {
  api: BookingAPI;
  utils: Utils;

  constructor(props: IProps) {
    super(props);

    this.state = {
      statusLoading: 0, // To cover when more then one status button are in a row
      paymentLoading: false,
    };

    this.api = new BookingAPI();
    this.utils = new Utils();
  }

  updateStatus(bookingId: number, statusId: number) {
    this.setState({
      statusLoading: statusId,
    });
    this.api
      .updateBookingStatus(bookingId, statusId)
      .then((res) => {
        const {
          value: { id, statusId: newStatusId, bookingEnd: newBookingEnd },
        } = res.data;
        this.props.onBookingUpdated(
          id,
          { statusId: newStatusId, bookingEnd: newBookingEnd },
          'statusId',
        );
        this.setState({
          statusLoading: 0,
        });

        SocketUtils.invoke('BookingNotify');
      })
      .catch(() => {
        this.setState({
          statusLoading: 0,
        });
        this.props.store!.UIStore.addFlashMessage({
          type: FlashMessageType.Error,
          text: 'Error updating status.',
          timeout: 10000,
        });
      });
  }

  makePayment(bookingId: number) {
    this.setState({
      paymentLoading: true,
    });
    this.api
      .makePayment(bookingId)
      .then((res) => {
        const { success, message } = res.data.value;
        if (success) {
          const { paymentDate } = res.data.value;
          this.props.onBookingUpdated(bookingId, paymentDate, 'paymentDate');
          this.setState({
            paymentLoading: false,
          });
        } else {
          this.setState({
            paymentLoading: false,
          });
          this.props.store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            title: 'Payment error',
            text: message,
            timeout: 10000,
          });
        }
      })
      .catch(() => {
        this.setState({
          paymentLoading: false,
        });
        this.props.store!.UIStore.addFlashMessage({
          type: FlashMessageType.Error,
          title: 'Payment error.',
          text: 'Payment unsuccessful.',
          timeout: 10000,
        });
      });
  }

  clickMe(e: any, bookingId: number) {
    function hasSomeParentTheClass(element: any, classname: string): boolean {
      if (element.className && element.className.split(' ').indexOf(classname) >= 0) {
        return true;
      }
      return (element.parentNode && hasSomeParentTheClass(element.parentNode, classname)) || false;
    }

    if (!hasSomeParentTheClass(e.target, 'ignore-clickMe')) {
      this.props.store!.UIStore.openModal({
        type: ModalConductor.Type.Booking,
        bookingId,
        onSave: (newDate: Moment.Moment) =>
          this.props.onBookingUpdated(bookingId, newDate, 'booking'),
      });
    }
  }

  render() {
    const { statusLoading, paymentLoading } = this.state;
    const { booking: b } = this.props;
    const currentStatus = getAllStatuses().filter((x) => x.id === this.props.booking.statusId)[0];

    const allNoShowPayments = b.payments
      ? b.payments.filter((p) => p.type === PaymentType.NoShow)
      : [];
    const noShowPayment: DashboardMetaPaymentType | undefined =
      allNoShowPayments[allNoShowPayments.length - 1];
    const prePaymentPayment =
      b.payments && b.payments.find((p) => p.type === PaymentType.PrePayment);

    return (
      <ListItemStyle
        key={b.id}
        statusColor={currentStatus.color}
        isCompleted={b.statusId === 11}
        onClick={(e: any) => this.clickMe(e, b.id)}
      >
        <td className="list-time">
          <div>{moment(b.start).format('HH:mm')}</div>
        </td>
        <td className="list-status ignore-clickMe">
          <StatusSelect statusColor={currentStatus.color} color={currentStatus.contrastColor}>
            <Select
              id="db-status-dropdown"
              value={this.props.booking.statusId}
              options={getAllStatuses().map((x) => ({
                value: x.id,
                label: x.name,
                disabled: x.id === 1 && b.sourceId > 1,
              }))}
              onChange={(s: any) => this.updateStatus(b.id, s.value)}
              searchable={false}
            />
          </StatusSelect>
        </td>
        <td className="list-name">
          <div>{b.bookingName ? b.bookingName : '(no name)'}</div>
        </td>
        <td className="list-guests">
          <div>({b.guests})</div>
        </td>
        <td className="list-icons">
          {(b.sourceId === 2 || b.sourceId === 5 || b.sourceId === 3) && (
            <span data-tooltip="Online booking">
              <i className="icon-web material-icons">language</i>
            </span>
          )}
          {(b.sourceId === 4 || b.sourceId === 7) && (
            <span data-tooltip="Hotel booking">
              <i className="material-icons">domain</i>
            </span>
          )}
          {noShowPayment && noShowPayment.canBeCharged && (
            <span data-tooltip="Card registered">
              <i className="material-icons">credit_card</i>
            </span>
          )}
          {b.hasMenu && (
            <span data-tooltip={b.menuName}>
              <i className="material-icons">restaurant_menu</i>
            </span>
          )}
          {b.hasEvent && (
            <span data-tooltip={b.eventName}>
              <i className="material-icons">event_note</i>
            </span>
          )}
          {noShowPayment && noShowPayment.isPaid && (
            <span
              data-tooltip={`No-Show paid ${moment(
                noShowPayment.paymentDate!,
                moment.defaultFormatUtc,
              ).format('MM/DD/YYYY')}`}
            >
              <i className="material-icons">attach_money</i>
            </span>
          )}
          {prePaymentPayment && prePaymentPayment.isPaid && (
            <span
              data-tooltip={`Prepaid ${moment(
                prePaymentPayment.paymentDate!,
                moment.defaultFormatUtc,
              ).format('MM/DD/YYYY')}`}
            >
              <i className="material-icons">attach_money</i>
            </span>
          )}
        </td>
        <td className="list-tables">
          <div>
            {b.tables && b.tables.length > 0
              ? b.tables.map((y) => y.name).join(', ')
              : '(no tables)'}
          </div>
        </td>
        <td className="list-texts">
          {b.bookingSpecialRequest && (
            <div>
              <div>Guest notes</div>
              {
                <span data-tooltip={b.bookingSpecialRequest}>
                  {b.bookingSpecialRequest.substr(0, 100)}
                  {b.bookingSpecialRequest.length > 100 && '...'}
                </span>
              }
            </div>
          )}
          {b.bookingInternalNotes && (
            <div>
              <div>Internal notes</div>
              {
                <span data-tooltip={b.bookingInternalNotes}>
                  {b.bookingInternalNotes.substr(0, 100)}
                  {b.bookingInternalNotes.length > 100 && '...'}
                </span>
              }
            </div>
          )}
        </td>
        <td className="list-actions ignore-clickMe">
          <div>
            {b.statusId === 2 && (
              <Fragment>
                <Button
                  isLoading={statusLoading === 10}
                  className="cancelled"
                  compact
                  onClick={() => {
                    if (confirm('Cancel this booking?')) {
                      this.updateStatus(b.id, 10);
                    }
                  }}
                >
                  Cancelled
                </Button>
                <Button
                  isLoading={statusLoading === 8}
                  className="arrived"
                  compact
                  onClick={() => this.updateStatus(b.id, 8)}
                >
                  Arrived
                </Button>
              </Fragment>
            )}
            {b.statusId === 9 && noShowPayment && noShowPayment.canBeCharged && (
              <Button
                isLoading={paymentLoading}
                className="charge"
                onClick={() => this.makePayment(b.id)}
                compact
              >
                <div>Charge</div>
                <div>
                  {noShowPayment.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} ISK
                </div>
              </Button>
            )}
            {b.statusId === 9 && noShowPayment && noShowPayment.isPaid ? (
              <PaidMsg>Paid {moment(noShowPayment.paymentDate!).format('MM/DD/YYYY')}</PaidMsg>
            ) : null}
            {b.statusId !== 2 && b.statusId !== 9 && b.statusId !== 10 && b.statusId !== 11 && (
              <Button
                isLoading={statusLoading === 11}
                className="completed"
                onClick={() => this.updateStatus(b.id, 11)}
                compact
              >
                Completed
              </Button>
            )}
          </div>
        </td>
      </ListItemStyle>
    );
  }
}

export default withRouter(ListItem);
