import React from 'react';

const NorwayFlagIcon = ({ size = 20, onClick }: { size?: number; onClick?: () => void }) => (
  <svg
    onClick={onClick}
    width={size}
    height={size}
    viewBox="0 0 250 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="250"
      height="250"
    >
      <circle cx="125" cy="125" r="125" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0)">
      <path d="M296.75 0H-47V250H296.75V0Z" fill="#EF2B2D" />
      <path d="M109.25 0H46.75V250H109.25V0Z" fill="white" />
      <path d="M296.75 93.75H-47V156.25H296.75V93.75Z" fill="white" />
      <path d="M93.625 0H62.375V250H93.625V0Z" fill="#002868" />
      <path d="M296.75 109.375H-47V140.625H296.75V109.375Z" fill="#002868" />
    </g>
  </svg>
);

export default NorwayFlagIcon;
