import React, { FunctionComponent } from 'react';
import { PaymentLinkDeliveryType, PaymentType } from '../enums/Payment';
import Input from './common/Input';
import Label from './common/Label';
import Select from './common/Select';

type Props = {
  paymentLinkMinutes: number;
  updatePaymentLinkMinutes: (minutes: number) => void;

  paymentLinkDeliveryType: PaymentLinkDeliveryType;
  updatePaymentLinkDeliveryType: (type: PaymentLinkDeliveryType) => void;

  paymentLinkTypeOptions: Array<{ value: PaymentType; label: string }>;
  paymentLinkType: PaymentType;
  updatePaymentLinkType: (type: PaymentType) => void;

  feePerGuest: number | null;
  updateFeePerGuest: (fee: number | null) => void;

  hideSendType?: boolean;
};

const PaymentLinkForm: FunctionComponent<Props> = ({
  paymentLinkMinutes,
  updatePaymentLinkMinutes,
  paymentLinkDeliveryType,
  updatePaymentLinkDeliveryType,
  paymentLinkTypeOptions,
  updatePaymentLinkType,
  paymentLinkType,
  feePerGuest,
  updateFeePerGuest,
  hideSendType = false,
}) => {
  return (
    <div className="db-group-inline">
      <div>
        <Label>Payment type</Label>
        <Select
          id="db-payment-link-type"
          value={paymentLinkType}
          options={paymentLinkTypeOptions}
          onChange={(type) => updatePaymentLinkType(parseInt(type.value, 10))}
          searchable={false}
          clearable={false}
        />
      </div>
      {!hideSendType && (
        <div>
          <Label>Send with</Label>
          <Select
            id="db-payment-link-delivery-type"
            value={paymentLinkDeliveryType}
            options={[
              { value: PaymentLinkDeliveryType.Email, label: 'Email' },
              { value: PaymentLinkDeliveryType.SMS, label: 'SMS' },
            ]}
            onChange={(type) => updatePaymentLinkDeliveryType(parseInt(type.value, 10))}
            searchable={false}
          />
        </div>
      )}
      <div>
        <Label>Booking expires in</Label>
        <Select
          id="db-payment-link-expires"
          value={paymentLinkMinutes}
          options={[
            { value: 0, label: 'Never' },
            { value: 60, label: '1 hr' },
            { value: 60 * 3, label: '3 hrs' },
            { value: 60 * 5, label: '5 hrs' },
            { value: 60 * 12, label: '12 hrs' },
            { value: 60 * 24, label: '24 hrs' },
            { value: 60 * 74, label: '3 days' },
            { value: 60 * 120, label: '5 days' },
            { value: 60 * 168, label: '1 week' },
          ]}
          onChange={(dur) => {
            updatePaymentLinkMinutes(dur.value);
          }}
          icon={<i className="material-icons">timer</i>}
        />
      </div>
      <div>
        <Label>Fee p/guest (ISK)</Label>
        <Input
          id="fee-per-guest"
          value={feePerGuest ? feePerGuest.toString() : ''}
          onChange={(value: number | null) => updateFeePerGuest(value)}
          placeholder="Fee in ISK"
        />
      </div>
    </div>
  );
};

export default PaymentLinkForm;
