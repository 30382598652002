import Axios from 'axios';
import { inject } from 'mobx-react';
import moment from 'moment-timezone';
import React, { FC, useEffect, useState } from 'react';
import ReactTable from 'react-table';
import styled from 'styled-components';
import { fetchSurveyAnswers, SurveyNS } from '../../../api/survey.api';
import Rating from '../../../components/Rating';
import ReactTableStyle from '../../../components/ReactTable.styled';
import RootStore from '../../../stores/RootStore';
import { FlashMessageType, ModalConductor } from '../../../stores/UIStore';

const Style = styled.div`
  .col-centered {
    display: flex;
    justify-content: center;
  }
  .col-guests {
    flex: 0 0 auto !important;
  }
  .col-rating {
    flex: 0 0 auto !important;
    margin: 0px 8px;
  }
  .col-date {
    flex: 8 0 auto !important;
    margin: 0px 8px;
    justify-content: flex-end;
  }
  .col-bookingId {
    flex: 8 0 auto !important;
    margin: 0px 8px;
  }
`;

type Props = {
  store?: RootStore;
};

const columns = [
  {
    Header: 'Booking ID',
    headerClassName: 'col-bookingId',
    accessor: 'bookingId',
    className: 'col-bookingId',
    filterable: true,
  },
  {
    Header: 'Name',
    headerClassName: 'col-bookingName',
    accessor: 'bookingName',
    className: 'col-bookingName',
    filterable: true,
  },
  {
    Header: 'Guests',
    headerClassName: 'col-guests',
    accessor: 'guests',
    className: 'col-centered col-guests',
  },
  {
    Header: 'Rating',
    headerClassName: 'col-rating',
    className: 'col-centered col-rating',
    accessor: 'averageRating',
    Cell: (props: { value: number }) => <Rating stars={props.value} />,
  },
  {
    Header: 'Note',
    headerClassName: 'col-note',
    className: 'col-note',
    accessor: 'comment',
  },
  {
    Header: 'Visit',
    headerClassName: 'col-visit col-date',
    accessor: 'start',
    className: 'col-centered col-date',
    Cell: (props: { value: moment.MomentInput }) => moment(props.value).format('YYYY-MM-DD HH:mm'),
  },
  {
    Header: 'Completed',
    headerClassName: 'col-created col-date',
    accessor: 'created',
    className: 'col-centered col-date',
    Cell: (props: { value: moment.MomentInput }) => moment(props.value).format('YYYY-MM-DD HH:mm'),
  },
];

const SatisfactionAnswers: FC<Props> = ({ store }) => {
  const [answers, setAnswers] = useState<SurveyNS.SurveyAnswerType[] | null>(null);

  useEffect(() => {
    const cancelToken = Axios.CancelToken.source();

    fetchSurveyAnswers(cancelToken)
      .then((res) => {
        setAnswers(res.data);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Error fetching satisfaction survey answers.',
          });
        }
      });

    return () => cancelToken.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (answers === null) {
    return <div>Loading...</div>;
  }

  return (
    <Style>
      <ReactTableStyle>
        <ReactTable
          data={answers}
          columns={columns}
          resizable={false}
          defaultSorted={[
            {
              id: 'created',
              desc: true,
            },
          ]}
          defaultFilterMethod={(filter: any, row: any) => {
            const id = filter.pivotId || filter.id;
            if (id === 'id') {
              return row[id] !== undefined ? String(row[id]).startsWith(filter.value) : true;
            }
            return row[id] !== undefined
              ? String(row[id])
                  .toLowerCase()
                  .trim()
                  .replace(/\s/g, '')
                  .includes(filter.value.toLowerCase().trim().replace(/\s/g, ''))
              : true;
          }}
          getTdProps={(state: any, rowInfo: any) => ({
            onClick: () => {
              store!.UIStore.openModal({
                type: ModalConductor.Type.SurveyDetails,
                id: rowInfo.original.id,
              });
            },
          })}
        />
      </ReactTableStyle>
    </Style>
  );
};

export default inject('store')(SatisfactionAnswers);
