import { AxiosResponse } from 'axios';
import API from './api';

const api = new API();

export enum RuleCriteriaType {
  BookingDurationOnline = 1,
  NoShow = 2,
  OnlineBookingBlocked = 3,
  MaxTotalGuestsOnline = 4,
  MaxBookingsOnline = 5,
  GuestsPerBookingOnline = 6,
  CustomText = 7,
  MaxTotalGuestsAtATime = 8,
  BookingNotice = 9,
  IgnoreOtherRules = 10,
  LimitTablesToTime = 11,
  MaxTotalGuestsAll = 12,
  Turnover = 13,
}

export type RuleCriteriaTableModel = {
  id: number;
  ruleCriteriaId: number;
  floorPlanTableId: number;
};

export type RuleCriteriaModel = {
  id: number;
  ruleId: number;
  type: RuleCriteriaType;
  priority: number;

  fromTime: string | null;
  toTime: string | null;

  minGuests: number | null;
  maxGuests: number | null;

  maxTotalGuests: number | null;
  maxBookings: number | null;
  bookingDurationMinutes: number | null;
  noShowPayment: number | null;
  turnaroundTimeMinutes: number | null;
  customText: string | null;
  bookingNoticeHours: number | null;

  tables: RuleCriteriaTableModel[];
};

export type RuleModel = {
  id: number;
  restaurantId: number;
  name: string;
  criterias: RuleCriteriaModel[];
};

export const getAllRules: () => Promise<AxiosResponse<RuleModel[]>> = () => api._get(`/rule`);

export const getRuleById: (id: number) => Promise<AxiosResponse<RuleModel>> = (id) =>
  api._get(`/rule/${id}`);

export const updateRule: (
  rule: RuleModel,
) => Promise<AxiosResponse<{ success: boolean; message: string }>> = (rule) =>
  api._put(`/rule`, rule);

export const createRule: (
  rule: RuleModel,
) => Promise<AxiosResponse<{ success: boolean; message: string }>> = (rule) =>
  api._post(`/rule`, rule);

export default class RuleAPI extends API {
  getRestaurantRules(restaurantId: number) {
    return this._get(`/rule/restaurant/${restaurantId}`);
  }

  getRestaurantRule(ruleId: number, restaurantId: number) {
    return this._get(`/rule/restaurant/${ruleId}/${restaurantId}`);
  }

  editRule(restaurantId: number, rule: any) {
    return this._put(`/rule/restaurant/${restaurantId}`, rule);
  }

  createRule(restaurantId: number, rule: any) {
    return this._post(`/rule/restaurant/${restaurantId}`, rule);
  }

  deleteRule(ruleId: number) {
    return this._delete(`/rule/${ruleId}`);
  }
}
