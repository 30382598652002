import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

import Restaurant from './Restaurant';
import Restaurants from './Restaurants';

type IProps = RouteComponentProps<any>;

class RestaurantRoutes extends Component<IProps> {
  render() {
    return (
      <div className="container">
        <Switch>
          <Route path={`${this.props.match.url}/:viewMode`} component={Restaurant} exact />
          <Route path={this.props.match.url} component={Restaurants} exact />
        </Switch>
      </div>
    );
  }
}

export default RestaurantRoutes;
