import moment from 'moment-timezone';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';
import styled from 'styled-components';

import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { FloorPlanAPINS, getFloorPlanTablesFlat } from '../../api/floorplan.api';
import RuleAPI, {
  createRule,
  getRuleById,
  RuleCriteriaModel,
  RuleCriteriaTableModel,
  RuleCriteriaType,
  RuleModel,
  updateRule,
} from '../../api/rule.api';
import OldButton from '../../components/Button';
import Button from '../../components/common/Button';
import Input from '../../components/Input';
import Label from '../../components/Label.styled';
import SelectStyle from '../../components/Select.styled';
import { IRootStore } from '../../stores/RootStore';
import { FlashMessageType } from '../../stores/UIStore';
import theme from '../../styles/theme';

const RuleContainer = styled.div`
  padding-bottom: 280px;

  & > div.name-container {
    width: 30%;
  }
`;

const DurationContainer = styled.div`
  width: 100%;

  .select-style {
    margin-bottom: 0px;
  }
`;

type SProps = { isDragging?: boolean };

const CriteriaItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 4px 24px 4px;
  margin-top: 8px;

  background-color: ${(props: SProps) => (props.isDragging ? '#ffbb99' : '#ffffff')};

  label {
    color: ${(props: SProps) => (props.isDragging ? '#ffffff' : theme.colors.dark2)};
  }

  & > div {
    margin-right: 16px;
  }
  &.new-criteria {
    justify-content: space-between;

    & > div:first-child {
      margin: 0;
      padding-top: 20px;
    }
    & > div:nth-child(2) {
      flex: 0 0 176px;
      margin-left: 32px;
      font-size: 12px;
      font-weight: 400;
      & > div {
        margin-bottom: 0px;
      }
    }
  }
  &.list-criteria {
    & > *:nth-child(-n + 2) {
      padding: 20px 8px 0px 0px;
      color: ${(props: SProps) => (props.isDragging ? '#ffffff' : theme.colors.dark2)};
    }
    & > div:nth-child(3) {
      flex: 0 0 176px;
      margin-left: 32px;
      font-size: 12px;
      font-weight: 400;
      & > div {
        margin-bottom: 0px;
      }
    }
  }
  & > div:last-child {
    margin-right: 0px;
  }

  .btn-delete {
    display: block;
    margin-top: 18px;
    font-size: 18px;
  }
`;

const durationSuggestions = [
  { value: 15, label: '15 mins' },
  { value: 30, label: '30 mins' },
  { value: 45, label: '45 mins' },
  { value: 60, label: '1 hr' },
  { value: 75, label: '1 ¼ hrs' },
  { value: 90, label: '1 ½ hrs' },
  { value: 105, label: '1 ¾ hrs' },
  { value: 120, label: '2 hrs' },
  { value: 135, label: '2 ¼ hrs' },
  { value: 150, label: '2 ½ hrs' },
  { value: 165, label: '2 ¾ hrs' },
  { value: 180, label: '3 hrs' },
  { value: 195, label: '3 ¼ hrs' },
  { value: 210, label: '3 ½ hrs' },
  { value: 225, label: '3 ¾ hrs' },
];

const CriteriaListContainer = styled.div`
  background-color: ${(props: { isDraggingOver: boolean }) =>
    props.isDraggingOver ? '#f2f2f2' : '#ffffff'};
  padding: 24px;
  border: 1px solid #f2f2f2;
  margin: 16px 0px;

  & > span.priority {
    color: '#f2f2f2';
  }
`;

const TimeContainer = styled.div`
  min-width: 75px;
  input {
    height: 40px;
    border: 1px solid ${theme.colors.neutral4};
    border-radius: 3px;
    font-size: 12px;
  }

  input:disabled {
    color: #828282;
  }
`;

type SelectItem = {
  label: string;
  value: any;
};

type Mode = 'create' | 'edit';
type InputType = 'time' | 'guests' | 'time-guests';

interface IProps extends RouteComponentProps<any> {
  store?: IRootStore;
}

type State = {
  viewMode: Mode;
  ruleName: string;
  newCriteria: RuleCriteriaModel;
  criterias: RuleCriteriaModel[];
  RuleCriteriaTypes: SelectItem[];
  allFloorPlanTables: Array<
    FloorPlanAPINS.IFloorPlanTable & {
      floorPlanName: string;
    }
  >;
};

@inject('store')
@observer
class Rule extends Component<IProps, State> {
  ruleApi: RuleAPI;
  constructor(props: IProps) {
    super(props);

    const viewMode = props.match.params.ruleId ? 'edit' : 'create';

    this.state = {
      viewMode,
      ruleName: '',
      newCriteria: {
        id: 0,
        ruleId: viewMode === 'edit' ? parseInt(props.match.params.ruleId, 10) : -1,
        priority: 0,
        type: 1,
        minGuests: null,
        maxGuests: null,
        fromTime: null,
        toTime: null,
        maxTotalGuests: null,
        maxBookings: null,
        bookingDurationMinutes: null,
        noShowPayment: null,
        customText: null,
        bookingNoticeHours: null,
        turnaroundTimeMinutes: null,
        tables: [],
      },
      criterias: [],
      RuleCriteriaTypes: Object.keys(RuleCriteriaType)
        .filter((k) => typeof RuleCriteriaType[k as any] !== 'number')
        .map((key: any) => ({
          value: key,
          label: this.getRuleCriteriaTypeName(parseInt(key, 10)),
        })),
      allFloorPlanTables: [],
    };

    this.ruleApi = new RuleAPI();
  }

  componentDidMount() {
    const { ruleId } = this.props.match.params;

    if (this.state.viewMode === 'edit') {
      getRuleById(parseInt(ruleId, 10))
        .then((res) => {
          const { criterias, name } = res.data;
          criterias.sort((a, b) => a.priority - b.priority);

          this.setState({
            criterias,
            ruleName: name,
          });
        })
        .catch((error) => {
          console.log('Could not fetch rule:', error);
        });
    }

    getFloorPlanTablesFlat()
      .then((res) => {
        this.setState({
          allFloorPlanTables: res.data,
        });
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          alert('Error fetching floor plans.');
        }
      });
  }

  onDragEnd(result: any) {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const criterias = [...this.state.criterias];
    const newCriteriaIds = criterias.map((x) => x.id);
    newCriteriaIds.splice(source.index, 1);
    newCriteriaIds.splice(destination.index, 0, draggableId); // This is the new order based on Ids
    const newCriteriasOrder: any = [];
    newCriteriaIds.forEach((id, index) => {
      const obj: any | null = criterias.find((x) => x.id === id);
      if (obj) {
        obj.priority = index + 1; // Update priority of criteria
        newCriteriasOrder.push(obj);
      }
    });

    this.setState({
      criterias: newCriteriasOrder,
    });
  }

  getCriteriaJsx(typeId: number, index: number | null) {
    switch (typeId) {
      case RuleCriteriaType.BookingDurationOnline:
        return [
          this.getInput('minGuests', 'number', 'Min. Guests', index),
          this.getInput('maxGuests', 'number', 'Max. Guests', index),
          this.getInput('fromTime', 'time', 'From', index),
          this.getInput('toTime', 'time', 'To', index),
          this.getInput('bookingDurationMinutes', 'duration', 'Duration', index),
        ];
      case RuleCriteriaType.NoShow:
        return [
          this.getInput('minGuests', 'number', 'Min. Guests', index),
          this.getInput('maxGuests', 'number', 'Max. Guests', index),
          this.getInput('fromTime', 'time', 'From', index),
          this.getInput('toTime', 'time', 'To', index),
          this.getInput('noShowPayment', 'number', 'ISK per Guest', index),
        ];
      case RuleCriteriaType.OnlineBookingBlocked:
        return [
          this.getInput('minGuests', 'number', 'Min. Guests', index),
          this.getInput('maxGuests', 'number', 'Max. Guests', index),
          this.getInput('fromTime', 'time', 'From', index),
          this.getInput('toTime', 'time', 'To', index),
        ];
      case RuleCriteriaType.MaxTotalGuestsOnline:
        return [
          this.getInput('fromTime', 'time', 'From', index),
          this.getInput('toTime', 'time', 'To (excluded)', index),
          this.getInput('maxTotalGuests', 'number', 'Max total guests online', index),
        ];
      case RuleCriteriaType.MaxTotalGuestsAll:
        return [
          this.getInput('fromTime', 'time', 'From', index),
          this.getInput('toTime', 'time', 'To (excluded)', index),
          this.getInput('maxTotalGuests', 'number', 'Max total guests (all)', index),
        ];
      case RuleCriteriaType.MaxBookingsOnline:
        return [
          this.getInput('fromTime', 'time', 'From', index),
          this.getInput('toTime', 'time', 'To', index),
          this.getInput('maxBookings', 'number', 'Max bookings online', index),
        ];
      case RuleCriteriaType.GuestsPerBookingOnline:
        return [
          this.getInput('minGuests', 'number', 'Min. guests', index),
          this.getInput('maxGuests', 'number', 'Max. guests', index),
          this.getInput('fromTime', 'time', 'From', index),
          this.getInput('toTime', 'time', 'To', index),
        ];
      case RuleCriteriaType.CustomText:
        return [this.getInput('customText', 'input', 'Message', index)];
      case RuleCriteriaType.MaxTotalGuestsAtATime:
        return [
          this.getInput(
            'maxTotalGuests',
            'number',
            'Max total guests at a time (online & manual)',
            index,
          ),
        ];
      case RuleCriteriaType.BookingNotice:
        return [this.getInput('bookingNoticeHours', 'number', 'Booking notice hours', index)];
      case RuleCriteriaType.IgnoreOtherRules:
        return [];
      case RuleCriteriaType.LimitTablesToTime:
        return [
          this.getInput('tables', 'tables', 'Tables', index),
          this.getInput('fromTime', 'time', 'From', index),
          this.getInput('toTime', 'time', 'To', index),
        ];
      case RuleCriteriaType.Turnover:
        return [
          this.getInput('turnaroundTimeMinutes', 'number', 'Turnover minutes', index),
          this.getInput('fromTime', 'time', 'From', index),
          this.getInput('toTime', 'time', 'To', index),
        ];
      default:
        return <div>Nothing</div>;
    }
  }

  getInput(field: string, inputType: string, placeholder: string, index: number | null) {
    const isNewCriteria = index === null;
    if (inputType === 'time' && typeof index !== 'undefined') {
      const val =
        index !== null
          ? (this.state.criterias as any)[index][field]
          : (this.state.newCriteria as any)[field];

      let momentVal;
      if (val) {
        momentVal = moment(val, 'HH:mm');
      }

      return (
        <TimeContainer key={`${field}-${index !== null && index ? index : 'newCriteria'}`}>
          <Label>{placeholder}</Label>
          <TimePicker
            placeholder={placeholder}
            value={momentVal}
            minuteStep={15}
            defaultOpenValue={moment('12:00', 'HH:mm')}
            allowEmpty
            showSecond={false}
            disabled={!isNewCriteria}
            onChange={(v) => this.handleNewCriteriaInputChange(field, v ? v.format('HH:mm') : null)}
          />
        </TimeContainer>
      );
    }

    if (inputType === 'duration' && typeof index !== 'undefined') {
      return (
        <DurationContainer key={`${field}-${index || 'newCriteria'}`}>
          <Label>{placeholder}</Label>
          <SelectStyle className="select-style">
            <Select
              value={
                index !== null
                  ? (this.state.criterias as any)[index][field]
                  : (this.state.newCriteria as any)[field]
              }
              options={durationSuggestions}
              onChange={(v: any) =>
                this.handleNewCriteriaInputChange('bookingDurationMinutes', v.value)
              }
              placeholder={placeholder}
              clearable={false}
              /*styles={{ marginBottom: 0 }}*/
              disabled={!isNewCriteria}
            />
          </SelectStyle>
        </DurationContainer>
      );
    }

    if (inputType === 'input' && typeof index !== 'undefined') {
      return (
        <Input
          title={placeholder}
          value={
            index !== null
              ? (this.state.criterias as any)[index][field]
              : (this.state.newCriteria as any)[field]
          }
          onChange={(v) => this.handleNewCriteriaInputChange(field, v)}
          key={`${field}-${index || 'newCriteria'}`}
          hideErrorMsg
          isDisabled={!isNewCriteria}
        />
      );
    }

    if (inputType === 'tables' && typeof index !== 'undefined') {
      return (
        <div style={{ flexGrow: 1 }} key={`${field}-${index || 'newCriteria'}`}>
          <Select
            simpleValue
            multi
            value={(
              (index !== null
                ? (this.state.criterias as any)[index][field]
                : (this.state.newCriteria as any)[field]) || []
            )
              .map((t: RuleCriteriaTableModel) => t.floorPlanTableId)
              .join(',')}
            onChange={(ids) => this.handleNewCriteriaInputChange(field, ids)}
            options={this.state.allFloorPlanTables
              .filter((t) => t.id > 0 && !t.isDeleted)
              .map((t) => ({
                label: `${t.name} (${t.floorPlanName})`,
                value: t.id,
              }))}
            placeholder="Select tables"
            disabled={!isNewCriteria}
          />
        </div>
      );
    }

    if (typeof index !== 'undefined') {
      return (
        <Input
          title={placeholder}
          value={
            index !== null
              ? (this.state.criterias as any)[index][field]
              : (this.state.newCriteria as any)[field]
          }
          onChange={(v) => this.handleNewCriteriaInputChange(field, v)}
          key={`${field}-${index || 'newCriteria'}`}
          hideErrorMsg
          isDisabled={!isNewCriteria}
        />
      );
    }
    return false;
  }

  getRuleCriteriaTypeName(typeId: number) {
    switch (typeId) {
      case RuleCriteriaType.BookingDurationOnline:
        return 'Booking duration online';
      case RuleCriteriaType.NoShow:
        return 'No show';
      case RuleCriteriaType.OnlineBookingBlocked:
        return 'Block "à la carte" online';
      case RuleCriteriaType.MaxTotalGuestsOnline:
        return 'Max total guests online';
      case RuleCriteriaType.MaxTotalGuestsAll:
        return 'Max total guests (all)';
      case RuleCriteriaType.MaxBookingsOnline:
        return 'Max bookings online';
      case RuleCriteriaType.GuestsPerBookingOnline:
        return 'Guests per booking online';
      case RuleCriteriaType.CustomText:
        return 'Custom message';
      case RuleCriteriaType.MaxTotalGuestsAtATime:
        return 'Max guests at a time';
      case RuleCriteriaType.BookingNotice:
        return 'Booking notice hours';
      case RuleCriteriaType.IgnoreOtherRules:
        return 'Ignore other rules';
      case RuleCriteriaType.LimitTablesToTime:
        return 'Limit tables to time';
      case RuleCriteriaType.Turnover:
        return 'Turnover';
      default:
        return 'Unknown';
    }
  }

  handleNewCriteriaInputChange(field: string, value: any) {
    const newCriteria = { ...this.state.newCriteria };

    if (field === 'tables') {
      newCriteria.tables = (value as string).split(',').map((x) => ({
        id: -1,
        ruleCriteriaId: newCriteria.id,
        floorPlanTableId: parseInt(x, 10),
      }));
    } else {
      (newCriteria as any)[field] = value;
    }

    if (field === 'type') {
      newCriteria.fromTime = null;
      newCriteria.toTime = null;
      newCriteria.minGuests = null;
      newCriteria.maxGuests = null;
      newCriteria.bookingDurationMinutes = null;
      newCriteria.noShowPayment = null;
      newCriteria.maxTotalGuests = null;
      newCriteria.maxBookings = null;
      newCriteria.customText = null;
      newCriteria.bookingNoticeHours = null;
      newCriteria.turnaroundTimeMinutes = null;
    }

    this.setState({
      newCriteria,
    });
  }

  pushNewCriteriaToList() {
    const newCriteria = { ...this.state.newCriteria };
    const numOfCriterias = this.state.criterias.length;
    const newId = numOfCriterias > 0 ? Math.max(...this.state.criterias.map((x) => x.id)) + 1 : 1; // Obtain UI id

    newCriteria.id = newId;
    newCriteria.priority = numOfCriterias + 1;

    this.setState({
      criterias: [...this.state.criterias, newCriteria],
      newCriteria: {
        id: 0,
        ruleId: this.state.viewMode === 'edit' ? parseInt(this.props.match.params.ruleId, 10) : -1,
        priority: 0,
        type: 1,
        minGuests: null,
        maxGuests: null,
        fromTime: null,
        toTime: null,
        maxTotalGuests: null,
        maxBookings: null,
        bookingDurationMinutes: null,
        noShowPayment: null,
        customText: null,
        bookingNoticeHours: null,
        turnaroundTimeMinutes: null,
        tables: [],
      },
    });
  }

  removeCriteriaFromList(index: number) {
    const criterias = [...this.state.criterias];
    criterias.splice(index, 1);
    for (let i = 0; i < criterias.length; i++) {
      criterias[i].priority = i + 1;
    }

    this.setState({
      criterias,
    });
  }

  newCriteriaValid() {
    const {
      bookingDurationMinutes: duration,
      type,
      maxTotalGuests,
      maxBookings,
      noShowPayment: noShow,
      customText,
      bookingNoticeHours,
      fromTime,
      toTime,
      tables,
      turnaroundTimeMinutes,
    } = this.state.newCriteria;

    const hasPaymentKey = this.props.store!.RestaurantStore.restaurant!.hasPaymentKey;
    if (type === RuleCriteriaType.NoShow && !hasPaymentKey) {
      // If restaurant doesn't have a payment key, then it can't use no show rules
      return false;
    }

    switch (type) {
      case RuleCriteriaType.BookingDurationOnline:
        return duration && duration > 0 ? this.InputValid('time-guests') : false;
      case RuleCriteriaType.NoShow:
        return noShow && noShow > 0 ? this.InputValid('time-guests') : false;
      case RuleCriteriaType.OnlineBookingBlocked:
        return this.InputValid('time-guests');
      case RuleCriteriaType.MaxTotalGuestsOnline:
        return maxTotalGuests && maxTotalGuests > 0 ? this.InputValid('time') : false;
      case RuleCriteriaType.MaxTotalGuestsAll:
        return maxTotalGuests && maxTotalGuests > 0 ? this.InputValid('time') : false;
      case RuleCriteriaType.MaxBookingsOnline:
        return maxBookings && maxBookings > 0 ? this.InputValid('time') : false;
      case RuleCriteriaType.GuestsPerBookingOnline:
        return this.InputValid('guests');
      case RuleCriteriaType.CustomText:
        return customText && customText.length > 2;
      case RuleCriteriaType.MaxTotalGuestsAtATime:
        return maxTotalGuests && maxTotalGuests > 0;
      case RuleCriteriaType.BookingNotice:
        return bookingNoticeHours && bookingNoticeHours > 0;
      case RuleCriteriaType.IgnoreOtherRules:
        return true;
      case RuleCriteriaType.LimitTablesToTime:
        return fromTime && toTime && tables && tables.length > 0;
      case RuleCriteriaType.Turnover:
        return Boolean(turnaroundTimeMinutes) && this.InputValid('time');
      default:
        return false;
    }
  }

  InputValid(type: InputType) {
    const { fromTime, toTime, minGuests, maxGuests } = this.state.newCriteria;

    const minGuestsInt = parseInt(minGuests as any, 10);
    const maxGuestsInt = parseInt(maxGuests as any, 10);

    switch (type) {
      case 'time': {
        // Time inputs mandatory
        if (fromTime && toTime) {
          return fromTime < toTime;
        } else if (!(fromTime || toTime)) {
          return true;
        }
        return false;
      }
      case 'guests': {
        // Guests inputs mandatory
        if (minGuestsInt && maxGuestsInt && minGuestsInt < maxGuestsInt) {
          if (fromTime && toTime) {
            return fromTime < toTime;
          } else if (!(fromTime || toTime)) {
            return true;
          }
        }
        return false;
      }
      case 'time-guests': {
        // Time and guests inputs mandatory
        if (fromTime && toTime && minGuests && maxGuests) {
          // All set
          return (
            minGuestsInt < maxGuestsInt && minGuestsInt > 0 && maxGuestsInt > 0 && fromTime < toTime
          );
        } else if (
          (minGuests && !maxGuests) ||
          (!minGuests && maxGuests) ||
          (fromTime && !toTime) ||
          (!fromTime && toTime)
        ) {
          // Either one not set
          return false;
        } else if (fromTime && toTime) {
          // Time valid
          return fromTime < toTime;
        } else if (minGuests && maxGuests) {
          // Guests valid
          return minGuestsInt < maxGuestsInt && minGuestsInt > 0 && maxGuestsInt > 0;
        } else if (!(fromTime && toTime && minGuests && maxGuests)) {
          // Nothing set
          return true;
        }
        return false;
      }
      default:
        return false;
    }
  }

  saveRule() {
    const { ruleId } = this.props.match.params;

    const rule: RuleModel = {
      id: ruleId,
      name: this.state.ruleName,
      criterias: this.state.criterias,
      restaurantId: -1,
    };

    if (this.state.viewMode === 'edit') {
      updateRule(rule)
        .then((res) => {
          this.props.store!.UIStore.addFlashMessage({
            type: res.data.success === true ? FlashMessageType.Success : FlashMessageType.Error,
            text: res.data.message,
            timeout: res.data.success === true ? 3000 : 10000,
          });
          this.props.history.push(`/rule`);
        })
        .catch(() => {
          this.props.store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Error updating rule.',
            timeout: 10000,
          });
        });
    } else if (this.state.viewMode === 'create') {
      createRule(rule)
        .then((res) => {
          this.props.store!.UIStore.addFlashMessage({
            type: res.data.success === true ? FlashMessageType.Success : FlashMessageType.Error,
            text: res.data.message,
            timeout: res.data.success === true ? 3000 : 10000,
          });
          this.props.history.push(`/rule`);
        })
        .catch((error) => {
          console.log('Could not create rule:', error);
        });
    }
  }

  render() {
    return (
      <RuleContainer>
        <h1>{this.state.viewMode === 'create' ? 'New Rule' : 'Edit Rule'}</h1>
        <Button
          id="btn-save-rul"
          className="btn-cta"
          disabled={this.state.criterias.length === 0 || !this.state.ruleName}
          onClick={() => this.saveRule()}
          isLoading={this.props.store!.UIStore.isLoading}
        >
          Save
        </Button>
        <sub>Here you can fine-tune your booking constraints.</sub>
        <div className="name-container">
          <Label>Rule Name</Label>
          <Input
            type="text"
            placeholder="Rule Name"
            value={this.state.ruleName}
            onChange={(v) => this.setState({ ruleName: v })}
            hideErrorMsg
          />
        </div>
        <DragDropContext onDragEnd={(res) => this.onDragEnd(res)}>
          <Droppable droppableId="list">
            {(p1, s1) => (
              <div ref={p1.innerRef} {...p1.droppableProps}>
                <CriteriaListContainer isDraggingOver={s1.isDraggingOver}>
                  <span className="priority">Priority</span>
                  {this.state.criterias.map((x, i) => (
                    <Draggable draggableId={x.id as any} index={i} key={`draggable-id-${x.id}`}>
                      {(p2, s2) => (
                        <div ref={p2.innerRef} {...p2.draggableProps} {...p2.dragHandleProps}>
                          <CriteriaItem className="list-criteria" isDragging={s2.isDragging}>
                            <i className="material-icons">drag_indicator</i>
                            <div>{x.priority}</div>
                            <div>
                              <Label>Criteria Type</Label>
                              <SelectStyle>
                                <Select
                                  value={this.state.criterias[i].type}
                                  options={this.state.RuleCriteriaTypes}
                                  placeholder="Off"
                                  clearable={false}
                                  disabled
                                />
                              </SelectStyle>
                            </div>
                            {this.getCriteriaJsx(x.type, i)}
                            <i
                              role="button"
                              tabIndex={0}
                              className="material-icons btn-delete"
                              onClick={() => this.removeCriteriaFromList(i)}
                            >
                              delete
                            </i>
                          </CriteriaItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {p1.placeholder}
                </CriteriaListContainer>
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <CriteriaItem className="new-criteria" key="new-criteria">
          <div>New Criteria</div>
          <div>
            <Label>Criteria Type</Label>
            <SelectStyle>
              <Select
                value={this.state.newCriteria.type}
                onChange={(v: any) =>
                  this.handleNewCriteriaInputChange('type', parseInt(v.value, 10))
                }
                options={this.state.RuleCriteriaTypes}
                placeholder="Off"
                clearable={false}
              />
            </SelectStyle>
          </div>
          {this.getCriteriaJsx(this.state.newCriteria.type, null)}
          <div>
            <Label>&nbsp;</Label>
            <OldButton
              onClick={() => this.pushNewCriteriaToList()}
              disabled={!this.newCriteriaValid()}
            >
              Add
            </OldButton>
          </div>
        </CriteriaItem>
        {this.state.newCriteria.type === RuleCriteriaType.NoShow &&
          !this.props.store!.RestaurantStore.restaurant!.hasPaymentKey && (
            <b style={{ color: 'red' }}>Please contact dineout@dineout.is to use this criteria.</b>
          )}
      </RuleContainer>
    );
  }
}

export default withRouter(Rule);
