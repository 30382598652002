const theme = {
  colors: {
    // Brand
    brand1: '#FFAB66',
    brand2: '#FFC49A',
    brand3: '#F7CA28',
    // Accent
    // Dark
    dark1: '#18191F',
    dark2: '#828282',
    // Neutral
    neutral1: '#F2F2F2',
    neutral2: '#e8e6e6',
    neutral3: '#fbfbfb',
    neutral4: '#C4C4C4',
    // Warning
    warning: '#F7CA28',
    // Error
    danger: '#EF5350',
    // Success
    success: '#70DB7B',
  },
};

export default theme;
