import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const Style = styled.div`
  position: relative;
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-between;

  background: #ffffff;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  font-size: 12px;

  * {
    outline: none;
  }

  input,
  textarea {
    border: 0px;
  }

  &:focus-within {
    outline: auto 5px -webkit-focus-ring-color;
    z-index: 99999;
  }

  /* Icon container */
  > div:last-child:not(:first-child) {
    display: none;
    width: 48px;
    height: 100%;

    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-left: 1px solid #c1c1c1;
    user-select: none;

    i {
      font-size: 16px;
      color: #999999;
    }

    ${(props: { hasIcon: boolean }) =>
      props.hasIcon &&
      css`
        display: flex;
      `}
  }
`;

type Props = {
  children: any;
  className?: string;
};

export default class Form extends Component<Props> {
  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <Style hasIcon={this.props.children.length === 2} className={this.props.className}>
        {this.props.children}
      </Style>
    );
  }
}
