import moment from 'moment-timezone';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import Datepicker from './common/Datepicker';
import Input from './common/Input';
import Select from './common/Select';
import TableButton from './TableButton.styled';

import BookingAPI, { AvailabilityTableType } from '../api/booking.api';
import RestaurantAPI, { RestaurantAPINS } from '../api/restaurant.api';
import Utils, { getAllStatuses } from '../utils';
import { generateTimes } from '../utils/booking.utils';

import { format, parseISO } from 'date-fns';
import { inject, observer } from 'mobx-react';
import FloorPlanAPI from '../api/floorplan.api';
import { PaymentLinkDeliveryType, PaymentType } from '../enums/Payment';
import { IRootStore } from '../stores/RootStore';
import theme from '../styles/theme';
import CheckboxNew from './CheckboxNew';
import Button from './common/Button';
import Label from './common/Label';
import { BookingModalStateType, BookingViewMode } from './ModalConductor/Booking';
import PaymentLinkForm from './PaymentLinkForm';

const DetailedBookingStyle = styled.div`
  padding-bottom: 80px;
  @media screen and (min-width: 768px) {
    padding-bottom: 0px;
  }

  .db-content {
    display: flex;
    flex-direction: column;
    padding: 16px;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      padding: 0px;
    }

    > div.left-side {
      /* Left */
      @media screen and (min-width: 768px) {
        flex-basis: 70%;
        padding: 16px 32px 0px 32px;
      }

      .db-booking-info-container {
        display: flex;
        flex-wrap: wrap;
        > div {
          flex-basis: calc(50% - 16px);
          &:nth-child(1),
          &:nth-child(2) {
            margin-bottom: 16px;
          }
        }

        @media screen and (min-width: 912px) {
          flex-wrap: nowrap;
          > div {
            flex-basis: inherit;
            &:nth-child(1),
            &:nth-child(2) {
              margin-bottom: 0px;
            }
          }
        }
      }

      .db-customer-info-container {
        .db-group-inline {
          flex-wrap: wrap;
          flex-basis: 100%;
          padding: 0px;

          > div {
            margin-right: 0px;
            margin-bottom: 8px;
          }

          @media screen and (min-width: 416px) {
            flex-wrap: nowrap;
            flex-basis: inherit;
            padding-bottom: 8px;

            > div {
              margin-bottom: 0px;
              &:first-child {
                margin-right: 16px;
              }
            }
          }
        }
      }

      .db-group-payments.db-group-inline {
        flex-wrap: wrap;
        flex-basis: 100%;
        align-items: flex-start;
        padding: 0px;

        .db-header {
          height: 32px;
          margin-top: 16px;

          > button {
            margin-left: 8px;
            padding: 0px;
            pointer-events: all;
            border: 0px;
            box-shadow: none;
            font-size: 9px;
            text-transform: uppercase;
            font-weight: 500;
            color: ${theme.colors.danger};
          }
        }

        > div {
          margin-right: 0px;
          margin-bottom: 8px;
        }

        @media screen and (min-width: 768px) {
          flex-wrap: nowrap;
          flex-basis: inherit;
          padding-bottom: 8px;

          > div {
            margin-bottom: 0px;
          }
        }
      }
    }

    > div.right-side {
      /* Right */
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      border-top: 1px solid #ebebeb;

      @media screen and (min-width: 768px) {
        flex-basis: 30%;
        padding: 0px 32px 32px 32px;
        margin-top: 0px;
        border-top: 0px;
        border-left: 1px solid #ebebeb;
      }

      > div {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
      }

      textarea {
        height: 100%;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        min-height: 144px;
        resize: none;
        font-size: 12px;
        border: none;
        padding: 0px;

        @media screen and (min-width: 768px) {
          min-height: none;
        }

        &::-webkit-input-placeholder {
          color: ${theme.colors.neutral4};
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: ${theme.colors.neutral4};
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: ${theme.colors.neutral4};
        }

        &:-ms-input-placeholder {
          color: ${theme.colors.neutral4};
        }

        &::placeholder {
          color: ${theme.colors.neutral4};
        }
      }
    }
  } /* .content ends */

  .db-header {
    display: flex;
    align-items: center;
    height: 64px;
    flex-shrink: 0;

    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 12px;
    letter-spacing: 0.666px;
    text-transform: uppercase;
  }

  .db-item-arrival.db-item {
    flex-basis: 120%;
  }

  .db-group-inline {
    display: flex;
    align-items: center;
    padding-bottom: 8px;

    > div {
      width: 100%;
    }
    > div:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const EditBookingInfoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0px 16px;
  font-size: 12px;
  border-bottom: 1px solid #ebebeb;

  @media screen and (min-width: 768px) {
    height: 48px;
    padding: 0px 32px;
    flex-direction: row;
  }

  > div {
    display: flex;
    align-items: center;
  }

  .db-info-header-booking-info {
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
    }

    > div {
      display: flex;
      align-items: center;
      margin-right: 16px;

      @media screen and (min-width: 768px) {
        margin-right: 0px;
      }

      &:not(:last-child) {
        &::after {
          content: '';
          display: none;
          width: 4px;
          height: 4px;
          margin: 0px 8px;
          background-color: #ccc;
          border-radius: 50%;

          @media screen and (min-width: 768px) {
            display: block;
          }
        }
      }

      label  {
        margin-right: 8px;
      }
    }
  }
`;

export const StatusSelect = styled.div`
  > div {
    border: 0px;
    background-color: transparent;

    .Select-control {
      overflow: unset;
      background-color: transparent;

      .Select-value {
        position: relative;
        padding: 0px !important;
        overflow: unset;

        &:hover {
          cursor: pointer;
        }

        .Select-value-label {
          height: 24px !important;
          padding: 8px !important;

          border-radius: 10px;
          text-transform: uppercase;
          font-size: 11px;
          font-weight: 400;
          letter-spacing: 0.333px;
          background-color: ${(props: { statusColor: string }) => props.statusColor};
          color: ${(props: { color: string }) => props.color} !important;
        }
      }
      .Select-input {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        background-color: transparent !important;
      }
      .Select-arrow-zone {
        border: 0px;
        width: 24px;
        background-color: transparent !important;
      }
    }
    .Select-menu-outer {
      width: 168px;
    }
  }
`;

const BtnSelectTable = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 36px;
  padding: 8px;
  margin-right: 2px;
  margin-bottom: 2px;
  border-radius: 3px;
  font-size: 11px;
  user-select: none;
  border: 2px solid ${theme.colors.neutral2};

  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    z-index: 1;
  }

  &::before {
    display: block;
    content: 'add_circle_outline';
    font-family: 'Material Icons';
    margin-right: 4px;
    font-size: 16px;
  }

  ${(props: { isActive: boolean }) =>
    props.isActive &&
    css`
      font-weight: 400;
      transition: all 111ms ease-in;

      &::before {
        content: 'remove_circle_outline';
      }
    `}

  ${(props: { isDisabled: boolean }) =>
    props.isDisabled &&
    css`
      opacity: 0.5;
      &:hover {
        cursor: default;
      }
    `};
`;

const Ldng = styled.div`
  display: flex;
  height: 80px;
  width: 80px;
  align-items: center;
  justify-content: center;
`;

const AddLink = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 0px;
  border: 0px;
  background: 0px;
  font-size: 12px;

  i {
    display: block;
    margin-right: 8px;
    font-size: 16px;
  }

  &:hover {
    cursor: pointer;
  }

  ${(props: { disabled: boolean }) =>
    props.disabled &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `};
`;

// Used for event and menu bookings
const AdditionalInfo = styled.div`
  display: flex;

  > div {
    display: flex;
    align-items: center;
    padding: 8px;
    margin-bottom: 8px;
    background-color: rgba(3, 169, 244, 0.1);
    border: 1px solid rgba(3, 169, 244, 0.3);
    border-radius: 3px;
    font-size: 12px;

    > i {
      font-size: 16px;
      color: #124156;
      margin-right: 4px;
    }
  }
`;

const TableContainer = styled.div`
  position: relative;
  padding-bottom: 8px;

  > div.waiting-list-overlay {
    display: none;

    > button {
      max-width: 200px;
    }
  }

  ${({ isWaitingList }: { isWaitingList: boolean }) =>
    isWaitingList &&
    css`
      > div.waiting-list-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8);
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 1;
        user-select: none;
      }
    `};
`;

enum InputFields {
  BookingName,
  BookingPhone,
  BookedByName,
  BookingEmail,
  BookingSpecialRequest,
  BookingInternalNotes,
  Start,
  Time,
  Duration,
  Guests,
}

type Props = {
  store?: IRootStore;
  restaurantCountry: 'is' | 'en' | 'es' | 'nb';
  booking: BookingModalStateType;
  allTables: AvailabilityTableType[];
  selectedTableIds: number[];
  isWalkIn: boolean;
  isSaving: boolean;
  viewMode: BookingViewMode;
  paymentLinkMinutes: number | null;
  feePerGuest: number | null;
  hasPaymentKey: boolean;
  paymentLinkSendType: PaymentLinkDeliveryType;
  paymentLinkType: PaymentType;
  onTableClick: (newSelectedTableIds: number[]) => void;
  updateBooking: (updatedBooking: BookingModalStateType) => void;
  updatePaymentLinkMinutes: (minutes: number | null) => void;
  updateFeePerGuest: (fee: number | null) => void;
  updatePaymentLinkSendType: (type: PaymentLinkDeliveryType) => void;
  updatePaymentLinkType: (type: PaymentType) => void;

  shouldSendSMS: boolean;
  updateShouldSendSMS: (state: boolean) => void;

  shouldSendEmail: boolean;
  updateShouldSendEmail: (state: boolean) => void;
};

type State = {
  /* Options */
  statusOptions: Array<{ value: number; label: string }>;
  guestsOptions: Array<{ value: number; label: string }>;
  durationOptions: Array<{ value: number; label: string }>;
  timeOptions: string[];

  /* UI */
  showCreditCard: boolean;
  showPaymentExpires: boolean;
  preferences: RestaurantAPINS.RestaurantPreferencesModel;
};

@inject('store')
@observer
export default class Booking extends Component<Props, State> {
  bookingAPI: BookingAPI;
  restaurantAPI: RestaurantAPI;
  floorPlanAPI: FloorPlanAPI;
  utils: Utils;

  constructor(props: Props) {
    super(props);

    // Guests options
    const guestOptions: Array<{ value: number; label: string }> = [];
    for (let i = 0; i < 2000; i++) {
      guestOptions.push({ value: i + 1, label: (i + 1).toString() });
    }

    const preferences = props.store!.RestaurantStore.restaurant!.preferences || {
      isBookedByNameMandatory: false,
      isBookingNameMandatory: false,
    };

    this.state = {
      /* Options */
      statusOptions: getAllStatuses().map((x) => ({
        label: x.name,
        value: x.id,
      })),
      guestsOptions: guestOptions,
      timeOptions: generateTimes('07:00', '02:00'),
      durationOptions: [
        { value: 15, label: '15 mins' },
        { value: 30, label: '30 mins' },
        { value: 45, label: '45 mins' },
        { value: 60, label: '1 hr' },
        { value: 75, label: '1 ¼ hrs' },
        { value: 90, label: '1 ½ hrs' },
        { value: 105, label: '1 ¾ hrs' },
        { value: 120, label: '2 hrs' },
        { value: 135, label: '2 ¼ hrs' },
        { value: 150, label: '2 ½ hrs' },
        { value: 165, label: '2 ¾ hrs' },
        { value: 180, label: '3 hrs' },
        { value: 195, label: '3 ¼ hrs' },
        { value: 210, label: '3 ½ hrs' },
        { value: 225, label: '3 ¾ hrs' },
        { value: 240, label: '4 hrs' },
        { value: 255, label: '4 ¼ hrs' },
        { value: 270, label: '4 ½ hrs' },
        { value: 285, label: '4 ¾ hrs' },
        { value: 300, label: '5 hrs' },
        { value: 315, label: '5 ¼ hrs' },
        { value: 330, label: '5 ½ hrs' },
        { value: 345, label: '5 ¾ hrs' },
        { value: 360, label: '6 hrs' },
        { value: 375, label: '6 ¼ hrs' },
        { value: 390, label: '6 ½ hrs' },
        { value: 405, label: '6 ¾ hrs' },
        { value: 420, label: '7 hrs' },
        { value: 435, label: '7 ¼ hrs' },
        { value: 450, label: '7 ½ hrs' },
        { value: 465, label: '7 ¾ hrs' },
        { value: 480, label: '8 hrs' },
        { value: 495, label: '8 ¼ hrs' },
        { value: 510, label: '8 ½ hrs' },
        { value: 525, label: '8 ¾ hrs' },
        { value: 540, label: '9 hrs' },
        { value: 555, label: '9 ¼ hrs' },
        { value: 570, label: '9 ½ hrs' },
        { value: 585, label: '9 ¾ hrs' },
        { value: 600, label: '10 hrs' },
        { value: 615, label: '10 ¼ hrs' },
        { value: 630, label: '10 ½ hrs' },
        { value: 645, label: '10 ¾ hrs' },
        { value: 660, label: '11 hrs' },
        { value: 675, label: '11 ¼ hrs' },
        { value: 690, label: '11 ½ hrs' },
        { value: 705, label: '11 ¾ hrs' },
        { value: 720, label: '12 hrs' },
      ],

      /* UI */
      showCreditCard: false,
      showPaymentExpires: false,
      preferences,
    };

    this.bookingAPI = new BookingAPI();
    this.restaurantAPI = new RestaurantAPI();
    this.floorPlanAPI = new FloorPlanAPI();
    this.utils = new Utils();
  }

  handleInputChange(field: InputFields, value: any) {
    const updatedBooking = Object.assign({}, this.props.booking);

    switch (field) {
      case InputFields.BookedByName:
        updatedBooking.bookedByName = value;
        break;
      case InputFields.BookingEmail:
        updatedBooking.bookingEmail = value;
        break;
      case InputFields.BookingInternalNotes:
        updatedBooking.bookingInternalNotes = value;
        break;
      case InputFields.BookingName:
        updatedBooking.bookingName = value;
        break;
      case InputFields.BookingPhone:
        updatedBooking.bookingPhone = value;
        break;
      case InputFields.BookingSpecialRequest:
        updatedBooking.bookingSpecialRequest = value;
        break;
      default:
        throw new Error('Input does not exists');
    }

    this.props.updateBooking(updatedBooking);
  }

  cardChange(event: any, type: string) {
    const { card } = this.props.booking;
    const newCard: any = Object.assign({}, card);

    newCard[type] = event.target.value;

    const updatedBooking = Object.assign({}, this.props.booking);
    updatedBooking.card = newCard;
    this.props.updateBooking(updatedBooking);
  }

  handleInputChangeRefetchTables(field: InputFields, value: any) {
    const updatedBooking = Object.assign({}, this.props.booking);

    if (field === InputFields.Time) {
      const { start } = this.props.booking;
      const newStart = start.clone();
      const values = value.split(':');

      newStart.set({
        hour: parseInt(values[0], 10),
        minute: parseInt(values[1], 10),
      });

      updatedBooking.start = newStart;
    } else if (field === InputFields.Start) {
      updatedBooking.start = value;
    } else if (field === InputFields.Guests) {
      updatedBooking.guests = value;
    } else if (field === InputFields.Duration) {
      updatedBooking.duration = value;
    }

    this.props.updateBooking(updatedBooking);
  }

  toggleTable(tableId: number) {
    const isFound = this.props.selectedTableIds.some((x) => x === tableId);

    if (isFound) {
      // Remove table from selected
      const newTables = this.props.selectedTableIds.filter((x) => x !== tableId);
      this.props.onTableClick(newTables);
    } else {
      this.props.onTableClick([...this.props.selectedTableIds, tableId]);
    }
  }

  // Renders info for event or menu if needed
  additionalInfoJsx() {
    const { eventId, menus } = this.props.booking;
    if (eventId) {
      return (
        <AdditionalInfo>
          <div>
            <i className="material-icons">event_note</i>
            <div>{this.props.booking.eventName}</div>
          </div>
        </AdditionalInfo>
      );
    } else if (menus.length > 0) {
      return (
        <AdditionalInfo>
          <div>
            <i className="material-icons">restaurant_menu</i>
            <div>{menus.map((m) => `${m.count}x ${m.name}`).join(', ')}</div>
          </div>
        </AdditionalInfo>
      );
    }
    return null;
  }

  render() {
    if (this.props.allTables.length === 0) {
      return <Ldng>Loading...</Ldng>;
    }

    let sourceName = 'Manual';
    if (this.props.booking.sourceId === 2 || this.props.booking.sourceId === 5) {
      sourceName = 'Web';
    } else if (this.props.booking.sourceId === 3) {
      sourceName = 'App';
    } else if (this.props.booking.sourceId === 6) {
      sourceName = 'Walk-In';
    } else if (this.props.booking.sourceId === 4 || this.props.booking.sourceId === 7) {
      sourceName = 'Hotel';
    }

    const allTablesSelected =
      this.props.allTables
        .filter((x) => x.isAvailable)
        .map((x) => x.id)
        .sort()
        .toString() === this.props.selectedTableIds.sort().toString();
    const suggestedTableIds = this.props.allTables.filter((x) => x.isSuggested).map((x) => x.id);
    const allSuggestedTablesSelected =
      suggestedTableIds.sort().toString() === this.props.selectedTableIds.sort().toString();
    const hasSuggestions = suggestedTableIds.length > 0;
    const currentStatus = getAllStatuses().filter((x) => x.id === this.props.booking.statusId)[0];

    const selectedTables = this.props.allTables.filter((x) =>
      this.props.selectedTableIds.includes(x.id),
    );
    const selectedMinGuests = selectedTables.reduce((a, b) => a + b.minGuests, 0);
    const selectedMaxGuests = selectedTables.reduce((a, b) => a + b.maxGuests, 0);

    return (
      <DetailedBookingStyle>
        {!this.props.isWalkIn && (
          <EditBookingInfoHeader>
            <div>
              <StatusSelect statusColor={currentStatus.color} color={currentStatus.contrastColor}>
                <Select
                  id="db-status-dropdown"
                  value={this.props.booking.statusId}
                  options={getAllStatuses().map((x) => ({
                    value: x.id,
                    label: x.name,
                    disabled:
                      this.props.viewMode === BookingViewMode.Edit &&
                      x.id === 1 &&
                      this.props.booking.sourceId > 1,
                  }))}
                  onChange={(status) => {
                    const updatedBooking = Object.assign({}, this.props.booking);
                    updatedBooking.statusId = status.value;

                    if (status.value === 12) {
                      // Booking status to waiting means that email/sms confirmations should not be sent and no tables can be selected
                      this.props.updateShouldSendSMS(false);
                      this.props.updateShouldSendEmail(false);
                      this.props.onTableClick([]);
                    }

                    this.props.updateBooking(updatedBooking);
                  }}
                  searchable={false}
                />
              </StatusSelect>
            </div>

            {this.props.viewMode === BookingViewMode.Edit && (
              <div className="db-info-header-booking-info">
                <div>
                  <Label>#</Label>
                  {this.props.booking.bookingId}
                </div>
                <div>
                  <Label>Created</Label>
                  {format(parseISO(`${this.props.booking.created!}Z`), 'MMM dd, yyyy HH:mm')}
                </div>
                <div>
                  <Label>Updated</Label>
                  {format(parseISO(`${this.props.booking.updated!}Z`), 'MMM dd, yyyy HH:mm')}
                </div>
                <div>
                  <Label>Source</Label>
                  {sourceName}
                </div>
              </div>
            )}
          </EditBookingInfoHeader>
        )}
        <div className="db-content">
          <div className="left-side">
            {this.additionalInfoJsx()}
            <div className="db-group-inline db-booking-info-container">
              <div className="db-item db-item-arrival">
                <Label>Arrival</Label>
                <Datepicker
                  value={moment(this.props.booking.start)}
                  timeIntervals={15}
                  onChange={(d) =>
                    this.handleInputChangeRefetchTables(
                      InputFields.Start,
                      moment(d.format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm'),
                    )
                  }
                />
              </div>

              <div>
                <Label>Time</Label>
                <Select
                  id="db-time"
                  value={this.props.booking.start.format('HH:mm')}
                  options={this.state.timeOptions.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  onChange={(dur) =>
                    this.handleInputChangeRefetchTables(InputFields.Time, dur.value)
                  }
                  icon={<i className="material-icons">access_time</i>}
                />
              </div>

              <div>
                <Label>Duration</Label>
                <Select
                  id="durationList"
                  value={this.props.booking.duration}
                  options={this.state.durationOptions}
                  onChange={(dur) =>
                    this.handleInputChangeRefetchTables(InputFields.Duration, dur.value)
                  }
                  icon={<i className="material-icons">timelapse</i>}
                />
              </div>

              <div>
                <Label>Guests</Label>
                <Select
                  id="guests"
                  onChange={(guests) =>
                    this.handleInputChangeRefetchTables(InputFields.Guests, guests.value)
                  }
                  value={this.props.booking.guests}
                  options={this.state.guestsOptions}
                  icon={<i className="material-icons">people_outline</i>}
                />
              </div>
            </div>

            <TableContainer isWaitingList={this.props.booking.statusId === 12}>
              <div className="waiting-list-overlay">
                <Button
                  id="waiting-list-to-booking"
                  onClick={() =>
                    this.props.updateBooking({
                      ...this.props.booking,
                      statusId: 2,
                    })
                  }
                >
                  Move from waiting list
                </Button>
              </div>
              <Label>
                Tables (min guests: {selectedMinGuests}, max guests: {selectedMaxGuests})
              </Label>
              <div>
                {this.props.allTables.map((t) => {
                  const isSuggested = suggestedTableIds.includes(t.id);
                  const isSelected = this.props.selectedTableIds.some((x) => x === t.id);

                  let title = t.name;
                  if (!t.isAvailable) {
                    title = 'Unavailable table';
                  } else if (isSuggested && !isSelected) {
                    title = 'Suggested table';
                  }

                  return (
                    <TableButton
                      isAvailable={t.isAvailable}
                      key={`box-${t.id}`}
                      isActive={isSelected}
                      onClick={() => this.toggleTable(t.id)}
                      isSuggested={isSuggested}
                      title={title}
                    >
                      {t.name}
                    </TableButton>
                  );
                })}

                <div>
                  {/* Select all tables */}
                  <BtnSelectTable
                    key={`box-select-all`}
                    isActive={allTablesSelected}
                    onClick={() => {
                      const newSelectedTableIds = allTablesSelected
                        ? []
                        : this.props.allTables.filter((x) => x.isAvailable).map((x) => x.id);
                      this.props.onTableClick(newSelectedTableIds);
                    }}
                    title="Select all available tables"
                  >
                    {allTablesSelected ? 'Deselect all available' : 'Select all available'}
                  </BtnSelectTable>

                  {/* Select suggested tables */}
                  <BtnSelectTable
                    key={`box-select-suggested`}
                    isDisabled={!hasSuggestions}
                    isActive={
                      suggestedTableIds.sort().toString() ===
                      this.props.selectedTableIds.sort().toString()
                    }
                    onClick={() => {
                      if (hasSuggestions) {
                        this.props.onTableClick(
                          allSuggestedTablesSelected ? [] : suggestedTableIds,
                        );
                      }
                    }}
                    title="Select suggested tables"
                  >
                    {allSuggestedTablesSelected && hasSuggestions
                      ? 'Deselect suggested'
                      : 'Select suggested'}
                  </BtnSelectTable>
                </div>
              </div>
            </TableContainer>

            {!this.props.isWalkIn && (
              <div className="db-customer-info-container">
                <div className="db-group-inline">
                  <div>
                    <Label>Name {this.state.preferences.isBookingNameMandatory && '*'}</Label>
                    <Input
                      id="booking-name"
                      value={this.props.booking.bookingName}
                      placeholder={
                        this.state.preferences.isBookingNameMandatory ? 'Name (required)' : 'Name'
                      }
                      onChange={(x) => this.handleInputChange(InputFields.BookingName, x)}
                      isRequired={this.state.preferences.isBookingNameMandatory}
                      isValid={
                        this.state.preferences.isBookingNameMandatory
                          ? !!(
                              this.props.booking.bookingName &&
                              this.props.booking.bookingName.length > 1
                            )
                          : true
                      }
                    />
                  </div>

                  <div>
                    <Label>Employee {this.state.preferences.isBookedByNameMandatory && '*'}</Label>
                    <Input
                      id="booking-emplyee-name"
                      value={this.props.booking.bookedByName}
                      placeholder={
                        this.state.preferences.isBookedByNameMandatory
                          ? 'Employee (required)'
                          : 'Employee'
                      }
                      onChange={(x) => this.handleInputChange(InputFields.BookedByName, x)}
                      isRequired={this.state.preferences.isBookedByNameMandatory}
                      isValid={
                        this.state.preferences.isBookedByNameMandatory
                          ? !!(
                              this.props.booking.bookedByName &&
                              this.props.booking.bookedByName.length > 1
                            )
                          : true
                      }
                    />
                  </div>
                </div>

                <div className="db-group-inline">
                  <div>
                    <Label
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      Mobile
                      {this.props.viewMode === BookingViewMode.New && (
                        <CheckboxNew
                          id="booking-should-send-sms"
                          onChange={() => this.props.updateShouldSendSMS(!this.props.shouldSendSMS)}
                          checked={this.props.shouldSendSMS}
                          disabled={this.props.booking.statusId === 12}
                          title="Send SMS"
                          tabIndex={-1}
                        />
                      )}
                    </Label>
                    <Input
                      id="booking-mobile"
                      value={this.props.booking.bookingPhone}
                      placeholder="Mobile"
                      type="tel"
                      onChange={(x) => this.handleInputChange(InputFields.BookingPhone, x)}
                      defaultCountry={
                        this.props.restaurantCountry === 'nb' ? 'no' : this.props.restaurantCountry
                      }
                    />
                  </div>

                  <div>
                    <Label
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      Email
                      {this.props.viewMode === BookingViewMode.New && (
                        <CheckboxNew
                          id="booking-should-send-email"
                          onChange={() =>
                            this.props.updateShouldSendEmail(!this.props.shouldSendEmail)
                          }
                          checked={this.props.shouldSendEmail}
                          disabled={this.props.booking.statusId === 12}
                          title="Send email"
                          tabIndex={-1}
                        />
                      )}
                    </Label>
                    <Input
                      id="booking-email"
                      value={this.props.booking.bookingEmail}
                      placeholder="Email"
                      type="email"
                      onChange={(x) => this.handleInputChange(InputFields.BookingEmail, x)}
                      icon={<i className="material-icons">alternate_email</i>}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="db-group-inline db-group-payments">
              <div>
                {this.props.viewMode === BookingViewMode.New &&
                  !this.props.isWalkIn &&
                  (this.state.showPaymentExpires &&
                  this.props.paymentLinkMinutes !== null &&
                  this.props.hasPaymentKey ? (
                    <div>
                      <div className="db-header">
                        Payment link
                        <button
                          onClick={() =>
                            this.setState(
                              {
                                showPaymentExpires: false,
                              },
                              () => this.props.updatePaymentLinkMinutes(null),
                            )
                          }
                        >
                          Remove
                        </button>
                      </div>
                      <PaymentLinkForm
                        paymentLinkMinutes={this.props.paymentLinkMinutes}
                        updatePaymentLinkMinutes={this.props.updatePaymentLinkMinutes}
                        paymentLinkDeliveryType={this.props.paymentLinkSendType}
                        updatePaymentLinkDeliveryType={this.props.updatePaymentLinkSendType}
                        paymentLinkTypeOptions={[
                          { value: PaymentType.NoShow, label: 'No show' },
                          {
                            value: PaymentType.PrePayment,
                            label: 'Pre payment',
                          },
                        ]}
                        paymentLinkType={this.props.paymentLinkType}
                        updatePaymentLinkType={this.props.updatePaymentLinkType}
                        feePerGuest={this.props.feePerGuest}
                        updateFeePerGuest={this.props.updateFeePerGuest}
                      />
                    </div>
                  ) : (
                    <AddLink
                      disabled={!this.props.hasPaymentKey}
                      onClick={() => {
                        if (this.props.hasPaymentKey) {
                          this.setState(
                            {
                              showPaymentExpires: true,
                            },
                            () => this.props.updatePaymentLinkMinutes(60 * 24),
                          );
                        }
                      }}
                    >
                      <i className="material-icons">add_circle_outline</i>Send payment link
                    </AddLink>
                  ))}
              </div>
            </div>
          </div>
          <div className="right-side">
            <div>
              <div className="db-header">Guest notes</div>
              <textarea
                placeholder="Write guest notes here"
                value={this.props.booking.bookingSpecialRequest}
                onChange={(x: any) =>
                  this.handleInputChange(InputFields.BookingSpecialRequest, x.target.value)
                }
              />
            </div>
            {!this.props.isWalkIn && (
              <div>
                <div className="db-header">Internal notes</div>
                <textarea
                  placeholder="Write internal notes here"
                  value={this.props.booking.bookingInternalNotes}
                  onChange={(x: any) =>
                    this.handleInputChange(InputFields.BookingInternalNotes, x.target.value)
                  }
                />
              </div>
            )}
          </div>
        </div>
      </DetailedBookingStyle>
    );
  }
}
