import moment from 'moment-timezone';
import * as Moment from 'moment-timezone';
import queryString from 'query-string';
import * as React from 'react';
import styled, { css } from 'styled-components';

import Button from '../../components/common/Button';
import GroupButtons from '../../components/GroupButtons.styled';
import StatusList from '../../components/StatusList';
import Utils, { getAllStatuses } from '../../utils';
import SocketUtils from '../../utils/socket';
import List from './List';
import ReservationCalendar from './ReservationCalendar';
import Timeline from './Timeline';

import axios, { CancelTokenSource } from 'axios';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DashboardAPI, { DashboardData, IBookingDashboard } from '../../api/dashboard.api';
import FloorPlanAPI from '../../api/floorplan.api';
import RestaurantAPI from '../../api/restaurant.api';
import Datepicker from '../../components/Datepicker';
import BookingStatusType from '../../enums/BookingStatusType';
import { PaymentType } from '../../enums/Payment';
import { IRootStore } from '../../stores/RootStore';
import { FlashMessageType, ModalConductor } from '../../stores/UIStore';
import { UserRole } from '../../stores/UserStore';
import theme from '../../styles/theme';
import { filterBookingsByTimeRange, getTimeRange } from '../../utils/booking.utils';
import GuestsNowCounter from './GuestsNowCounter';
import ListCollapsable from './ListCollapsable';
import ListOverview from './ListOverview';
import Settings from './Settings';

type STimelineItemJsxProps = { statusId: number; bgColor: string };

const TimelineItemJsx = styled.div`
  position: relative;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 1px 2px 1px 4px;
  color: ${(props: { color: string }) => props.color};
  font-size: 11px;
  overflow: hidden;

  ${(props: { disabled: boolean }) =>
    props.disabled &&
    css`
      pointer-events: none;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      > * {
        display: none !important;
      }
    `};

  &:hover {
    cursor: pointer;
  }

  .text-container {
    overflow: hidden;
    white-space: nowrap;
    line-height: 14px;
  }

  .icons {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 14px;
    display: flex;
    align-items: center;
    padding-left: 4px;
    background-color: ${(props: STimelineItemJsxProps) => props.bgColor};

    > *:not(:last-child) {
      margin-right: 3px;
    }

    i {
      font-size: 15px;
    }
    .icon-web,
    .icon-info {
      font-size: 15px;
    }
  }
`;

const DashboardLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: 12px;

  /* Left part */
  .dashboard-sidebar {
    position: relative;
    display: flex;
    width: 280px;
    margin-right: 24px;
    padding-top: 16px;
    flex-direction: column;
    box-shadow: 4px 6px 25px rgba(132, 132, 132, 0.1);
    border-right: 1px solid #ebebeb;

    @media screen and (max-width: 812px) and (orientation: landscape) {
      display: none;
    }

    .dashboard-sidebar-top {
      flex-grow: 1;
      padding-bottom: 40px;

      .datepicker {
        text-align: center;
        .react-datepicker {
          border: 1px solid ${theme.colors.neutral2};
        }
      }
      .sums {
        display: flex;
        justify-content: space-between;
        padding: 8px;
        font-size: 12px;
        font-weight: 400;
        & > div:first-child {
          margin-right: 8px;
        }

        .dashboard-waiting-list-link {
          &.is-active a::before {
            background-color: ${theme.colors.success};
          }

          a {
            display: flex;
            align-items: center;

            &::before {
              content: '';
              display: block;
              width: 8px;
              height: 8px;
              margin-right: 4px;
              border-radius: 50%;
              background-color: transparent;
            }
          }
        }
      }
      .btn-new-booking-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 16px;

        .btn-new-booking {
          width: ${(100 / 3) * 2}%;
          background: none;
          background-color: ${theme.colors.success};
        }
      }
    }

    .dashboard-sidebar-toolbar {
      display: none;

      position: sticky;
      left: 0px;
      bottom: 0px;
      height: 40px;
      width: 280px;

      align-items: center;
      justify-content: space-between;

      border-top: 1px solid #ebebeb;
      box-shadow: 0px -6px 25px rgba(255, 255, 255, 0.9);
      background-color: #fff;

      @media screen and (min-width: 768px) {
        display: flex;
      }

      > div {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #ebebeb;
          cursor: pointer;
          transition: background-color 111ms linear;
        }

        &.active {
          background-color: ${theme.colors.brand1};
          color: #fff;
        }

        &:nth-child(even) {
          border-left: 1px solid #ebebeb;
          border-right: 1px solid #ebebeb;
        }

        &:last-child {
          border-right: 0px;
        }

        > div {
          text-align: center;

          &.toolbar-item-has-value {
            > i {
              display: flex;
              justify-content: center;

              &::after {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                margin-right: -8px;
                background-color: ${theme.colors.success};
                border-radius: 50%;
              }
            }
          }

          > i {
            display: block;
            font-size: 17px;
          }
          > div {
            display: block;
            margin-top: 4px;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 9px;
          }
        }
      }
    }
  }

  /* Right part */
  > div:last-child {
    padding-top: 16px;
    padding-right: 16px;
    margin-bottom: 32px;

    .dashboard-settings-container {
      display: flex;
      .btn-reservation-view-mode {
        height: 42px;
      }
      .settings-dropdowns {
        margin-right: 8px;
        .Select-control {
          height: 42px;
        }
      }
      .time-range-container {
        margin-left: auto; /* Pull to the right */
      }
    }

    .status-color-list {
      margin-top: 4em;
    }

    .time-range-container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 29px;

      > div > div:first-child {
        margin-bottom: 0px;
      }

      .summary {
        display: flex;
        padding: 8px;
        justify-content: space-around;
        background-color: #fafafa;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    @media screen and (max-width: 414px) and (orientation: portrait) {
      display: none;
    }
  }

  @media screen and (max-width: 414px) and (orientation: portrait) {
    /* Portrait iPhone 8 Plus */
    grid-template-columns: 1fr;
    > div:first-child {
      width: 100%;
      box-shadow: none;
      padding-right: 0px;
      margin-right: 0px;
    }
  }
  @media screen and (max-width: 812px) and (orientation: landscape) {
    /* Landscape iPhone X */
    grid-template-columns: 1fr;

    .settings-dropdowns {
      display: none;
    }
  }
`;

const SocketMessage = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  font-size: 12px;
  text-align: center;
  color: #333;
  line-height: 16px;
  box-shadow: 0 0 16px 0px gainsboro;
  background-color: #70db7b;
  z-index: 9999;
  opacity: 0;
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  &:hover {
    cursor: pointer;
  }

  i {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation-delay: 1s;
    transform: translate3d(0, 0, 0);
    margin-right: 8px;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes fadeInUp {
    from {
      bottom: -50px;
    }
    to {
      bottom: 0px;
      opacity: 1;
    }
  }
`;

const RestaurantNote = styled.div`
  margin: 8px;
  padding: 8px;
  border-radius: 3px;
  background-color: rgba(3, 169, 244, 0.1);
  border: 1px solid rgba(3, 169, 244, 0.3);
  margin-bottom: 16px;
  white-space: break-spaces;
`;

type BookingUpdateProperty = 'statusId' | 'paymentDate' | 'booking';

enum TimeRange {
  All = 'All',
  Lunch = 'Lunch',
  Dinner = 'Dinner',
}

type TimelineItem = {
  id: string;
  start: Date;
  end: Date;
  bgColor: string;
  groupId: number;
  content: any;
};

enum ViewMode {
  Timeline = 'Timeline',
  List = 'List',
  Calendar = 'Calendar',
}

interface IProps extends RouteComponentProps<any> {
  store?: IRootStore;
}

type State = {
  viewMode: ViewMode;
  timeRange: TimeRange;
  selectedDate: Moment.Moment;
  dashboardData: DashboardData | null;
  timelineItems: TimelineItem[];
  timelineGroups: Array<{ id: number; name: string }>;
  calendarData: {
    reservations: Array<{
      id: number;
      statusId: BookingStatusType;
      guests: number;
      date: moment.Moment;
      floorPlanName: string;
    }>;
    calendarDays: moment.Moment[];
    selectedDate: moment.Moment;
  } | null;
  isSocketMessage: boolean;
  restaurantNote: string | null;
  isCollapsableListOpen: boolean;
  hasOpeningHoursOverride: boolean;
};

@inject('store')
@observer
class Dashboard extends React.Component<IProps, State> {
  /**
   * Class properties
   */
  dashboardApi: DashboardAPI;
  restaurantApi: RestaurantAPI;
  floorPlanApi: FloorPlanAPI;
  isCancelled: boolean;
  dateFormat: string;
  hasOpeningHoursOverrideCancelToken: CancelTokenSource | null;

  constructor(props: IProps) {
    super(props);

    this.dateFormat = 'YYYY-MM-DD';

    const { viewMode, date } = this.getUrlParams(props.history.location.search);

    this.state = {
      viewMode,
      timeRange: TimeRange.All,
      selectedDate: date,
      dashboardData: null,
      timelineItems: [],
      timelineGroups: [],
      calendarData: null,
      isSocketMessage: false,
      restaurantNote: null,
      isCollapsableListOpen: false,
      hasOpeningHoursOverride: false,
    };

    this.dashboardApi = new DashboardAPI();
    this.restaurantApi = new RestaurantAPI();
    this.floorPlanApi = new FloorPlanAPI();
    this.isCancelled = false;
    this.hasOpeningHoursOverrideCancelToken = null;
  }

  componentDidMount() {
    this.fetchTimelineOrCalendarData();
    this.fetchRestaurantNote();
    this.fetchHasOpeningHoursOverride();

    SocketUtils.subscribe('ReceiveNotification', () => {
      this.setState({
        isSocketMessage: true,
      });
    });
  }

  componentDidUpdate(prevProps: IProps, prevState: State) {
    const { date, viewMode, refetchData } = this.getUrlParams(this.props.history.location.search);
    const newDate = moment(date, this.dateFormat);

    // If route doesn't have parameters, we set them
    if (this.props.history.location.search === '') {
      this.props.history.replace({
        pathname: '/',
        search: `?date=${newDate.format(this.dateFormat)}&viewMode=${ViewMode.Timeline}`,
      });
    }

    // If url param changed, then we fetch new data
    if (!newDate.isSame(this.state.selectedDate, 'date') || refetchData) {
      // Remove refetched from url parameter to prevent endless loop of refetching
      this.props.history.replace({
        pathname: '/',
        search: `?date=${newDate.format(this.dateFormat)}&viewMode=${viewMode}`,
      });

      this.setState(
        {
          selectedDate: newDate,
          viewMode,
          hasOpeningHoursOverride: false,
        },
        () => {
          this.fetchTimelineOrCalendarData();
          this.fetchRestaurantNote();
          this.fetchHasOpeningHoursOverride();
        },
      );
    } else if (prevState.viewMode !== ViewMode.Calendar && viewMode === ViewMode.Calendar) {
      // If not changing the date, but changing from/to calendar view then we fetch calendar data
      this.setState({
        viewMode,
      });
      this.fetchCalendarData();
    } else if (prevState.viewMode !== viewMode) {
      this.setState({
        viewMode,
      });
    }
  }

  fetchHasOpeningHoursOverride() {
    if (this.hasOpeningHoursOverrideCancelToken) {
      this.hasOpeningHoursOverrideCancelToken.cancel();
    }

    this.hasOpeningHoursOverrideCancelToken = axios.CancelToken.source();

    this.dashboardApi
      .hasOpeningHoursOverride(
        this.state.selectedDate.format(moment.defaultFormatUtc),
        this.hasOpeningHoursOverrideCancelToken,
      )
      .then((res) => {
        this.setState({
          hasOpeningHoursOverride: res.data,
        });
      })
      .catch(() => {
        // Do nothing
      });
  }

  getUrlParams(search: string): {
    date: Moment.Moment;
    viewMode: ViewMode;
    refetchData: boolean;
  } {
    let selectedDate = new Utils().roundUpQuarter(moment());
    let viewMode: ViewMode = ViewMode.Timeline;
    let refetchData = false;

    const query = queryString.parse(search);
    if (query) {
      if (Object.getOwnPropertyDescriptor(query, 'viewMode')) {
        viewMode = query.viewMode as ViewMode;
      }
      if (Object.getOwnPropertyDescriptor(query, 'date')) {
        selectedDate = moment(query.date, this.dateFormat);
      }
      if (Object.getOwnPropertyDescriptor(query, 'refetchData')) {
        refetchData = !!query.refetchData;
      }
    }

    return {
      date: selectedDate,
      viewMode,
      refetchData,
    };
  }

  getDaysInCalendar() {
    const { selectedDate } = this.state;
    let firstSundayOfCal = selectedDate.clone().startOf('month');
    firstSundayOfCal =
      firstSundayOfCal.format('ddd') === 'Sun'
        ? firstSundayOfCal
        : selectedDate.clone().startOf('month').isoWeekday(0);
    const lastDayOfCal = selectedDate.clone().endOf('month').endOf('week');

    const daysInMonth = [firstSundayOfCal];
    const dateIterator = firstSundayOfCal.clone();
    while (dateIterator.add(1, 'day').diff(lastDayOfCal) < 0) {
      daysInMonth.push(dateIterator.clone());
    }
    return daysInMonth;
  }

  generateTimelineItems(bookings: IBookingDashboard[]): TimelineItem[] {
    const utils = new Utils();
    const statusColors = getAllStatuses();
    const timelineItems: any[] = [];

    bookings
      .filter((x) => x.statusId !== BookingStatusType.Waiting)
      .forEach((x: IBookingDashboard) => {
        if (x.tables && x.tables.length > 0) {
          const status = statusColors.find((s) => s.id === x.statusId);
          const tableNames = x.tables.map((t) => t.name).join(', ');
          const bookingName = x.bookingName !== '' ? x.bookingName : '(no name)';

          const startDate = new Date(`${x.start}Z`);
          const startHours =
            startDate.getUTCHours() < 10 ? `0${startDate.getUTCHours()}` : startDate.getUTCHours();
          const startMinutes =
            startDate.getUTCMinutes() < 10
              ? `0${startDate.getUTCMinutes()}`
              : startDate.getUTCMinutes();

          // Guests notes and internal notes
          let notes = '';
          if (x.bookingSpecialRequest) {
            notes += 'Guest notes: ';
            notes +=
              x.bookingSpecialRequest.length > 100
                ? `${x.bookingSpecialRequest.substr(0, 100)}...`
                : x.bookingSpecialRequest;
            notes += '. ';
          }

          if (x.bookingInternalNotes) {
            notes += 'Internal notes: ';
            notes +=
              x.bookingInternalNotes.length > 100
                ? `${x.bookingInternalNotes.substr(0, 100)}...`
                : x.bookingInternalNotes;
          }

          const tooltip = `[${
            status ? status.name : ''
          }] ${bookingName} at ${startHours}:${startMinutes} for ${utils
            .numberToWord(x.guests)
            .toLowerCase()} - Tables: ${tableNames}`;

          x.tables.forEach((t) => {
            const noShowPayment =
              x.payments && x.payments.find((p) => p.type === PaymentType.NoShow);
            const prePaymentPayment =
              x.payments && x.payments.find((p) => p.type === PaymentType.PrePayment);

            const item: TimelineItem = {
              id: x.id.toString(),
              start: startDate,
              end: new Date(`${x.end}Z`),
              groupId: t.floorPlanTableId,
              bgColor: status ? status.color : '#CCC',
              content: (
                <TimelineItemJsx
                  title={tooltip}
                  disabled={
                    x.sourceId !== 1 &&
                    x.statusId === BookingStatusType.Hold &&
                    !x.paymentLinkSentDate
                  }
                  bgColor={status ? status.color : '#CCC'}
                  color={status ? status.contrastColor : '#FFF'}
                >
                  <div className="text-container">{bookingName}</div>
                  <div className="text-container">
                    {`${startHours}:${startMinutes}`} for {x.guests} guests
                  </div>
                  <div className="icons">
                    {x.statusId === 1 && (
                      <span title="Hold">
                        <i className="icon-block material-icons">block</i>
                      </span>
                    )}
                    {x.tables.length > 1 && (
                      <span title="Combo booking">
                        <i className="material-icons">link</i>
                      </span>
                    )}
                    {(x.sourceId === 2 || x.sourceId === 5 || x.sourceId === 3) && (
                      <span title="Online booking">
                        <i className="icon-web material-icons">language</i>
                      </span>
                    )}
                    {(x.sourceId === 4 || x.sourceId === 7) && (
                      <span title="Hotel booking">
                        <i className="material-icons">domain</i>
                      </span>
                    )}
                    {notes && notes !== '' && (
                      <span title={notes}>
                        <i className="icon-info material-icons">info_outline</i>
                      </span>
                    )}
                    {noShowPayment && noShowPayment.canBeCharged && (
                      <span title="Card registered">
                        <i className="material-icons">credit_card</i>
                      </span>
                    )}
                    {x.hasMenu && (
                      <span title={x.menuName || ''}>
                        <i className="material-icons">restaurant_menu</i>
                      </span>
                    )}
                    {x.hasEvent && (
                      <span title={x.eventName || ''}>
                        <i className="material-icons">event_note</i>
                      </span>
                    )}
                    {noShowPayment && noShowPayment.isPaid && (
                      <span
                        title={`No-Show paid ${moment(
                          noShowPayment.paymentDate!,
                          moment.defaultFormatUtc,
                        ).format('MM/DD/YYYY')}`}
                      >
                        <i className="material-icons">attach_money</i>
                      </span>
                    )}
                    {prePaymentPayment && prePaymentPayment.isPaid && (
                      <span
                        title={`Prepaid ${moment(
                          prePaymentPayment.paymentDate!,
                          moment.defaultFormatUtc,
                        ).format('MM/DD/YYYY')}`}
                      >
                        <i className="material-icons">attach_money</i>
                      </span>
                    )}
                  </div>
                </TimelineItemJsx>
              ),
            };
            timelineItems.push(item);
          });
        }
      });

    return timelineItems;
  }

  fetchRestaurantNote() {
    this.dashboardApi.getRestaurantNote(this.state.selectedDate).then((res) => {
      const { data } = res;
      const note = data ? data.note : '';
      this.setState({
        restaurantNote: note,
      });
    });
  }

  fetchTimelineData() {
    const { selectedDate } = this.state;

    this.setState(
      {
        dashboardData: {
          bookings: [],
          floorPlanTables: this.state.dashboardData ? this.state.dashboardData.floorPlanTables : [],
        },
      },
      () => {
        this.dashboardApi
          .getTimelineData(selectedDate)
          .then((response) => {
            const { bookings, floorPlanTables } = response.data;

            const dashboardData: DashboardData = {
              bookings,
              floorPlanTables,
            };

            const timelineItems: TimelineItem[] = this.generateTimelineItems(bookings);
            const timelineGroups = floorPlanTables.map((x) => ({
              id: x.id,
              name: x.name,
            }));

            this.setState(
              {
                dashboardData,
                timelineItems,
                timelineGroups,
              },
              () => {
                this.dashboardApi
                  .getDashboardMeta(selectedDate)
                  .then((res) => {
                    const { data: bookingsData } = res;
                    const bkns = [...this.state.dashboardData!.bookings];
                    const newBookings = bkns.map((x) => {
                      const data = bookingsData.find((b: any) => b.id === x.id);

                      if (!data) {
                        return x;
                      }

                      return {
                        ...x,
                        payments:
                          this.props.store!.RestaurantStore.user!.role === UserRole.Staff
                            ? []
                            : data.payments,
                        hasMenu: data.hasMenu,
                        hasEvent: data.hasEvent,
                        eventName: data.eventName,
                        menuName: data.menuName,
                      };
                    });
                    this.setState({
                      dashboardData: {
                        bookings: newBookings,
                        floorPlanTables: this.state.dashboardData
                          ? this.state.dashboardData.floorPlanTables
                          : [],
                      },
                      timelineItems: this.generateTimelineItems(newBookings),
                    });
                  })
                  .catch(() => {
                    this.props.store!.UIStore.addFlashMessage({
                      type: FlashMessageType.Error,
                      text: 'Error fetching no show payments',
                      timeout: 5000,
                    });
                  });
              },
            );
          })
          .catch(() => {
            this.props.store!.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Error fetching timeline data',
              timeout: 5000,
            });
          });
      },
    );
  }

  fetchCalendarData() {
    const calendarDays = this.getDaysInCalendar();
    const dateFrom = calendarDays[0].format(this.dateFormat);
    const dateTo = calendarDays[calendarDays.length - 1].format(this.dateFormat);

    this.dashboardApi.getCalendarReservations(dateFrom, dateTo).then((response) => {
      const reservations = response.data.map((item) => {
        return {
          id: item.id,
          statusId: item.statusId,
          guests: item.guests,
          floorPlanName: item.floorPlanName,
          date: moment(item.start),
        };
      });

      const calendarData = {
        reservations,
        calendarDays,
        selectedDate: this.state.selectedDate,
      };
      this.setState({ calendarData });
    });
  }

  fetchTimelineOrCalendarData() {
    const { viewMode } = this.state;
    if (viewMode === ViewMode.Timeline || viewMode === ViewMode.List) {
      this.fetchTimelineData();
    } else if (viewMode === ViewMode.Calendar) {
      this.fetchCalendarData();
      this.fetchTimelineData();
    }
  }

  changeDateAndRefetch(selectedDate: Moment.Moment) {
    this.props.history.push({
      pathname: '/',
      search: `?date=${selectedDate.format(this.dateFormat)}&viewMode=${
        this.state.viewMode
      }&refetchData=true`,
    });
  }

  changeView(viewMode: ViewMode) {
    this.props.history.push({
      pathname: '/',
      search: `?date=${this.state.selectedDate.format(this.dateFormat)}&viewMode=${viewMode}`,
    });

    if (viewMode === ViewMode.Calendar && viewMode !== this.state.viewMode) {
      this.fetchCalendarData();
    }

    this.setState({ viewMode });
  }

  changeDateAndSwitchToList(selectedDate: Moment.Moment) {
    this.props.history.push({
      pathname: '/',
      search: `?date=${selectedDate.format(this.dateFormat)}&viewMode=${ViewMode.List}`,
    });

    this.setState({
      viewMode: ViewMode.List,
    });
  }

  onBookingUpdated(bookingId: number, newValue: any, type: BookingUpdateProperty) {
    if (type === 'booking') {
      const newDate = newValue as Moment.Moment;
      this.changeDateAndRefetch(newDate);
    } else {
      const dashboardData = Object.assign({}, this.state.dashboardData);
      const bookings = [...dashboardData.bookings];
      const newBookings = bookings.map((x) => {
        const tempBooking = { ...x };
        if (tempBooking.id === bookingId) {
          if (type === 'statusId') {
            const newStatusAndEnd = newValue as {
              statusId: number;
              bookingEnd: string;
            };
            tempBooking.end = newStatusAndEnd.bookingEnd;
            tempBooking.statusId = newStatusAndEnd.statusId;
          } else if (type === 'paymentDate') {
            // Update no show payment date
            const payments =
              tempBooking.payments &&
              tempBooking.payments.map((p) => {
                if (p.type === PaymentType.NoShow) {
                  p.paymentDate = newValue;
                  p.isPaid = true;
                  p.canBeCharged = false;
                }

                return p;
              });
            tempBooking.payments = payments || [];
          }
        }
        return tempBooking;
      });

      this.setState({
        dashboardData: {
          floorPlanTables: this.state.dashboardData ? this.state.dashboardData.floorPlanTables : [],
          bookings: newBookings,
        },
        timelineItems: this.generateTimelineItems(newBookings),
      });
    }
  }

  onTimelineChange(data: any): Promise<boolean> {
    return new Promise((resolve) => {
      const bookingId = parseInt(data.id, 10);
      const startTime = data.startTime as Date;
      const tableIds: number[] = Object.assign(
        [],
        data.groups.map((x: any) => x.id),
      );

      // Check which property changed: TIME or TABLE
      const oldBookingData = this.state.dashboardData!.bookings.find((x) => x.id === bookingId)!;
      const oldTableIds: number[] = Object.assign(
        [],
        oldBookingData.tables.map((t) => t.floorPlanTableId),
      )!;

      const sortPred = (a: number, b: number) => a - b;
      const haveTablesChanged =
        JSON.stringify(tableIds.sort(sortPred)) !== JSON.stringify(oldTableIds.sort(sortPred));
      const hasStartChanged = !moment(startTime).isSame(oldBookingData.start);

      let confirmMsg = '';
      if (haveTablesChanged && !hasStartChanged) {
        // Only tables have changed
        confirmMsg = 'You are changing a TABLE. Please confirm to continue.';
      } else if (hasStartChanged && !haveTablesChanged) {
        // Only time has changed
        confirmMsg = 'You are changing the TIME. Please confirm to continue.';
      } else {
        // Both TIME and TABLES have changed
        confirmMsg = 'You are changing both the TIME and a TABLE. Please confirm to continue.';
      }

      // Ask user to confirm changes
      if (confirm(confirmMsg)) {
        this.dashboardApi
          .updateBookingFromTimeline(bookingId, startTime, tableIds)
          .then((res) => {
            const { dashboardData } = this.state;
            if (dashboardData) {
              const { id, newTableIds } = res.data.value;
              let { start, end } = res.data.value;
              const { bookings, floorPlanTables } = dashboardData;
              start = (start as string).replace('Z', '');
              end = (end as string).replace('Z', '');

              // Hax cuz api models are inconsistent
              const fpts = floorPlanTables.map((x) => ({
                floorPlanTableId: x.id,
                name: x.name,
              }));

              const updatedBookings = bookings.map((x) => {
                const b = Object.assign({}, x);
                if (b.id === id) {
                  b.start = start;
                  b.end = end;
                  b.tables = fpts.filter((t) => newTableIds.indexOf(t.floorPlanTableId) !== -1);
                }
                return b;
              });

              const updatedDashboardData: DashboardData = Object.assign({}, dashboardData);
              updatedDashboardData.bookings = updatedBookings;

              this.setState(
                {
                  timelineItems: this.generateTimelineItems(updatedBookings),
                  dashboardData: updatedDashboardData,
                },
                () => {
                  SocketUtils.invoke('BookingNotify');
                  resolve(true);
                },
              );
            }
          })
          .catch(() => {
            resolve(false);
            alert('Could not update booking');
          });
      } else {
        resolve(false);
      }
    });
  }

  filterOutHoldAndNoShowAndWaiting(bknsToFilter: IBookingDashboard[]): IBookingDashboard[] {
    return bknsToFilter.filter(
      (x) =>
        x.statusId !== BookingStatusType.NoShow &&
        x.statusId !== BookingStatusType.Waiting &&
        !(x.statusId === BookingStatusType.Hold && x.sourceId > 1),
    );
  }

  filterOutHoldAndNoShowAndWaitingAndCancelled(
    bknsToFilter: IBookingDashboard[],
  ): IBookingDashboard[] {
    return this.filterOutHoldAndNoShowAndWaiting(bknsToFilter).filter(
      (x) => x.statusId !== BookingStatusType.Cancelled,
    );
  }

  render() {
    const {
      viewMode,
      timeRange,
      dashboardData,
      calendarData,
      selectedDate,
      isSocketMessage,
      timelineItems,
      timelineGroups,
    } = this.state;

    const filteredBookings = filterBookingsByTimeRange(
      timeRange,
      this.state.selectedDate,
      this.state.dashboardData?.bookings || [],
    );

    // Filter out unfinished web bookings
    const allBookingsToday = dashboardData
      ? dashboardData.bookings.filter(
          (x) => !(x.statusId === BookingStatusType.Hold && x.sourceId > 1),
        )
      : [];
    const waitingListCount = allBookingsToday.filter(
      (x) =>
        x.statusId === BookingStatusType.Waiting &&
        moment(x.start, moment.defaultFormatUtc).isAfter(moment()),
    ).length;

    const { startTime, endTime } = getTimeRange(this.state.timeRange, this.state.selectedDate);

    return (
      <DashboardLayout>
        <div className="dashboard-sidebar">
          <div className="dashboard-sidebar-top">
            <div className="btn-new-booking-container">
              <Button
                id="dashboard-btn-new-booking"
                className="btn-new-booking"
                onClick={() =>
                  this.props.store!.UIStore.openModal({
                    type: ModalConductor.Type.Booking,
                    date: this.state.selectedDate,
                    onSave: (newDate: Moment.Moment) => {
                      this.changeDateAndRefetch(newDate);
                    },
                  })
                }
              >
                New Booking
              </Button>
            </div>

            <Datepicker
              value={selectedDate}
              onChange={(d) => this.changeDateAndRefetch(d)}
              onMonthChange={(d) =>
                viewMode === ViewMode.Calendar ? this.changeDateAndRefetch(d.startOf('month')) : {}
              }
              inline
            />

            {!this.state.dashboardData && <div>Loading...</div>}

            {this.state.dashboardData ? (
              <div>
                {this.state.restaurantNote && (
                  <RestaurantNote>{this.state.restaurantNote}</RestaurantNote>
                )}

                <GuestsNowCounter
                  bookings={this.state.dashboardData?.bookings ?? []}
                  selectedDate={this.state.selectedDate.toDate()}
                />

                <div className="sums">
                  <div>
                    Bookings:&nbsp;
                    {this.filterOutHoldAndNoShowAndWaitingAndCancelled(allBookingsToday).length}
                  </div>
                  <div>
                    Guests:&nbsp;
                    {this.filterOutHoldAndNoShowAndWaitingAndCancelled(allBookingsToday)
                      .map((x) => x.guests)
                      .reduce((a, b) => a + b, 0)}
                  </div>
                  <div
                    className={
                      'dashboard-waiting-list-link' + (waitingListCount > 0 ? ' is-active' : '')
                    }
                    title="Waiting list"
                  >
                    <a onClick={() => this.props.history.push('/waitinglist')}>
                      WL:&nbsp;{waitingListCount}
                    </a>
                  </div>
                </div>

                <ListOverview
                  bookings={this.filterOutHoldAndNoShowAndWaiting(allBookingsToday)}
                  onClick={(bookingId) => {
                    this.props.store!.UIStore.openModal({
                      type: ModalConductor.Type.Booking,
                      bookingId,
                      onSave: (newDate: Moment.Moment) => {
                        this.changeDateAndRefetch(newDate);
                      },
                    });
                  }}
                />
              </div>
            ) : null}
          </div>

          <div className="dashboard-sidebar-toolbar">
            <div
              onClick={() =>
                this.props.store!.UIStore.openModal({
                  type: ModalConductor.Type.OpeningHoursOverride,
                  date: this.state.selectedDate,
                  onSave: () => this.fetchHasOpeningHoursOverride(),
                })
              }
              title="Extend opening hours for this day"
            >
              <div className={this.state.hasOpeningHoursOverride ? 'toolbar-item-has-value' : ''}>
                <i className="material-icons">access_time</i>
                <div>Opening Hrs.</div>
              </div>
            </div>
            <div
              onClick={() =>
                this.props.store!.UIStore.openModal({
                  type: ModalConductor.Type.BookingPrintDate,
                  date: this.state.selectedDate,
                })
              }
              title="Print a list of bookings"
            >
              <div>
                <i className="material-icons">print</i>
                <div>Print</div>
              </div>
            </div>
            <div
              onClick={() =>
                this.props.store!.UIStore.openModal({
                  type: ModalConductor.Type.RestaurantNote,
                  date: this.state.selectedDate,
                  onSave: (newNote: string) => {
                    this.setState({ restaurantNote: newNote });
                    this.props.store!.UIStore.addFlashMessage({
                      type: FlashMessageType.Success,
                      text: 'Restaurant note updated',
                    });
                  },
                })
              }
              title="Restaurant note"
            >
              <div className={this.state.restaurantNote ? 'toolbar-item-has-value' : ''}>
                <i className="material-icons">note_add</i>
                <div>Note</div>
              </div>
            </div>
            <div
              onClick={() =>
                this.setState({
                  isCollapsableListOpen: !this.state.isCollapsableListOpen,
                })
              }
              className={this.state.isCollapsableListOpen ? 'active' : ''}
              title="Booking list grouped by time of day"
            >
              <div>
                <i className="material-icons icon ">chrome_reader_mode</i>
                <div>Group</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          {isSocketMessage && (
            <SocketMessage
              onClick={() => {
                this.setState(
                  {
                    isSocketMessage: false,
                  },
                  () => this.changeDateAndRefetch(moment().startOf('day')),
                );
              }}
            >
              <i className="material-icons">notifications_active</i>
              <div>Booking changes have been made. Click here to refresh dashboard.</div>
            </SocketMessage>
          )}
          <div>
            <div className="dashboard-settings-container">
              <GroupButtons tabIndex={0} buttonWidth={80} className="btn-reservation-view-mode">
                <div
                  className={viewMode === ViewMode.Timeline ? 'active' : ''}
                  onClick={() => this.changeView(ViewMode.Timeline)}
                  role="button"
                  tabIndex={0}
                >
                  Timeline
                </div>
                <div
                  className={viewMode === ViewMode.List ? 'active' : ''}
                  onClick={() => this.changeView(ViewMode.List)}
                  role="button"
                  tabIndex={0}
                >
                  List
                </div>
                <div
                  className={viewMode === ViewMode.Calendar ? 'active' : ''}
                  onClick={() => this.changeView(ViewMode.Calendar)}
                  role="button"
                  tabIndex={0}
                >
                  Calendar
                </div>
              </GroupButtons>
              <div style={{ width: 8 }} />
              <Settings
                selectedDate={this.state.selectedDate.toDate()}
              />
              <div className="time-range-container">
                <div>
                  <GroupButtons tabIndex={0} buttonWidth={80}>
                    <div
                      className={timeRange === TimeRange.All ? 'active' : ''}
                      onClick={() => this.setState({ timeRange: TimeRange.All })}
                      role="button"
                      tabIndex={0}
                    >
                      All
                    </div>
                    <div
                      className={timeRange === TimeRange.Lunch ? 'active' : ''}
                      onClick={() => this.setState({ timeRange: TimeRange.Lunch })}
                      role="button"
                      tabIndex={0}
                    >
                      Lunch
                    </div>
                    <div
                      className={timeRange === TimeRange.Dinner ? 'active' : ''}
                      onClick={() => this.setState({ timeRange: TimeRange.Dinner })}
                      role="button"
                      tabIndex={0}
                    >
                      Dinner
                    </div>
                  </GroupButtons>
                  <div className="summary">
                    <div>
                      Bookings:&nbsp;
                      {this.filterOutHoldAndNoShowAndWaitingAndCancelled(filteredBookings).length}
                    </div>
                    <div>
                      Guests:&nbsp;
                      {this.filterOutHoldAndNoShowAndWaitingAndCancelled(filteredBookings)
                        .map((x) => x.guests)
                        .reduce((a, b) => a + b, 0)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {viewMode === ViewMode.Timeline ? (
              <Timeline
                items={timelineItems}
                groups={timelineGroups}
                groupLabel="Tables"
                groupHeight={32}
                startTime={startTime}
                endTime={endTime}
                onChange={(data) => this.onTimelineChange(data)}
                onClick={(id) => {
                  if (!dashboardData) {
                    return;
                  }
                  const booking = dashboardData.bookings.find((x) => x.id === parseInt(id, 10));
                  if (booking) {
                    const isHoldFromWeb =
                      booking.sourceId > 1 &&
                      booking.statusId === 1 &&
                      !booking.paymentLinkSentDate;
                    if (!isHoldFromWeb) {
                      this.props.store!.UIStore.openModal({
                        type: ModalConductor.Type.Booking,
                        bookingId: booking.id,
                        onSave: (newDate: Moment.Moment) => {
                          this.changeDateAndRefetch(newDate);
                        },
                      });
                    }
                  }
                }}
                onHoverClick={(data) =>
                  this.props.store!.UIStore.openModal({
                    type: ModalConductor.Type.Booking,
                    date: this.state.selectedDate,
                    preselectedTableId: data.group.id,
                    preselectedTime: moment(data.time, 'YYYY-MM-DDTHH:mm'),
                    onSave: (newDate: Moment.Moment) => {
                      this.changeDateAndRefetch(newDate);
                    },
                  })
                }
              />
            ) : null}
            {viewMode === ViewMode.Calendar ? (
              <ReservationCalendar
                data={calendarData}
                changeDate={(date) => this.changeDateAndSwitchToList(date)}
              />
            ) : null}
            {viewMode === ViewMode.List ? (
              <List
                bookings={filteredBookings}
                viewMode={timeRange}
                onBookingUpdated={(bookingId, newStatusId, type) =>
                  this.onBookingUpdated(bookingId, newStatusId, type)
                }
              />
            ) : null}
            <StatusList />
          </div>
          {this.state.isCollapsableListOpen && (
            <ListCollapsable
              selectedDate={this.state.selectedDate}
              bookings={this.filterOutHoldAndNoShowAndWaitingAndCancelled(allBookingsToday)}
              onStatusUpdated={(bookingId, newStatusAndEnd) =>
                this.onBookingUpdated(bookingId, newStatusAndEnd, 'statusId')
              }
              onClick={(bookingId) => {
                this.props.store!.UIStore.openModal({
                  type: ModalConductor.Type.Booking,
                  bookingId,
                  onSave: (newDate: Moment.Moment) => {
                    this.changeDateAndRefetch(newDate);
                  },
                });
              }}
              onClose={() => this.setState({ isCollapsableListOpen: false })}
            />
          )}
        </div>
      </DashboardLayout>
    );
  }
}

export default withRouter(Dashboard);
