import axios, { CancelTokenSource } from 'axios';

const baseUrl = process.env.API_URL + '/api';

export default class API {
  _put(url: string, data: any, cancelToken?: CancelTokenSource) {
    const requestUrl = `${baseUrl}${url}/`;
    return axios
      .put(requestUrl, data, cancelToken ? { cancelToken: cancelToken.token } : {})
      .then((response) => response)
      .catch((error) => {
        console.log('Error ', error);
        throw error;
      });
  }

  _get(url: string, cancelToken?: CancelTokenSource) {
    let requestUrl = `${baseUrl}${url}`;
    if (!url.includes('?')) {
      requestUrl += '/';
    }

    return axios
      .get(requestUrl, cancelToken ? { cancelToken: cancelToken.token } : {})
      .then((response) => response)
      .catch((error) => {
        console.log('Error ', error);
        throw error;
      });
  }

  _post(url: string, data: any, cancelToken?: CancelTokenSource) {
    const requestUrl = `${baseUrl}${url}/`;

    return axios
      .post(requestUrl, data, cancelToken ? { cancelToken: cancelToken.token } : {})
      .then((response) => response)
      .catch((error) => {
        console.log('Error ', error);
        throw error;
      });
  }

  _delete(url: string, cancelToken?: CancelTokenSource) {
    const requestUrl = `${baseUrl}${url}/`;

    return axios
      .delete(requestUrl, cancelToken ? { cancelToken: cancelToken.token } : {})
      .then((response) => response)
      .catch((error) => {
        console.log('Error ', error);
        throw error;
      });
  }
}
