import Axios, { CancelTokenSource } from 'axios';
import moment from 'moment-timezone';
import * as Moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import {
  exportTicks,
  getAllRestaurantBillings,
  RestaurantBillingBookingType,
  RestaurantBillingModel,
  updateRestaurantBillings,
} from '../../api/restaurantbilling.api';
import CheckboxNew from '../../components/CheckboxNew';
import Button from '../../components/common/Button';
import Input from '../../components/common/Input';
import DateScroller from '../../components/DateScroller';
import SelectStyle from '../../components/Select.styled';

const Style = styled.div`
  padding: 16px;

  table {
    thead tr {
      background-color: #fff;
      z-index: 100;

      th {
        position: sticky;
        top: 0px;
        background-color: #fff;
        z-index: 100;
        text-align: left;
      }
    }

    tr.not-active {
      .to-hide {
        display: none;
      }
    }
  }
`;

let saveCt: CancelTokenSource | null = null;
let exportCt: CancelTokenSource | null = null;

const RestaurantBilling = () => {
  const [restaurants, setRestaurants] = useState<
    Array<RestaurantBillingModel & { restaurantName: string }>
  >([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Moment.Moment>(moment());
  const [smsGlobalPrice, setSMSGlobalPrice] = useState(0);
  const [bookingAmountGlobal, setBookingAmountGlobal] = useState(0);

  useEffect(() => {
    const ct = Axios.CancelToken.source();

    getAllRestaurantBillings(ct)
      .then((res) => {
        setRestaurants(res.data);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          alert('Error fetching billings');
        }
      });
  }, []);

  // Cleanup
  useEffect(() => {
    return () => {
      if (saveCt) {
        saveCt.cancel();
      }
      if (exportCt) {
        exportCt.cancel();
      }
    };
  }, []);

  const save = useCallback(() => {
    if (saveCt) {
      saveCt.cancel();
    }

    saveCt = Axios.CancelToken.source();

    const frmtd: RestaurantBillingModel[] = restaurants.map((r) => ({
      ...r,
      adHeroAmount: r.adHeroAmount || 0,
      adPromotedAmount: r.adPromotedAmount || 0,
      adROTMAmount: r.adROTMAmount || 0,
      adTopAmount: r.adTopAmount || 0,
      bookingAmount: r.bookingAmount || 0,
      smsAmount: r.smsAmount || 0,
      subscriptionAmount: r.subscriptionAmount || 0,
      ssn: r.ssn || '',

      orderCommissionTotal: r.orderUnpaidCommissionPct || 0,
      hardwareOneTimeFee: r.hardwareOneTimeFee || 0,
      hardwareMonthly: r.hardwareMonthly || 0,
      qrOneTimeFee: r.qrOneTimeFee || 0,
      websiteMonthly: r.websiteMonthly || 0,
      websiteOneTimeFee: r.websiteOneTimeFee || 0,
    }));

    updateRestaurantBillings(frmtd, saveCt)
      .then((res) => {
        res.data ? alert('Successfully saved') : alert('Could not save :(');
        setHasChanged(false);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          alert('Error saving.');
        }
      });
  }, [restaurants]);

  const exportTicksFunc = () => {
    if (exportCt) {
      exportCt.cancel();
    }

    exportCt = Axios.CancelToken.source();

    exportTicks(selectedDate, exportCt)
      .then((e) => {
        window.open(e.data);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          alert('Error exporting.');
        }
      });
  };

  return (
    <Style>
      <h1>Billing Settings</h1>
      <Button id="btn-save-billing" className="btn-cta" onClick={save}>
        Save
      </Button>
      <sub>Modify billing settings for each restaurant.</sub>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', marginBottom: 24 }}>
          <div style={{ width: 100, marginRight: 16 }}>
            <Button id="btn-export" onClick={exportTicksFunc} disabled={hasChanged}>
              Export
            </Button>
          </div>
          <DateScroller
            mode="month"
            selectedDate={selectedDate}
            onUpdate={(date) => setSelectedDate(date)}
          />
        </div>

        <div style={{ display: 'flex', marginBottom: 24 }}>
          <Input
            id="inp-sms-global"
            value={smsGlobalPrice.toString()}
            onChange={(val) => setSMSGlobalPrice(val)}
          />
          <div style={{ width: 100, marginLeft: 16 }}>
            <Button
              id="btn-apply-global"
              onClick={() => {
                if (confirm('This will reset all SMS amounts to selected value')) {
                  setRestaurants(
                    restaurants.map((x) => ({
                      ...x,
                      smsAmount: smsGlobalPrice,
                    })),
                  );
                }
              }}
            >
              Apply SMS price
            </Button>
          </div>
        </div>

        <div style={{ display: 'flex', marginBottom: 24 }}>
          <Input
            id="inp-booking-amount-global"
            value={bookingAmountGlobal.toString()}
            onChange={(val) => setBookingAmountGlobal(val)}
          />
          <div style={{ width: 100, marginLeft: 16 }}>
            <Button
              id="btn-apply-global-bkn"
              onClick={() => {
                if (confirm('This will reset all booking amounts to selected value')) {
                  setRestaurants(
                    restaurants.map((x) => ({
                      ...x,
                      bookingAmount: bookingAmountGlobal,
                    })),
                  );
                }
              }}
            >
              Apply booking price
            </Button>
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th style={{ width: '50px' }}>Active</th>
            <th>Restaurant</th>
            <th>Company</th>
            <th>SSN</th>
            <th>Subscription Amount</th>
            <th>Booking Type</th>
            <th>Booking Amount</th>
            <th>SMS Amount</th>
            <th>Ad Hero Amount</th>
            <th>Ad Of The Month Amount</th>
            <th>Ad Top 6 Amount</th>
            <th>Ad Promoted Amount</th>

            <th>Order % (unpaid)</th>
            <th>Hardware One Time</th>
            <th>Hardware Monthly</th>
            <th>QRCodes One Time</th>
            <th>Website Monthly</th>
            <th>Website One Time</th>
          </tr>
        </thead>
        <tbody>
          {restaurants
            .sort((a, b) => (a.restaurantName < b.restaurantName ? -1 : 1))
            .map((rest) => (
              <tr
                key={`rest-${rest.restaurantId}`}
                className={rest.isActive ? 'active' : 'not-active'}
              >
                <td style={{ width: '50px' }}>
                  <CheckboxNew
                    id={`inp-active-${rest.restaurantId}`}
                    checked={rest.isActive}
                    onChange={() => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        { ...rest, isActive: !rest.isActive },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>
                <td style={{ width: '180px' }}>{rest.restaurantName}</td>
                <td className="to-hide">
                  <Input
                    id={`inp-company-${rest.restaurantId}`}
                    value={rest.companyName}
                    placeholder="Company name"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        { ...rest, companyName: val },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>
                <td className="to-hide">
                  <Input
                    id={`inp-ssn-${rest.restaurantId}`}
                    value={rest.ssn}
                    placeholder="SSN"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        { ...rest, ssn: val },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>
                <td className="to-hide">
                  <Input
                    id={`inp-subscr-${rest.restaurantId}`}
                    value={rest.subscriptionAmount.toString()}
                    type="number"
                    placeholder="Subscription amount"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        {
                          ...rest,
                          subscriptionAmount: val ? parseInt(val, 10) : val,
                        },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>
                <td className="to-hide">
                  <SelectStyle style={{ width: '150px' }}>
                    <Select
                      value={rest.bookingType}
                      options={[
                        {
                          value: RestaurantBillingBookingType.PerBooking,
                          label: 'Per booking',
                        },
                        {
                          value: RestaurantBillingBookingType.PerGuest,
                          label: 'Per guest',
                        },
                      ]}
                      placeholder="Booking type"
                      clearable={false}
                      onChange={(val: any) => {
                        setRestaurants([
                          ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                          { ...rest, bookingType: parseInt(val.value, 10) },
                        ]);
                        setHasChanged(true);
                      }}
                    />
                  </SelectStyle>
                </td>
                <td className="to-hide">
                  <Input
                    id={`inp-bookingamount-${rest.restaurantId}`}
                    value={rest.bookingAmount.toString()}
                    type="number"
                    placeholder="Booking amount"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        {
                          ...rest,
                          bookingAmount: val ? parseInt(val, 10) : val,
                        },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>
                <td className="to-hide">
                  <Input
                    id={`inp-sms-${rest.restaurantId}`}
                    value={rest.smsAmount.toString()}
                    type="number"
                    placeholder="SMS amount"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        {
                          ...rest,
                          smsAmount: val ? parseInt(val, 10) : val,
                        },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>
                <td className="to-hide">
                  <Input
                    id={`inp-adhero-${rest.restaurantId}`}
                    value={rest.adHeroAmount.toString()}
                    type="number"
                    placeholder="Ad Hero Amount"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        {
                          ...rest,
                          adHeroAmount: val ? parseInt(val, 10) : val,
                        },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>
                <td className="to-hide">
                  <Input
                    id={`inp-adrotm-${rest.restaurantId}`}
                    value={rest.adROTMAmount.toString()}
                    type="number"
                    placeholder="Ad Of Month Amount"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        {
                          ...rest,
                          adROTMAmount: val ? parseInt(val, 10) : val,
                        },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>
                <td className="to-hide">
                  <Input
                    id={`inp-adtop-${rest.restaurantId}`}
                    value={rest.adTopAmount.toString()}
                    type="number"
                    placeholder="Ad Top 6 Amount"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        {
                          ...rest,
                          adTopAmount: val ? parseInt(val, 10) : val,
                        },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>
                <td className="to-hide">
                  <Input
                    id={`inp-promoted-${rest.restaurantId}`}
                    value={rest.adPromotedAmount.toString()}
                    type="number"
                    placeholder="Ad Promoted Amount"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        {
                          ...rest,
                          adPromotedAmount: val ? parseInt(val, 10) : val,
                        },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>

                <td className="to-hide">
                  <Input
                    id={`inp-order-pct-${rest.restaurantId}`}
                    value={rest.orderUnpaidCommissionPct.toString()}
                    type="number"
                    placeholder="Order % (unpaid)"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        {
                          ...rest,
                          orderUnpaidCommissionPct: val ? parseInt(val, 10) : val,
                        },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>

                <td className="to-hide">
                  <Input
                    id={`inp-hardware-one-time-${rest.restaurantId}`}
                    value={rest.hardwareOneTimeFee.toString()}
                    type="number"
                    placeholder="Hardware One Time"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        {
                          ...rest,
                          hardwareOneTimeFee: val ? parseInt(val, 10) : val,
                        },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>

                <td className="to-hide">
                  <Input
                    id={`inp-hardware-monthly-${rest.restaurantId}`}
                    value={rest.hardwareMonthly.toString()}
                    type="number"
                    placeholder="Hardware Monthly"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        {
                          ...rest,
                          hardwareMonthly: val ? parseInt(val, 10) : val,
                        },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>

                <td className="to-hide">
                  <Input
                    id={`inp-qr-one-time-${rest.restaurantId}`}
                    value={rest.qrOneTimeFee.toString()}
                    type="number"
                    placeholder="QR One Time Fee"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        {
                          ...rest,
                          qrOneTimeFee: val ? parseInt(val, 10) : val,
                        },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>

                <td className="to-hide">
                  <Input
                    id={`inp-website-monthly-${rest.restaurantId}`}
                    value={rest.websiteMonthly.toString()}
                    type="number"
                    placeholder="Website Monthly"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        {
                          ...rest,
                          websiteMonthly: val ? parseInt(val, 10) : val,
                        },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>

                <td className="to-hide">
                  <Input
                    id={`inp-website-one-time-${rest.restaurantId}`}
                    value={rest.websiteOneTimeFee.toString()}
                    type="number"
                    placeholder="Website One Time"
                    onChange={(val) => {
                      setRestaurants([
                        ...restaurants.filter((x) => x.restaurantId !== rest.restaurantId),
                        {
                          ...rest,
                          websiteOneTimeFee: val ? parseInt(val, 10) : val,
                        },
                      ]);
                      setHasChanged(true);
                    }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Style>
  );
};

export default RestaurantBilling;
