import axios, { CancelTokenSource } from 'axios';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RestaurantAPI, { RestaurantAPINS } from '../../api/restaurant.api';
import Checkbox from '../../components/CheckboxNew';
import Button from '../../components/common/Button';
import RootStore from '../../stores/RootStore';
import { FlashMessageType } from '../../stores/UIStore';

const Style = styled.div`
  padding-bottom: 180px;

  table {
    margin: 0;
    border-collapse: collapse;

    thead tr th {
      position: sticky;
      top: 0;
      background-color: #fff;
    }

    tr {
      &:hover {
        cursor: default !important;
      }
      &:nth-child(even) {
        background-color: #e8e8e8;
      }
    }

    td.data-toggle {
      width: 80px;
      padding: 8px;
      text-align: center;

      button {
        outline: none;
      }
      &:hover {
        cursor: default;
      }
    }
  }
`;

type Props = {
  store?: RootStore;
};

const Exclusive: React.FunctionComponent<Props> = ({ store }) => {
  const [restaurants, setRestaurants] = useState<Array<{ id: number; name: string }>>([]);
  const [exclusives, setExclusives] = useState<
    Array<Omit<RestaurantAPINS.RestaurantExclusiveModel, 'id'>>
  >([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const update = async () => {
    try {
      setBtnLoading(true);
      await new RestaurantAPI().updateExclusives(exclusives);
      store!.UIStore.addFlashMessage({
        type: FlashMessageType.Success,
        text: 'Updated restaurants of the month',
      });
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      store!.UIStore.addFlashMessage({
        type: FlashMessageType.Error,
        text: 'Updating restaurants of the month',
      });
      console.log('Error', error);
    }
  };

  const fetchRestaurants = async (cancelToken: CancelTokenSource) => {
    try {
      const res = await new RestaurantAPI().getExclusiveRestaurants(cancelToken);
      setRestaurants(res.data.restaurants);
      setExclusives(res.data.exclusives);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.log('Error', error);
      }
      store!.UIStore.addFlashMessage({
        type: FlashMessageType.Error,
        text: 'Fetching restaurants of the month',
      });
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    fetchRestaurants(cancelToken);

    return () => {
      cancelToken.cancel('Fetching restaurants of the month data cancelled in unmount');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOrRemoveExclusive = (
    restaurantId: number,
    type: RestaurantAPINS.RestaurantExclusiveType,
  ) => {
    const obj = exclusives.find((x) => x.restaurantId === restaurantId && x.type === type);

    if (!obj) {
      setExclusives([
        ...exclusives,
        {
          restaurantId,
          type,
          imgUrlBig: '',
          imgUrlMobile: '',
          imgUrlTablet: '',
        },
      ]);
    } else {
      setExclusives(
        exclusives.filter((x) => !(x.restaurantId === restaurantId && x.type === type)),
      );
    }
  };

  const updateHeroImageUrl = (
    restaurantId: number,
    imgType: 'bigImage' | 'tabletImage' | 'mobileImage',
    value: string,
  ) => {
    const obj = exclusives.find(
      (x) =>
        x.restaurantId === restaurantId && x.type === RestaurantAPINS.RestaurantExclusiveType.Hero,
    )!;

    switch (imgType) {
      case 'bigImage':
        obj.imgUrlBig = value;
        break;
      case 'tabletImage':
        obj.imgUrlTablet = value;
        break;
      case 'mobileImage':
        obj.imgUrlMobile = value;
        break;
      default:
        break;
    }

    setExclusives([
      ...exclusives.filter(
        (x) =>
          !(
            x.restaurantId === restaurantId &&
            x.type === RestaurantAPINS.RestaurantExclusiveType.Hero
          ),
      ),
      obj,
    ]);
  };

  if (restaurants.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Style>
      <div className="container">
        <h1>Ad Management</h1>
        <Button
          id="ads-btn-save"
          onClick={() => update()}
          isLoading={btnLoading}
          className="btn-cta"
        >
          Update
        </Button>
        <sub>Restaurants of the month, heros, featured and promoted.</sub>

        <table>
          <thead>
            <tr>
              <th>Restaurant</th>
              <th>Of the month</th>
              <th>Hero</th>
              <th>Featured</th>
              <th>Promoted</th>
            </tr>
          </thead>
          <tbody>
            {restaurants.map((r) => (
              <>
                <tr key={`ad-management-key-${r.id}`}>
                  <td>{r.name}</td>
                  <td className="data-toggle">
                    <Checkbox
                      id={`ad-rotm-${r.id}`}
                      checked={
                        exclusives.filter(
                          (x) =>
                            x.restaurantId === r.id &&
                            x.type === RestaurantAPINS.RestaurantExclusiveType.RestaurantOfTheMonth,
                        ).length === 1
                      }
                      onChange={() =>
                        setOrRemoveExclusive(
                          r.id,
                          RestaurantAPINS.RestaurantExclusiveType.RestaurantOfTheMonth,
                        )
                      }
                    />
                  </td>
                  <td className="data-toggle">
                    <Checkbox
                      id={`ad-hero-${r.id}`}
                      checked={
                        exclusives.filter(
                          (x) =>
                            x.restaurantId === r.id &&
                            x.type === RestaurantAPINS.RestaurantExclusiveType.Hero,
                        ).length === 1
                      }
                      onChange={() =>
                        setOrRemoveExclusive(r.id, RestaurantAPINS.RestaurantExclusiveType.Hero)
                      }
                    />
                  </td>
                  <td className="data-toggle">
                    <Checkbox
                      id={`ad-featured-${r.id}`}
                      checked={
                        exclusives.filter(
                          (x) =>
                            x.restaurantId === r.id &&
                            x.type === RestaurantAPINS.RestaurantExclusiveType.Featured,
                        ).length === 1
                      }
                      onChange={() =>
                        setOrRemoveExclusive(r.id, RestaurantAPINS.RestaurantExclusiveType.Featured)
                      }
                    />
                  </td>
                  <td className="data-toggle">
                    <Checkbox
                      id={`ad-promoted-${r.id}`}
                      checked={
                        exclusives.filter(
                          (x) =>
                            x.restaurantId === r.id &&
                            x.type === RestaurantAPINS.RestaurantExclusiveType.Promoted,
                        ).length === 1
                      }
                      onChange={() =>
                        setOrRemoveExclusive(r.id, RestaurantAPINS.RestaurantExclusiveType.Promoted)
                      }
                    />
                  </td>
                </tr>
                {exclusives.findIndex(
                  (x) =>
                    x.restaurantId === r.id &&
                    x.type === RestaurantAPINS.RestaurantExclusiveType.Hero,
                ) >= 0 && (
                  <tr key={`exl-inputs-${r.id}`}>
                    <td>
                      <input
                        placeholder="Big image url"
                        value={
                          exclusives.find(
                            (x) =>
                              x.restaurantId === r.id &&
                              x.type === RestaurantAPINS.RestaurantExclusiveType.Hero,
                          )?.imgUrlBig ?? ''
                        }
                        onChange={(val) =>
                          updateHeroImageUrl(r.id, 'bigImage', val.currentTarget.value)
                        }
                      />
                      <input
                        placeholder="Tablet image url"
                        value={
                          exclusives.find(
                            (x) =>
                              x.restaurantId === r.id &&
                              x.type === RestaurantAPINS.RestaurantExclusiveType.Hero,
                          )?.imgUrlTablet ?? ''
                        }
                        onChange={(val) =>
                          updateHeroImageUrl(r.id, 'tabletImage', val.currentTarget.value)
                        }
                      />
                      <input
                        placeholder="Mobile image url"
                        value={
                          exclusives.find(
                            (x) =>
                              x.restaurantId === r.id &&
                              x.type === RestaurantAPINS.RestaurantExclusiveType.Hero,
                          )?.imgUrlMobile ?? ''
                        }
                        onChange={(val) =>
                          updateHeroImageUrl(r.id, 'mobileImage', val.currentTarget.value)
                        }
                      />
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </Style>
  );
};

export default inject('store')(observer(Exclusive));
