import moment from 'moment-timezone';
import * as Moment from 'moment-timezone';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IBookingDashboard } from '../../api/dashboard.api';
import { PaymentType } from '../../enums/Payment';
import { getAllStatuses } from '../../utils';
import { filterBookingsByTimeRange } from '../../utils/booking.utils';
import ListCollapsableStatusBox from './ListCollapsableStatusBox';

const Style = styled.div`
  position: absolute;
  top: 16px;
  right: 0px;
  width: 300px;

  background-color: #fff;
  box-shadow: -2px 0px 18px 0px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  z-index: 99;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  .lc-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background-color: #1c1c1c;
    color: #fff;
    border-top-left-radius: 3px;

    .lc-icon-close {
      color: #fff;
    }
  }

  .lc-empty {
    padding: 8px;
  }
`;

const NumberBox = styled.div`
  display: flex;
  user-select: none;

  > div {
    width: 32px;
    text-align: center;

    > div:first-child {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 5px;
    }
  }
`;

const ListItem = styled.div`
  > .lc-li-header {
    display: flex;
    padding: 8px;

    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    user-select: none;

    &:hover {
      cursor: pointer;
    }
  }

  > .lc-li-body {
    display: ${(props: { isOpen: boolean }) => (props.isOpen ? 'block' : 'none')};
  }
`;

type BookingStyleType = {
  bgColor: string;
  color: string;
};

const BookingStyle = styled.div`
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;

  font-size: 12px;
  border-bottom: 1px solid #ebebeb;

  > .lc-li-booking-status-and-icons {
    position: relative;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background-color: ${(props: BookingStyleType) => props.bgColor};
    color: ${(props: BookingStyleType) => props.color};

    &:hover {
      cursor: pointer;
    }

    > .lc-li-booking-status-hotspot {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      > div {
        text-align: center;
      }

      i {
        color: ${(props: BookingStyleType) => props.color};
        font-size: 14px;
      }
    }

    > .lc-li-booking-status-buttons {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 200px;
      z-index: 1;

      &:hover {
        cursor: default;
      }
    }
  }

  > .lc-li-booking-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    &:hover {
      cursor: pointer;
    }

    > .lc-li-booking-info {
      display: flex;
      align-items: center;
      flex-grow: 1;
      padding-left: 8px;

      > div > div:first-child {
        margin-bottom: 4px;
      }
    }
    > .lc-li-booking-tables {
      padding: 0px 8px;
    }
  }
`;

const FilterButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;

  > button {
    height: 24px;
    width: 100%;
    text-align: center;
    border: 0px;
    background-color: transparent;
    outline: 0px !important;

    &.active {
      background-color: #e8e8e8;
      font-weight: 500;
    }
  }
`;

const calculateExpected = (bookings: IBookingDashboard[]): number => {
  let count = 0;

  bookings.forEach((b) => {
    const isHold = b.statusId === 1 && b.sourceId === 1;
    if (isHold || b.statusId === 2 || b.statusId === 3 || b.statusId === 4) {
      count += b.guests;
    } else if (b.statusId === 5) {
      // Partially seated we take half of guests
      count += Math.floor(b.guests / 2);
    }
  });

  return count;
};

const calculateSeated = (bookings: IBookingDashboard[]): number => {
  let count = 0;

  bookings.forEach((b) => {
    if (b.statusId === 6) {
      count += b.guests;
    } else if (b.statusId === 5) {
      count += Math.ceil(b.guests / 2);
    }
  });

  return count;
};

const allStatuses = getAllStatuses();

interface IBookingWithNotes extends IBookingDashboard {
  notes: string;
  color: string;
  bgColor: string;
}

type Props = {
  bookings: IBookingDashboard[];
  selectedDate: Moment.Moment;
  onStatusUpdated: (
    bookingId: number,
    newStatusAndEnd: { statusId: number; bookingEnd: string },
  ) => void;
  onClick: (bookingId: number) => void;
  onClose: () => void;
};

const ListCollapsable: FunctionComponent<Props> = ({
  bookings,
  selectedDate,
  onStatusUpdated,
  onClick,
  onClose,
}) => {
  const [expectedCount, setExpectedCount] = useState<number>(0);
  const [seatedCount, setSeatedCount] = useState<number>(0);
  const [groups, setGroups] = useState<Array<{
    time: string;
    bookings: IBookingWithNotes[];
    expectedCount: number;
    seatedCount: number;
  }> | null>(null);
  const [isOpenList, setIsOpenList] = useState<string[]>([]);
  const [bookingIdStatusBox, setBookingIdStatusBox] = useState<number | null>(null);
  const [filter, setFilter] = useState<'All' | 'Lunch' | 'Dinner'>('All');

  useEffect(() => {
    const filteredBookings = filterBookingsByTimeRange(filter, selectedDate, bookings);

    // Expected count
    setExpectedCount(calculateExpected(filteredBookings));

    // Seated count
    setSeatedCount(calculateSeated(filteredBookings));

    // Group by time of day
    const g = filteredBookings
      .map((x) => {
        let notes = '';
        if (x.bookingSpecialRequest) {
          notes += 'Guest notes: ';
          notes +=
            x.bookingSpecialRequest.length > 100
              ? `${x.bookingSpecialRequest.substr(0, 100)}...`
              : x.bookingSpecialRequest;
          notes += '. ';
        }

        if (x.bookingInternalNotes) {
          notes += 'Internal notes: ';
          notes +=
            x.bookingInternalNotes.length > 100
              ? `${x.bookingInternalNotes.substr(0, 100)}...`
              : x.bookingInternalNotes;
        }

        const obj = {
          ...x,
          start: moment(x.start).format(moment.defaultFormatUtc),
          notes,
          bgColor: allStatuses.find((s) => s.id === x.statusId)!.color,
          color: allStatuses.find((s) => s.id === x.statusId)!.contrastColor,
        };

        return obj;
      })
      .reduce((h: any, obj: IBookingDashboard) => {
        h[obj.start] = (h[obj.start] || []).concat(obj);
        return h;
      }, {});

    const arr: Array<{
      time: string;
      bookings: IBookingWithNotes[];
      expectedCount: number;
      seatedCount: number;
    }> = [];
    for (const key in g) {
      if (g.hasOwnProperty(key)) {
        const bkns: IBookingWithNotes[] = g[key];
        arr.push({
          time: moment(key, moment.defaultFormatUtc).format('HH:mm'),
          bookings: bkns,
          expectedCount: calculateExpected(bkns),
          seatedCount: calculateSeated(g[key]),
        });
      }
    }

    setGroups(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookings, filter]);

  useEffect(() => {
    setBookingIdStatusBox(null);
  }, [isOpenList]);

  return (
    <Style>
      <div className="lc-header">
        <a onClick={() => onClose()}>
          <i className="material-icons lc-icon-close">close</i>
        </a>

        <NumberBox>
          <div title="Expected guests">
            <div>E</div>
            <div>{expectedCount}</div>
          </div>
          <div title="Seated guests">
            <div>S</div>
            <div>{seatedCount}</div>
          </div>
        </NumberBox>
      </div>

      <FilterButtons>
        <button className={filter === 'All' ? 'active' : ''} onClick={() => setFilter('All')}>
          All
        </button>
        <button className={filter === 'Lunch' ? 'active' : ''} onClick={() => setFilter('Lunch')}>
          Lunch
        </button>
        <button className={filter === 'Dinner' ? 'active' : ''} onClick={() => setFilter('Dinner')}>
          Dinner
        </button>
      </FilterButtons>

      {groups && groups.length === 0 ? (
        <div className="lc-empty">No data</div>
      ) : (
        <div className="lc-body">
          {groups &&
            groups.map((b) => (
              <ListItem key={b.time} isOpen={isOpenList.includes(b.time)}>
                <div
                  className="lc-li-header"
                  onClick={() => {
                    if (isOpenList.includes(b.time)) {
                      setIsOpenList(isOpenList.filter((x) => x !== b.time));
                    } else {
                      setIsOpenList([...isOpenList, b.time]);
                    }
                  }}
                >
                  <div>{b.time}</div>
                  <div>
                    <NumberBox>
                      <div title="Expected guests">
                        <div>E</div>
                        <div>{b.expectedCount}</div>
                      </div>
                      <div title="Seated guests">
                        <div>S</div>
                        <div>{b.seatedCount}</div>
                      </div>
                    </NumberBox>
                  </div>
                </div>
                <div className="lc-li-body">
                  {b.bookings.map((x) => {
                    const noShowPayment =
                      x.payments && x.payments.find((p) => p.type === PaymentType.NoShow);

                    return (
                      <BookingStyle key={`li-booking-${x.id}`} bgColor={x.bgColor} color={x.color}>
                        <div className="lc-li-booking-status-and-icons">
                          <div
                            className="lc-li-booking-status-hotspot"
                            onClick={() => setBookingIdStatusBox(x.id)}
                          >
                            <div>
                              <div>
                                {x.notes && x.notes !== '' && (
                                  <span title={x.notes}>
                                    <i className="icon-info material-icons">info_outline</i>
                                  </span>
                                )}
                              </div>
                              {noShowPayment && noShowPayment.canBeCharged && (
                                <span title="Card registered">
                                  <i className="material-icons">credit_card</i>
                                </span>
                              )}
                            </div>
                          </div>

                          {bookingIdStatusBox === x.id && (
                            <div className="lc-li-booking-status-buttons">
                              <ListCollapsableStatusBox
                                bookingId={x.id}
                                statusId={x.statusId}
                                onUpdated={(bookingId, newStatusAndEnd) =>
                                  onStatusUpdated(bookingId, newStatusAndEnd)
                                }
                                onClose={() => setBookingIdStatusBox(null)}
                              />
                            </div>
                          )}
                        </div>
                        <div className="lc-li-booking-content" onClick={() => onClick(x.id)}>
                          <div className="lc-li-booking-info">
                            <div>
                              <div>
                                {x.bookingName} ({x.guests})
                              </div>
                              <div>{x.bookingPhone}</div>
                            </div>
                          </div>
                          <div className="lc-li-booking-tables">
                            {x.tables &&
                              x.tables.map((t) => (
                                <div key={`lc-tables-${x.id}-${t.floorPlanTableId}`}>{t.name}</div>
                              ))}
                          </div>
                        </div>
                      </BookingStyle>
                    );
                  })}
                </div>
              </ListItem>
            ))}
        </div>
      )}
    </Style>
  );
};

export default ListCollapsable;
