import {
  Button,
  Checkbox,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Popover,
} from '@material-ui/core';
import { add, format, isBefore, isSameHour, isSameMinute, set } from 'date-fns';
import React, { useMemo } from 'react';
import { WaitingListTimeModel } from './Settings';

const AddWaitingTimesList = ({
  selectedDate,
  isPopperOpenState,
  anchorRef,
  selectedWaitingListTimesState,
  doSave,
}: {
  selectedDate: Date;
  isPopperOpenState: [boolean, React.Dispatch<boolean>];
  anchorRef: HTMLElement;
  selectedWaitingListTimesState: [
    WaitingListTimeModel[],
    React.Dispatch<React.SetStateAction<WaitingListTimeModel[]>>,
  ];
  doSave: (invalidateOnly?: boolean) => void;
}) => {
  const [selectedWaitingListTimes, setSelectedWaitingListTimes] = selectedWaitingListTimesState;
  const [isPopperOpen, setIsPopperOpen] = isPopperOpenState;

  const times: Date[] = useMemo(() => {
    // Populate waiting list times
    let begin: Date = set(selectedDate, {
      hours: 8,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    const end: Date = set(selectedDate, {
      hours: 23,
      minutes: 45,
      seconds: 0,
      milliseconds: 0,
    });

    const times: Date[] = [];
    while (isBefore(begin, end) || (isSameHour(begin, end) && isSameMinute(begin, end))) {
      times.push(new Date(begin));
      begin = add(begin, { minutes: 15 });
    }

    return times;
  }, [selectedDate]);

  return (
    <Popover
      open={isPopperOpen}
      onClose={() => {
        doSave(true);
        setIsPopperOpen(false);
      }}
      anchorEl={anchorRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      style={{ height: 404 }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <List
          disablePadding
          dense
          style={{ height: 300, overflow: 'auto' }}
          subheader={
            <ListSubheader style={{ backgroundColor: '#fff' }}>
              Choose waiting list times
            </ListSubheader>
          }
        >
          {times.map((time) => {
            const labelId = `checkbox-list-label-${time}`;

            return (
              <ListItem
                disableGutters
                key={`waiting-time-opt-${time}`}
                role={undefined}
                dense
                button
                onClick={() => {
                  const anyExists =
                    selectedWaitingListTimes.filter(
                      (x) => isSameHour(x.dateTime, time) && isSameMinute(x.dateTime, time),
                    ).length > 0;
                  if (anyExists) {
                    setSelectedWaitingListTimes([
                      ...selectedWaitingListTimes.filter(
                        (x) => !(isSameHour(x.dateTime, time) && isSameMinute(x.dateTime, time)),
                      ),
                    ]);
                  } else {
                    setSelectedWaitingListTimes([
                      ...selectedWaitingListTimes,
                      {
                        id:
                          selectedWaitingListTimes.length === 0
                            ? -1
                            : Math.min(...selectedWaitingListTimes.map((x) => x.id)) - 1,
                        restaurantId: -1,
                        dateTime: time,
                      },
                    ]);
                  }
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={selectedWaitingListTimes.some(
                      (x) => isSameHour(x.dateTime, time) && isSameMinute(x.dateTime, time),
                    )}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={format(time, 'HH:mm')} />
                <ListItemSecondaryAction>
                  <div></div>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              doSave();
              setIsPopperOpen(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </div>
    </Popover>
  );
};

export default AddWaitingTimesList;
