import React from 'react';
import styled from 'styled-components';
import ProgressBar from '../../../components/ProgressBar';

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;

  > div:first-child {
    width: 80px;
    margin-right: 8px;
  }

  > div:nth-child(2) {
    width: 40px; /*Contain in 40px because the percentage string can be 3 or 4 chars*/
  }
`;

const Progress = ({ ratio }: { ratio: number }) => (
  <ProgressWrapper>
    <ProgressBar ratio={ratio} />
    <div>{`${Math.round(ratio * 100)}%`}</div>
  </ProgressWrapper>
);

export default Progress;
