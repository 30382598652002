import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import FloorPlan from './FloorPlan';
import FloorPlans from './FloorPlans';

const FloorPlanRoutes: FC<RouteComponentProps> = ({ match }) => (
  <div className="container">
    <Switch>
      <Route path={match.url} component={FloorPlans} exact />
      <Route path={`${match.url}/create`} component={FloorPlan} exact />
      <Route path={`${match.url}/:id`} component={FloorPlan} exact />
    </Switch>
  </div>
);

export default FloorPlanRoutes;
