import Axios, { CancelTokenSource } from 'axios';
import { inject } from 'mobx-react';
import moment from 'moment-timezone';
import * as Moment from 'moment-timezone';
import TimePicker from 'rc-time-picker';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styled, { css } from 'styled-components';
import { CategoryAPINS, getAllCategories } from '../../api/category.api';
import { CityAPINS, getAllCities } from '../../api/city.api';
import {
  createRestaurant,
  getRestaurant,
  RestaurantAPINS,
  updateRestaurant,
} from '../../api/restaurant.api';
import { CountryCodeType } from '../../api/translation.api';
import { UserAPINS } from '../../api/user.api';
import CheckboxNew from '../../components/CheckboxNew';
import Button from '../../components/common/Button';
import GroupButtons from '../../components/GroupButtons.styled';
import Input from '../../components/Input';
import Label from '../../components/Label.styled';
import SelectStyle from '../../components/Select.styled';
import TabsStyle from '../../components/Tabs.styled';
import TextArea from '../../components/TextArea';
import Users from '../../components/Users';
import RestaurantType from '../../enums/RestaurantType';
import { IRootStore } from '../../stores/RootStore';
import { FlashMessageType } from '../../stores/UIStore';
import { UserRole } from '../../stores/UserStore';
import theme from '../../styles/theme';
import { getAllRestaurantImageTypes, isEmailValid, isPhoneValid, isUrlValid } from '../../utils';
import { getAllTimezones } from '../../api/timezone.api';

const Style = styled.div``;

const SmsContainer = styled.div`
  #chk-sms-confirmation {
    display: flex;
    align-items: center;
    input {
      width: auto;
    }
  }

  span {
    margin-left: 8px;
  }

  .reminder-container {
    width: 30%;
    margin-top: 8px;
  }

  > * {
    margin-top: 24px;
  }
`;

const ChkPolicy = styled.div`
  #chk-policy-accept {
    display: flex;
    align-items: center;
    input {
      width: auto;
    }
  }

  ${(props: { isDisabled: boolean }) =>
    props.isDisabled &&
    css`
      > div {
        opacity: 0.3;
        pointer-events: none;
      }
    `};
`;

const MenuMandatory = styled.div`
  > label {
    display: flex;
    align-items: center;
    > input {
      width: auto;
      height: 24px;
    }
  }
`;

const OpeningHourContainer = styled.div`
  .input-container {
    display: flex;
    > div:nth-child(2),
    > div:nth-child(3) {
      margin-left: 8px;
    }
    > div:last-child {
      width: 100%;
    }
  }

  .rc-time-picker {
    input {
      height: 40px;
      border-color: ${theme.colors.neutral4};
    }
  }

  .day-text {
    min-height: 18px;
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
  }

  .closed {
    margin: 4px 0px 8px 0px;
  }

  .error {
    margin-bottom: 16px;
    font-size: 12px;
    div:first-child {
      color: #ef5350;
    }
  }
`;

const CategoriesStyle = styled.div`
  margin-bottom: 29px;
  font-size: 12px;
  color: ${theme.colors.dark1};
  &,
  & * {
    box-shadow: none !important;
  }
  .Select-input {
    height: 24px;
    > input {
      height: 24px;
    }
  }
  .Select-control {
    border-radius: 3px;
    min-height: 180px;
  }
  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
  }
  .Select,
  .Select.is-pseudo-focused,
  .Select:focus,
  input:focus {
    border: none;
  }
  .Select.is-focused {
    outline: rgb(94, 158, 215) auto 5px;
  }
  .Select-control {
    border: 1px solid ${theme.colors.neutral4} !important;
  }
  .Select-multi-value-wrapper {
    padding: 8px;
  }
  .Select-arrow-zone {
    width: 32px;
    padding-right: 0px !important;
    border-left: 1px solid ${theme.colors.neutral4};
    background-color: #fff;
  }
  .Select-option.is-focused {
    background-color: ${theme.colors.neutral1};
  }
  .Select--multi {
    z-index: 99999;
    .Select-value {
      background-color: ${theme.colors.brand2} !important;
      border-color: ${theme.colors.brand1} !important;
      color: #fff !important;
    }
    .Select-value-icon {
      border-right: 1px solid ${theme.colors.brand1} !important;
    }
    .Select--multi .Select-value-icon:hover,
    .Select--multi .Select-value-icon:focus {
      background-color: ${theme.colors.danger} !important;
      color: #fff !important;
    }
  }
`;

let postPutCancelToken: CancelTokenSource | null = null;

interface IProps extends RouteComponentProps {
  store: IRootStore;
}

const countryOptions = [
  { label: 'Iceland', value: 'is' },
  { label: 'Spain', value: 'es' },
  { label: 'Norway', value: 'nb' },
];

const Restaurant: FC<IProps> = ({ store, match, history }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const viewMode = useMemo(() => (match.params as { viewMode: 'edit' | 'create' }).viewMode, []);

  // Restaurant data
  const [restaurant, setRestaurant] =
    useState<RestaurantAPINS.IRestaurantReservableModel>(restaurantInitial);
  const [locations, setLocations] =
    useState<RestaurantAPINS.RestaurantLocationModel[]>(locationInitial);
  const [openingHours, setOpeningHours] =
    useState<RestaurantAPINS.RestaurantOpeningHoursModel[]>(ohInitial);
  const [categories, setCategories] = useState<RestaurantAPINS.RestaurantCategoryRestaurantModel[]>(
    [],
  );
  const [allTimezones, setAllTimezones] = useState<string[]>([]);
  const [images, setImages] = useState<RestaurantAPINS.IRestaurantImageModel[]>(imagesInitial);
  const [users, setUsers] = useState<UserAPINS.IUserAdminModelWithPassword[]>([]);
  const [allRules, setAllRules] = useState<Array<{ id: number; name: string }>>([]);
  const [dayOfWeekRules, setDayOfWeekRules] = useState<
    Array<{ dayOfWeek: number; ruleId: number }>
  >([]);

  // Other data
  const [allCategories, setAllCategories] = useState<
    CategoryAPINS.IRestaurantCategoryWithLanguageModel[]
  >([]);
  const [allCities, setAllCities] = useState<CityAPINS.CityModel[]>([]);

  // UI
  const [isRestaurantLoaded, setIsRestaurantLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Restaurant fetch
  useEffect(() => {
    const cancelToken = Axios.CancelToken.source();

    if (viewMode === 'edit') {
      store.UIStore.setIsLoading(true);

      getRestaurant(cancelToken)
        .then((res) => {
          if (!res.data.restaurant.preferences) {
            res.data.restaurant.preferences = {
              isBookedByNameMandatory: false,
              isBookingNameMandatory: false,
            };
          }

          setRestaurant(res.data.restaurant);
          setLocations(res.data.locations);
          setOpeningHours(res.data.openingHours);
          setCategories(res.data.categories);
          setImages(res.data.images.length > 0 ? res.data.images : imagesInitial);
          setUsers(
            res.data.users.map((x) => ({
              ...x,
              password: '',
            })),
          );
          setAllRules(res.data.allRules);
          setDayOfWeekRules(res.data.dayOfWeekRules);

          setIsRestaurantLoaded(true);
        })
        .catch((e) => {
          if (!Axios.isCancel(e)) {
            store.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Error fetching restaurant data.',
            });
          }
        });
    } else {
      setIsRestaurantLoaded(true);
    }

    return () => {
      cancelToken.cancel();
      store.UIStore.setIsLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // All categories fetch
  useEffect(() => {
    const cancelToken = Axios.CancelToken.source();

    getAllCategories(cancelToken)
      .then((res) => {
        setAllCategories(res.data);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not fetch categories.',
            timeout: 10000,
          });
        }
      });

    return () => cancelToken.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // All cities fetch
  useEffect(() => {
    const cancelToken = Axios.CancelToken.source();

    getAllCities(cancelToken)
      .then((res) => {
        setAllCities(res.data);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not fetch cities.',
            timeout: 10000,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // All timezones fetch
  useEffect(() => {
    const cancelToken = Axios.CancelToken.source();
    getAllTimezones(cancelToken)
      .then((res) => {
        setAllTimezones(res.data);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not fetch time zones.',
            timeout: 10000,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Validation
  const isFormValid: boolean = useMemo(() => {
    return (
      restaurant.name.length > 1 &&
      restaurant.slug.length > 1 &&
      isEmailValid(restaurant.email) &&
      isPhoneValid(restaurant.phone) &&
      isUrlValid(restaurant.website) &&
      categories.length > 0 &&
      users.length > 0 &&
      !!restaurant.country
    );
  }, [restaurant, categories, users]);

  const isOHInputValid = (openFrom: string | null, openTo: string | null) => {
    if (!openFrom && !openTo) {
      return true;
    }
    if ((!openFrom && openTo) || (openFrom && !openTo)) {
      return false;
    }
    if (openFrom && openTo) {
      const from = moment(openFrom, 'HH:mm');
      const to = moment(openTo, 'HH:mm');
      return from < to;
    }
    return false;
  };

  // Cleanup
  useEffect(() => {
    return () => {
      if (postPutCancelToken) {
        postPutCancelToken.cancel();
      }
    };
  }, []);

  const saveRestaurant = () => {
    setIsSaving(true);

    if (postPutCancelToken) {
      postPutCancelToken.cancel();
    }

    postPutCancelToken = Axios.CancelToken.source();

    const data = {
      restaurant,
      locations,
      categories,
      users,
      openingHours,
      images,
      dayOfWeekRules,
    };

    if (viewMode === 'create') {
      createRestaurant(data, postPutCancelToken)
        .then((res) => {
          if (res.status === 200) {
            store!.UIStore.addFlashMessage({
              type: FlashMessageType.Success,
              text: 'Restaurant successfully created.',
            });
          } else if (res.status === 206) {
            store!.UIStore.addFlashMessage({
              type: FlashMessageType.Info,
              text: 'Restaurant partially created. Please open the newly created restaurant and try again.',
              timeout: 10000,
            });
          }

          history.replace('/restaurant');
        })
        .catch((e) => {
          setIsSaving(false);
          if (!Axios.isCancel(e)) {
            store!.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Could not create restaurant.',
              timeout: 10000,
            });
          }
        });
    } else {
      updateRestaurant(data, postPutCancelToken)
        .then((res) => {
          if (res.status === 200) {
            store!.UIStore.addFlashMessage({
              type: FlashMessageType.Success,
              text: 'Restaurant successfully updated.',
            });
          } else if (res.status === 206) {
            store!.UIStore.addFlashMessage({
              type: FlashMessageType.Info,
              text: `Restaurant partially updated. ${res.data}`,
            });
          }
          setIsSaving(false);
        })
        .catch((e) => {
          setIsSaving(false);
          if (!Axios.isCancel(e)) {
            store!.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Could not update restaurant.',
              timeout: 10000,
            });
          }
        });
    }
  };

  // Helpers
  const getDayOfWeekRuleValue = (dayOfWeek: number) => {
    const dow = dayOfWeekRules.find((x) => x.dayOfWeek === dayOfWeek);
    if (dow) {
      return dow.ruleId;
    }
    return null;
  };

  const getOHErrorMsg = () => {
    return (
      <div className="error">
        <div>Invalid opening hours:</div>
        <div>1. Opening time must be before closing time</div>
        <div>2. Closing time must be before midnight</div>
      </div>
    );
  };

  const updateDayOfWeekRule = (dayOfWeek: number, ruleId: number | null) => {
    const dowIndex = dayOfWeekRules.findIndex((x) => x.dayOfWeek === dayOfWeek);

    if (dowIndex !== -1) {
      let dow = [...dayOfWeekRules];
      if (!ruleId) {
        dow = dow.filter((x) => x.dayOfWeek !== dayOfWeek);
        setDayOfWeekRules(dow);
      } else {
        dow[dowIndex] = { ruleId, dayOfWeek };
        setDayOfWeekRules(dow);
      }
    } else if (ruleId) {
      const dow = [...dayOfWeekRules];
      dow.push({ ruleId, dayOfWeek });
      setDayOfWeekRules(dow);
    }
  };

  const renderSmsRemindersJsx = () => {
    const smsReminderValues: Array<{ label: string; value: number | null }> = [
      { label: 'Off', value: null },
    ];
    Array(24)
      .fill(0)
      .forEach((_, i) => {
        smsReminderValues.push({ label: (i + 1).toString(), value: i + 1 });
      });
    smsReminderValues.push({ label: '48', value: 48 });
    smsReminderValues.push({ label: '72', value: 72 });

    return (
      <div>
        Number of hours before the customer will receive reminder:
        <div className="reminder-container">
          <SelectStyle>
            <Select
              value={restaurant.smsReminder as any}
              onChange={(v: any) => setRestaurant({ ...restaurant, smsReminder: v.value })}
              options={smsReminderValues}
              placeholder="Off"
              clearable={false}
            />
          </SelectStyle>
        </div>
      </div>
    );
  };

  const updateImages = (value: string, id: number) => {
    const imagesArray = [...images];

    const slot = imagesArray.find((x) => x.id === id);
    if (slot) {
      slot.url = value;
      setImages(imagesArray);
    }
  };

  if (!isRestaurantLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Style>
      <h1>{viewMode === 'create' ? 'Create restaurant' : 'Edit restaurant'}</h1>
      <Button
        id="btn-save-restaurant"
        onClick={() => saveRestaurant()}
        className="btn-cta btn-save"
        isLoading={isSaving}
        disabled={!isFormValid}
      >
        Save
      </Button>
      <sub>Here you can view and update details about this restaurant.</sub>

      <TabsStyle>
        <Tabs>
          <TabList>
            <Tab>Information</Tab>
            <Tab>Users</Tab>
            <Tab disabled={viewMode === 'create'}>Opening hours</Tab>
            <Tab disabled={viewMode === 'create'}>Online</Tab>
            <Tab disabled={viewMode === 'create'}>Texts</Tab>
            <Tab disabled={viewMode === 'create'}>Payments</Tab>
            <Tab disabled={viewMode === 'create'}>Other</Tab>
            {store.RestaurantStore.user!.role === UserRole.Admin && (
              <Tab disabled={viewMode === 'create'}>Images</Tab>
            )}
          </TabList>

          {/* INFORMATION */}
          <TabPanel>
            <Grid fluid>
              <Row>
                <Col xs={5}>
                  <h3>General</h3>
                  <Input
                    title="Restaurant name"
                    type="text"
                    value={restaurant.name}
                    onChange={(newValue) => setRestaurant({ ...restaurant, name: newValue })}
                    isValid={restaurant.name.length > 1}
                    errorMessage="Restaurant name must be more than 1 character."
                    isRequired
                  />
                  {store.RestaurantStore.user!.role === UserRole.Admin && (
                    <Input
                      title="Slug"
                      type="text"
                      value={restaurant.slug}
                      onChange={(newValue) =>
                        setRestaurant({
                          ...restaurant,
                          slug: newValue.toLowerCase(),
                        })
                      }
                      isValid={restaurant.slug.length >= 1}
                      errorMessage="Slug must not be empty and more than 1 character."
                      isRequired
                    />
                  )}
                  {store.RestaurantStore.user!.role === UserRole.Admin && (
                    <>
                      <Label>Country</Label>
                      <SelectStyle>
                        <Select
                          simpleValue
                          value={restaurant.country}
                          onChange={(country: any) => {
                            console.log(country);
                            if (country) {
                              const c = country as CountryCodeType;
                              setRestaurant({ ...restaurant, country: c });
                            }
                          }}
                          options={countryOptions}
                          placeholder="Select country"
                          clearable={false}
                        />
                      </SelectStyle>
                    </>
                  )}
                  <Row>
                    <Col xs={6}>
                      <Input
                        title="Email"
                        type="email"
                        value={restaurant.email}
                        onChange={(newValue) =>
                          setRestaurant({
                            ...restaurant,
                            email: newValue.toLowerCase(),
                          })
                        }
                        isRequired
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        title="Phone"
                        type="tel"
                        value={restaurant.phone}
                        onChange={(newValue) => setRestaurant({ ...restaurant, phone: newValue })}
                        isRequired
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Input
                        title="SSN"
                        type="number"
                        value={restaurant.ssn || ''}
                        onChange={(newValue) => setRestaurant({ ...restaurant, ssn: newValue })}
                        isValid={!!restaurant.ssn && restaurant.ssn.length === 10}
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        title="VAT number"
                        value={restaurant.vatNumber || ''}
                        onChange={(newValue) =>
                          setRestaurant({ ...restaurant, vatNumber: newValue })
                        }
                      />
                    </Col>
                  </Row>
                  {store.RestaurantStore.user!.role === UserRole.Admin && (
                    <>
                      <Row>
                        <Col xs={6}>
                          <CheckboxNew
                            checked={restaurant.isPublished}
                            id="chk-is-published"
                            onChange={() =>
                              setRestaurant({
                                ...restaurant,
                                isPublished: !restaurant.isPublished,
                              })
                            }
                            title="Is published?"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <CheckboxNew
                            checked={restaurant.type === RestaurantType.Restaurant}
                            id="chk-is-bookable"
                            onChange={() =>
                              setRestaurant({
                                ...restaurant,
                                type:
                                  restaurant.type === RestaurantType.Restaurant
                                    ? RestaurantType.WalkIn
                                    : RestaurantType.Restaurant,
                              })
                            }
                            title="Is bookable?"
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
                <Col xs={5} xsOffset={1}>
                  <Row>
                    <Col xs={12}>
                      <h3>Website</h3>
                      <Label>Price category</Label>
                      <GroupButtons tabIndex={0} buttonWidth={64}>
                        <div
                          className={restaurant.priceCategoryId === 1 ? 'active' : ''}
                          onClick={() => setRestaurant({ ...restaurant, priceCategoryId: 1 })}
                          role="button"
                          tabIndex={0}
                        >
                          $
                        </div>
                        <div
                          className={restaurant.priceCategoryId === 2 ? 'active' : ''}
                          onClick={() => setRestaurant({ ...restaurant, priceCategoryId: 2 })}
                          role="button"
                          tabIndex={0}
                        >
                          $$
                        </div>
                        <div
                          className={restaurant.priceCategoryId === 3 ? 'active' : ''}
                          onClick={() => setRestaurant({ ...restaurant, priceCategoryId: 3 })}
                          role="button"
                          tabIndex={0}
                        >
                          $$$
                        </div>
                        <div
                          className={restaurant.priceCategoryId === 4 ? 'active' : ''}
                          onClick={() => setRestaurant({ ...restaurant, priceCategoryId: 4 })}
                          role="button"
                          tabIndex={0}
                        >
                          $$$$
                        </div>
                      </GroupButtons>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Label>
                        Categories <span style={{ color: '#EF5350' }}>*</span>
                      </Label>
                      <CategoriesStyle>
                        <Select
                          simpleValue
                          multi
                          value={categories.map((x) => ({
                            value: x.restaurantCategoryId,
                            label: allCategories.filter((ac) => ac.id === x.restaurantCategoryId)[0]
                              ?.name,
                          }))}
                          onChange={(newVal) => {
                            const newCats: string = newVal as any;
                            let newCatsIds: number[] = [];
                            if (newCats && newCats.length > 0) {
                              newCatsIds = newCats.split(',').map((catId) => parseInt(catId, 10));
                            }
                            setCategories(
                              newCatsIds.map((newCatId, i) => ({
                                id: -1,
                                restaurantId: -1,
                                restaurantCategoryId: newCatId,
                                order: i,
                              })),
                            );
                          }}
                          options={allCategories.map((x) => ({
                            label: x.name,
                            value: x.id,
                          }))}
                          placeholder="Select categories"
                          clearable={false}
                        />
                      </CategoriesStyle>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Input
                        title="Website"
                        type="text"
                        value={restaurant.website}
                        onChange={(newValue) =>
                          setRestaurant({
                            ...restaurant,
                            website: newValue.toLowerCase(),
                          })
                        }
                        isRequired
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Grid>

            <Grid fluid>
              <h3>Location</h3>
              <Row>
                <Col xs={4}>
                  <Input
                    title="Address"
                    type="text"
                    value={locations[0].address}
                    onChange={(newValue) => setLocations([{ ...locations[0], address: newValue }])}
                  />
                </Col>
                <Col xs={4}>
                  <Label>City</Label>
                  <SelectStyle>
                    <Select
                      simpleValue
                      value={locations[0].cityId}
                      onChange={(newValue) =>
                        setLocations([{ ...locations[0], cityId: newValue as any }])
                      }
                      options={allCities.map((x) => ({
                        label: `${x.zipCode} - ${x.name}`,
                        value: x.id,
                      }))}
                      placeholder="Select city"
                      clearable={false}
                    />
                  </SelectStyle>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <Input
                    title="Latitude"
                    type="text"
                    value={locations[0].lat?.toString()}
                    onChange={(newValue) => setLocations([{ ...locations[0], lat: newValue }])}
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    title="Longitude"
                    type="text"
                    value={locations[0].lon?.toString()}
                    onChange={(newValue) => setLocations([{ ...locations[0], lon: newValue }])}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <div>
                    <Label>Time zone</Label>
                    <SelectStyle>
                      <Select
                        value={locations[0].timeZone}
                        onChange={(v: any) => {
                          if (v.value) {
                            setLocations([{ ...locations[0], timeZone: v.value }]);
                          }
                        }}
                        disabled={viewMode === 'edit'}
                        options={allTimezones.map((tz) => ({ label: tz, value: tz }))}
                        placeholder="Select a timezone"
                        clearable={false}
                      />
                    </SelectStyle>
                  </div>
                </Col>
              </Row>
            </Grid>
          </TabPanel>

          {/* USERS */}
          <TabPanel>
            <Grid fluid>
              <Row>
                <Col xs={11}>
                  <h3>User list</h3>
                  <Users users={users} onChange={(newUsers) => setUsers(newUsers)} />
                </Col>
              </Row>
            </Grid>
          </TabPanel>

          {/* OPENING HOURS */}
          <TabPanel>
            <Grid fluid>
              <Row>
                <Col xs={5}>
                  <OpeningHourContainer>
                    <h3>Sunday</h3>
                    <div className="input-container">
                      <div>
                        <Label>From</Label>
                        <TimePicker
                          id="sunday-from"
                          value={
                            openingHours[0].sundayFrom
                              ? moment(openingHours[0].sundayFrom, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                sundayFrom: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>To</Label>
                        <TimePicker
                          id="sunday-to"
                          value={
                            openingHours[0].sundayTo
                              ? moment(openingHours[0].sundayTo, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                sundayTo: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>Rule</Label>
                        <SelectStyle>
                          <Select
                            value={getDayOfWeekRuleValue(0) as any}
                            onChange={(v: any) => updateDayOfWeekRule(0, v ? v.value : null)}
                            options={allRules.map((x) => ({
                              label: x.name,
                              value: x.id,
                            }))}
                            placeholder="Off"
                            clearable
                          />
                        </SelectStyle>
                      </div>
                    </div>
                    {!isOHInputValid(openingHours[0].sundayFrom, openingHours[0].sundayTo) &&
                      getOHErrorMsg()}
                  </OpeningHourContainer>
                </Col>
                <Col xs={5} xsOffset={1}>
                  <OpeningHourContainer>
                    <h3>Monday</h3>
                    <div className="input-container">
                      <div>
                        <Label>From</Label>
                        <TimePicker
                          id="monday-from"
                          value={
                            openingHours[0].mondayFrom
                              ? moment(openingHours[0].mondayFrom, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                mondayFrom: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>To</Label>
                        <TimePicker
                          id="monday-to"
                          value={
                            openingHours[0].mondayTo
                              ? moment(openingHours[0].mondayTo, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                mondayTo: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>Rule</Label>
                        <SelectStyle>
                          <Select
                            value={getDayOfWeekRuleValue(1) as any}
                            onChange={(v: any) => updateDayOfWeekRule(1, v ? v.value : null)}
                            options={allRules.map((x) => ({
                              label: x.name,
                              value: x.id,
                            }))}
                            placeholder="Off"
                            clearable
                          />
                        </SelectStyle>
                      </div>
                    </div>
                    {!isOHInputValid(openingHours[0].mondayFrom, openingHours[0].mondayTo) &&
                      getOHErrorMsg()}
                  </OpeningHourContainer>
                </Col>
              </Row>
              <Row>
                <Col xs={5}>
                  <OpeningHourContainer>
                    <h3>Tuesday</h3>
                    <div className="input-container">
                      <div>
                        <Label>From</Label>
                        <TimePicker
                          id="tuesday-from"
                          value={
                            openingHours[0].tuesdayFrom
                              ? moment(openingHours[0].tuesdayFrom, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                tuesdayFrom: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>To</Label>
                        <TimePicker
                          id="tuesday-to"
                          value={
                            openingHours[0].tuesdayTo
                              ? moment(openingHours[0].tuesdayTo, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                tuesdayTo: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>Rule</Label>
                        <SelectStyle>
                          <Select
                            value={getDayOfWeekRuleValue(2) as any}
                            onChange={(v: any) => updateDayOfWeekRule(2, v ? v.value : null)}
                            options={allRules.map((x) => ({
                              label: x.name,
                              value: x.id,
                            }))}
                            placeholder="Off"
                            clearable
                          />
                        </SelectStyle>
                      </div>
                    </div>
                    {!isOHInputValid(openingHours[0].tuesdayFrom, openingHours[0].tuesdayTo) &&
                      getOHErrorMsg()}
                  </OpeningHourContainer>
                </Col>
                <Col xs={5} xsOffset={1}>
                  <OpeningHourContainer>
                    <h3>Wednesday</h3>
                    <div className="input-container">
                      <div>
                        <Label>From</Label>
                        <TimePicker
                          id="wednesday-from"
                          value={
                            openingHours[0].wednesdayFrom
                              ? moment(openingHours[0].wednesdayFrom, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                wednesdayFrom: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>To</Label>
                        <TimePicker
                          id="wednesday-to"
                          value={
                            openingHours[0].wednesdayTo
                              ? moment(openingHours[0].wednesdayTo, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                wednesdayTo: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>Rule</Label>
                        <SelectStyle>
                          <Select
                            value={getDayOfWeekRuleValue(3) as any}
                            onChange={(v: any) => updateDayOfWeekRule(3, v ? v.value : null)}
                            options={allRules.map((x) => ({
                              label: x.name,
                              value: x.id,
                            }))}
                            placeholder="Off"
                            clearable
                          />
                        </SelectStyle>
                      </div>
                    </div>
                    {!isOHInputValid(openingHours[0].wednesdayFrom, openingHours[0].wednesdayTo) &&
                      getOHErrorMsg()}
                  </OpeningHourContainer>
                </Col>
              </Row>
              <Row>
                <Col xs={5}>
                  <OpeningHourContainer>
                    <h3>Thursday</h3>
                    <div className="input-container">
                      <div>
                        <Label>From</Label>
                        <TimePicker
                          id="thursday-from"
                          value={
                            openingHours[0].thursdayFrom
                              ? moment(openingHours[0].thursdayFrom, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                thursdayFrom: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>To</Label>
                        <TimePicker
                          id="thursday-to"
                          value={
                            openingHours[0].thursdayTo
                              ? moment(openingHours[0].thursdayTo, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                thursdayTo: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>Rule</Label>
                        <SelectStyle>
                          <Select
                            value={getDayOfWeekRuleValue(4) as any}
                            onChange={(v: any) => updateDayOfWeekRule(4, v ? v.value : null)}
                            options={allRules.map((x) => ({
                              label: x.name,
                              value: x.id,
                            }))}
                            placeholder="Off"
                            clearable
                          />
                        </SelectStyle>
                      </div>
                    </div>
                    {!isOHInputValid(openingHours[0].thursdayFrom, openingHours[0].thursdayTo) &&
                      getOHErrorMsg()}
                  </OpeningHourContainer>
                </Col>
                <Col xs={5} xsOffset={1}>
                  <OpeningHourContainer>
                    <h3>Friday</h3>
                    <div className="input-container">
                      <div>
                        <Label>From</Label>
                        <TimePicker
                          id="friday-from"
                          value={
                            openingHours[0].fridayFrom
                              ? moment(openingHours[0].fridayFrom, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                fridayFrom: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>To</Label>
                        <TimePicker
                          id="friday-to"
                          value={
                            openingHours[0].fridayTo
                              ? moment(openingHours[0].fridayTo, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                fridayTo: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>Rule</Label>
                        <SelectStyle>
                          <Select
                            value={getDayOfWeekRuleValue(5) as any}
                            onChange={(v: any) => updateDayOfWeekRule(5, v ? v.value : null)}
                            options={allRules.map((x) => ({
                              label: x.name,
                              value: x.id,
                            }))}
                            placeholder="Off"
                            clearable
                          />
                        </SelectStyle>
                      </div>
                    </div>
                    {!isOHInputValid(openingHours[0].fridayFrom, openingHours[0].fridayTo) &&
                      getOHErrorMsg()}
                  </OpeningHourContainer>
                </Col>
              </Row>
              <Row>
                <Col xs={5}>
                  <OpeningHourContainer>
                    <h3>Saturday</h3>
                    <div className="input-container">
                      <div>
                        <Label>From</Label>
                        <TimePicker
                          id="saturday-from"
                          value={
                            openingHours[0].saturdayFrom
                              ? moment(openingHours[0].saturdayFrom, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                saturdayFrom: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>To</Label>
                        <TimePicker
                          id="saturday-to"
                          value={
                            openingHours[0].saturdayTo
                              ? moment(openingHours[0].saturdayTo, 'HH:mm')
                              : undefined
                          }
                          minuteStep={15}
                          defaultOpenValue={moment('12:00', 'HH:mm')}
                          allowEmpty
                          showSecond={false}
                          onChange={(v: Moment.Moment | null) =>
                            setOpeningHours([
                              {
                                ...openingHours[0],
                                saturdayTo: v?.format('HH:mm') ?? null,
                              },
                            ])
                          }
                        />
                      </div>
                      <div>
                        <Label>Rule</Label>
                        <SelectStyle>
                          <Select
                            value={getDayOfWeekRuleValue(6) as any}
                            onChange={(v: any) => updateDayOfWeekRule(6, v ? v.value : null)}
                            options={allRules.map((x) => ({
                              label: x.name,
                              value: x.id,
                            }))}
                            placeholder="Off"
                            clearable
                          />
                        </SelectStyle>
                      </div>
                    </div>
                    {!isOHInputValid(openingHours[0].saturdayFrom, openingHours[0].saturdayTo) &&
                      getOHErrorMsg()}
                  </OpeningHourContainer>
                </Col>
              </Row>
            </Grid>
          </TabPanel>

          {/* ONLINE */}
          <TabPanel>
            <Grid fluid>
              <Row>
                <Col xs={5}>
                  <h3>Online bookings</h3>
                  <Row>
                    <Col xs={6}>
                      <Label>Seating duration</Label>
                      <SelectStyle>
                        <Select
                          simpleValue
                          clearable={false}
                          value={restaurant.onlineDurationMinutes}
                          onChange={(interval: any) =>
                            setRestaurant({
                              ...restaurant,
                              onlineDurationMinutes: parseInt(interval, 10),
                            })
                          }
                          options={seatingDurations}
                          placeholder="Online duration"
                        />
                      </SelectStyle>
                    </Col>
                    <Col xs={6}>
                      <Label>Booking time interval</Label>
                      <SelectStyle>
                        <Select
                          simpleValue
                          clearable={false}
                          value={restaurant.bookingIntervalMinutes}
                          onChange={(interval: any) =>
                            setRestaurant({
                              ...restaurant,
                              bookingIntervalMinutes: parseInt(interval, 10),
                            })
                          }
                          options={bookingIntervalsSuggestions}
                          placeholder="Booking interval"
                        />
                      </SelectStyle>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Label>Min online guests</Label>
                      <SelectStyle>
                        <Select
                          value={restaurant.onlineMinGuests || ''}
                          onChange={(g: any) =>
                            setRestaurant({
                              ...restaurant,
                              onlineMinGuests: g ? g.value : null,
                            })
                          }
                          options={maxGuestsSuggestions}
                          placeholder="Select min online guests"
                          clearable
                        />
                      </SelectStyle>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Label>Max online guests</Label>
                      <SelectStyle>
                        <Select
                          value={restaurant.onlineMaxGuests}
                          onChange={(interval: any) =>
                            setRestaurant({
                              ...restaurant,
                              onlineMaxGuests: interval.value,
                            })
                          }
                          options={maxGuestsSuggestions}
                          placeholder="Select max online guests"
                          clearable={false}
                        />
                      </SelectStyle>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <TextArea
                        title="Group bookings text"
                        value={restaurant.onlineMaxGuestsText || ''}
                        onChange={(text: any) =>
                          setRestaurant({
                            ...restaurant,
                            onlineMaxGuestsText: text,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={7}>
                      <h3>Menu selection online</h3>
                      <MenuMandatory>
                        <label>
                          <input
                            name="onlineMenuMandatory"
                            type="checkbox"
                            checked={restaurant.onlineMenuMandatory}
                            onChange={(val) =>
                              setRestaurant({
                                ...restaurant,
                                onlineMenuMandatory: val.target.checked,
                              })
                            }
                          />
                          <span>&nbsp;Menu select mandatory</span>
                        </label>
                      </MenuMandatory>
                    </Col>
                    <Col xs={5}>
                      <h3>Online cancellation</h3>
                      <MenuMandatory>
                        <label>
                          <input
                            name="onlineCancellationDisabled"
                            type="checkbox"
                            checked={restaurant.onlineCancellationDisabled}
                            onChange={(val) =>
                              setRestaurant({
                                ...restaurant,
                                onlineCancellationDisabled: val.target.checked,
                              })
                            }
                          />
                          <span>&nbsp;Disabled</span>
                        </label>
                      </MenuMandatory>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6} xsOffset={1}>
                  <h3>Reservation policy</h3>
                  <ChkPolicy isDisabled={!restaurant.requirePolicyAccept}>
                    <label id="chk-policy-accept" htmlFor="requirePolicyAccept">
                      <input
                        id="requirePolicyAccept"
                        name="requirePolicyAccept"
                        type="checkbox"
                        checked={restaurant.requirePolicyAccept}
                        onChange={(val) =>
                          setRestaurant({
                            ...restaurant,
                            requirePolicyAccept: val.target.checked,
                          })
                        }
                      />
                      <span>&nbsp;&nbsp;Enable reservation policy</span>
                    </label>
                    <br />
                    <br />
                    <div>
                      <TextArea
                        title="Reservation policy"
                        value={restaurant.policyText || ''}
                        rows={40}
                        onChange={(val) => setRestaurant({ ...restaurant, policyText: val })}
                      />
                    </div>
                  </ChkPolicy>
                </Col>
              </Row>
            </Grid>
          </TabPanel>

          {/* TEXTS */}
          <TabPanel>
            <Grid fluid className="text-fields">
              <Row>
                <Col xs={6}>
                  <h3>Restaurant texts</h3>
                  <TextArea
                    title="About"
                    value={restaurant.about}
                    onChange={(newValue) => setRestaurant({ ...restaurant, about: newValue })}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <TextArea
                    title="Info"
                    value={restaurant.info}
                    onChange={(newValue) => setRestaurant({ ...restaurant, info: newValue })}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <TextArea
                    title="Note"
                    value={restaurant.note || ''}
                    onChange={(newValue) => setRestaurant({ ...restaurant, note: newValue })}
                  />
                </Col>
              </Row>
            </Grid>
          </TabPanel>

          {/* PAYMENTS */}
          <TabPanel>
            <Grid fluid>
              <Row>
                <Col xs={2}>
                  <h3>No show fee</h3>
                  <Input
                    title="Amount per guest in ISK"
                    placeholder="No Fee"
                    type="number"
                    value={restaurant.noShowFee ? restaurant.noShowFee.toString() : ''}
                    onChange={(newValue) =>
                      setRestaurant({
                        ...restaurant,
                        noShowFee: newValue ? parseInt(newValue, 10) : null,
                      })
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </TabPanel>

          {/* OTHER */}
          <TabPanel>
            <Grid fluid>
              <Row>
                <Col xs={5}>
                  <h3>Booking constraints</h3>
                  <CheckboxNew
                    id="is-booking-name-mandatory"
                    onChange={() =>
                      setRestaurant({
                        ...restaurant,
                        preferences: {
                          ...restaurant.preferences,
                          isBookingNameMandatory: !restaurant.preferences.isBookingNameMandatory,
                        },
                      })
                    }
                    checked={restaurant.preferences.isBookingNameMandatory}
                    title="Customer name mandatory"
                  />

                  <CheckboxNew
                    id="is-booked-by-name-mandatory"
                    onChange={() =>
                      setRestaurant({
                        ...restaurant,
                        preferences: {
                          ...restaurant.preferences,
                          isBookedByNameMandatory: !restaurant.preferences.isBookedByNameMandatory,
                        },
                      })
                    }
                    checked={restaurant.preferences.isBookedByNameMandatory}
                    title="Employee name mandatory"
                  />
                </Col>
                <Col xs={5}>
                  <h3>Satisfaction survey</h3>
                  <Label>
                    Satisfaction surveys will be sent at 10AM (GMT) if a booking has the status
                    &quot;Completed&quot;
                  </Label>

                  <CheckboxNew
                    id="sendSurveys"
                    onChange={() =>
                      setRestaurant({
                        ...restaurant,
                        sendSurveys: !restaurant.sendSurveys,
                      })
                    }
                    checked={restaurant.sendSurveys}
                    title="Send surveys?"
                  />
                </Col>
              </Row>
              <br />
              <br />
              <br />
              <br />
              <Row>
                <Col xs={5}>
                  <h3>Google Analytics</h3>
                  <Input
                    key="rest-ga-tracking-id"
                    title="GA Tracking ID"
                    placeholder="GA Tracking ID"
                    type="text"
                    value={restaurant.gaTrackingId || undefined}
                    onChange={(newValue) =>
                      setRestaurant({ ...restaurant, gaTrackingId: newValue })
                    }
                  />
                </Col>
                <Col xs={5}>
                  <h3>Facebook Pixel</h3>
                  <Input
                    key="rest-fb-pixel-id"
                    title="Pixel ID"
                    placeholder="Pixel ID"
                    type="text"
                    value={restaurant.fbPixelId || undefined}
                    onChange={(newValue) => setRestaurant({ ...restaurant, fbPixelId: newValue })}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <br />
              <br />
              <Row>
                <Col xs={6}>
                  <h3>SMS notification</h3>
                  <SmsContainer>
                    <CheckboxNew
                      id="smsConfirmation"
                      onChange={() =>
                        setRestaurant({
                          ...restaurant,
                          smsConfirmation: !restaurant.smsConfirmation,
                        })
                      }
                      checked={restaurant.smsConfirmation}
                      title="Send confirmation to customers when they book a table"
                    />
                    {renderSmsRemindersJsx()}
                  </SmsContainer>
                </Col>

                <Col xs={4}>
                  <h3>Email notification</h3>
                  <p>Notify when a web booking is created/cancelled</p>
                  <div>
                    <Label>User role</Label>
                    <SelectStyle>
                      <Select
                        simpleValue
                        value={restaurant.emailNotificationRecipient}
                        onChange={(recipientType: any) =>
                          setRestaurant({
                            ...restaurant,
                            emailNotificationRecipient:
                              recipientType || RestaurantAPINS.EmailNotificationRecipientType.None,
                          })
                        }
                        options={[
                          {
                            value: RestaurantAPINS.EmailNotificationRecipientType.None,
                            label: 'Off',
                          },
                          {
                            value: RestaurantAPINS.EmailNotificationRecipientType.Manager,
                            label: 'Manager',
                          },
                          {
                            value: RestaurantAPINS.EmailNotificationRecipientType.Staff,
                            label: 'Staff',
                          },
                          {
                            value: RestaurantAPINS.EmailNotificationRecipientType.Everyone,
                            label: 'Everyone',
                          },
                        ]}
                        placeholder="Notifications off"
                      />
                    </SelectStyle>
                  </div>
                </Col>
              </Row>
            </Grid>
          </TabPanel>

          {/* IMAGES */}
          {store.RestaurantStore.user!.role === UserRole.Admin && (
            <TabPanel>
              <Grid fluid>
                <Row>
                  <Col xs={11}>
                    <h3>Upload images</h3>
                    {images.map((img) => {
                      const typeName =
                        getAllRestaurantImageTypes().find((imgType) => imgType.id === img.type)
                          ?.name ?? '';

                      return (
                        <Input
                          key={`img-id-${img.id}`}
                          title={typeName}
                          placeholder={typeName}
                          type="text"
                          value={img.url}
                          onChange={(value) => updateImages(value, img.id)}
                          isRequired
                          isValid={img.url.length > 10}
                        />
                      );
                    })}
                  </Col>
                </Row>
              </Grid>
            </TabPanel>
          )}
        </Tabs>
      </TabsStyle>
    </Style>
  );
};

export default inject('store')(Restaurant);

const nowStr: string = moment().format('YYYY-MM-DDTHH:mm');
const restaurantInitial: RestaurantAPINS.IRestaurantReservableModel = {
  id: -1,
  name: '',
  email: '',
  ssn: '',
  phone: '',
  website: 'http://',
  about: '',
  note: '',
  info: '',
  priceCategoryId: 1,
  onlineDurationMinutes: 60,
  onlineMinGuests: null,
  onlineMaxGuests: 10,
  onlineMaxGuestsText: '',
  onlineMenuMandatory: false,
  onlineCancellationDisabled: false,
  bookingIntervalMinutes: 30,
  isPublished: false,
  smsConfirmation: false,
  smsReminder: 0,
  noShowFee: null,
  policyText: '',
  requirePolicyAccept: false,
  emailNotificationRecipient: RestaurantAPINS.EmailNotificationRecipientType.None,
  preferences: {
    isBookingNameMandatory: false,
    isBookedByNameMandatory: false,
  },
  slug: '',
  gaTrackingId: null,
  fbPixelId: null,
  sendSurveys: false,
  type: RestaurantAPINS.RestaurantType.Restaurant,
  cancellationCutoffMinutes: null,
  created: nowStr,
  updated: nowStr,
  isDeleted: false,
  redirectUrl: '',
  country: 'is',
  vatNumber: '',
};

const imagesInitial = [
  {
    id: -1,
    publicId: '',
    restaurantId: -1,
    type: RestaurantAPINS.RestaurantImageType.Cover,
    url: '',
  },
  {
    id: -2,
    publicId: '',
    restaurantId: -1,
    type: RestaurantAPINS.RestaurantImageType.Logo,
    url: '',
  },
  {
    id: -3,
    publicId: '',
    restaurantId: -1,
    type: RestaurantAPINS.RestaurantImageType.Misc,
    url: '',
  },
  {
    id: -4,
    publicId: '',
    restaurantId: -1,
    type: RestaurantAPINS.RestaurantImageType.Misc,
    url: '',
  },
  {
    id: -5,
    publicId: '',
    restaurantId: -1,
    type: RestaurantAPINS.RestaurantImageType.Misc,
    url: '',
  },
  {
    id: -6,
    publicId: '',
    restaurantId: -1,
    type: RestaurantAPINS.RestaurantImageType.Misc,
    url: '',
  },
  {
    id: -7,
    publicId: '',
    restaurantId: -1,
    type: RestaurantAPINS.RestaurantImageType.ThermalPrinterLogo,
    url: '',
  },
];

const locationInitial: RestaurantAPINS.RestaurantLocationModel[] = [
  {
    id: -1,
    address: '',
    cityId: 1,
    lat: null,
    lon: null,
    phone: '+354',
    restaurantId: -1,
    timeZone: 'Atlantic/Reykjavik',
  },
];

const ohInitial: RestaurantAPINS.RestaurantOpeningHoursModel[] = [
  {
    id: -1,
    restaurantLocationId: -1,
    fridayFrom: null,
    fridayTo: null,
    mondayFrom: null,
    mondayTo: null,
    saturdayFrom: null,
    saturdayTo: null,
    sundayFrom: null,
    sundayTo: null,
    thursdayFrom: null,
    thursdayTo: null,
    tuesdayFrom: null,
    tuesdayTo: null,
    wednesdayFrom: null,
    wednesdayTo: null,
  },
];

const seatingDurations = [
  { value: 15, label: '15 mins' },
  { value: 30, label: '30 mins' },
  { value: 45, label: '45 mins' },
  { value: 60, label: '1 hr' },
  { value: 75, label: '1 ¼ hrs' },
  { value: 90, label: '1 ½ hrs' },
  { value: 105, label: '1 ¾ hrs' },
  { value: 120, label: '2 hrs' },
  { value: 135, label: '2 ¼ hrs' },
  { value: 150, label: '2 ½ hrs' },
  { value: 165, label: '2 ¾ hrs' },
  { value: 180, label: '3 hrs' },
  { value: 195, label: '3 ¼ hrs' },
  { value: 210, label: '3 ½ hrs' },
  { value: 225, label: '3 ¾ hrs' },
];

const bookingIntervalsSuggestions = [
  { value: 15, label: '¼ hr' },
  { value: 30, label: '½ hr' },
  { value: 60, label: '1 hr' },
  { value: 90, label: '1 ½ hrs' },
  { value: 120, label: '2 hrs' },
  { value: 150, label: '2 ½ hrs' },
  { value: 180, label: '3 hrs' },
];

const maxGuestsSuggestions: Array<{ label: string; value: number }> = Array(30)
  .fill(null)
  .map((_: any, i: number) => {
    const val = i + 1;
    return { label: val.toString(), value: val };
  });
