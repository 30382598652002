import moment from 'moment-timezone';
import * as Moment from 'moment-timezone';
import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { inject } from 'mobx-react';
import RuleAPI, { getAllRules } from '../../api/rule.api';
import Button from '../../components/common/Button';
import { IRootStore } from '../../stores/RootStore';
import { FlashMessageType } from '../../stores/UIStore';
import theme from '../../styles/theme';

const TableStyle = styled.table`
  width: 100%;
  font-size: 12px;
  border-spacing: 0px;

  thead tr th {
    text-align: left;
    font-weight: 500;
  }
  th,
  td {
    height: 40px;
    padding: 0px 24px 0px 8px;

    &:last-child {
      padding-right: 8px;
    }
  }

  tbody {
    tr {
      cursor: pointer;
    }
    tr:nth-child(even) {
      &,
      &::after {
        background-color: ${theme.colors.neutral1};
      }
    }
    i.delete-rule {
      outline: none;
      &:hover {
        color: ${theme.colors.danger};
      }
    }
  }
`;

type Rule = {
  id: number;
  name: string;
  criteriaCount: number;
  nextUseDate: Moment.Moment;
};

interface IProps extends RouteComponentProps<any> {
  store?: IRootStore;
}

interface IState {
  rules: Rule[];
}

@inject('store')
class RulesList extends Component<IProps, IState> {
  ruleApi: RuleAPI;
  constructor(props: IProps) {
    super(props);

    this.state = {
      rules: [],
    };

    this.ruleApi = new RuleAPI();
  }

  componentDidMount() {
    getAllRules().then((r) => {
      this.setState({
        rules: r.data
          ? r.data.map((x: any) => ({
              id: x.id,
              name: x.name,
              criteriaCount: x.criteriaCount,
              nextUseDate: moment(x.nextUseDate),
            }))
          : [],
      });
    });
  }

  renderUseDate(nextUseDate: Moment.Moment) {
    if (!nextUseDate.isValid()) {
      return 'Not in use';
    } else if (nextUseDate.isSame(moment(), 'day')) {
      return 'Today';
    }
    return nextUseDate.format('DD MMM YYYY');
  }

  render() {
    return (
      <div>
        <h1>Rules</h1>
        <Button
          id="btn-new-rule"
          onClick={() => this.props.history.push(`/rule/create`)}
          className="btn-cta"
        >
          Create new
        </Button>
        <sub>Here you can define restrictions and settings for online bookings.</sub>

        <Grid fluid>
          <Row>
            <Col xs={8}>
              <TableStyle>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Criteria Count</th>
                    <th>Next Use Date</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.state.rules.map((x) => (
                    <tr
                      key={x.id}
                      onClick={(e: any) => {
                        if (
                          e.target &&
                          e.target.classList &&
                          e.target.classList.contains('delete-rule')
                        ) {
                          return;
                        }
                        this.props.history.push(`/rule/edit/${x.id}`);
                      }}
                    >
                      <td>{x.name}</td>
                      <td>{x.criteriaCount}</td>
                      <td>{this.renderUseDate(x.nextUseDate)}</td>
                      <td>
                        <i
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            this.ruleApi
                              .deleteRule(x.id)
                              .then((res) => {
                                if (!confirm('Are you sure you want to delete this rule?')) {
                                  return;
                                }
                                const { ruleId } = res.data.value;
                                const rules = [...this.state.rules].filter(
                                  (rule) => rule.id !== ruleId,
                                );
                                this.setState({
                                  rules,
                                });
                                this.props.store!.UIStore.addFlashMessage({
                                  type: FlashMessageType.Success,
                                  text: 'Successfully deleted rule.',
                                });
                              })
                              .catch(() => {
                                this.props.store!.UIStore.addFlashMessage({
                                  type: FlashMessageType.Error,
                                  text: 'Error deleting rule.',
                                });
                              });
                          }}
                          className="material-icons delete-rule"
                        >
                          close
                        </i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableStyle>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(RulesList);
