export const ratioToPercentage = (ratio: number): string => {
  if (ratio < 0 || ratio > 1) {
    return '';
  }

  return `${ratio * 100}%`;
};

export const ratioOfExistingFields = (obj: Record<string, any> | null): number => {
  if (!obj) {
    return 0;
  }

  const noOfNonEmptyFields = Object.values(obj).reduce((acc, value) => (value ? acc + 1 : acc), 0);
  const noOfFields = Object.values(obj).length;

  return noOfNonEmptyFields / noOfFields;
};
