import axios, { CancelTokenSource } from 'axios';
import moment from 'moment-timezone';
import * as Moment from 'moment-timezone';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';
import ReportAPI, { BookingStatistics } from '../../api/report.api';
import Button from '../../components/common/Button';
import Datepicker from '../../components/common/Datepicker';
import TabsStyle from '../../components/Tabs.styled';
import { getAllStatuses } from '../../utils';
import SatisfactionAnswers from './Satisfaction';

const Style = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1024px;
  padding: 16px;
`;

const StatisticsStyle = styled.div`
  .archive-statistics-header {
    position: sticky;
    top: 0px;
    margin: 0px -16px 48px -16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 9px 20px -12px #cacaca;
    padding: 8px 16px;
    border-radius: 3px;

    background-color: #fff;
    z-index: 99999;

    .archive-statistics-guests-bookings-total {
      display: flex;
      font-size: 12px;

      > div:first-child {
        margin-right: 8px;
      }
    }

    .archive-statistics-period {
      display: flex;
      align-items: center;
      font-size: 12px;

      .archive-statistics-date-from,
      .archive-statistics-date-to {
        width: 112px;
        margin: 0 8px;
      }
      #archive-statistics-btn-update {
        width: 112px;
      }
    }
  }

  .archive-row {
    display: flex;
    > div {
      flex-basis: 50%;
      margin-bottom: 64px;
    }

    &.archive-row-full-width {
      > div {
        flex-basis: 100%;
      }
    }
  }
`;

const TooltipStyle = styled.div`
  background-color: #fff;
  border: 1px solid #e8e8e8;
  padding: 8px;

  .tooltip-style-label {
    margin-bottom: 8px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
  }
`;

const reportApi = new ReportAPI();

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#58508d', '#bc5090', '#003f5c'];

const RADIAN = Math.PI / 180;

const statusColors = getAllStatuses();

const renderCustomizedLabel: (obj: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  payload: any;
}) => React.ReactElement = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  let fill = '#FFF';
  if (payload.statusId) {
    fill = statusColors.find((sc) => sc.id === payload.statusId)!.contrastColor;
  }

  return (
    <text x={x} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Archive: FunctionComponent = () => {
  const [dateFrom, setDateFrom] = useState<Moment.Moment>(moment().startOf('month'));
  const [dateTo, setDateTo] = useState<Moment.Moment>(moment());
  const [isLoading, setIsLoading] = useState(true);

  const [statisticsData, setStatisticsData] = useState<BookingStatistics | boolean | null>(null);
  const fetchCancelToken = useRef<CancelTokenSource | null>(null);

  const fetchStatistics = (cancelToken: CancelTokenSource) => {
    reportApi
      .getBookingStatistics(dateFrom, dateTo, cancelToken)
      .then((res) => {
        setStatisticsData(res.data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        if (!axios.isCancel(e)) {
          throw new Error('Could not fetch booking statistics.');
        }
      });
  };

  useEffect(() => {
    fetchCancelToken.current = axios.CancelToken.source();
    fetchStatistics(fetchCancelToken.current);

    return () => {
      if (fetchCancelToken.current) {
        fetchCancelToken.current.cancel();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (statisticsData === null) {
    return <Style>Loading...</Style>;
  }

  return (
    <Style>
      <h1>Archive</h1>
      <sub>Useful statistics and other information</sub>

      <TabsStyle>
        <Tabs>
          <TabList>
            <Tab>Statistics</Tab>
            <Tab>Satisfaction</Tab>
          </TabList>

          <TabPanel>
            <StatisticsStyle>
              <div className="archive-statistics-header">
                <div className="archive-statistics-guests-bookings-total">
                  <div>
                    <b>Guests:</b>{' '}
                    {typeof statisticsData !== 'boolean' ? statisticsData.guestsTotal : 0}
                  </div>
                  <div>
                    <b>Bookings:</b>{' '}
                    {typeof statisticsData !== 'boolean' ? statisticsData.bookingsTotal : 0}
                  </div>
                </div>
                <div className="archive-statistics-period">
                  <b>Period:</b>
                  <Datepicker
                    value={dateFrom}
                    onChange={(val) => {
                      if (dateTo.isBefore(val)) {
                        setDateTo(val);
                      }
                      setDateFrom(val);
                    }}
                    showIcon={false}
                    className="archive-statistics-date-from"
                  />
                  <span>-</span>
                  <Datepicker
                    value={dateTo}
                    onChange={(val) => {
                      if (dateFrom.isAfter(val)) {
                        setDateFrom(val);
                      }
                      setDateTo(val);
                    }}
                    showIcon={false}
                    className="archive-statistics-date-to"
                  />
                  <Button
                    id="archive-statistics-btn-update"
                    onClick={() => {
                      setIsLoading(true);

                      if (fetchCancelToken.current) {
                        fetchCancelToken.current.cancel();
                      }

                      fetchCancelToken.current = axios.CancelToken.source();
                      fetchStatistics(fetchCancelToken.current);
                    }}
                    isLoading={isLoading}
                  >
                    Update
                  </Button>
                </div>
              </div>

              {typeof statisticsData !== 'boolean' ? (
                <>
                  <div className="archive-row" style={{ height: 500 }}>
                    <div>
                      <h3>Booking status</h3>
                      <ResponsiveContainer>
                        <PieChart>
                          <Legend wrapperStyle={{ fontSize: 12 }} verticalAlign="top" height={36} />
                          <Tooltip
                            content={({ active, payload }: { active: boolean; payload: any }) => {
                              if (active) {
                                return (
                                  <TooltipStyle>
                                    <div className="tooltip-style-label">{payload[0].name}</div>
                                    <div>
                                      <b>Bookings:</b> {payload[0].value}
                                    </div>
                                  </TooltipStyle>
                                );
                              }
                              return null;
                            }}
                          />
                          <Pie
                            isAnimationActive={false}
                            data={statisticsData.statusCount.map((x) => ({
                              name: x.metric,
                              value: x.count,
                              statusId: x.statusId,
                            }))}
                            labelLine={false}
                            dataKey="value"
                            label={renderCustomizedLabel as any}
                          >
                            {statisticsData.statusCount.map((entry, index) => {
                              const status = statusColors.find((x) => x.id === entry.statusId)!;
                              return <Cell key={`cell-status-${index}`} fill={status.color} />;
                            })}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    <div>
                      <h3>Booking origin</h3>
                      <ResponsiveContainer>
                        <PieChart>
                          <Legend wrapperStyle={{ fontSize: 12 }} verticalAlign="top" height={36} />
                          <Tooltip
                            content={({ active, payload }: { active: boolean; payload: any }) => {
                              if (active) {
                                return (
                                  <TooltipStyle>
                                    <div className="tooltip-style-label">{payload[0].name}</div>
                                    <div>
                                      <b>Bookings:</b> {payload[0].value}
                                    </div>
                                  </TooltipStyle>
                                );
                              }

                              return null;
                            }}
                          />
                          <Pie
                            isAnimationActive={false}
                            data={statisticsData.originCount.map((x) => ({
                              name: x.metric,
                              value: x.count,
                            }))}
                            labelLine={false}
                            fill="#8884d8"
                            dataKey="value"
                            label={renderCustomizedLabel as any}
                          >
                            {statisticsData.statusCount.map((entry, index) => (
                              <Cell
                                key={`cell-origin-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>

                  <div className="archive-row archive-row-full-width">
                    <div>
                      <h3>Guests per day</h3>
                      <ResponsiveContainer width="100%" height={400}>
                        <AreaChart
                          data={statisticsData.guestsPerDay.map((x) => ({
                            name: x.metric,
                            uv: x.count,
                          }))}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip
                            content={({
                              active,
                              payload,
                              label,
                            }: {
                              active: boolean;
                              payload: any;
                              label: string;
                            }) => {
                              if (active) {
                                return (
                                  <TooltipStyle>
                                    <div className="tooltip-style-label">{label}</div>
                                    <div>
                                      <b>Guests:</b> {payload[0].payload.uv}
                                    </div>
                                  </TooltipStyle>
                                );
                              }

                              return null;
                            }}
                          />
                          <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  </div>

                  <div className="archive-row">
                    <div>
                      <h3>Arrival times (bookings)</h3>
                      <BarChart
                        width={500}
                        height={300}
                        data={statisticsData.arrivalTimesBookings.map((x) => ({
                          name: x.metric,
                          uv: x.count,
                        }))}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip
                          content={({
                            active,
                            payload,
                            label,
                          }: {
                            active: boolean;
                            payload: any;
                            label: string;
                          }) => {
                            if (active) {
                              return (
                                <TooltipStyle>
                                  <div className="tooltip-style-label">{label}</div>
                                  <div>
                                    <b>Bookings:</b> {payload[0].payload.uv}
                                  </div>
                                </TooltipStyle>
                              );
                            }

                            return null;
                          }}
                        />
                        <Bar dataKey="uv" fill="#82ca9d" />
                      </BarChart>
                    </div>
                    <div>
                      <h3>Arrival times (guests)</h3>
                      <BarChart
                        width={500}
                        height={300}
                        data={statisticsData.arrivalTimesGuests.map((x) => ({
                          name: x.metric,
                          uv: x.count,
                        }))}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip
                          content={({
                            active,
                            payload,
                            label,
                          }: {
                            active: boolean;
                            payload: any;
                            label: string;
                          }) => {
                            if (active) {
                              return (
                                <TooltipStyle>
                                  <div className="tooltip-style-label">{label}</div>
                                  <div>
                                    <b>Guests:</b> {payload[0].payload.uv}
                                  </div>
                                </TooltipStyle>
                              );
                            }

                            return null;
                          }}
                        />
                        <Bar dataKey="uv" fill="#82ca9d" />
                      </BarChart>
                    </div>
                  </div>

                  <div className="archive-row">
                    <div>
                      <h3>Group sizes</h3>
                      <BarChart
                        width={500}
                        height={300}
                        data={statisticsData.groupSize.map((x) => ({
                          name: x.metric,
                          uv: x.count,
                        }))}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip
                          content={({
                            active,
                            payload,
                            label,
                          }: {
                            active: boolean;
                            payload: any;
                            label: string;
                          }) => {
                            if (active) {
                              return (
                                <TooltipStyle>
                                  <div className="tooltip-style-label">Group size: {label}</div>
                                  <div>
                                    <b>Bookings:</b> {payload[0].payload.uv}
                                  </div>
                                </TooltipStyle>
                              );
                            }

                            return null;
                          }}
                        />
                        <Bar dataKey="uv" fill="#8884d8" />
                      </BarChart>
                    </div>
                    <div>
                      <h3>Booking duration</h3>
                      <BarChart
                        width={500}
                        height={300}
                        data={statisticsData.durations.map((x) => ({
                          name: x.metric,
                          uv: x.count,
                        }))}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip
                          content={({
                            active,
                            payload,
                            label,
                          }: {
                            active: boolean;
                            payload: any;
                            label: string;
                          }) => {
                            if (active) {
                              return (
                                <TooltipStyle>
                                  <div className="tooltip-style-label">Duration: {label}</div>
                                  <div>
                                    <b>Bookings:</b> {payload[0].payload.uv}
                                  </div>
                                </TooltipStyle>
                              );
                            }

                            return null;
                          }}
                        />
                        <Bar dataKey="uv" fill="#8884d8" />
                      </BarChart>
                    </div>
                  </div>
                </>
              ) : (
                <div>No bookings</div>
              )}
            </StatisticsStyle>
          </TabPanel>
          <TabPanel>
            <SatisfactionAnswers />
          </TabPanel>
        </Tabs>
      </TabsStyle>
    </Style>
  );
};

export default Archive;
