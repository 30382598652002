import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from '../styles/theme';

type SProps = {
  width: number | null;
  compact: boolean;
  buttonType: string;
  icon: any;
  secondary: boolean;
  disabled: boolean;
  isLoading: boolean;
};

const ButtonStyle = styled.div`
  position: relative;
  display: inline-block;
  background: ${theme.colors.brand1};
  background: linear-gradient(180deg, ${theme.colors.brand1}, ${theme.colors.brand2});
  background-size: 200% 200%;
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  transition: all 0.1s ease-in-out;
  pointer-events: none;
  user-select: none;

  &:hover,
  & *:hover {
    cursor: pointer;
  }

  button {
    background-color: transparent;
    width: 100%;
    height: 40px;
    padding-top: 4px;
    padding-left: 32px;
    padding-right: 32px;
    color: #fff;
    font-weight: 300;
    border: none;
    box-sizing: border-box;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    border-radius: 3px;
    pointer-events: all;

    ${(props: SProps) =>
      props.width &&
      css`
        width: ${props.width}px;
      `};

    ${(props: SProps) =>
      props.compact &&
      css`
        height: 32px;
        padding-left: 16px;
        padding-right: 16px;
      `};

    &:active {
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
  }

  &.btn-cta {
    position: relative;
    top: -7px;
    left: 16px;
  }

  ${(props: SProps) =>
    props.buttonType === 'danger' &&
    css`
      background: ${theme.colors.danger} !important;
    `};

  ${(props: SProps) =>
    props.icon &&
    css`
      button {
        padding-left: 30px;
      }
      &::before {
        position: absolute;
        top: 9px;
        left: 10px;
        font-size: 20px;
        font-family: 'Material Icons';
        content: '${props.icon}';
      }
    `};

  /* Secondary */
  ${(props: SProps) =>
    props.secondary &&
    css`
      background: none;
      button {
        color: ${theme.colors.dark2};
        border: 1px solid ${theme.colors.neutral2};
      }
    `}

  /* Disabled */
  ${(props: SProps) =>
    props.disabled &&
    css`
      background: ${theme.colors.neutral4};
      &:hover {
        cursor: none;
        pointer-events: none;
      }
    `}

  /* Spinner */
  .spinner-container {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .spinner {
    animation: rotator 1.4s linear infinite;
    .path {
      stroke-dasharray: 187;
      stroke-dashoffset: 0;
      transform-origin: center;
      animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
    }
  }

  @keyframes colors {
    0% {
      stroke: #fff;
    }
    50% {
      stroke: #fff;
    }
    100% {
      stroke: #fff;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  ${(props: SProps) =>
    props.isLoading &&
    css`
      .spinner-container {
        opacity: 1;
        transition: opacity 0.2s ease-in;
      }
      button {
        opacity: 0;
        transition: opacity 0.2s ease-out;
      }
    `};
`;

interface IProps extends RouteComponentProps<any> {
  id?: string;
  onClick?: () => void;
  children: any;
  className?: string;
  to?: string | null;
  buttonType?: string;
  icon?: string | null;
  disabled?: boolean;
  secondary?: boolean;
  isLoading?: boolean;
  compact?: boolean;
  width?: number | null;
}

class Button extends Component<IProps> {
  static defaultProps = {
    id: '',
    className: '',
    to: null,
    buttonType: 'default',
    icon: null,
    disabled: false,
    secondary: false,
    isLoading: false,
    width: null,
    compact: false,
  };

  onClick() {
    if (this.props.disabled) {
      return;
    }

    const toPath = this.props.to;
    if (toPath) {
      this.props.history.push(toPath);
      return;
    } else if (this.props.onClick !== undefined) {
      this.props.onClick();
    }
  }

  render() {
    return (
      <ButtonStyle
        id={this.props.id}
        buttonType={this.props.buttonType!}
        icon={this.props.icon}
        className={`btn ${this.props.className}`}
        disabled={this.props.disabled! || this.props.isLoading!}
        secondary={this.props.secondary!}
        isLoading={this.props.isLoading!}
        compact={this.props.compact!}
        width={this.props.width!}
      >
        <div className="spinner-container">
          <svg
            className="spinner"
            width="32px"
            height="32px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="path"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
        </div>
        <button type="button" onClick={() => this.onClick()}>
          {this.props.children}
        </button>
      </ButtonStyle>
    );
  }
}

export default withRouter(Button);
