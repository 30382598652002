/* eslint-disable @typescript-eslint/ban-types */
import 'normalize.css';
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Layout from './Layout';
import PageNotFound from './pages/404';
import Login from './pages/Login';
import Logout from './pages/Logout';

type State = {};
type Props = {};

export default class App extends Component<Props, State> {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" exact render={() => <Login {...this.props} />} />
          <Route path="/logout" exact render={() => <Logout {...this.props} />} />
          <Route path="/" component={Layout} />
          <Route component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}
