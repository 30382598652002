import axios from 'axios';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import MenuAPI, { MenuListItem } from '../../api/menu.api';
import Button from '../../components/common/Button';
import { IRootStore } from '../../stores/RootStore';
import { FlashMessageType } from '../../stores/UIStore';

const ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background-color: #fff;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  padding: 0px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 8px;

  max-width: 512px;

  &:hover {
    background-color: #f2f2f2;
  }

  > i {
    color: #d9d9d9;
  }

  /* Name and description */
  > .m-name-and-desc {
    width: 200px;
    overflow: hidden;

    > div {
      display: flex;
      height: 16px;
      align-items: center;

      &:last-child {
        /* Description */
        font-size: 12px;
        color: #999;
        font-weight: 300;
      }
    }
  }

  > .m-is-published {
    font-size: 12px;
    font-weight: 300;
    color: #999;

    > div {
      display: flex;
      height: 16px;
      align-items: center;
    }
  }
`;

interface IMenusProps extends RouteComponentProps {
  store: IRootStore;
}

const Menus: FC<IMenusProps> = ({ store, history }) => {
  const menuAPI = useMemo(() => new MenuAPI(), []);
  const [menus, setMenus] = useState<MenuListItem[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    menuAPI
      .getRestaurantMenus(cancelToken)
      .then((res) => {
        setMenus(res.data.menus);
        setLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          store.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Error fetching menus.',
          });
          setLoading(false);
        }
      });

    return () => cancelToken.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h1>Menus</h1>
      <Button id="m-create" onClick={() => history.push('/menu/create')} className="btn-cta">
        Create new
      </Button>
      <sub>
        Here you can create menus and charge for them when customers are making a reservation
        online.
      </sub>

      {menus.map((m) => (
        <ListItemWrapper key={m.id} onClick={() => history.push(`/menu/${m.id}`)}>
          <div className="m-name-and-desc">
            <div>{m.name.length > 26 ? `${m.name.substr(0, 26)}...` : m.name}</div>
            <div>{m.floorPlanName}</div>
          </div>

          <div className="m-is-published">
            <div>{m.isPublished ? 'Published' : 'Unpublished'}</div>
          </div>

          <i className="material-icons">keyboard_arrow_right</i>
        </ListItemWrapper>
      ))}
    </>
  );
};

export default inject('store')(observer(Menus));
