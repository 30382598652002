import { isSameDay, isWithinInterval, parseISO } from 'date-fns';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import BookingStatusType from '../../enums/BookingStatusType';
import useInterval from '../../hooks/useInterval';

const Style = styled.div`
  display: flex;
  padding: 8px;
  margin: 8px;
  align-items: center;

  background-color: #fff;
  border: 1px solid #e8e8e8;

  span {
    margin-right: 8px;
  }
`;

const GuestsNowCounter = ({
  bookings,
  selectedDate,
}: {
  bookings: Array<{
    id: number;
    start: string;
    end: string;
    statusId: BookingStatusType;
    guests: number;
  }>;
  selectedDate: Date;
}) => {
  const [now, setNow] = useState(new Date());

  const formatted = useMemo(() => {
    return bookings.map((b) => ({
      ...b,
      start: parseISO(b.start),
      end: parseISO(b.end),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookings, now]);

  const total = useMemo(() => {
    return formatted
      .filter(
        (b) =>
          b.statusId !== BookingStatusType.Cancelled &&
          b.statusId !== BookingStatusType.Hold &&
          b.statusId !== BookingStatusType.NoShow &&
          b.statusId !== BookingStatusType.Completed,
      )
      .filter((b) =>
        isWithinInterval(now, {
          start: b.start,
          end: b.end,
        }),
      )
      .reduce((a, b) => a + b.guests, 0);
  }, [formatted, now]);

  useInterval(() => {
    setNow(new Date());
  }, 60000);

  if (!isSameDay(now, selectedDate)) {
    return null;
  }

  return (
    <Style>
      <span className="material-icons" style={{ fontSize: 18 }}>
        storefront
      </span>
      <div>
        Guests now: <b>{total}</b>
      </div>
    </Style>
  );
};

export default GuestsNowCounter;
