import React, { useContext } from 'react';
import { MenuTranslationType } from '../../../../api/translation.api';
import Input from '../../../../components/common/Input';
import Label from '../../../../components/common/Label';
import { MenuStateDispatchType, TranslationContext } from '../../Translator';
import FormSection from './FormSection';
import FormWrapper from './FormWrapper.styled';

const MenuForm = ({
  translationState: [menuState, setMenuState],
}: {
  translationState: MenuStateDispatchType;
}) => {
  const {
    currentLanguage: [language],
  } = useContext(TranslationContext);

  const updateField = <K extends keyof MenuTranslationType>(
    key: K,
    value: MenuTranslationType[K],
  ) => {
    const tempState = { ...menuState };
    tempState[language][key] = value;
    setMenuState(tempState);
  };

  return (
    <FormWrapper>
      <FormSection source={{ value: menuState.en.name }}>
        <Label>Name</Label>
        <Input
          id="menu-name"
          type="text"
          value={menuState[language].name}
          onChange={(value: string) => updateField('name', value)}
        />
      </FormSection>
      <FormSection source={{ value: menuState.en.shortDescription || '' }}>
        <Label>Short description (max 600 characters)</Label>
        <textarea
          value={menuState[language].shortDescription || ''}
          onChange={(event) =>
            updateField(
              'shortDescription',
              event.target.value.length > 600
                ? event.target.value.substr(0, 600)
                : event.target.value,
            )
          }
        />
      </FormSection>
    </FormWrapper>
  );
};

export default MenuForm;
