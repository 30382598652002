import React from 'react';
import styled, { css } from 'styled-components';

const CheckboxStyled = styled.button`
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 12px;
  background-color: transparent;
  border: 0px;
  padding: 0px;

  &:hover {
    cursor: pointer;
  }

  > input {
    display: none;
  }
  i {
    display: block;
    margin-right: 4px;
    font-size: 18px;
  }

  ${({ disabled }: { disabled: boolean }) =>
    disabled &&
    css`
      &:hover {
        cursor: not-allowed;
      }
    `};
`;

type Props = {
  id: string;
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
  title?: string;
  tabIndex?: number;
};

const CheckboxNew = ({ id, checked, onChange, title, tabIndex = 0, disabled = false }: Props) => (
  <CheckboxStyled
    id={`chk-${id}`}
    className="checkbox"
    role="button"
    htmlFor={title}
    disabled={disabled}
    onClick={(e: any) => {
      e.preventDefault();
      onChange();
    }}
    tabIndex={tabIndex}
  >
    <input id={title} name={title} type="checkbox" defaultChecked={checked} tabIndex={tabIndex} />
    {checked ? (
      <i className="material-icons">check_box</i>
    ) : (
      <i className="material-icons">check_box_outline_blank</i>
    )}
    <div>{title}</div>
  </CheckboxStyled>
);

export default CheckboxNew;
