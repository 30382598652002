import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import Categories from './Categories';
import PageListSelector from './PageListSelector';
import PageSelector from './PageSelector';
import Translator from './Translator';

const Translate = ({ match }: RouteComponentProps) => {
  return (
    <div className="container">
      <Switch>
        <Route path={match.url} component={PageSelector} exact />
        <Route
          path={[`${match.url}/menu`, `${match.url}/event`]}
          exact
          component={PageListSelector}
        />
        <Route
          path={[
            `${match.url}/menu/:menuId`,
            `${match.url}/event/:eventId`,
            `${match.url}/restaurant`,
          ]}
          exact
          component={Translator}
        />
        <Route path={`${match.url}/categories`} exact component={Categories} />
        <Route path="*">
          <div>404</div>
        </Route>
      </Switch>
    </div>
  );
};

export default Translate;
