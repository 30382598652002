import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import Menu from './Menu';
import Menus from './Menus';

const MenuRoutes: FC<RouteComponentProps> = ({ match }) => (
  <div className="container">
    <Switch>
      <Route path={match.url} component={Menus} exact />
      <Route path={`${match.url}/create`} component={Menu} exact />
      <Route path={`${match.url}/:id`} component={Menu} exact />
    </Switch>
  </div>
);

export default MenuRoutes;
