import React, { Component } from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import styled from 'styled-components';

import theme from '../../styles/theme';
import Form from './Form';

const SearchStyle = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;

  &,
  & * {
    box-shadow: none !important;
  }

  .Select-multi-value-wrapper {
    width: 100%;
  }
  .Select-clear-zone {
    display: flex;
    align-items: center;
  }

  .Select {
    height: 100%;
    width: 100%;
  }

  .Select-control {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    border-radius: 0px;

    .Select-value-label {
      display: flex;
      height: 100%;
      align-items: center;
    }

    .Select-input {
      height: 46px;

      > input {
        height: 100%;
        padding: 0px;
        margin-left: 5px;
      }
    }
  }

  .Select-menu-outer {
    max-height: 1000px;
  }

  .Select-menu {
    max-height: 380px;
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    display: flex;
    height: 100%;
    padding: 0px 16px;
    align-items: center;
    line-height: inherit;
  }
  .Select,
  .Select.is-pseudo-focused,
  .Select:focus,
  input:focus {
    border: none;
  }
  .Select.is-focused {
    outline: none;
  }
  .Select-control {
    border: none !important;
  }
  .Select-arrow-zone {
    display: ${(props: { showArrow: boolean }) => (props.showArrow ? 'flex' : 'none')};
    width: 48px;
    padding-right: 0px !important;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #ebebeb;
    background-color: #fff;
  }
  .Select-option.is-focused {
    background-color: ${theme.colors.neutral1};
  }
  .Select--multi {
    .Select-value {
      background-color: ${theme.colors.brand2} !important;
      border-color: ${theme.colors.brand1} !important;
      color: #fff !important;
    }
    .Select-value-icon {
      border-right: 1px solid ${theme.colors.brand1} !important;
    }
    .Select--multi .Select-value-icon:hover,
    .Select--multi .Select-value-icon:focus {
      background-color: ${theme.colors.danger} !important;
      color: #fff !important;
    }
  }
`;

type Props = {
  id: string;
  value: any;
  options: Array<{ value: any; label: string }>;
  onChange: (newValue: { value: any }) => void;
  icon?: any;
  searchable: boolean;
  clearable: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  simpleValue?: boolean;
  placeholder?: string;
};

export default class SelectWithStyles extends Component<Props> {
  static defaultProps = {
    searchable: true,
    clearable: false,
    autoFocus: false,
    disabled: false,
    simpleValue: false,
    placeholder: 'Choose',
  };

  render() {
    return (
      <Form>
        <SearchStyle showArrow={!this.props.icon}>
          <Select
            id={this.props.id}
            name={this.props.id}
            value={this.props.value}
            options={this.props.options}
            onChange={(newValue: any) => this.props.onChange(newValue)}
            placeholder={this.props.placeholder}
            clearable={this.props.clearable}
            searchable={this.props.searchable}
            autoFocus={this.props.autoFocus}
            disabled={this.props.disabled}
            simpleValue={this.props.simpleValue}
          />
        </SearchStyle>
        {this.props.icon && <div>{this.props.icon}</div>}
      </Form>
    );
  }
}
