import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  getEventProgressData,
  getMenuProgressData,
  PageListItemType,
} from '../../api/translation.api';
import ListItem from './components/ListItem';
import { ListWrapper } from './PageSelector';

const PageListSelector = ({ match, history }: RouteComponentProps) => {
  const [pageListItems, setPageListItems] = useState<PageListItemType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const type = match.path.includes('event') ? 'event' : match.path.includes('menu') ? 'menu' : null;

  useEffect(() => {
    if (!type) {
      return;
    }

    const cancelToken = Axios.CancelToken.source();

    switch (type) {
      case 'event':
        getEventProgressData(cancelToken)
          .then((res) => {
            setPageListItems(res.data);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            setError(true);
          });
        break;
      case 'menu':
        getMenuProgressData(cancelToken)
          .then((res) => {
            setPageListItems(res.data);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            setError(true);
          });
        break;
      default:
        setLoading(false);
        setError(true);
        break;
    }

    return () => cancelToken.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!type) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <h1>{type === 'event' ? 'Events' : 'Menus'}</h1>
      <sub>{`Select ${type === 'event' ? 'an event' : 'a menu'} to translate`}</sub>

      {error && <div>Something went wrong</div>}

      {!loading && !error ? (
        <ListWrapper>
          {pageListItems.map((item) => (
            <ListItem
              key={item.id}
              title={item.name}
              progressRatio={item.progressRatio}
              onClick={() => history.push(`${match.url}/${item.id}`)}
            />
          ))}
        </ListWrapper>
      ) : (
        <div>Loading..</div>
      )}
    </>
  );
};

export default PageListSelector;
