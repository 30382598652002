import * as firebase from 'firebase/app';
import 'firebase/auth';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { inject, observer } from 'mobx-react';
import UserAPI from '../api/user.api';
import LogoFork from '../assets/logo-fork.svg';
import Logo from '../assets/logo.svg';
import Button from '../components/Button';
import { IRootStore } from '../stores/RootStore';
import theme from '../styles/theme';

const LoginPageStyle = styled.div`
  position: relative;
  height: 100vh;
  background-color: ${theme.colors.dark1};
  color: #fff;

  .logo {
    margin: 8px;
  }

  .logo-fork {
    display: block;
    margin: 0 auto;
    margin-top: 40px;
  }

  .form-wrapper {
    display: flex;
    margin-top: 40px;

    & > div {
      max-width: 500px;
      text-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      display: flex;
      margin: 0 auto;
    }

    input {
      display: block;
      width: 304px;
      height: 40px;
      margin-bottom: 8px;
      padding: 8px;
      border: 0px;
      font-size: 12px;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset;
      }
      ::placeholder {
        color: ${theme.colors.dark2};
      }
    }

    button {
      width: 304px;
    }

    .msg-error {
      width: 304px;
      text-align: center;
      padding: 16px;
      color: ${theme.colors.danger};
    }
  }
`;

type State = {
  username: string;
  password: string;
  redirect: boolean;
  isLoading: boolean;
  errorMessage: string;
};

type Props = {
  store?: IRootStore;
};

@inject('store')
@observer
class Login extends Component<Props, State> {
  api: UserAPI;

  constructor(props: Props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      redirect: false,
      isLoading: false,
      errorMessage: '',
    };

    this.api = new UserAPI();
  }

  componentDidMount() {
    this.props.store!.RestaurantStore.setUser(null);
  }

  submitLogin() {
    this.setState({ isLoading: true, errorMessage: '' });

    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.username, this.state.password)
      .then(() => {
        this.setState({ redirect: true });
      })
      .catch((error: any) => {
        this.setState({ isLoading: false, errorMessage: error.message });
      });
  }

  handleKeyPress(e: any) {
    if (e.key === 'Enter') {
      this.submitLogin();
    }
  }

  render() {
    const { redirect, isLoading, errorMessage } = this.state;
    if (redirect) {
      return <Redirect to="/" />;
    }

    return (
      <LoginPageStyle>
        <object className="logo" data={Logo} type="image/svg+xml" aria-label="Dineout logo" />
        <object
          className="logo-fork"
          data={LogoFork}
          type="image/svg+xml"
          aria-label="Dineout fork"
        />
        <div className="form-wrapper">
          <div>
            <p>Please log in.</p>
            <input
              type="text"
              value={this.state.username}
              name="username"
              placeholder="Username"
              onChange={(e) => {
                const { value } = e.target;
                this.setState({
                  username: value,
                });
              }}
              onKeyPress={(e) => this.handleKeyPress(e)}
            />
            <input
              type="password"
              value={this.state.password}
              name="password"
              placeholder="Password"
              onChange={(e) => {
                const { value } = e.target;
                this.setState({
                  password: value,
                });
              }}
              onKeyPress={(e) => this.handleKeyPress(e)}
            />
            <Button onClick={() => this.submitLogin()} isLoading={isLoading} disabled={isLoading}>
              Login
            </Button>
            {errorMessage ? <div className="msg-error">{errorMessage}</div> : null}
          </div>
        </div>
      </LoginPageStyle>
    );
  }
}

export default Login;
