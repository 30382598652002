import {
  Card,
  CircularProgress,
  Divider,
  MenuItem,
  Select as MuiSelect,
  Typography,
} from '@material-ui/core';
import format from 'date-fns/format';
import React, { useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import AddWaitingTimesList from './AddWaitingTimesList';
import API from '../../api/api';
import { Check, KeyboardArrowRight } from '@material-ui/icons';
import { AxiosResponse } from 'axios';

enum OverrideType {
  None = 0,
  CloseOnlineBooking = 1,
  CloseRestaurant = 2,
  WaitingListOnly = 3,
  FullyBooked = 4,
}

type RuleModel = {
  id: number;
  name: string;
};

type WaitingListTimeModelAPIResponse = {
  id: number;
  restaurantId: number;
  dateTime: string;
};

export type WaitingListTimeModel = Omit<WaitingListTimeModelAPIResponse, 'dateTime'> & {
  dateTime: Date;
};

type DashboardSettingsModelAPIResponse = {
  overrideType: OverrideType | null;
  waitingListTimes: WaitingListTimeModelAPIResponse[];
};

type DashboardSettingsModel = Omit<DashboardSettingsModelAPIResponse, 'waitingListTimes'> & {
  waitingListTimes: WaitingListTimeModel[];
};

const api = new API();

const Settings = ({ selectedDate }: { selectedDate: Date }) => {
  const ref = useRef<any>();

  // State
  const isPopperOpenState = useState(false);
  const [, setIsPopperOpen] = isPopperOpenState;

  const selectedWaitingListTimesState = useState<WaitingListTimeModel[]>([]);
  const [selectedWaitingListTimes, setSelectedWaitingListTimes] = selectedWaitingListTimesState;

  // Query
  const overrideQueryKey = `/dashboard/override/${format(selectedDate, 'yyyy-MM-dd')}`;
  const ruleQueryKey = `dashboard/rule/${format(selectedDate, 'yyyy-MM-dd')}`;
  const queryClient = useQueryClient();

  const { isLoading: isOverrideLoading, data: overrideData } = useQuery<DashboardSettingsModel>(
    overrideQueryKey,
    async () => {
      const res: AxiosResponse<DashboardSettingsModelAPIResponse> = await api._get(
        overrideQueryKey,
      );

      const { data } = res;
      return {
        ...data,
        waitingListTimes: data.waitingListTimes.map((x) => ({
          ...x,
          dateTime: new Date(x.dateTime),
        })),
      };
    },
    {
      onSuccess: (data) => setSelectedWaitingListTimes([...data.waitingListTimes]),
    },
  );

  const { isLoading: isRuleLoading, data: ruleData } = useQuery<number | null>(ruleQueryKey);

  const { isLoading: isAllRulesLoading, data: allRulesData } =
    useQuery<RuleModel[]>('dashboard/rule');

  const { mutate: mutateOverride, isLoading: isMutationLoading } = useMutation(
    async (settings: DashboardSettingsModel) =>
      await api._post(overrideQueryKey, {
        ...settings,
        waitingListTimes: settings.waitingListTimes.map((x) => ({
          ...x,
          dateTime: format(x.dateTime, "yyyy-MM-dd'T'HH:mm"),
        })),
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(overrideQueryKey);
      },
    },
  );

  const { mutate: mutateRule, isLoading: isRuleMutationLoading } = useMutation(
    async (ruleId: number | null) =>
      await api._post(`/dashboard/rule/${format(selectedDate, 'yyyy-MM-dd')}/${ruleId}`, null),
    {
      onSettled: () => {
        queryClient.invalidateQueries(ruleQueryKey);
      },
    },
  );

  const doMutate = (overrideType?: OverrideType) => {
    let times = [...selectedWaitingListTimes];
    let ovrType: OverrideType | null = null;
    if (overrideType != null) {
      ovrType = OverrideType.None == overrideType ? null : overrideType;
    } else if (selectedWaitingListTimes.length > 0) {
      ovrType = null;
    } else {
      ovrType = overrideData?.overrideType ?? null;
    }

    if (overrideType === OverrideType.None) {
      times = [];
    }

    mutateOverride({
      overrideType: ovrType,
      waitingListTimes: times,
    });
  };

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <AddWaitingTimesList
        selectedDate={selectedDate}
        anchorRef={ref.current}
        isPopperOpenState={isPopperOpenState}
        selectedWaitingListTimesState={selectedWaitingListTimesState}
        doSave={(invalidateOnly) => {
          if (invalidateOnly) {
            queryClient.invalidateQueries(overrideQueryKey);
          } else {
            doMutate();
          }
        }}
      />

      <Card elevation={0} style={{ border: '1px solid #ebebeb' }}>
        <MuiSelect
          style={{ height: 40, minWidth: 144 }}
          value={overrideData?.overrideType ?? 0}
          onChange={(e) => {
            const val = parseInt(e.target.value, 10);

            if (val === -99) {
              e.preventDefault();
              setIsPopperOpen(true);

              return;
            }

            let msg = '';
            if (val === OverrideType.CloseOnlineBooking) {
              msg = 'This will block all online bookings including events. Confirm to continue.';
            } else if (val === OverrideType.CloseRestaurant) {
              msg = 'This will block all bookings including online and admin. Confirm to continue.';
            } else if (val === OverrideType.WaitingListOnly) {
              msg = 'Confirm waiting list only for this day';
            } else if (val === OverrideType.FullyBooked) {
              msg = 'Confirm fully booked for this day';
            } else {
              msg = 'Confirm removing override and waiting list times.';
            }

            if (confirm(msg)) {
              doMutate(val);
            }
          }}
          renderValue={() => {
            if (isOverrideLoading || isMutationLoading) {
              return (
                <div style={{ marginLeft: 8 }}>
                  <CircularProgress size={24} />
                </div>
              );
            }

            let text = '';
            switch (overrideData?.overrideType) {
              case OverrideType.CloseOnlineBooking: {
                text = 'Online Closed';
                break;
              }
              case OverrideType.CloseRestaurant: {
                text = 'Restaurant Closed';
                break;
              }
              case OverrideType.FullyBooked: {
                text = 'Fully Booked';
                break;
              }
              case OverrideType.WaitingListOnly: {
                text = 'Waiting List Only';
                break;
              }
              default: {
                text = 'No Override';
                break;
              }
            }

            if (!overrideData?.overrideType && selectedWaitingListTimes.length > 0) {
              text = `Waiting List (${selectedWaitingListTimes.length})`;
            }

            return <div style={{ fontSize: 12, marginLeft: 8 }}>{text}</div>;
          }}
          disableUnderline
        >
          <MenuItem value={OverrideType.None} style={{ fontSize: 12 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>No Override</div>
          </MenuItem>
          <Divider />
          {[
            { value: OverrideType.CloseOnlineBooking, label: 'Online Closed' },
            { value: OverrideType.CloseRestaurant, label: 'Restaurant Closed' },
            { value: OverrideType.FullyBooked, label: 'Fully Booked' },
          ].map((x) => (
            <MenuItem key={`override-item-${x.value}`} value={x.value} style={{ fontSize: 12 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>{x.label}</div>
                {overrideData?.overrideType === x.value && (
                  <Check
                    fontSize="small"
                    style={{
                      marginLeft: 8,
                    }}
                  />
                )}
              </div>
            </MenuItem>
          ))}
          <div style={{ height: 16 }} />
          <Divider />

          <Typography style={{ margin: 8 }} variant="caption">
            Waiting List
          </Typography>
          <MenuItem value={OverrideType.WaitingListOnly} style={{ fontSize: 12 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>All Day</div>
              {overrideData?.overrideType === OverrideType.WaitingListOnly && (
                <Check
                  fontSize="small"
                  style={{
                    marginLeft: 8,
                  }}
                />
              )}
            </div>
          </MenuItem>
          <MenuItem value={-99} style={{ fontSize: 12 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                Specific Times
                {overrideData &&
                  overrideData.overrideType == null &&
                  overrideData.waitingListTimes.length > 0 &&
                  ` (${overrideData?.waitingListTimes.length})`}
              </div>
              <KeyboardArrowRight
                fontSize="small"
                style={{
                  marginLeft: 8,
                }}
              />
            </div>
          </MenuItem>
        </MuiSelect>
      </Card>

      <div style={{ width: 8 }} />

      <Card elevation={0} style={{ border: '1px solid #ebebeb' }}>
        <MuiSelect
          value={ruleData ?? 0}
          style={{ height: 40, minWidth: 144 }}
          disableUnderline
          onChange={(e) => {
            mutateRule(
              Boolean(e.target.value) && parseInt(e.target.value, 10) > 0
                ? parseInt(e.target.value, 10)
                : null,
            );
          }}
          renderValue={() => {
            if (isRuleLoading || isRuleMutationLoading || isAllRulesLoading) {
              return (
                <div style={{ marginLeft: 8 }}>
                  <CircularProgress size={24} />
                </div>
              );
            }

            return (
              <div style={{ fontSize: 12, marginLeft: 8 }}>
                {allRulesData?.find((x) => x.id === ruleData)?.name ?? 'No Rule'}
              </div>
            );
          }}
        >
          <MenuItem value={0} style={{ fontSize: 12 }}>
            No Rule
          </MenuItem>
          <Divider />
          {allRulesData?.map((x) => (
            <MenuItem key={`rule-id-${x.id}`} value={x.id} style={{ fontSize: 12 }}>
              {x.name}
            </MenuItem>
          ))}
        </MuiSelect>
      </Card>
    </div>
  );
};

export default Settings;
