import moment from 'moment-timezone';
import * as Moment from 'moment-timezone';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

import theme from '../../styles/theme';
import Form from './Form';

const DatepickerStyle = styled.div`
  display: block;
  height: 100%;
  width: 100%;

  * {
    user-select: none;
  }

  > div:first-child {
    height: 100%;
    width: 100%;
  }

  .react-datepicker {
    height: 100%;
    border-color: 0px !important;
    border-radius: 0px !important;
  }
  .react-datepicker__header {
    background-color: ${theme.colors.neutral3};
  }
  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }
  .react-datepicker__input-container {
    display: flex;
    height: 100%;
  }
  .react-datepicker__close-icon {
    position: absolute;
    top: 6px;
    right: 61px;
    z-index: 1001;
    &::after {
      background: none;
      background-color: ${theme.colors.neutral2};
      color: ${theme.colors.dark1};
    }
  }
  .react-datepicker-popper {
    z-index: 99999999;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: ${theme.colors.brand1};
  }
  input {
    width: 100%;
    height: 100%;
    font-size: 12px;
    padding: 0px 16px;
    border: 0px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    padding: 0px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding: 8px 5px;
    text-align: center;
  }
  .react-datepicker__today-button {
    background-color: ${theme.colors.neutral3};
  }
`;

type Props = {
  value: Moment.Moment;
  timeIntervals: number;
  showTimeSelect: boolean;
  onChange: (newDate: Moment.Moment) => void;
  autoFocus: boolean;
  showIcon: boolean;
  className?: string;
};

export default class Datepicker extends Component<Props> {
  static defaultProps = {
    value: moment(),
    timeIntervals: undefined,
    showTimeSelect: false,
    autoFocus: false,
    showIcon: true,
    className: '',
  };

  render() {
    return (
      <Form className={this.props.className}>
        <DatepickerStyle className="datepicker">
          <DatePicker
            selected={this.props.value}
            onChange={(date: moment.Moment) => this.props.onChange(date)}
            dateFormat={this.props.showTimeSelect ? 'MMM D, YYYY HH:mm' : 'MMM D, YYYY'}
            timeFormat="HH:mm"
            showTimeSelect={this.props.showTimeSelect}
            shouldCloseOnSelect
            timeIntervals={this.props.timeIntervals}
            todayButton="Today"
            tabIndex={0}
            autoFocus={this.props.autoFocus}
          />
        </DatepickerStyle>
        {this.props.showIcon && (
          <div>
            <i className="material-icons">calendar_today</i>
          </div>
        )}
      </Form>
    );
  }
}
