import { createGlobalStyle } from 'styled-components';
import theme from './theme';

export default createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;
    * {
      box-sizing: border-box;
    }
  }
  body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    font-size: 14px;
    background-color: #FCFCFC;
    color: #1c1c1c;
    object {
      pointer-events: none;
    }
  }
  #app {
    height: 100%;
    width: 100%;
    & > div {
      height: 100%;
    }
  }
  main {
    height: 100%;
    padding: 0px;

    & > div {
      height: 100%;
    }
  }
  .container {
    max-width: 1024px;
    margin: 0 auto;
    padding: 16px 16px 80px 16px;
  }
  input, textarea {
    width: 100%;
    height: 48px;
    padding: 0px 16px;
    background: #ffffff;
    border: 1px solid #c1c1c1;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;

    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: ${theme.colors.neutral4};
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: ${theme.colors.neutral4};
      opacity: 1;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: ${theme.colors.neutral4};
      opacity: 1;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${theme.colors.neutral4};
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${theme.colors.neutral4};
    }

    ::placeholder {
      /* Most modern browsers support this now. */
      color: ${theme.colors.neutral4};
    }
  }
  textarea {
    padding-top: 16px;
  }
  .quill {
    border-radius: 3px;
    .ql-toolbar.ql-snow {
      border: 1px solid #c1c1c1;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    .ql-container.ql-snow {
      border: 1px solid #c1c1c1;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    .ql-editor {
      font-weight: 400;
      background-color: #FFF;
      font-size: 12px;

      &.ql-blank {
        &::before {
          color: ${theme.colors.neutral4};
          font-style: normal;
          letter-spacing: .3px;
        }
      }
    }
  }
  a {
    position: relative;
    font-size: 12px;
    text-decoration: none;
    color: #18191f;

    &.underlined::after {
      content: '';
      position: absolute;
      left: 0px;
      bottom: -3px;
      display: block;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(
        to right,
        #18191f 60%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: left bottom;
      background-size: 8px 1px;
      background-repeat: repeat-x;
    }
    &:hover {
      cursor: pointer;
    }
  }
  h1 {
    display: inline-block;
    margin: 25px 0px 0px -2px;
    min-width: 100px;
    min-height: 46px;
    font-size: 28px;
    font-weight: 300;
  }
  h1 + sub,
  h1 + .btn + sub {
    display: block;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 48px;
    line-height: 16px;
    color: #999;
  }
  h2 {
    font-size: 20px;
    font-weight: 500;
  }
  h3 {
    min-height: 18px;
    margin: 0px;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 600;
  }
  h4 {
    margin-bottom: 16px;
    margin-top: 16px;
    font-weight: 300;
    font-size: 16px;
  }
  button {
    &:hover {
      cursor: pointer;
    }
  }
  .container-fluid {
    /* react-flexbox-grid fix */
    padding: 0px;
  }

  /**
    * Tooltip Styles
    */

  /* Add this attribute to the element that needs a tooltip */
  [data-tooltip] {
    position: relative;
    cursor: pointer;
  }

  /* Hide the tooltip content by default */
  [data-tooltip]:before,
  [data-tooltip]:after {
    visibility: hidden;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    pointer-events: none;
    z-index: 99999999;
  }

  /* Position tooltip above the element */
  [data-tooltip]:before {
    position: absolute;
    bottom: 110%;
    left: 50%;
    margin-bottom: 5px;
    transform: translateX(-50%);
    padding: 7px;
    width: 160px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.95);
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    z-index: 99999999;
    font-size: 12px;
  }

  /* Triangle hack to make tooltip look like a speech bubble */
  [data-tooltip]:after {
    position: absolute;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    border-top: 5px solid #000;
    border-top: 5px solid hsla(0, 0%, 20%, 0.95);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: ' ';
    font-size: 0;
    line-height: 0;
    z-index: 99999999;
  }

  /* Show tooltip content on hover */
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    transition: all 0.3s ease-in;
    z-index: 99999999;
  }

  @keyframes din-fadein-1 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
