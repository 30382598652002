import React, { Component } from 'react';

export default class EventRoutes extends Component {
  render() {
    return (
      <div
        className="container"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <span className="material-icons" style={{ fontSize: 48, color: '#999' }}>
            tag_faces
          </span>
          <br />
          <h4 style={{ fontWeight: 'bold' }}>Page moved</h4>
          Events have been moved to Partner, our new and improved system.
          <br />
          Please visit{' '}
          <a
            href="https://partner.dineout.is/events"
            style={{ fontWeight: 'bold', fontSize: 14 }}
            target="_blank"
            rel="noreferrer"
          >
            https://partner.dineout.is/events
          </a>{' '}
          to set up and modify events.
          <br />
          You can log in with the same credentials you use here.
          <br />
        </div>
      </div>
    );
  }
}
