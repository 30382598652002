import axios, { CancelTokenSource } from 'axios';
import { inject, observer } from 'mobx-react';
import * as Moment from 'moment-timezone';
import moment from 'moment-timezone';
import Payment from 'payment';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import BookingAPI, { AvailabilityTableType } from '../../../api/booking.api';
import { RestaurantAPINS } from '../../../api/restaurant.api';
import { PaymentLinkDeliveryType, PaymentType } from '../../../enums/Payment';
import PrintBooking from '../../../pages/Booking/Print/PrintBooking';
import { IRootStore } from '../../../stores/RootStore';
import { FlashMessageType } from '../../../stores/UIStore';
import theme from '../../../styles/theme';
import Utils, { isEmailValid } from '../../../utils';
import SocketUtils from '../../../utils/socket';
import Booking from '../../Booking';
import Button from '../../common/Button';
import LoadingSpinner from '../../common/LoadingSpinner';
import Notify from './Notify';
import PaymentSender from './PaymentSender';

const Ldng = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Style = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 496px;

  @media screen and (min-width: 768px) {
    max-width: 1120px;
    width: calc(100vw - 32px);
    opacity: 0;
    animation: bkn-fade-in 333ms cubic-bezier(0.14, 1.24, 1, 1) forwards;

    @keyframes bkn-fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  .db-modal-content {
    flex-grow: 1;
  }

  .db-button-container {
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 0px;
    margin: 0px;
    padding: 0px;
    z-index: 1;

    @media print {
      display: none;
    }

    @media screen and (min-width: 768px) {
      position: relative;
      padding: 16px 32px 16px 32px;
      border-top: 1px solid #ebebeb;
      > div {
        width: 70%;
      }
    }

    > div {
      display: flex;
      align-items: center;

      > div {
        width: 100%;

        @media screen and (min-width: 768px) {
          &:not(:first-child) {
            margin-left: 16px;
          }
        }
      }
    }

    .db-btn-cancel-booking-container {
      display: none;

      @media screen and (min-width: 768px) {
        display: block;
        flex-basis: ${(100 / 3) * 2}%;
      }
    }

    button {
      border-radius: 0px;

      @media screen and (min-width: 768px) {
        border-radius: 3px;
      }

      &.db-btn-cancel-booking {
        background-color: transparent;
        color: ${theme.colors.danger};
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  height: 72px;
  padding: 0px 32px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  overflow: auto;

  font-style: normal;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 0.666px;
  text-transform: uppercase;

  @media print {
    display: none;
  }

  > div:first-child {
    display: flex;
    height: 100%;
    align-items: center;

    .db-header-title {
      font-weight: 600;
      padding-right: 32px;
      user-select: none;
    }

    .db-header-tabs {
      display: flex;
      height: 100%;
    }
  }

  i {
    display: none;
    color: #999999;

    @media screen and (min-width: 768px) {
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

const Tab = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0px 16px;
  padding-top: 3px; /* Compensate for the border */
  font-size: 12px;
  letter-spacing: normal;
  font-weight: 400;
  border-bottom: 3px solid #fff;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  ${(props: { active: boolean }) =>
    props.active &&
    css`
      border-color: #ccc;
    `}

  ${(props: { disabled: boolean }) =>
    props.disabled &&
    css`
      color: #ccc;
      &:hover {
        cursor: not-allowed;
      }
    `};
`;

const TabWrapper = styled.div`
  display: none;

  ${(props: { active: boolean }) =>
    props.active &&
    css`
      display: block;
    `}
`;

enum TabType {
  Details,
  Print,
  Notify,
  Payment,
}

export type MenuSelection = {
  id: number;
  name: string;
  paymentType: PaymentType;
  paymentAmount: number;
  count: number;
};

export enum BookingViewMode {
  Edit,
  New,
}

export type BookingModalStateType = {
  bookingId: number | null;
  start: Moment.Moment;
  guests: number;
  duration: number;
  bookingName: string;
  bookingEmail: string;
  bookingSpecialRequest: string;
  bookingPhone: string;
  bookingInternalNotes: string;
  bookedByName: string;
  sourceId: number;
  created: string | null;
  updated: string | null;
  expires: string | null;
  paymentLinkSentDate: string | null;
  eventId: number | null;
  eventName: string | null;
  menus: MenuSelection[];
  statusId: number;
  card: {
    number: string;
    expires: string;
    cvc: string;
  };
};

type Props = {
  store?: IRootStore;
  bookingId?: number;
  date: Moment.Moment;
  preselectedTime?: Moment.Moment;
  preselectedTableId?: number;
  isWalkIn: boolean;
  onSave: (newDate: Moment.Moment) => void;
};

type State = {
  restaurantCountry: 'is' | 'es' | 'nb';
  selectedTab: TabType;
  booking: BookingModalStateType;
  /* Floor plan */
  allTables: AvailabilityTableType[];
  /* Payment link */
  feePerGuest: number | null;
  hasPaymentKey: boolean;
  paymentLinkMinutes: number | null;
  paymentLinkSendType: PaymentLinkDeliveryType;
  paymentLinkType: PaymentType;
  /* Email/SMS */
  shouldSendEmail: boolean;
  shouldSendSMS: boolean;
  /* UI */
  viewMode: BookingViewMode;
  selectedTableIds: number[];
  tableAutoSuggestionDisabled: boolean;
  isSaving: boolean;
  preferences: RestaurantAPINS.RestaurantPreferencesModel;
};

@inject('store')
@observer
export default class BookingModal extends Component<Props, State> {
  static defaultProps = {
    date: moment(),
  };

  bookingAPI: BookingAPI;
  bookingFetchCancelToken?: CancelTokenSource;
  fetchSuggestedTablesCancelToken?: CancelTokenSource;
  cancelBookingCancelToken?: CancelTokenSource;
  noShowFeeCancelToken?: CancelTokenSource;
  preventCmdS: boolean;

  constructor(props: Props) {
    super(props);

    const {
      RestaurantStore: { restaurant },
    } = props.store!;

    // Set preselected time if needed
    let start;
    if (props.preselectedTime) {
      start = props.date.clone().set({
        h: props.preselectedTime.get('hours'),
        m: props.preselectedTime.get('minutes'),
        s: 0,
        ms: 0,
      });
    } else {
      const now = moment();
      const tempDate = props.date.clone();
      tempDate.set({ hour: now.hours(), minute: now.minutes(), second: 0 });
      start = new Utils().roundUpQuarter(tempDate);
    }

    const tableAutoSuggestionDisabled: boolean = !!props.preselectedTableId || !!props.bookingId;

    const viewMode =
      props.bookingId && props.bookingId > 0 ? BookingViewMode.Edit : BookingViewMode.New;

    const preferences = props.store!.RestaurantStore.restaurant!.preferences || {
      isBookedByNameMandatory: false,
      isBookingNameMandatory: false,
    };

    this.state = {
      restaurantCountry: restaurant?.country ?? 'is',
      selectedTab: TabType.Details,
      /* Floor plan */
      allTables: [],
      /* Payment link */
      feePerGuest: restaurant!.noShowFee,
      hasPaymentKey: restaurant!.hasPaymentKey,
      paymentLinkSendType: PaymentLinkDeliveryType.Email,
      paymentLinkMinutes: null,
      paymentLinkType: PaymentType.NoShow,

      /* Email/SMS */
      shouldSendEmail: true,
      shouldSendSMS: this.props.store!.RestaurantStore.restaurant!.smsConfirmation,

      /* Booking */
      booking: {
        bookingId: props.bookingId || null,
        statusId: 2,
        bookingName: props.isWalkIn ? '(walk in)' : '',
        bookingEmail: '',
        bookingSpecialRequest: '',
        bookingInternalNotes: '',
        bookingPhone: '',
        bookedByName: '',
        start,
        guests: 2,
        duration: restaurant!.onlineDurationMinutes,
        sourceId: 1,
        created: null,
        updated: null,
        expires: null,
        paymentLinkSentDate: null,
        eventId: null,
        eventName: null,
        menus: [],
        card: {
          number: '',
          expires: '',
          cvc: '',
        },
      },
      /* UI */
      viewMode,
      tableAutoSuggestionDisabled,
      selectedTableIds: props.preselectedTableId ? [props.preselectedTableId] : [],
      isSaving: false,
      preferences,
    };

    this.bookingAPI = new BookingAPI();

    this.onCmdS = this.onCmdS.bind(this);
    this.preventCmdS = true;
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onCmdS);

    if (this.state.viewMode === BookingViewMode.Edit) {
      /* EDIT */
      this.fetchBooking().then(() => {
        this.getNoShowFee();
        this.getFloorPlanWithAvailabilityAndSuggestions().then(() => (this.preventCmdS = false));
      });
    } else {
      /* NEW */
      this.getNoShowFee();
      this.getFloorPlanWithAvailabilityAndSuggestions().then(() => (this.preventCmdS = false));
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onCmdS);

    if (this.bookingFetchCancelToken) {
      this.bookingFetchCancelToken.cancel('Unmount: cancelled fetching booking.');
    }
    if (this.fetchSuggestedTablesCancelToken) {
      this.fetchSuggestedTablesCancelToken.cancel('Unmount: cancelled fetching tables.');
    }
    if (this.cancelBookingCancelToken) {
      this.cancelBookingCancelToken.cancel('Unmount: cancelled booking cancel');
    }
    if (this.noShowFeeCancelToken) {
      this.noShowFeeCancelToken.cancel('Unmount: no show fee request cancelled');
    }

    this.props.store!.UIStore.setHasUnsavedChanges(false);
  }

  getNoShowFee() {
    if (this.noShowFeeCancelToken) {
      this.noShowFeeCancelToken.cancel('No show fee request cancelled');
    }

    this.noShowFeeCancelToken = axios.CancelToken.source();
    this.bookingAPI
      .getNoShowFee(this.state.booking.start, this.state.booking.guests, this.noShowFeeCancelToken)
      .then((r) => {
        this.setState({
          feePerGuest: r.data,
        });
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          this.props.store!.UIStore.addFlashMessage({
            type: FlashMessageType.Error,
            text: 'Could not fetch no show fee.',
          });
        }
      });
  }

  isFormValid() {
    const { card, bookingEmail, bookingPhone, bookedByName, bookingName } = this.state.booking;
    const isCardEmptyOrValid: boolean =
      !(card.number || card.expires || card.cvc) ||
      (Payment.fns.validateCardNumber(card.number) &&
        Payment.fns.validateCardExpiry(card.expires) &&
        Payment.fns.validateCardCVC(card.cvc));

    let isBookedByNameValid = true;
    if (!this.props.isWalkIn && this.state.preferences.isBookedByNameMandatory) {
      isBookedByNameValid = !!(bookedByName && bookedByName.length > 1);
    }

    let isBookingNameValid = true;
    if (!this.props.isWalkIn && this.state.preferences.isBookingNameMandatory) {
      isBookingNameValid = !!(bookingName && bookingName.length > 1);
    }

    /**
     * Payment link validation
     */
    const { hasPaymentKey, feePerGuest, paymentLinkMinutes, paymentLinkSendType } = this.state;
    const paymentLinkSelected = paymentLinkMinutes !== null && paymentLinkMinutes >= 0;
    let isPaymentLinkEmptyOrValid = false;
    // Check if payment link info is correctly provided
    if (paymentLinkSelected && hasPaymentKey && feePerGuest && feePerGuest > 0) {
      if (paymentLinkSendType === PaymentLinkDeliveryType.Email) {
        isPaymentLinkEmptyOrValid = isEmailValid(bookingEmail);
      } else if (paymentLinkSendType === PaymentLinkDeliveryType.SMS) {
        isPaymentLinkEmptyOrValid = !!bookingPhone && bookingPhone.length > 5;
      }
    } else if (!paymentLinkSelected) {
      isPaymentLinkEmptyOrValid = true;
    }

    return (
      isCardEmptyOrValid && isPaymentLinkEmptyOrValid && isBookedByNameValid && isBookingNameValid
    );
  }

  setTab(selectedTab: TabType): void {
    this.setState({
      selectedTab,
    });
  }

  onCmdS(e: KeyboardEvent) {
    if (e.keyCode === 83 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      e.preventDefault();
      if (!this.state.isSaving && !this.preventCmdS && this.isFormValid()) {
        this.preventCmdS = true;
        this.saveBooking();
      }
    }
  }

  getFloorPlanWithAvailabilityAndSuggestions(): Promise<any> {
    this.preventCmdS = true;

    if (this.fetchSuggestedTablesCancelToken) {
      this.fetchSuggestedTablesCancelToken.cancel('Cancelled fetching suggested tables.');
    }
    this.fetchSuggestedTablesCancelToken = axios.CancelToken.source();

    return new Promise((resolve) => {
      this.bookingAPI
        .getFloorPlanWithAvailabilityAndSuggestions(
          this.state.booking.start,
          this.state.booking.duration,
          this.state.booking.guests,
          this.state.booking.bookingId,
          this.fetchSuggestedTablesCancelToken!,
        )
        .then((r) => {
          if (axios.isCancel(r)) {
            resolve(null);
            return;
          }

          this.setState(
            {
              allTables: r.data,
              selectedTableIds: !this.state.tableAutoSuggestionDisabled
                ? r.data.filter((x) => x.isSuggested).map((x) => x.id)
                : this.state.selectedTableIds,
            },
            () => {
              resolve(null);
            },
          );
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            this.props.store!.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Could not fetch tables.',
            });
          }
          resolve(null);
        });
    });
  }

  fetchBooking() {
    this.bookingFetchCancelToken = axios.CancelToken.source();

    return new Promise((resolve) => {
      this.bookingAPI
        .getBookingByIdV2(this.state.booking.bookingId!, this.bookingFetchCancelToken!)
        .then((r) => {
          if (axios.isCancel(r)) {
            resolve(null);
            return;
          }
          const { booking } = r.data;
          const bookingTables = booking.tables
            ? booking.tables.map((x: any) => x.floorPlanTableId)
            : [];
          const isWaitingList = booking.status === 12;

          this.setState(
            {
              booking: {
                bookingId: booking.id,
                bookingName: booking.bookingName,
                bookingEmail: booking.bookingEmail,
                bookingPhone: booking.bookingPhone,
                bookingSpecialRequest: booking.bookingSpecialRequest || '',
                bookingInternalNotes: booking.bookingInternalNotes || '',
                bookedByName: booking.bookedByName,
                statusId: booking.status,
                start: moment(booking.start),
                guests: booking.guests,
                duration: booking.duration,
                created: booking.created,
                updated: booking.updated,
                sourceId: booking.sourceId,
                expires: booking.expires,
                paymentLinkSentDate: booking.paymentLinkSentDate,
                eventId: booking.eventId,
                eventName: booking.eventName,
                menus: booking.menus,
                card: {
                  cvc: '',
                  number: '',
                  expires: '',
                },
              },
              selectedTableIds: bookingTables,
              shouldSendSMS: this.state.shouldSendSMS && !isWaitingList,
              shouldSendEmail: this.state.shouldSendEmail && !isWaitingList,
            },
            () => resolve(null),
          );
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            this.props.store!.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Could not fetch booking.',
            });
          }
          resolve(null);
        });
    });
  }

  book(allowDoubleBooking = false) {
    this.preventCmdS = true;

    this.setState({ isSaving: true }, () => {
      const tempCard = { ...this.state.booking.card };
      const newCard = new Utils().createCreditCard(tempCard);
      const data = {
        id: this.state.booking.bookingId,
        bookingName: this.state.booking.bookingName,
        bookingPhone: this.state.booking.bookingPhone,
        bookingEmail: this.state.booking.bookingEmail,
        bookingSpecialRequest: this.state.booking.bookingSpecialRequest,
        bookingInternalNotes: this.state.booking.bookingInternalNotes,
        bookedByName: this.state.booking.bookedByName,
        start: this.state.booking.start.format('YYYY-MM-DDTHH:mm'),
        guests: this.state.booking.guests,
        duration: this.state.booking.duration,
        tableIds: this.state.selectedTableIds,
        allowDoubleBooking,
        statusId: this.state.booking.statusId,
        card: newCard,
        paymentLinkMinutes: this.state.paymentLinkMinutes,
        paymentLinkSendType: this.state.paymentLinkSendType,
        noShowFee:
          this.state.paymentLinkType === PaymentType.NoShow ? this.state.feePerGuest : null,
        prePaymentFee:
          this.state.paymentLinkType === PaymentType.PrePayment ? this.state.feePerGuest : null,
        isWalkIn: this.props.isWalkIn,
        shouldSendEmail: this.state.shouldSendEmail,
        shouldSendSMS: this.state.shouldSendSMS,
      };

      if (this.state.viewMode === BookingViewMode.New) {
        this.bookingAPI
          .createQuickBooking(data)
          .then((r) => {
            const { statusId, message } = r.data.value;

            if (statusId === 1) {
              if (this.state.booking.start.isSame(moment(), 'day')) {
                this.invoke();
              }
              this.props.store!.UIStore.addFlashMessage({
                type: FlashMessageType.Success,
                text: message,
              });

              this.props.onSave(this.state.booking.start);
              this.props.store!.UIStore.closeModal();
              return;
            } else if (statusId === 2) {
              if (confirm(message)) {
                this.book(true);
                return; // Return and leave preventCmdS set to true
              }
            } else if (statusId === 3) {
              this.props.store!.UIStore.addFlashMessage({
                type: FlashMessageType.Error,
                text: message,
              });
            }

            this.setState({ isSaving: false });
            this.preventCmdS = false;
          })
          .catch((e) => {
            this.props.store!.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: (e.response && e.response.data) || 'Could not create booking.',
            });

            this.preventCmdS = false;
            this.setState({ isSaving: false });
          });
      } else if (this.state.booking.bookingId) {
        this.bookingAPI
          .updateBooking(this.state.booking.bookingId, data)
          .then((r) => {
            const { success, message } = r.data.value;
            if (success) {
              if (this.state.booking.start.isSame(moment(), 'day')) {
                this.invoke();
              }

              this.props.store!.UIStore.addFlashMessage({
                type: FlashMessageType.Success,
                text: message,
              });

              this.props.onSave(this.state.booking.start);
              this.props.store!.UIStore.closeModal();
              return;
            } else if (
              confirm('Table selection will result in double booking. Do you want to continue?')
            ) {
              this.book(true);
              return; // Return and leave preventCmdS set to true
            }

            this.setState({ isSaving: false });
            this.preventCmdS = false;
          })
          .catch(() => {
            this.props.store!.UIStore.addFlashMessage({
              type: FlashMessageType.Error,
              text: 'Could not update booking.',
            });

            this.setState({ isSaving: false });
            this.preventCmdS = false;
          });
      } else {
        this.props.store!.UIStore.addFlashMessage({
          type: FlashMessageType.Error,
          text: 'Error in booking. Please contact administrator.',
        });

        this.preventCmdS = false;
        this.setState({ isSaving: false });
      }
    });
  }

  saveBooking() {
    const isWaitingList = this.state.booking.statusId === 12;
    if (this.state.selectedTableIds.length > 0) {
      this.book();
    } else if (
      isWaitingList &&
      confirm('A waiting list booking will be created. Do you want to continue?')
    ) {
      this.book();
    } else if (confirm('No tables are selected. Do you want to continue?')) {
      this.book();
    } else {
      this.preventCmdS = false;
    }
  }

  cancelBooking() {
    if (this.cancelBookingCancelToken) {
      this.cancelBookingCancelToken.cancel();
    }

    this.cancelBookingCancelToken = axios.CancelToken.source();

    this.bookingAPI
      .updateBookingStatus(this.state.booking.bookingId!, 10, this.cancelBookingCancelToken)
      .then(() => {
        this.props.store!.UIStore.addFlashMessage({
          type: FlashMessageType.Success,
          text: 'Booking cancelled.',
        });
        this.props.onSave(this.state.booking.start);
        this.props.store!.UIStore.closeModal();
      })
      .catch(() => {
        this.props.store!.UIStore.addFlashMessage({
          type: FlashMessageType.Error,
          text: 'Error cancelling booking.',
        });
      });
  }

  invoke() {
    SocketUtils.invoke('BookingNotify');
  }

  render() {
    if (this.state.allTables.length === 0) {
      return (
        <Ldng>
          <LoadingSpinner />
        </Ldng>
      );
    }

    const { isWalkIn, bookingId } = this.props;
    const { selectedTab } = this.state;

    const isEditBooking = bookingId && bookingId > 0;
    let headerTitle = 'New booking';
    if (isWalkIn) {
      headerTitle = 'New walk-in';
    } else if (isEditBooking) {
      headerTitle = 'Edit booking';
    }

    return (
      <Style>
        <Header>
          <div>
            <div className="db-header-title">{headerTitle}</div>
            {isEditBooking && (
              <div className="db-header-tabs">
                <Tab
                  active={selectedTab === TabType.Details}
                  onClick={() => this.setTab(TabType.Details)}
                >
                  Details
                </Tab>
                {isEditBooking && (
                  <>
                    <Tab
                      disabled={this.state.booking.statusId === 12}
                      active={selectedTab === TabType.Notify}
                      onClick={() =>
                        this.state.booking.statusId !== 12 && this.setTab(TabType.Notify)
                      }
                    >
                      Notify
                    </Tab>
                    <Tab
                      active={selectedTab === TabType.Payment}
                      onClick={() => this.setTab(TabType.Payment)}
                    >
                      Payment
                    </Tab>
                    <Tab
                      active={selectedTab === TabType.Print}
                      onClick={() => this.setTab(TabType.Print)}
                    >
                      Print
                    </Tab>
                  </>
                )}
              </div>
            )}
          </div>
          <i className="material-icons" onClick={() => this.props.store!.UIStore.closeModal(true)}>
            close
          </i>
        </Header>
        <div className="db-modal-content">
          <TabWrapper active={selectedTab === TabType.Details}>
            <Booking
              restaurantCountry={this.state.restaurantCountry}
              booking={this.state.booking}
              selectedTableIds={this.state.selectedTableIds}
              allTables={this.state.allTables}
              viewMode={this.state.viewMode}
              isWalkIn={this.props.isWalkIn}
              isSaving={this.state.isSaving}
              /* Payment link */
              hasPaymentKey={this.state.hasPaymentKey}
              feePerGuest={this.state.feePerGuest}
              updateFeePerGuest={(fee: number | null) =>
                this.setState({
                  feePerGuest: fee,
                })
              }
              paymentLinkSendType={this.state.paymentLinkSendType}
              updatePaymentLinkSendType={(type: PaymentLinkDeliveryType) =>
                this.setState({
                  paymentLinkSendType: type,
                })
              }
              paymentLinkMinutes={this.state.paymentLinkMinutes}
              updatePaymentLinkMinutes={(minutes: number | null) =>
                this.setState({
                  paymentLinkMinutes: minutes,
                })
              }
              paymentLinkType={this.state.paymentLinkType}
              updatePaymentLinkType={(type) =>
                this.setState({
                  paymentLinkType: type,
                })
              }
              shouldSendSMS={this.state.shouldSendSMS}
              updateShouldSendSMS={(state) =>
                this.setState({
                  shouldSendSMS: state,
                })
              }
              shouldSendEmail={this.state.shouldSendEmail}
              updateShouldSendEmail={(state) =>
                this.setState({
                  shouldSendEmail: state,
                })
              }
              updateBooking={(updatedBooking: BookingModalStateType) => {
                // If start (date, time), guests or duration has changed, we refetch table availability and suggestions
                const startHasChanged =
                  updatedBooking.start.format('YYYY-MM-DDTHH:mm') !==
                  this.state.booking.start.format('YYYY-MM-DDTHH:mm');
                const guestsHasChanged = updatedBooking.guests !== this.state.booking.guests;
                const durationHasChanged = updatedBooking.duration !== this.state.booking.duration;

                this.setState({ booking: updatedBooking }, () => {
                  this.props.store!.UIStore.setHasUnsavedChanges(true);

                  if (startHasChanged || guestsHasChanged || durationHasChanged) {
                    this.getFloorPlanWithAvailabilityAndSuggestions().then(
                      () => (this.preventCmdS = false),
                    );
                    this.getNoShowFee();
                  }
                });
              }}
              onTableClick={(newSelectedTableIds: number[]) =>
                this.setState(
                  {
                    selectedTableIds: newSelectedTableIds,
                    tableAutoSuggestionDisabled: true,
                  },
                  () => this.props.store!.UIStore.setHasUnsavedChanges(true),
                )
              }
            />
          </TabWrapper>
          {this.state.viewMode === BookingViewMode.Edit && (
            <>
              <TabWrapper active={selectedTab === TabType.Notify}>
                <Notify
                  bookingId={bookingId!}
                  bookingEmail={this.state.booking.bookingEmail}
                  bookingPhone={this.state.booking.bookingPhone}
                />
              </TabWrapper>
              <TabWrapper active={selectedTab === TabType.Payment}>
                <PaymentSender
                  bookingId={bookingId!}
                  bookingEmail={this.state.booking.bookingEmail}
                  bookingPhone={this.state.booking.bookingPhone}
                  guests={this.state.booking.guests}
                  feePerGuest={this.props.store!.RestaurantStore.restaurant!.noShowFee || 0}
                />
              </TabWrapper>
              <TabWrapper active={selectedTab === TabType.Print}>
                <PrintBooking
                  booking={this.state.booking}
                  allTables={this.state.allTables}
                  selectedTableIds={this.state.selectedTableIds}
                />
              </TabWrapper>
            </>
          )}
        </div>
        <div className="db-button-container">
          <div>
            {this.state.viewMode === BookingViewMode.Edit && (
              <div className="db-btn-cancel-booking-container">
                <Button
                  id="db-btn-cancel-booking"
                  className="db-btn-cancel-booking"
                  secondary
                  onClick={() => {
                    if (confirm('Confirm booking cancellation')) {
                      this.cancelBooking();
                    }
                  }}
                  disabled={false}
                >
                  Cancel booking
                </Button>
              </div>
            )}
            <div>
              <Button
                id="db-btn-cancel"
                className="db-btn-cancel"
                secondary
                onClick={() => this.props.store!.UIStore.closeModal(true)}
                disabled={this.state.isSaving}
              >
                {this.state.viewMode === BookingViewMode.Edit ? 'Cancel changes' : 'Cancel'}
              </Button>
            </div>
            <div>
              <Button
                id="db-btn-save"
                className="db-btn-save"
                isLoading={this.state.isSaving}
                onClick={() => this.saveBooking()}
                disabled={!this.isFormValid() || this.state.isSaving}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Style>
    );
  }
}
