import { action, observable } from 'mobx';
import { RestaurantAPINS } from '../api/restaurant.api';

export enum UserRole {
  Admin = 'Admin',
  Manager = 'Manager',
  Staff = 'Staff',
}

export interface IUser {
  email: string;
  restaurantId: number | undefined;
  firebaseUId: string;
  role: UserRole;
  proxyRole: UserRole;
}

interface IRestaurant {
  id: number;
  name: string;
  onlineDurationMinutes: number;
  noShowFee: number | null;
  hasPaymentKey: boolean;
  smsConfirmation: boolean;
  preferences: RestaurantAPINS.RestaurantPreferencesModel | null;
  country: 'is' | 'es' | 'nb'; // TODO: change nb to no
  timeZone: string;
}

export default class RestaurantStore {
  @observable user: IUser | null;
  @observable restaurant: IRestaurant | null;

  constructor() {
    this.user = null;
    this.restaurant = null;
  }

  @action
  setUser(user: IUser | null) {
    if (user) {
      // If admin is proxying as a restaurant
      const usr: IUser = Object.assign({}, user);
      usr.proxyRole = usr.role;

      if (usr.role === UserRole.Admin && usr.restaurantId) {
        usr.proxyRole = UserRole.Manager;
      }

      this.user = usr;
    } else {
      this.user = null;
    }
  }

  @action
  setRestaurant(restaurant: IRestaurant | null) {
    this.restaurant = restaurant;
  }
}
