// TODO: tussuböggur síðan í gær
// TODO: ef gluggi er minnkaður og síðan item fært, þá er það weird

import * as React from 'react';
import styled, { css } from 'styled-components';

type STCProps = {
  yAxisLength: number;
  yAxisHeight: number;
  xAxisLength: number;
  xAxisWidth: number;
};

const TimelineContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  font-size: 1em;
  user-select: none;

  /* Spacing for top row */
  > div {
    position: relative;
    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: 1em;
    }
  }

  .group-label {
    display: flex;
    align-items: flex-end;
    padding-right: 3em;
    font-weight: bold;
  }

  .group-grid {
    display: grid;
    grid-template-rows: repeat(
      ${(props: STCProps) => props.yAxisLength},
      ${(props: STCProps) => props.yAxisHeight}px
    );

    > div {
      display: flex;
      align-items: center;
      padding-right: 8px;
    }
  }

  .time-interval-grid {
    position: sticky;
    top: 0px;
    background: #fcfcfc;
    z-index: 10;
    display: grid;
    grid-template-columns: repeat(${(props: STCProps) => props.xAxisLength}, 1fr);
    height: 2.5em;

    > div {
      position: relative;
      > div {
        position: absolute;
        left: -50%;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 1px;
        height: 0.5em;
        background: rgb(0, 0, 0);
      }
      &:nth-child(4n + 1)::after {
        height: 1em;
      }
    }
  }

  #item-grid {
    position: relative;
    display: grid;
    grid-template-rows: repeat(
      ${(props: STCProps) => props.yAxisLength},
      ${(props: STCProps) => props.yAxisHeight}px
    );
    grid-template-columns: repeat(${(props: STCProps) => props.xAxisLength}, 1fr);
    background: linear-gradient(90deg, #ccc 1px, transparent 1px),
      linear-gradient(0deg, #ccc 1px, transparent 1px);
    background-size: ${(props: STCProps) => props.xAxisWidth}px 1px,
      1px ${(props: STCProps) => props.yAxisHeight}px;
    background-position-x: left;
    background-position-y: top;

    > div:not(.destination-item) {
      pointer-events: all;
      > * {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }
    }

    .destination-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 9999999;
      background-color: rgba(255, 255, 255, 0.7);
      font-size: 11px;
      overflow: hidden;

      > div {
        display: flex;
        align-items: center;
        flex: 1;
        width: 100%;
        padding-left: 0.2em;

        &:first-child {
          flex-shrink: 0;
          &::before {
            content: 'access_time';
            font-family: 'Material Icons';
            margin-right: 4px;
            font-size: 14px;
          }
        }
        &:last-child {
          white-space: pre;
          &::before {
            content: 'event_seat';
            font-family: 'Material Icons';
            margin-right: 4px;
            font-size: 14px;
          }
        }
      }
    }
  }

  #hover-grid {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(
      ${(props: STCProps) => props.yAxisLength},
      ${(props: STCProps) => props.yAxisHeight}px
    );
    grid-template-columns: repeat(${(props: STCProps) => props.xAxisLength}, 1fr);
    pointer-events: none;

    #hover-item {
      position: relative;
      display: none;
      background-color: #f3f3f3;
      pointer-events: all;
      &:hover {
        cursor: pointer;
      }

      #hover-item-info {
        position: absolute;
        top: -${(props: STCProps) => props.yAxisHeight}px;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        white-space: nowrap;
        text-align: center;
        padding: 8px;
        background-color: #fff;
        box-shadow: 0px 0px 9px 1px #dedede;
        z-index: 100;
      }
    }
  }
`;

type SGISProps = {
  colStart: number;
  colEnd: number;
  row: number;
  bgColor: string;
  isBeingDragged: boolean;
  arrow: {
    left: boolean;
    right: boolean;
  };
  groupHeight: number;
};

const GridItemStyle = styled.div`
  position: relative;
  grid-column-start: ${(props: SGISProps) => props.colStart};
  grid-column-end: ${(props: SGISProps) => props.colEnd};
  grid-row-start: ${(props: SGISProps) => props.row};

  > div {
    background-color: ${(props: SGISProps) => props.bgColor};
  }

  ${(props: SGISProps) =>
    props.isBeingDragged &&
    css`
      transition: opacity 0.1s ease-in;
      opacity: 0;
    `};

  ${(props: SGISProps) =>
    props.arrow.left &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 1px;
        left: -8px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: ${props.groupHeight / 2 - 1}px 8px ${props.groupHeight / 2 - 1}px 0;
        border-color: transparent ${props.bgColor} transparent transparent;
        z-index: 1;
      }
      > div {
        border-left: 0px;
      }
    `};

  ${(props: SGISProps) =>
    props.arrow.right &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 1px;
        right: -8px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: ${props.groupHeight / 2 - 1}px 8px ${props.groupHeight / 2 - 1}px 0;
        border-color: transparent ${props.bgColor} transparent transparent;
        transform: rotate(180deg);
        z-index: 1;
      }
      > div {
        border-right: 0px;
      }
    `};
`;

type GridItem = {
  id: string;
  row: number;
  colStart: number;
  colEnd: number;
  colStartOffset: number;
  colEndOffset: number;
  bgColor: string;
  content: JSX.Element;
  isCurrentNode?: boolean;
  isBeingDragged: boolean;
};

type NodeItem = {
  isCurrentNode: boolean;
  item: any;
};

type Draggable = {
  nodes: NodeItem[];
  isMouseDown: boolean;
  hasBeenDragged: boolean;
  isIntersectingCombo: boolean;
  x: number;
  y: number;
  delta: {
    x: number;
    y: number;
  };
  scrollY: number;
  gridItems: GridItem[];
};

type HoverItemInfo = {
  row: number;
  col: number;
};

type TimeInterval = 15 | 30 | 60;

type GroupItem = {
  id: number;
  name: string;
};

type Item = {
  id: string;
  start: Date;
  end: Date;
  groupId: number;
  bgColor: string;
  content: JSX.Element;
};

type Props = {
  items: Item[];
  groups: GroupItem[];
  startTime: Date;
  endTime: Date;
  timeInterval: TimeInterval;
  groupLabel: string;
  groupHeight: number;
  onClick: (data: any) => void;
  onChange: (data: any) => Promise<any>;
  onHoverClick: (data: { group: GroupItem; time: Date }) => void;
};

type State = {
  gridItems: GridItem[];
  intervals: Date[];
  itemWidth: number;
};

export default class Timeline extends React.Component<Props, State> {
  static defaultProps = {
    items: [],
    startTime: new Date(new Date().setHours(10, 0, 0, 0)),
    endTime: new Date(new Date().setHours(22, 0, 0, 0)),
    timeInterval: 15,
    groupLabel: 'Groups',
    groupHeight: 40,
  };

  draggable: Draggable | null;
  hoverItemInfo: HoverItemInfo | null;

  constructor(props: Props) {
    super(props);

    const intervals = this.getTimeIntervals();

    this.draggable = null;
    this.hoverItemInfo = null;

    const { groups } = this.props;
    this.state = {
      gridItems: this.generateGridItems(groups, intervals),
      intervals,
      itemWidth: 0.0,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.updateGridItemWidth());
    window.addEventListener('mousemove', (e) => this.onMouseMove(e));
    window.addEventListener('mouseup', (e) => this.onMouseUp(e));
  }

  componentDidUpdate(prevProps: Props) {
    const { startTime, endTime, items, groupLabel, timeInterval, groupHeight, groups } = this.props;

    if (
      startTime !== prevProps.startTime ||
      endTime !== prevProps.endTime ||
      JSON.stringify(items) !== JSON.stringify(prevProps.items) ||
      groupLabel !== prevProps.groupLabel ||
      timeInterval !== prevProps.timeInterval ||
      groupHeight !== prevProps.groupHeight ||
      JSON.stringify(groups) !== JSON.stringify(prevProps.groups)
    ) {
      const intervals = this.getTimeIntervals();
      this.setState(
        {
          gridItems: this.generateGridItems(groups, intervals),
          intervals,
        },
        () => this.updateGridItemWidth(),
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateGridItemWidth);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  }

  onMouseDown(e: MouseEvent, itemId: string): void {
    const itemGrid: HTMLElement | null = document.getElementById('item-grid');
    const items = this.state.gridItems.slice().filter((x) => x.id === itemId);
    const currentNode: any = e.currentTarget;
    const dragNodes = document.getElementsByClassName(itemId);

    if (
      !itemGrid ||
      !items ||
      (items && items.length > 0 && items[0].colStartOffset < 0 && items[0].colEndOffset > 0)
    ) {
      return;
    }

    const { groupHeight } = this.props;
    const { top: gridTop } = itemGrid.getBoundingClientRect();
    const mouseRow = Math.ceil((e.clientY - gridTop) / groupHeight);

    items.forEach((x) => {
      if (x.row === mouseRow) {
        x.isCurrentNode = true;
      } else {
        x.isCurrentNode = false;
      }
    });

    const nodes = [];
    for (let i = 0; i < dragNodes.length; i++) {
      if (dragNodes[i].isSameNode(currentNode)) {
        nodes.push({
          isCurrentNode: true,
          item: dragNodes[i],
        });
      } else {
        nodes.push({
          isCurrentNode: false,
          item: dragNodes[i],
        });
      }
    }

    this.draggable = {
      nodes,
      isMouseDown: true,
      hasBeenDragged: false,
      isIntersectingCombo: false,
      x: 0,
      y: 0,
      delta: {
        x: 0,
        y: 0,
      },
      scrollY: window.scrollY,
      gridItems: items,
    };
  }

  onMouseUp(e: any): void {
    if (this.draggable && this.draggable.isMouseDown) {
      const { draggable } = this;
      const { intervals } = this.state;
      const oldGridItems = this.state.gridItems
        .slice()
        .map((x) => Object.assign({}, x, { isBeingDragged: false }));

      const rowAndCol = this.getDraggableRowAndCol(draggable);
      const { nodes, gridItems, hasBeenDragged, isIntersectingCombo } = draggable;
      const currentNode = nodes.find((x) => x.isCurrentNode === true);
      const tempItem = gridItems.find((x) => x.isCurrentNode === true);
      const itemId = tempItem ? tempItem.id : null;
      const itemGrid: HTMLElement | null = document.getElementById('item-grid');
      const destinationItems: any = document.getElementsByClassName('destination-item');

      if (!hasBeenDragged) {
        this.draggable = null;
        this.setState(
          {
            gridItems: this.state.gridItems.map((x) => ({
              ...x,
              isBeingDragged: false,
            })),
          },
          () => this.props.onClick(itemId),
        );
        return;
      }

      if (
        !itemGrid ||
        !destinationItems ||
        !(destinationItems.length > 0) ||
        !itemId ||
        !currentNode
      ) {
        return;
      }

      const {
        top: gridTop,
        left: gridLeft,
        bottom: gridBottom,
        right: gridRight,
      } = itemGrid.getBoundingClientRect();

      const isOutsideGrid =
        e.clientY < gridTop ||
        e.clientY > gridBottom ||
        e.clientX < gridLeft ||
        e.clientX > gridRight;

      let newGridItems: GridItem[] = [];
      if (!isOutsideGrid && !isIntersectingCombo) {
        // Mouse is inside grid -> update GridItem row/col in state
        newGridItems = this.state.gridItems.slice().map((x) => {
          const item = x;
          if (itemId === item.id) {
            item.row = item.isCurrentNode ? item.row + rowAndCol.deltaRow : item.row;
            item.isBeingDragged = false;
            item.isCurrentNode = false;
            if (item.colStartOffset + rowAndCol.deltaCol < 0 && item.colStartOffset !== 0) {
              item.colStart = 1;
              item.colStartOffset += rowAndCol.deltaCol;
            } else {
              item.colStart += item.colStartOffset + rowAndCol.deltaCol;
              item.colStartOffset = 0;
            }
            if (item.colEndOffset + rowAndCol.deltaCol > 0 && item.colEndOffset !== 0) {
              item.colEnd = intervals.length;
              item.colEndOffset += rowAndCol.deltaCol;
            } else {
              item.colEnd += item.colEndOffset + rowAndCol.deltaCol;
              item.colEndOffset = 0;
            }
          }
          return item;
        });
      } else {
        // Mouse is outside of grid -> discard changes and snap to initial position
        newGridItems = this.state.gridItems.filter((x) => x.isBeingDragged === true);
        if (
          !newGridItems ||
          !(newGridItems.length > 0) ||
          !destinationItems ||
          !(destinationItems.length > 0)
        ) {
          return;
        }

        // Revert changes for destination items
        newGridItems.forEach((x, i) => {
          destinationItems[i].style.gridRowStart = x.row.toString();
          destinationItems[i].style.gridColumnStart = x.colStart.toString();
          destinationItems[i].style.gridColumnEnd = x.colEnd.toString();
        });

        newGridItems = this.state.gridItems.slice().map((x) => {
          const item = x;
          if (itemId === item.id) {
            item.isBeingDragged = false;
          }
          return item;
        });
      }

      // Calculate new start/end and groups for items and pass to onChange props
      if (
        (rowAndCol.deltaCol !== 0 || rowAndCol.deltaRow !== 0) &&
        !isOutsideGrid &&
        !isIntersectingCombo
      ) {
        const { groups } = this.props;
        const filteredGridItems = newGridItems.filter((x) => x.id === itemId);
        const newGroups = filteredGridItems.map((x) => groups[x.row - 1]);
        const newStartTime = intervals[filteredGridItems[0].colStart - 1];
        const newEndTime = intervals[filteredGridItems[0].colEnd - 1];

        this.props
          .onChange({
            id: itemId,
            groups: newGroups,
            startTime: newStartTime,
            endTime: newEndTime,
          })
          .then((success: any) => {
            if (success) {
              // Update state with new col/row (or just set isBeingDragged to false)
              this.resetDestinationItemsAndCurrentNode(destinationItems, currentNode);
            } else {
              // Revert changes
              this.setState(
                {
                  gridItems: oldGridItems,
                },
                () => this.resetDestinationItemsAndCurrentNode(destinationItems, currentNode),
              );
            }
          });
      } else {
        // Revert changes
        this.setState(
          {
            gridItems: oldGridItems,
          },
          () => this.resetDestinationItemsAndCurrentNode(destinationItems, currentNode),
        );
      }
      this.draggable = null; // Reset draggable
    }
  }

  resetDestinationItemsAndCurrentNode(destinationItems: any, currentNode: any) {
    // Hide destination element
    const { length } = destinationItems;
    for (let i = 0; i < length; i++) {
      destinationItems[0].remove();
    }

    currentNode.item.style.transform = ''; // Reset grid item transform
  }

  onMouseMove(e: MouseEvent): void {
    const itemGrid: HTMLElement | null = document.getElementById('item-grid');
    const hoverItem: HTMLElement | null = document.getElementById('hover-item');
    if (!itemGrid || !hoverItem) {
      return;
    }

    const {
      top: gridTop,
      left: gridLeft,
      bottom: gridBottom,
      right: gridRight,
    } = itemGrid.getBoundingClientRect();
    const isMouseInGrid =
      e.clientY > gridTop &&
      e.clientY < gridBottom &&
      e.clientX > gridLeft &&
      e.clientX < gridRight;

    // Init hover item
    hoverItem.style.display = 'none';
    this.hoverItemInfo = null;

    if (!this.draggable) {
      /*
        Next two lines prevent the hover item to display
        if mouse is hovering over another element layered
        on top of the timeline.
      */
      const ev = e as any | null;
      const isHoveringOverTimeline =
        ev &&
        ev.target &&
        ev.target.id &&
        (ev.target.id === 'item-grid' || ev.target.id === 'hover-item');

      // Hover functionality
      if (isMouseInGrid && isHoveringOverTimeline) {
        const rect = itemGrid.getBoundingClientRect();
        const cellWidth = rect.width / this.state.intervals.length;
        const cellHeight = rect.height / this.props.groups.length;

        const yPos = e.clientY - rect.top;
        const row: number = Math.floor(yPos / cellHeight) + 1;
        const xPos = e.clientX - rect.left;
        const col: number = Math.floor(xPos / cellWidth) + 1;

        const { groups } = this.props;
        const { intervals } = this.state;
        const groupName = groups[row - 1].name;
        const time = intervals[col - 1];
        const minutesStr =
          time.getUTCMinutes() > 9 ? time.getUTCMinutes() : `0${time.getUTCMinutes()}`;
        const hoursStr = time.getUTCHours() > 9 ? time.getUTCHours() : `0${time.getUTCHours()}`;

        const hoverItemInfo: HTMLElement | null = document.getElementById('hover-item-info');
        if (hoverItemInfo) {
          hoverItemInfo.remove();
        }
        const hoverItemInfoDiv = document.createElement('div');
        hoverItemInfoDiv.id = 'hover-item-info';
        hoverItemInfoDiv.textContent = `${hoursStr}:${minutesStr} - ${groupName}`;
        hoverItem.appendChild(hoverItemInfoDiv);

        const intersectingLen = this.state.gridItems.filter(
          (x) => col >= x.colStart && col < x.colEnd && x.row === row,
        ).length;

        if (intersectingLen === 0) {
          hoverItem.style.display = 'block';
          hoverItem.style.position = 'relative';
          hoverItem.style.gridRow = row.toString();
          hoverItem.style.gridColumnStart = col.toString();
          hoverItem.style.gridColumnEnd = (col + 1).toString();

          this.hoverItemInfo = {
            row,
            col,
          };
        } else {
          hoverItem.style.display = 'none';
          this.hoverItemInfo = null;
        }
      } else {
        hoverItem.style.display = 'none';
        this.hoverItemInfo = null;
      }
      // Return cuz we don't want to execute dragging code
      return;
    } else if (!this.draggable.isMouseDown) {
      return;
    }

    const { nodes, scrollY } = this.draggable;
    const currentNode = nodes.find((x) => x.isCurrentNode);
    if (!currentNode) {
      return;
    }

    const {
      top: nodeTop,
      left: nodeLeft,
      bottom: nodeBottom,
      right: nodeRight,
    } = currentNode.item.getBoundingClientRect();

    const movementX: number = e.movementX || 0;
    let movementY: number = e.movementY || 0;

    const deltaYScroll = window.scrollY - scrollY;
    this.draggable.scrollY = window.scrollY;
    movementY += deltaYScroll;

    const { gridItems, hasBeenDragged, delta } = this.draggable;

    // If mouse is in grid
    if (isMouseInGrid) {
      // Mouse going up
      if (movementY < 0) {
        if (nodeTop + movementY >= gridTop) {
          // Allow changes if movement is more than gridTop
          delta.y += movementY;
        } else {
          // Calculate difference from gridTop and nodeTop and snap node to top
          delta.y += gridTop - nodeTop;
        }
      } else if (movementY > 0) {
        // Moving down
        if (nodeBottom + movementY <= gridBottom) {
          // Allow changes if movement is less than gridBottom
          delta.y += movementY;
        } else {
          delta.y += gridBottom - nodeBottom;
        }
      }

      // Moving left
      if (movementX < 0) {
        if (nodeLeft + movementX >= gridLeft) {
          delta.x += movementX;
        } else {
          delta.x += gridLeft - nodeLeft;
        }
      } else if (movementX > 0) {
        // Moving right
        if (nodeRight + movementX <= gridRight) {
          delta.x += movementX;
        } else {
          delta.x += gridRight - nodeRight;
        }
      }
    } else {
      // Find out where mouse is outside grid
      const outsideY = e.clientY < gridTop || e.clientY > gridBottom;
      const outsideX = e.clientX < gridLeft || e.clientX > gridRight;
      if (outsideY && outsideX) {
        return;
      }
      if (outsideY && nodeLeft + movementX >= gridLeft && nodeRight + movementX <= gridRight) {
        delta.x += movementX;
      }
      if (outsideX && nodeTop + movementY >= gridTop && nodeBottom + movementY <= gridBottom) {
        delta.y += movementY;
      }
    }

    const startDragIfNotStarted = Math.abs(delta.x) > 5 || Math.abs(delta.y) > 5;

    if (!hasBeenDragged && startDragIfNotStarted) {
      // Set destination items for the first time
      if (gridItems.length === 0 || !gridItems[0]) {
        throw Error('No items in grid');
      }

      const itemId = gridItems[0].id;
      this.setState({
        gridItems: this.state.gridItems.map((x) => {
          const item = x;
          if (item.id === itemId) {
            item.isBeingDragged = true;
          }
          return item;
        }),
      });

      const items = this.state.gridItems.slice().filter((x) => x.id === itemId);
      items.forEach(() => {
        const div = document.createElement('div');
        div.classList.add('destination-item');
        itemGrid.appendChild(div);
      });

      this.updateDestinationItem(true);

      this.draggable.hasBeenDragged = true; // Set this to prevent inserting draggable items again in each move
    }

    if (hasBeenDragged) {
      // Check if gridItem that is being dragged intersects other gridItems within same booking
      const rowAndCol = this.getDraggableRowAndCol(this.draggable);
      let isIntersectingCombo = false;
      const currentItem = gridItems.find((x) => x.isCurrentNode!);
      if (currentItem) {
        gridItems.forEach((x) => {
          if (!x.isCurrentNode && currentItem.row + rowAndCol.deltaRow === x.row) {
            isIntersectingCombo = true;
          }
        });
      }
      this.draggable.isIntersectingCombo = isIntersectingCombo;

      // Update position
      currentNode.item.style.transform = `translate(${delta.x}px, ${delta.y}px)`;
      this.updateDestinationItem(isMouseInGrid);
    }
  }

  getTimeIntervals(): Date[] {
    const { startTime, endTime, timeInterval } = Object.assign({}, this.props);

    // Reset seconds and milliseconds
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);
    endTime.setSeconds(0);
    endTime.setMilliseconds(0);

    const diff = Math.abs((startTime as any) - (endTime as any));
    const minutes = Math.floor(diff / 1000 / 60);
    const intervalCount = Math.ceil(minutes / timeInterval);

    function addMinutes(d: Date, mins: number): Date {
      return new Date(d.getTime() + mins * 60000);
    }

    const intervals = [];
    let iterDate = startTime;
    for (let i = 0; i < intervalCount; i++) {
      intervals.push(iterDate);
      iterDate = addMinutes(iterDate, timeInterval);
    }

    return intervals;
  }

  getDraggableRowAndCol(draggable: Draggable) {
    const { delta } = draggable;

    // Find node position in grid
    const left = delta.x;
    const top = delta.y;
    const { groupHeight } = this.props;
    const { itemWidth } = this.state;

    const deltaCol = Math.round(left / itemWidth);
    const deltaRow = Math.round(top / groupHeight);
    return {
      deltaCol,
      deltaRow,
    };
  }

  updateDestinationItem(isValidDrop: boolean): void {
    // Update position and style of destination items
    // Inject destination-item nodes into grid corresponding to current booking
    const itemGrid = document.getElementById('item-grid');
    const { draggable } = this;
    if (!draggable || !itemGrid) {
      return;
    }

    const { gridItems, isIntersectingCombo } = draggable;

    const rowAndCol = this.getDraggableRowAndCol(draggable);
    const { intervals } = this.state;
    const { groups, timeInterval } = this.props;

    const destinationItems: any = document.getElementsByClassName('destination-item');

    gridItems.forEach((x, i) => {
      const groupName = x.isCurrentNode
        ? groups[x.row + rowAndCol.deltaRow - 1].name
        : groups[x.row - 1].name;
      // Calculate time difference from startGridTime to bookingTime if there is colStartOffset
      let time: Date = intervals[x.colStart + rowAndCol.deltaCol - 1];
      if (x.colStartOffset < 0) {
        const minOffset = timeInterval * (x.colStartOffset + rowAndCol.deltaCol) * -1 * 60000;
        time = new Date(intervals[0].getTime() - minOffset);
      }
      const minutesStr =
        time.getUTCMinutes() > 9 ? time.getUTCMinutes() : `0${time.getUTCMinutes()}`;
      const hoursStr = time.getUTCHours() > 9 ? time.getUTCHours() : `0${time.getUTCHours()}`;

      destinationItems[i].style.display = 'flex';
      destinationItems[i].style.gridColumnEnd = (x.colEnd + rowAndCol.deltaCol).toString();
      destinationItems[i].style.border = `2px dotted #${
        isValidDrop && !isIntersectingCombo ? '9FDF71' : 'EF5350'
      }`;
      if (!x.isCurrentNode) {
        destinationItems[i].style.borderColor = '#f3f3f3';
      }
      destinationItems[i].style.zIndex = x.isCurrentNode ? '10' : '1';
      destinationItems[
        i
      ].innerHTML = `<div class='destination-time'>${hoursStr}:${minutesStr}</div><div>${groupName}</div>`;

      if (rowAndCol.deltaRow === 0) {
        destinationItems[i].style.gridRowStart = (x.row + rowAndCol.deltaRow).toString();
      } else if (Math.abs(rowAndCol.deltaRow) > 0 && x.isCurrentNode) {
        destinationItems[i].style.gridRowStart = (x.row + rowAndCol.deltaRow).toString();
      }

      // Handle item start offset if needed
      if (x.colStartOffset + rowAndCol.deltaCol < 0 && x.colStartOffset !== 0) {
        destinationItems[i].style.gridColumnStart = '1';
      } else {
        destinationItems[i].style.gridColumnStart = (
          x.colStartOffset +
          x.colStart +
          rowAndCol.deltaCol
        ).toString();
      }
      // Handle item end offset if needed
      if (x.colEndOffset + rowAndCol.deltaCol > 0 && x.colEndOffset !== 0) {
        destinationItems[i].style.gridColumnEnd = (intervals.length + 1).toString();
      } else {
        destinationItems[i].style.gridColumnEnd = (
          x.colEndOffset +
          x.colEnd +
          rowAndCol.deltaCol
        ).toString();
      }
    });
  }

  updateGridItemWidth(): void {
    const itemGrid: HTMLElement | null = document.getElementById('item-grid');
    if (!itemGrid) {
      return;
    }

    const width: string = window.getComputedStyle(itemGrid)!.width!.split('px')[0];

    this.setState({
      itemWidth: parseFloat(width) / this.state.intervals.length,
    });
  }

  generateGridItems(groups: GroupItem[], intervals: Date[]): GridItem[] {
    const { items, timeInterval, startTime, endTime } = Object.assign({}, this.props);

    // Remove items that have item.start >= endTime || item.end <= startTime
    const filteredItems = items.filter((x) => {
      // Reset seconds and milliseconds
      x.start.setSeconds(0);
      x.start.setMilliseconds(0);
      x.end.setSeconds(0);
      x.end.setMilliseconds(0);

      return !(x.start.getTime() >= endTime.getTime() || x.end.getTime() <= startTime.getTime());
    });

    // Loop through prop.items
    const gridItems: GridItem[] = [];

    filteredItems.forEach((item: Item) => {
      // Booking within grid
      const row = groups.findIndex((z) => z.id === item.groupId) + 1;
      if (row <= 0) {
        // If item contains a group id that doesn't exists, then we dismiss it to prevent misplacement in timeline
        return;
      }

      const minInterval = intervals[0];
      const maxInterval = intervals[intervals.length - 1];
      const isStartOutside = item.start.getTime() < minInterval.getTime();
      const isEndOutside = item.end.getTime() > maxInterval.getTime();

      function getIntervalIndex(s: Date, e: Date, ti: number): number {
        const d = Math.abs((s as any) - (e as any));
        const m = Math.floor(d / 1000 / 60);
        return Math.ceil(m / ti);
      }

      let colStart = 0;
      let colEnd = 0;
      let colStartOffset = 0;
      let colEndOffset = 0;
      // GridItem within grid
      if (!isStartOutside && !isEndOutside) {
        colStart = getIntervalIndex(minInterval, item.start, timeInterval) + 1;
        colEnd = colStart + getIntervalIndex(item.start, item.end, timeInterval);
      } else if (isStartOutside && isEndOutside) {
        // Item spans all columns
        colStartOffset = getIntervalIndex(item.start, minInterval, timeInterval) * -1;
        colEndOffset = getIntervalIndex(maxInterval, item.end, timeInterval);
        colStart = 1;
        colEnd = intervals.length + 1;
      } else if (isStartOutside) {
        // Calculate difference between item start and grid start to place item correctly on grid
        colStartOffset = getIntervalIndex(item.start, minInterval, timeInterval) * -1;
        colStart = 1;
        colEnd = colStart + getIntervalIndex(minInterval, item.end, timeInterval);
      } else if (isEndOutside) {
        // Calculate difference between item end and grid end to place item correctly on grid
        colEndOffset = getIntervalIndex(maxInterval, item.end, timeInterval);
        colEnd = intervals.length + 1;
        colStart = intervals.length - getIntervalIndex(item.start, maxInterval, timeInterval);
      }

      gridItems.push({
        id: item.id,
        row,
        colStart,
        colEnd,
        colStartOffset,
        colEndOffset,
        bgColor: item.bgColor,
        content: item.content,
        isBeingDragged: false,
      });
    });

    this.updateGridItemWidth();

    return gridItems;
  }

  render() {
    const { groupLabel, groupHeight } = this.props;
    const { intervals, gridItems, itemWidth } = this.state;

    const { groups } = this.props;
    if (!groups || groups.length === 0) {
      return null;
    }

    return (
      <TimelineContainer
        yAxisHeight={groupHeight}
        xAxisWidth={itemWidth}
        yAxisLength={groups.length}
        xAxisLength={intervals.length}
      >
        <div className="group-label">{groupLabel}</div>
        <div className="time-interval-grid">
          {intervals.map((x) => (
            <div key={`iv-${x.toString()}`}>
              {x.getUTCMinutes() === 0 ? (
                <div>{`${(x.getUTCHours() < 10 ? '0' : '') + x.getUTCHours()}:${
                  (x.getUTCMinutes() < 10 ? '0' : '') + x.getUTCMinutes()
                }`}</div>
              ) : (
                <div />
              )}
            </div>
          ))}
        </div>
        <div className="group-grid">
          {groups.map((x) => (
            <div key={`group-${x.id}`}>{x.name}</div>
          ))}
        </div>
        <div>
          <div id="item-grid">
            {gridItems.map((x, i) => (
              <GridItemStyle
                key={`item-${x.id}-${x.row}-${i}`} // eslint-disable-line
                className={x.id}
                row={x.row}
                colStart={x.colStart}
                colEnd={x.colEnd}
                groupHeight={groupHeight}
                arrow={{
                  left: x.colStartOffset !== 0,
                  right: x.colEndOffset !== 0,
                }}
                bgColor={x.bgColor}
                isBeingDragged={x.isBeingDragged}
                onMouseDown={(e: any) => this.onMouseDown(e, x.id)}
              >
                {x.content}
              </GridItemStyle>
            ))}
          </div>
          <div id="hover-grid">
            <div
              id="hover-item"
              role="button"
              tabIndex={0}
              onClick={() => {
                if (this.hoverItemInfo) {
                  // Get row and col
                  const { row, col } = this.hoverItemInfo;
                  // Get table id and time
                  const group = groups[row - 1];
                  const time = intervals[col - 1];
                  // Push history
                  this.props.onHoverClick({
                    group,
                    time,
                  });
                }
              }}
            >
              <div id="hover-item-info" />
            </div>
          </div>
        </div>
      </TimelineContainer>
    );
  }
}
