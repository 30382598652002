import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../stores/RootStore';
import { FlashMessageType } from '../../stores/UIStore';
import ErrorMessage from './Error';
import InfoMessage from './Info';
import SuccessMessage from './Success';
import WarningMessage from './Warning';

const FlashMessageContainer = styled.div`
  position: fixed;
  top: 96px;
  right: 0px;
  height: 100%;
  min-width: 280px;
  z-index: 999999999;
  pointer-events: none;

  > div {
    pointer-events: all;
  }

  ${(props: { noMessages: boolean }) =>
    props.noMessages &&
    css`
      right: -280px;
    `};
`;

type Props = {
  store?: IRootStore;
};

@inject('store')
@observer
export default class FlashMessage extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const messages = this.props.store!.UIStore.flashMessages.map((msg) => {
      switch (msg.type) {
        case FlashMessageType.Success:
          return <SuccessMessage text={msg.text} title={msg.title} key={msg.id} />;
        case FlashMessageType.Error:
          return <ErrorMessage text={msg.text} title={msg.title} key={msg.id} />;
        case FlashMessageType.Warning:
          return <WarningMessage text={msg.text} title={msg.title} key={msg.id} />;
        case FlashMessageType.Info:
          return <InfoMessage text={msg.text} title={msg.title} key={msg.id} />;
        default:
          return null;
      }
    });

    return <FlashMessageContainer noMessages={!messages.length}>{messages}</FlashMessageContainer>;
  }
}
