import React, { Component } from 'react';
import styled from 'styled-components';

import Button from './Button';
import TextArea from './TextArea';

import { inject, observer } from 'mobx-react';
import * as Moment from 'moment-timezone';
import RestaurantAPI from '../api/restaurant.api';
import { IRootStore } from '../stores/RootStore';
import { FlashMessageType } from '../stores/UIStore';

const Style = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 3px;

  textarea {
    width: 288px;
  }

  .btn-container {
    display: flex;
    align-items: center;

    .btn {
      flex-basis: 50%;
    }

    > a {
      display: block;
      flex-basis: 50%;
      text-align: center;
    }
  }
`;

type Props = {
  store?: IRootStore;
  date: Moment.Moment;
  onSave: (newNote: string) => void;
};

type State = {
  noteText: string;
  isLoading: boolean;
};

@inject('store')
@observer
export default class RestaurantNote extends Component<Props, State> {
  restaurantAPI: RestaurantAPI;
  constructor(props: Props) {
    super(props);

    this.restaurantAPI = new RestaurantAPI();

    this.state = {
      noteText: '',
      isLoading: true,
    };
  }

  componentDidMount() {
    const { UIStore } = this.props.store!;

    this.setState({
      isLoading: true,
    });

    this.restaurantAPI
      .getRestaurantNote(this.props.date)
      .then((res) => {
        const { data } = res;
        if (data) {
          this.setState({
            noteText: data,
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch(() => {
        UIStore.addFlashMessage({
          type: FlashMessageType.Error,
          text: 'Could not fetch note',
          timeout: 5000,
        });

        this.setState({
          isLoading: false,
        });
      });
  }

  updateNote() {
    const { UIStore } = this.props.store!;

    this.setState({
      isLoading: true,
    });

    this.restaurantAPI
      .updateRestaurantNote(this.props.date, this.state.noteText)
      .then(() => {
        this.props.onSave(this.state.noteText);
        UIStore.closeModal();
      })
      .catch(() => {
        UIStore.addFlashMessage({
          type: FlashMessageType.Error,
          text: 'Could not update note',
          timeout: 5000,
        });

        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <Style>
        <TextArea
          title={`Note for ${this.props.date.format('L')}`}
          value={this.state.noteText}
          placeholder={this.state.isLoading ? 'Loading...' : 'Type note here...'}
          onChange={(text) => this.setState({ noteText: text })}
        />
        <div className="btn-container">
          <a href="javascript:void(0)" onClick={() => this.props.store!.UIStore.closeModal()}>
            Cancel
          </a>
          <Button onClick={() => this.updateNote()} isLoading={this.state.isLoading}>
            {' '}
            Save
          </Button>
        </div>
      </Style>
    );
  }
}
